import React, { useEffect, useMemo } from 'react'
import { ViewHeader } from 'components/headers'
import Layout from 'components/Layout'
import { createCurrencyFormatter } from 'utils/currency'
import { isBanxware, isBanxy } from 'setup/tenant'
import { useQueryParams } from 'hooks'
import { ROUTES } from 'constants/routes'
import { useLanguage } from 'context/Language'
import { useLoanOffer } from 'context/LoanOffer'
import { useAuth } from 'context/Auth'
import { useNavigation } from 'routing/hooks/useNavigation'
import { BusinessDetails, LoanApplication } from 'types/loanApplication'
import { useReferralPartnerParam } from 'hooks/useReferralPartnerParam'
import { LoanPurpose } from '@banxware/offer-generation'
import { DefaultForm } from './components/DefaultForm'
import { Chooser } from './Chooser'

export const HomePageLoan = () => {
  const { locale } = useLanguage()
  const formatter = createCurrencyFormatter(locale)
  const {
    query: { email, revenue },
  } = useQueryParams()
  const { navigateTo } = useNavigation()
  const {
    applicationForm,
    voucherCode,
    discountValue,
    discountReason,
    discountType,
  } = useLoanOffer()
  const { token } = useAuth()
  const host = window.location.host
  useReferralPartnerParam()

  const currencyValidatorFactory = (message) => (value) =>
    value &&
    formatter.format(value).includes('NaN') &&
    Number.isNaN(formatter.revert(value))
      ? message
      : undefined

  const formatOnBlur = (value, validator) => {
    if (validator(value) || value === '') return undefined
    const backFormatted = formatter.revert(value)
    return formatter.format(backFormatted)
  }

  const initialValues: LoanApplication = useMemo(
    () => ({
      ...applicationForm,
      approximateSales:
        revenue?.toString() ||
        applicationForm?.approximateSales?.toString().slice(0, -2),
      businessDetails: {
        ...(applicationForm?.businessDetails || {}),
        address: {
          ...applicationForm?.address,
          country: 'DEU',
        },
      } as BusinessDetails,
      tenantSpecific: {
        ...(applicationForm?.tenantSpecific || {}),
        expectedSettlementAmount:
          applicationForm?.tenantSpecific?.expectedSettlementAmount
            ?.toString()
            .slice(0, -2),
        specialization: applicationForm?.tenantSpecific?.specialization,
        typeOfPractice: applicationForm?.tenantSpecific?.typeOfPractice,
        useCase: applicationForm?.tenantSpecific?.useCase,
        selectedLoanPurpose: (isBanxy ? 'Inventory' : undefined) as LoanPurpose,
      },
      industry: applicationForm?.tenantSpecific?.industryType,

      timeOfRunBusiness: applicationForm?.timeOfRunBusiness,
      voucher: voucherCode,
      discountType,
      discountValue,
      discountReason,
      ...(email ? { merchant: { email } } : {}),
    }),
    [
      email,
      revenue,
      voucherCode,
      applicationForm,
      discountReason,
      discountType,
      discountValue,
    ],
  )

  useEffect(() => {
    if (!token && host.includes('portal')) {
      navigateTo(ROUTES.LOGIN)
    }
  }, [host, navigateTo, token])

  if (isBanxy || isBanxware) {
    return <Chooser />
  }

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="businessLegal.header"
          subId="expressApplicationHomePage.subheading"
        />
      }
      withProgress
    >
      <Layout.MiddleAndRight>
        <DefaultForm
          formatter={formatter}
          formatOnBlur={formatOnBlur}
          currencyValidatorFactory={currencyValidatorFactory}
          initialValues={initialValues}
        />
      </Layout.MiddleAndRight>
    </Layout>
  )
}

export default HomePageLoan
