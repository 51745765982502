import React, { useState, CSSProperties, ReactElement } from 'react'
import fp from 'lodash/fp'
import { TextField, TextFieldProps, SxProps } from '@mui/material'
import { InputGrid, InputWrapper } from 'components/Input'
import { useHint, useFieldError } from 'hooks'
import { cleanFormikProps } from 'components/Form'
import { FieldProps } from 'formik'

type TextFieldComponentProps = FieldProps &
  TextFieldProps & {
    mb?: number
    inputContainerSx?: SxProps
    inputWrapperSx?: SxProps
    textFieldStyles?: CSSProperties
    hintContainerSx?: SxProps
    boxed?: boolean
    formatOnBlur?: (value: string) => string
    formatOnChange?: (value: string) => string
    hint: ReactElement
    label?: React.ReactNode
    loading?: boolean
  }

export const TextFieldComponent = (props: TextFieldComponentProps) => {
  const {
    field: { value, onBlur, onChange, ...restField },
    form: { touched, errors, submitCount },
    meta,
    hint,
    mb = 3,
    inputContainerSx = {},
    inputWrapperSx = {
      pr: 3,
    },
    textFieldStyles = { width: '100%' },
    hintContainerSx = {},
    formatOnChange = fp.identity,
    formatOnBlur = fp.identity,
    boxed,
    InputProps = {},
    ...restProps
  } = props

  const textFieldProps = cleanFormikProps(restProps)

  const [focus, setFocus] = useState(false)

  const [hasFieldError, fieldError] = useFieldError({
    touched,
    name: restField.name,
    submitCount,
    errors,
  })

  const { hintComponent, TriggerComponent } = useHint({
    hint,
    boxed,
    error: hasFieldError,
    errorId: fieldError,
    whiteSpace: 'pre-line',
  })

  return (
    <InputGrid mb={mb}>
      <InputGrid.Input sx={inputContainerSx}>
        <InputWrapper
          error={hasFieldError}
          sx={inputWrapperSx}
          boxed={boxed}
          focus={focus}
        >
          <TextField
            style={textFieldStyles}
            onBlur={(event) => {
              const intermediateValue = formatOnBlur(event.target.value)
              if (intermediateValue)
                onChange({
                  target: {
                    name: event.target.name,
                    value: intermediateValue,
                  },
                })
              onBlur(event)
              setFocus(false)
            }}
            onChange={(event) => {
              const intermediateValue = formatOnChange(event.target.value)
              onChange({
                target: {
                  name: event.target.name,
                  value: intermediateValue,
                },
              })
            }}
            onFocus={() => setFocus(true)}
            error={hasFieldError}
            {...restField}
            {...textFieldProps}
            value={value || ''}
            InputProps={{
              ...InputProps,
              startAdornment: (focus || !!value) && InputProps.startAdornment,
              endAdornment: (
                <>
                  {InputProps.endAdornment}
                  <TriggerComponent sx={{ ml: 2 }} />
                </>
              ),
              disableUnderline: true,
            }}
            InputLabelProps={{
              sx: { paddingRight: '40px', whiteSpace: 'normal' },
            }}
            variant="standard"
          />
        </InputWrapper>
      </InputGrid.Input>
      <InputGrid.Hint sx={hintContainerSx} boxed={boxed}>
        {hintComponent}
      </InputGrid.Hint>
    </InputGrid>
  )
}
