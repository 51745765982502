import React from 'react'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { useMenu } from 'hooks/useMenu'
import activeTenant from 'setup/tenant'
import { useLanguage } from 'context/Language'
import { XIcon, GlobeIcon } from './icons'

export const useLanguageMenu = () => {
  const { handleSwitchLanguage, locale } = useLanguage()

  const {
    TriggerComponent,
    Menu,
    MenuItem,
    anchorElement: langMenuRef,
  } = useMenu({
    iconOpen: <GlobeIcon />,
    iconClose: <XIcon />,
    iconSize: '20px',
    animateIcon: false,
    anchorVerticalTop: 51,
  })

  const langMenu = (
    <Menu>
      <Flex
        // @ts-expect-error Property 'borders' does not exist on type 'Theme'
        sx={{ borderBottom: (theme) => theme.borders.primary }}
        p={4}
      >
        <Box width="24px" height="24px" mr={3}>
          <GlobeIcon />
        </Box>
        <Typography variant="body1" color="text.heading.main">
          <FormattedMessage id="topHeader.changeLanguage" />
        </Typography>
      </Flex>
      {activeTenant.tenantLocales.map((loc) => (
        <MenuItem
          key={loc}
          onClick={() => handleSwitchLanguage(loc)}
          selected={locale === loc}
          data-cy={`langMenuLocale-${loc}`}
        >
          <FormattedMessage id={`lang.${loc}`} />
        </MenuItem>
      ))}
    </Menu>
  )

  return {
    langMenu,
    langMenuRef,
    globeButton: <TriggerComponent data-cy="langMenuOpen" />,
  }
}
