import React, { useEffect, useRef } from 'react'
import { Flex, Box } from 'components/structural'
import Layout from 'components/Layout'
import { Grid } from 'components/Grid'
import { FormattedMessage } from 'react-intl'
import { CircularProgress, Typography } from '@mui/material'
import { OffersIcon } from 'components/icons'
import { ROUTES } from 'constants/routes'
import { ReactComponent as ErrorIcon } from 'images/bullets/error.svg'
import { ViewHeader } from 'components/headers'
import {
  TIME_OF_RUN_BUSINESS,
  REJECTION_CODE_V2,
} from 'constants/loanApplication'
import { isLinkIntegration, isBanxy, isPayOne } from 'setup/tenant'
import { isEqual, noop } from 'lodash'
import { useNavigation } from 'routing/hooks/useNavigation'
import { ApplicationForm, useLoanOffer } from 'context/LoanOffer'
import { useLoanApplication } from 'context/LoanApplication'
import type { LoanApplication } from 'types/loanApplication'
import { useQueryStringApplication } from 'hooks/useQueryStringApplication'
import localforage from 'localforage'
import { useCalculations } from 'views/LoanApplication/LoanOffers/useLoanOfferCalculations'
import { VoucherLoanOfferBox } from './components/VoucherLoanOfferBox'
import { LoanOfferBox } from './components/LoanOfferBox'
import { CustomizeLoanOfferBox } from './components/CustomiseLoanOfferBox'
import { useHandleSelectedOffer } from '../CustomizeLoanOffer/useHandleSelectedOffer'

export const LoanOffersView = () => {
  const { queryStringApplication, clear: clearQueryStringApplication } =
    useQueryStringApplication()
  const { currentLoanApplication } = useLoanApplication()
  const { navigateTo } = useNavigation()

  const queryStringApplicationRef = useRef<Partial<ApplicationForm>>(null)
  const currentLoanApplicationRef = useRef<LoanApplication>(null)
  const {
    applicationForm,
    offer,
    isLoading,
    requestLoanOffer,
    applyVoucher,
    setApplicationForm,
    isHydrating,
    fetchSelectedLoanOffer,
  } = useLoanOffer()

  useEffect(() => {
    if (
      queryStringApplicationRef.current ||
      currentLoanApplicationRef.current
    ) {
      return
    }
    const requestOffersAndProceed = async () => {
      // bail out and redirect to the root if we cannot genemonthlyRepayment an offer here
      if (
        !queryStringApplication &&
        !currentLoanApplication &&
        !applicationForm
      ) {
        navigateTo(ROUTES.ROOT)
        return
      }

      // request an offer if the values are passed through as query params
      if (
        queryStringApplication &&
        !isEqual(queryStringApplicationRef.current, queryStringApplication)
      ) {
        queryStringApplicationRef.current = queryStringApplication

        await localforage.clear()
        await requestLoanOffer({
          revenue: {
            value: String(queryStringApplication?.approximateSales),
            currency: 'EUR',
          },
          timeOfRunBusiness: Number(queryStringApplication.timeOfRunBusiness),
          loanPurpose: isBanxy ? 'Inventory' : (undefined as any),
        })

        setApplicationForm({
          ...queryStringApplication,
          timeOfRunBusiness: TIME_OF_RUN_BUSINESS.find(
            (opt) =>
              opt.value === Number(queryStringApplication.timeOfRunBusiness),
          )?.range,
        })

        clearQueryStringApplication()
        return
      }

      if (
        currentLoanApplication &&
        currentLoanApplication.status !== 'rejected' &&
        currentLoanApplication.status !== 'closed' &&
        currentLoanApplication.status !== 'approved' &&
        !isEqual(currentLoanApplicationRef.current, currentLoanApplication)
      ) {
        currentLoanApplicationRef.current = currentLoanApplication
        await Promise.all([
          requestLoanOffer({
            revenue: {
              value: String(currentLoanApplication.approximateSales),
              currency: 'EUR',
            },
            timeOfRunBusiness: isLinkIntegration
              ? Number(currentLoanApplication.timeOfRunBusiness)
              : TIME_OF_RUN_BUSINESS.find(
                  (time) =>
                    time.range === currentLoanApplication.timeOfRunBusiness,
                )?.value,
            loanPurpose: isBanxy ? 'Inventory' : (undefined as any),
            ...(isLinkIntegration && {
              industry: isPayOne
                ? currentLoanApplication.tenantSpecific?.industry
                : currentLoanApplication.tenantSpecific?.mcc,
            }),
          }),
          applyVoucher(currentLoanApplication.tenantSpecific.voucher),
        ])
        setApplicationForm({
          approximateSales: Number(currentLoanApplication.approximateSales),
          timeOfRunBusiness: currentLoanApplication.timeOfRunBusiness,
          tenantSpecific: currentLoanApplication.tenantSpecific,
        })
      }
    }

    if (!isHydrating && !isLoading) {
      requestOffersAndProceed().catch(noop)
    }
  }, [
    currentLoanApplication,
    requestLoanOffer,
    isHydrating,
    applicationForm,
    setApplicationForm,
    applyVoucher,
    queryStringApplication,
    navigateTo,
    isLoading,
    clearQueryStringApplication,
  ])

  useEffect(() => {
    if (currentLoanApplication?.id) {
      fetchSelectedLoanOffer(currentLoanApplication?.id).catch(noop)
    }
  }, [fetchSelectedLoanOffer, currentLoanApplication?.id])

  useEffect(() => {
    if (!offer?.eligibility) return
    if (
      offer?.eligibility === REJECTION_CODE_V2.NON_ELIGIBLE_TIME_OF_RUN_BUSINESS
    )
      navigateTo(ROUTES.LOAN_REJECTION_TIME_OF_RUN_BUSINESS)
    if (offer?.eligibility === REJECTION_CODE_V2.NON_ELIGIBLE_REVENUE)
      navigateTo(ROUTES.LOAN_REJECTION_REVENUE)
  }, [offer, navigateTo])

  if (!offer) {
    return (
      <Flex alignItems="center" flexDirection="column">
        <FormattedMessage tagName="h2" id="pollLoanOffers.fetching" />
        <CircularProgress data-testid="spinner" />
      </Flex>
    )
  }

  if (!offer.eligibility) {
    return <LoanOffers />
  }

  return <></>
}

export const LoanOffers = () => {
  const { offer, isVoucherValid, voucherCode } = useLoanOffer()
  const {
    term,
    percentageRate,
    payoutAmount,
    feeInEuro,
    originalFee,
    monthlyRepayment,
  } = useCalculations()

  const handleSelectOffer = useHandleSelectedOffer({
    payoutAmount,
    term,
    feeInEuro,
    percentageRate,
  })

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="loanOffers.viewHeader.heading"
          icon={<OffersIcon style={{ position: 'relative', top: '-10px' }} />}
          subId="expressApplicationLoanOffers.subheading.newOfferEndpoint"
        />
      }
      withProgress
    >
      <Grid.Column start={2} span={2}>
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          maxWidth="1296px"
          flexWrap="wrap"
        >
          {offer?.maxAmount && (
            <Flex flexDirection="column" width="100%" alignItems="center">
              <Flex
                flexDirection={['column', 'column', 'row']}
                width="100%"
                justifyContent="center"
              >
                {isVoucherValid ? (
                  <Flex flexDirection="column" width="100%">
                    <VoucherLoanOfferBox
                      onClick={handleSelectOffer}
                      amount={payoutAmount}
                      discountedFee={feeInEuro}
                      originalFee={originalFee}
                      rate={monthlyRepayment as any}
                      duration={offer.term}
                    />
                    <CustomizeLoanOfferBox />
                  </Flex>
                ) : (
                  <Flex flexDirection="column" width="100%">
                    <LoanOfferBox
                      onClick={handleSelectOffer}
                      amount={payoutAmount}
                      fee={feeInEuro}
                      rate={monthlyRepayment as any}
                      duration={offer.term}
                    />
                    <CustomizeLoanOfferBox />
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Grid.Column>
    </Layout>
  )
}
