import React from 'react'
import { styled } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import activeTenant from 'setup/tenant'

const NavigationLink = styled('a')`
  text-decoration: none;
  white-space: pre;
  :not(:last-child) {
    margin-right: 32px;
  }
`

export const FooterNavigation = (props) => (
  <nav {...props}>
    <NavigationLink
      href={activeTenant.privacyPolicy}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Typography variant="caption" color="text.subtle.main">
        <FormattedMessage id="link.privacyPolicy" />
      </Typography>
    </NavigationLink>
    <NavigationLink
      href={activeTenant.termsOfService}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Typography variant="caption" color="text.subtle.main">
        <FormattedMessage id="link.termsOfService" />
      </Typography>
    </NavigationLink>
    <NavigationLink
      href={activeTenant.imprintLink}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Typography variant="caption" color="text.subtle.main">
        <FormattedMessage id="link.legal" />
      </Typography>
    </NavigationLink>
  </nav>
)
