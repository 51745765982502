import React, { useCallback } from 'react'
import { Form } from 'components/Form'
import { TimeOfRunBusiness, ApproximateSales } from 'components/Fields'
import { requiredValidator, composeValidators } from 'utils/forms/validators'
import {
  TIME_OF_RUN_BUSINESS,
  REJECTION_CODE_V2,
} from 'constants/loanApplication'
import {
  isLinkIntegration,
  isGetRaoul,
  isBanxware,
  isPayOne,
  isBanxy,
} from 'setup/tenant'
import { ROUTES } from 'constants/routes'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from 'components/Fields/TextField'
import { useNavigation } from 'routing/hooks/useNavigation'
import { IndustryField } from 'components/Fields/IndustryField'
import { useLoanOffer } from 'context/LoanOffer'
import { useLoanApplication } from 'context/LoanApplication'
import { LoanApplication } from 'types/loanApplication'

type DefaultFormProps = {
  formatOnBlur: (
    value: unknown,
    validatorFunction: (v: unknown) => unknown,
  ) => unknown
  currencyValidatorFactory: (value: unknown) => (v: unknown) => any
  formatter: any
  initialValues: Partial<LoanApplication>
}

export const DefaultForm = ({
  formatOnBlur,
  currencyValidatorFactory,
  initialValues,
  formatter,
}: DefaultFormProps) => {
  const { navigateTo } = useNavigation()
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const {
    isLoading,
    setApplicationForm,
    applyVoucher,
    applicationForm,
    requestLoanOffer,
    discountType,
    discountValue,
    discountReason,
  } = useLoanOffer()

  const intl = useIntl()

  const validateVoucher = useCallback(
    async (voucher: string) => {
      const isValid = await applyVoucher(voucher)

      // we should allow empty vouchers and valid vouchers
      // but for invalid vouchers, we nudge the users
      if (!isValid && voucher) {
        return intl.formatMessage({ id: 'loanOffer.invalidVoucher' })
      }
    },
    [applyVoucher, intl],
  )

  const handleSubmit = useCallback(
    async (values: Partial<LoanApplication>) => {
      const { industry, tenantSpecific, timeOfRunBusiness, voucher } = values

      const approximateSales =
        values?.approximateSales &&
        formatter.revert(values.approximateSales) * 100

      const revenue = {
        value: String(approximateSales),
        currency: 'EUR',
      }

      const timeOfRunBusinessInteger = TIME_OF_RUN_BUSINESS.find(
        (time) => time.range === timeOfRunBusiness,
      )?.value

      setApplicationForm({
        approximateSales,
        timeOfRunBusiness,
        voucher,
        tenantSpecific: {
          ...applicationForm?.tenantSpecific,
          ...tenantSpecific,
          voucher,
          industry,
        },
      })
      // @TODO - these need to be handled and typed uniquely so we should use a type guard or similar here, or pull this check out and handle seperately
      const loanOffers = await requestLoanOffer({
        revenue,
        timeOfRunBusiness: timeOfRunBusinessInteger,
        industry,
        loanPurpose: isBanxy ? 'Inventory' : (undefined as any),
      })

      if (
        currentLoanApplication?.id &&
        currentLoanApplication.status !== 'rejected' &&
        currentLoanApplication.status !== 'closed' &&
        currentLoanApplication.status !== 'approved'
      ) {
        await updateLoanApplicationById({
          loanApplicationId: currentLoanApplication.id,
          loanApplication: {
            ...currentLoanApplication,
            approximateSales,
            timeOfRunBusiness: values.timeOfRunBusiness,
            tenantSpecific: {
              ...(isBanxy && {
                selectedLoanPurpose: 'Inventory' as any,
              }),
              ...currentLoanApplication?.tenantSpecific,
              voucher,
              discountType,
              discountValue,
              discountReason,
            },
          },
        })
      }
      if (
        timeOfRunBusiness === '0-6' ||
        loanOffers.eligibility ===
          REJECTION_CODE_V2.NON_ELIGIBLE_TIME_OF_RUN_BUSINESS
      ) {
        navigateTo(ROUTES.LOAN_REJECTION_TIME_OF_RUN_BUSINESS)
      } else if (
        loanOffers.maxAmount ||
        loanOffers.eligibility === REJECTION_CODE_V2.NON_ELIGIBLE_REVENUE
      ) {
        navigateTo(ROUTES.LOAN_OFFERS)
      }
    },
    [
      currentLoanApplication,
      formatter,
      navigateTo,
      applicationForm,
      requestLoanOffer,
      setApplicationForm,
      updateLoanApplicationById,
      discountType,
      discountValue,
      discountReason,
    ],
  )
  const approximateSalesValidator = currencyValidatorFactory(
    'validators.approximateSalesValueIncorrect',
  )

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    >
      <TimeOfRunBusiness />
      <ApproximateSales
        formatOnBlur={(value) => formatOnBlur(value, approximateSalesValidator)}
        validate={composeValidators(
          requiredValidator,
          approximateSalesValidator,
        )}
      />
      {(isLinkIntegration || isPayOne) && <IndustryField />}
      {(isGetRaoul || isBanxy || isBanxware) && (
        <TextField
          validate={validateVoucher}
          name="voucher"
          label={<FormattedMessage id="fields.voucher" />}
        />
      )}
    </Form>
  )
}
