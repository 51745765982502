import { useMemo, useCallback } from 'react'
import { useLocation, useHistory, generatePath } from 'react-router-dom'
import activeTenant, {
  isGetRaoul,
  isBanxware,
  isPayOne,
  isTeleCash,
} from 'setup/tenant'
import { LEGAL_FORMS } from 'constants/loanApplication'
import { ROUTES } from 'constants/routes'
import { useConfiguration } from 'context/Configuration'
import { useLoanApplication } from 'context/LoanApplication'

export const useFlowNavigation = () => {
  const { currentLoanApplication, shouldAllowNewApplication } =
    useLoanApplication()
  const { pathname } = useLocation()
  const { configuration } = useConfiguration()
  const history = useHistory()

  const allRoutes: typeof ROUTES[keyof typeof ROUTES][] = useMemo(() => {
    if (!currentLoanApplication?.businessDetails) {
      return activeTenant.loanApplicationFlow
    }

    const legalForm = currentLoanApplication.businessDetails.legalForm

    const hasStakeholder =
      legalForm === LEGAL_FORMS.GMBH ||
      legalForm === LEGAL_FORMS.OHG ||
      legalForm === LEGAL_FORMS.GMBH_COKG ||
      legalForm === LEGAL_FORMS.GBR ||
      legalForm === LEGAL_FORMS.UG

    const hasDocumentUpload =
      legalForm === LEGAL_FORMS.SOLEPROPRIETOR ||
      legalForm === LEGAL_FORMS.GBR ||
      isGetRaoul ||
      isBanxware

    const hasCodat =
      !!configuration?.tenant.codatEnabled && !shouldAllowNewApplication

    const filteredRoutes = [...activeTenant.loanApplicationFlow].filter(
      (route) => {
        if (
          (!hasStakeholder && route === ROUTES.STAKEHOLDERS) ||
          (!hasCodat && route === ROUTES.CONNECT_SHOPPING_PLATFORM) ||
          (!isPayOne &&
            !isTeleCash &&
            route === ROUTES.ADDITIONAL_INFORMATION) ||
          (!hasDocumentUpload && route === ROUTES.DOCUMENTS_UPLOAD)
        ) {
          return false
        }
        return true
      },
    )
    return filteredRoutes
  }, [
    currentLoanApplication?.businessDetails,
    configuration,
    shouldAllowNewApplication,
  ])

  const currentIndex = useMemo(() => {
    const currentRoute = pathname.split('/').slice(-1)[0]
    return allRoutes.findIndex(
      (route) => route.split('/').slice(-1)[0] === currentRoute,
    )
  }, [allRoutes, pathname])

  const prevRoute = currentLoanApplication?.id
    ? generatePath(allRoutes[Math.max(0, currentIndex - 1)], {
        id: currentLoanApplication.id,
      })
    : allRoutes[Math.max(0, currentIndex - 1)]

  const hasPrevRoute = prevRoute && prevRoute !== pathname

  const nextRoute =
    currentLoanApplication?.id &&
    generatePath(allRoutes[Math.min(currentIndex + 1, allRoutes.length - 1)], {
      id: currentLoanApplication.id,
    })

  const goToPrevPage = useCallback(() => {
    if (prevRoute === pathname) return
    history.push(prevRoute)
  }, [history, pathname, prevRoute])

  const goToNextPage = useCallback(() => {
    if (nextRoute === pathname) return
    history.push(nextRoute)
  }, [history, nextRoute, pathname])

  const goToPageAfterRoute = useCallback(
    (pageRoute: typeof ROUTES[keyof typeof ROUTES]) => {
      const routeIndex = allRoutes.findIndex((route) => route === pageRoute)

      const route = generatePath(
        allRoutes[Math.min(routeIndex + 1, allRoutes.length - 1)],
        {
          id: currentLoanApplication?.id,
        },
      )
      history.push(route)
    },
    [allRoutes, currentLoanApplication?.id, history],
  )

  return {
    goToPrevPage,
    goToNextPage,
    goToPageAfterRoute,
    prevRoute,
    nextRoute,
    allRoutes,
    hasPrevRoute,
  }
}
