import React from 'react'
import { Formik, Form } from 'formik'
import { Flex, Box } from 'components/structural'
import { Grid } from 'components/Grid'
import { FormattedMessage } from 'react-intl'
import { Layout } from 'components/Layout'
import { Button } from 'components/buttons'
import { Password } from 'components/Fields'
import {
  requiredValidator,
  passwordStrengthValidator,
  composeValidators,
} from 'utils/forms/validators'
import { ValidationList } from 'components/ValidationList'
import { Typography } from '@mui/material'

type ChangePasswordProps = {
  handleChangePassword: (args: {
    password: string
    passwordConfirmation: string
  }) => void
  handleNavigateToRequestReset: () => void
  isExpired: boolean
  isLoading: boolean
}

export function ChangePassword(props: ChangePasswordProps) {
  return (
    <Formik
      onSubmit={props.handleChangePassword}
      style={{ width: '100%' }}
      initialValues={{
        password: '',
        passwordConfirmation: '',
      }}
    >
      {({
        isValid,
        submitCount,
        values: { password, passwordConfirmation },
      }) => (
        <Form data-testid="pantherNewPasswordForm">
          <Flex flexDirection="column" flex="1 0 auto" width="100%">
            <Grid columns={3}>
              <Grid.Column
                sx={{
                  gridColumnEnd: ['span 3', 'span 3', 'span 2'],
                }}
              >
                <Typography variant="body2">
                  <FormattedMessage
                    id={`panther2Go.resetPassword.subheading${
                      props.isExpired ? '.expired' : ''
                    }`}
                  />
                </Typography>
              </Grid.Column>
            </Grid>
            {!props.isExpired && (
              <>
                <Box mt={5} flexDirection="column">
                  <Password
                    name="password"
                    validate={composeValidators(
                      requiredValidator,
                      passwordStrengthValidator,
                    )}
                  />
                  <Password
                    validate={composeValidators(requiredValidator, (value) =>
                      value !== password
                        ? 'validators.passwordsDoNotMatch'
                        : undefined,
                    )}
                    name="passwordConfirmation"
                    label={<FormattedMessage id="fields.passwordRepeat" />}
                  />
                  <ValidationList
                    password={password}
                    passwordConfirmation={passwordConfirmation}
                  />
                </Box>
              </>
            )}
            <Layout.MiddleAndRightMain>
              <Button
                type={!props.isExpired ? 'submit' : ''}
                onClick={
                  props.isExpired
                    ? props.handleNavigateToRequestReset
                    : undefined
                }
                loading={props.isLoading}
                disabled={!isValid && submitCount > 0}
                style={{
                  marginTop: '24px',
                  borderRadius: 0,
                  width: '100%',
                }}
              >
                <FormattedMessage
                  id={
                    !props.isExpired
                      ? 'panther2Go.setPassword.buttonText'
                      : 'panther2Go.resetPassword.buttonText'
                  }
                />
              </Button>
            </Layout.MiddleAndRightMain>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
