import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field as FormikField } from 'formik'
import { urlValidator } from 'utils/forms/validators'
import { TextFieldComponent } from './TextFieldComponent'

export const Website = ({ mb, name = 'website' }) => (
  <FormikField
    component={TextFieldComponent}
    data-testid="formFieldWebsite"
    name={name}
    mb={mb}
    label={<FormattedMessage id="fields.website" />}
    validate={urlValidator}
  />
)
