import { useState, useCallback } from 'react'
import { useConfiguration } from 'context/Configuration'
import { useError } from 'context/Error'

export function useInitializeApplication() {
  const { fetchConfiguration } = useConfiguration()
  const { handleApiRequestError } = useError()
  const [isApplicationInitialized, setIsApplicationInitialized] =
    useState<boolean>(false)

  const initializeApplication = useCallback(async () => {
    try {
      await fetchConfiguration()
    } catch (error) {
      handleApiRequestError(error)
    } finally {
      setIsApplicationInitialized(true)
    }
  }, [fetchConfiguration, handleApiRequestError])

  return {
    isApplicationInitialized,
    initializeApplication,
  }
}
