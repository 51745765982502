import React, { useState } from 'react'
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { format } from 'date-fns'
import { ArrowDownIcon } from 'components/icons'
import { AccordionDetail } from 'components/AccordionDetail'
import { formatCurrency, createCurrencyFormatter } from 'utils/currency'
import { DATE_FORMAT } from 'constants/format'
import { useLanguage } from 'context/Language'
import type { LoanHistory } from 'context/Loans'

const getConfig = (type: string) => {
  switch (type) {
    case 'repayment':
      return {
        transactionSymbol: '-',
        headerColor: 'text.normal.main',
        valueColor: 'text.normal.main',
      }
    case 'loanFee':
      return {
        transactionSymbol: '+',
        headerColor: 'text.normal.main',
        valueColor: 'text.normal.main',
      }
    default:
      return {
        transactionSymbol: '+',
        headerColor: 'global.okGreen.main',
        valueColor: 'global.okGreen.main',
      }
  }
}

const useStyles = makeStyles((theme: any) => ({
  expandIcon: {
    position: 'absolute',
    padding: 0,
    right: 0,
    fontSize: 0,
    bottom: 16,
    [theme.breakpoints.up('tablet')]: {
      bottom: 'auto',
      right: theme.spacing(3),
    },
  },
}))

export const LoanHistoryAccordion = ({
  amount,
  creditorAccount,
  currency,
  date,
  id,
  status,
  title,
  type,
}: LoanHistory) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const { locale } = useLanguage()
  const formatter = createCurrencyFormatter(locale)
  const handleChange = (__, newExpanded: boolean) => {
    setExpanded(newExpanded)
  }
  const classes = useStyles()
  const { transactionSymbol, headerColor, valueColor } = getConfig(type)

  return (
    <Box>
      <MuiAccordion onChange={handleChange} expanded={expanded} square>
        <Box
          sx={{
            // @ts-expect-error Property 'borders' does not exist on type 'Theme'
            borderTop: (theme) => theme.borders.primary,
          }}
        >
          <AccordionSummary
            classes={classes}
            expandIcon={
              <Box width="16px" height="16px" mr={[3, 0, 0]}>
                <ArrowDownIcon />
              </Box>
            }
          >
            <Flex
              flexGrow="1"
              py={[3, 3, 4]}
              flexDirection={['column-reverse', 'row', 'row']}
            >
              <Flex flex="0 1 25%" pl={[3, 0, 0]}>
                <Box flex="1 1 auto" pl={[0, 3, 3]}>
                  <Typography variant="body2" color="text.subtle.main">
                    {format(new Date(date), DATE_FORMAT)}
                  </Typography>
                </Box>
              </Flex>
              <Flex
                px={[3, 0, 0]}
                flex="0 1 50%"
                justifyContent="space-between"
              >
                <>
                  <Box sx={{ color: headerColor }}>
                    <Typography variant="body1" color="inherit">
                      <FormattedMessage
                        id={`dashboard.loanHistoryRecords.${type}`}
                      />
                    </Typography>
                  </Box>
                  <Box sx={{ color: valueColor }}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      style={{ fontWeight: 500 }}
                    >
                      {transactionSymbol}{' '}
                      {amount ? formatCurrency(amount) : '-'}
                    </Typography>
                  </Box>
                </>
              </Flex>
            </Flex>
          </AccordionSummary>
        </Box>

        <Box>
          <AccordionDetails>
            <Flex
              bgcolor={expanded ? 'global.bgB.main' : '#fff'}
              flexGrow="1"
              py={[3, 3, 4]}
            >
              <Box
                flexShrink="1"
                flexGrow="0"
                flexBasis={['0', '25%', '25%']}
              />
              <Flex
                px={[3, 0, 0]}
                flexShrink="1"
                flexGrow="0"
                flexBasis={['100%', '50%', '50%']}
                flexDirection="column"
              >
                {id && (
                  <AccordionDetail
                    fieldKey="dashboard.loanHistoryRecords.id"
                    fieldValue={id}
                  />
                )}
                {date && (
                  <AccordionDetail
                    fieldKey="dashboard.loanHistoryRecords.date"
                    fieldValue={format(new Date(date), DATE_FORMAT)}
                  />
                )}
                {amount > 0 && (
                  <AccordionDetail
                    fieldKey="dashboard.loanHistoryRecords.amount"
                    fieldValue={
                      typeof amount === 'number'
                        ? formatCurrency(amount)
                        : formatter.format(parseFloat(amount))
                    }
                  />
                )}
                {currency && (
                  <AccordionDetail
                    fieldKey="dashboard.loanHistoryRecords.currency"
                    fieldValue={currency}
                  />
                )}
                {title && (
                  <AccordionDetail
                    fieldKey="dashboard.loanHistoryRecords.title"
                    fieldValue={title}
                  />
                )}
                {creditorAccount && (
                  <AccordionDetail
                    fieldKey="dashboard.loanHistoryRecords.account"
                    fieldValue={creditorAccount}
                  />
                )}
                {status && (
                  <AccordionDetail
                    fieldKey="dashboard.loanHistoryRecords.status"
                    fieldValue={
                      <FormattedMessage
                        id={`dashboard.loanHistoryRecords.${status}`}
                      />
                    }
                  />
                )}
              </Flex>
            </Flex>
          </AccordionDetails>
        </Box>
      </MuiAccordion>
    </Box>
  )
}
