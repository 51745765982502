import { useState, useCallback } from 'react'
import { API_URL, API_PATH } from 'constants/api'
import { useApiRequest } from 'context/utils/useApiRequest'

const RESET_PASSWORD_REDIRECT_URL = `${window.location.origin}/new-password`

export function useResetPassword() {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isTokenValid, setIsTokenValid] = useState(false)
  const [isRequestSent, setIsRequestSent] = useState(false)
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)
  const { postRequest, getRequest } = useApiRequest()

  const validateResetToken = useCallback(
    async (token) => {
      try {
        setIsTokenValid(false)
        setIsLoading(true)
        await getRequest({
          url: `${API_URL}${API_PATH.RESET_PASSWORD}/validate?token=${token}`,
        })
        setIsTokenValid(true)
      } catch (error) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    },
    [getRequest],
  )

  const requestPasswordReset = useCallback(
    async ({ email }) => {
      try {
        setIsRequestSent(false)
        setIsLoading(true)
        await postRequest({
          url: `${API_URL}${API_PATH.RESET_PASSWORD}`,
          body: {
            reset_password: {
              email,
              url: RESET_PASSWORD_REDIRECT_URL,
            },
          },
        })
        setIsRequestSent(true)
      } catch (error) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    },
    [postRequest],
  )

  const changePassword = useCallback(
    async ({ password, passwordConfirmation, resetToken }) => {
      try {
        setIsPasswordChanged(false)
        setIsLoading(true)
        await postRequest({
          url: `${API_URL}${API_PATH.NEW_PASSWORD}`,
          body: {
            new_password: {
              password,
              password_confirmation: passwordConfirmation,
              reset_password_token: resetToken,
            },
          },
        })
        setIsPasswordChanged(true)
      } catch (error) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    },
    [postRequest],
  )

  return {
    changePassword,
    isError,
    isLoading,
    isPasswordChanged,
    isRequestSent,
    isTokenValid,
    requestPasswordReset,
    validateResetToken,
  }
}
