import banxyApple from 'images/favicons/banxy/apple-touch-icon.png'
import banxy32 from 'images/favicons/banxy/favicon-32x32.png'
import banxy16 from 'images/favicons/banxy/favicon-16x16.png'
import banxyPin from 'images/favicons/banxy/safari-pinned-tab.svg'
import banxyManifest from 'images/favicons/banxy/site.webmanifest'
// Icons used in manifest
import '!file-loader?name=banxware-[name].[ext]!../images/favicons/banxware/android-chrome-192x192.png'
import '!file-loader?name=banxware-[name].[ext]!../images/favicons/banxware/android-chrome-512x512.png'
import { ReactComponent as BanxyLogo } from 'images/logos/banxware-no-r.svg'
import { EXPRESS_FLOW, ROUTES } from 'constants/routes'
import { TenantConfig, TenantPalette, TenantShape } from '.'

export const palette: TenantPalette = {
  global: {
    bgA: '#FFFFFF',
    bgB: '#FFFBF4',
    line: '#BDBDBD',
    icoA: '#7000FF',
    icoB: '#F4EBFF',
    icoOff: '#ECECEC',
    errorRed: '#FB6060',
    okGreen: '#68AC57',
    onDark: '#FFFFFF',
  },
  header: {
    bg: '#FFFFFF',
    title: '#0D0D0D',
    text: '#0A0B20',
    link: '#7000FF',
  },
  input: {
    bgA: '#FFFFFF',
    bgB: '#FFFFFF',
    stroke: '#BDBDBD',
    placeholder: '#ECECEC',
    label: '#7000FF',
    value: '#0A0B20',
    ico: '#7000FF',
    icoOff: '#ECECEC',
  },
  text: {
    normal: '#0A0B20',
    heading: '#6B6B6B',
    link: '#7000FF',
    subtle: '#6B6B6B',
    off: '#ECECEC',
  },
  button: {
    bgA: '#5325B8',
    bgB: '#FAFAFA',
    bgC: '#F4EBFF',
    labelA: '#FEFEFE',
    labelB: '#6B6B6B',
    labelC: '#7000FF',
  },
}

export const shape: TenantShape = {
  borderRadius: 0,
  fieldBorderRadius: '0',
}

const banxyTenantConfig: TenantConfig = {
  name: 'Banxy',
  tenantCode: 'BXY',
  title: 'Banxware',
  logo: {
    component: BanxyLogo,
    width: 220,
  },
  iconSet: {
    appleTouch: banxyApple,
    favicon16: banxy16,
    favicon32: banxy32,
    manifest: banxyManifest,
    pin: banxyPin,
    tileColor: '#da532c',
  },
  supportContactNumber: '00 49 30 311 995 87',
  contactEmail: 'hello@banxy.io',
  faqLink: 'https://help.banxware.com/support/solutions/80000433830',
  imprintLink: '/imprint',
  termsOfService: 'https://docs.banxware.com/AGB%20Banxware%20MCA.pdf',
  privacyPolicy:
    'https://docs.banxware.com/Datenschutzinfo%20Banxware%20MCA.pdf',
  tenantOverrides: {
    translationOverrideSuffix: 'bxy',
    customLinkingPlatforms: [
      {
        value: 'zalando',
        message: 'Zalando',
        status: null,
        redirectUrl: '',
      },
    ],
  },
  theme: {
    palette,
    shape,
  },
  gtmId: 'GTM-NSRNPZ2',
  loanApplicationFlow: EXPRESS_FLOW,
  customizeLoanOfferRoute: ROUTES.CUSTOMIZE_LOAN_OFFER_BXY,
}

export default banxyTenantConfig
