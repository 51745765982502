import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { initGtm } from 'utils/gtm'
import { tenantCode } from 'setup/tenant'
import { domainEnvironment } from 'setup/environment'
import { DOMAIN_ENVIRONMENTS } from 'constants/environments'
import packageJson from '../package.json'
import { App } from './App'

initGtm()

export const SENTRY_ENVIRONMENTS = {
  DEV: 'development',
  STG: 'staging',
  PROD: 'production',
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  // Important that this release string matches the one used in the configuration
  // of SentryWebpackPlugin in webpack.prod.js.
  release: `${packageJson.name}@${packageJson.version}`,
  environment:
    SENTRY_ENVIRONMENTS[domainEnvironment] || SENTRY_ENVIRONMENTS.DEV,
  debug: domainEnvironment === DOMAIN_ENVIRONMENTS.DEV,
  initialScope: {
    tags: {
      tenant: tenantCode,
      e2e: !!window.Cypress,
    },
  },
})

/* eslint-disable import/extensions */
if (!window.Intl) {
  require.ensure(['intl', 'intl/locale-data/jsonp/en.js'], (require) => {
    require('intl')
    require('intl/locale-data/jsonp/en.js')
  })
}

window.xs2a?.configure({
  'logo-alignment': 'end',
  'open-privacy-policy-in-tab': true,
})

ReactDOM.render(<App />, document.getElementById('app'))
