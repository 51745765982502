import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Grid } from 'components/Grid'
import { useLoanOffer } from 'context/LoanOffer'
import { useLoanApplication } from 'context/LoanApplication'
import { HubspotMultiLingualFormEmbed } from 'components/HubspotFormEmbed'

type Props = {
  rejectionReason?: string
}

export const EmailSubscriptionForm = ({ rejectionReason }: Props) => {
  const { applicationForm } = useLoanOffer()
  const { currentLoanApplication } = useLoanApplication()

  const { approximateSales, timeOfRunBusiness } =
    applicationForm || currentLoanApplication || {}

  return (
    <>
      <Grid columns={3}>
        <Grid.Column
          sx={{
            paddingBottom: '4rem',
            gridColumnEnd: ['span 3', 'span 3', 'span 2'],
          }}
        >
          <Typography sx={{ padding: '0 0 2rem 0' }} variant="h5">
            <FormattedMessage id="rejection.emailText" />
          </Typography>
          <HubspotMultiLingualFormEmbed
            forms={[
              {
                formId: '5a779a04-0115-4f92-929b-e9c3188fd8c3',
                language: 'en',
              },
              {
                formId: '8cdcd66b-f571-4db0-a07e-a6293a521132',
                language: 'de',
              },
            ]}
            targetClass="rejection-form"
            onHiddenFields={(fields) => {
              fields.forEach((field) => {
                if (field.name.includes('monthly_revenue')) {
                  field.value = approximateSales as any
                }

                if (field.name.includes('time_in_business')) {
                  field.value = timeOfRunBusiness
                }

                if (field.name.includes('rejection_reason')) {
                  field.value = rejectionReason
                }
              })
            }}
          />
        </Grid.Column>
      </Grid>
    </>
  )
}

export default EmailSubscriptionForm
