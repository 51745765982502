import React from 'react'
import { Flex, Box } from 'components/structural'
import { TopHeader } from 'components/headers'
import { Footer } from 'components/Footer'
import { ApplicationProgress } from 'components/ApplicationProgress'
import { Grid } from 'components/Grid'
import { useError } from 'context/Error'
import { GenericError } from 'components/GenericError'

export const Layout = ({
  children,
  banner,
  topHeader,
  viewHeader,
  topHeaderProps,
  footer,
  withProgress,
  withoutGenericError,
  ...restProps
}) => {
  const { error } = useError()

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      style={{ minHeight: '100vh', overflow: 'hidden' }}
    >
      <Flex
        flexDirection="column"
        flex="1 0 auto"
        alignItems="center"
        {...restProps}
      >
        {banner}
        {topHeader || <TopHeader {...topHeaderProps} />}
        {viewHeader}
        <Box
          px={[3, 6, 4]}
          pt={[5, 6, 7]}
          pb={9}
          flex="1 0 auto"
          maxWidth="1344px"
          width="100%"
        >
          <Grid>
            {!withoutGenericError && (
              <Grid.Column
                sx={{
                  gridColumnStart: [1, 1, 2],
                  gridColumnEnd: [5, 5, 4],
                }}
              >
                {error && <GenericError mb={3} errorMessage={error.message} />}
              </Grid.Column>
            )}
            {withProgress && (
              <Grid.Column span={1} sx={{ display: ['none', 'none', 'block'] }}>
                <ApplicationProgress />
              </Grid.Column>
            )}
            {children}
          </Grid>
        </Box>
        {footer || <Footer />}
      </Flex>
    </Flex>
  )
}

const FullSpan = ({ children }) => (
  <Box
    sx={{
      gridColumnStart: '1',
      gridColumnEnd: 'span 4',
    }}
  >
    {children}
  </Box>
)

const Left = ({ children, sx, ...rest }) => (
  <Box
    sx={{
      gridColumnStart: '1',
      gridColumnEnd: ['span 4', 'span 4', 'span 1'],
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

const Middle = ({ children, sx, ...rest }) => (
  <Box
    sx={{
      gridColumnStart: ['1', '1', '2'],
      gridColumnEnd: ['span 4', 'span 4', 'span 2'],
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

const MiddleAndRight = ({ children, sx, ...rest }) => (
  <Box
    sx={{
      gridColumnStart: ['1', '1', '2'],
      gridColumnEnd: ['span 4', 'span 4', 'span 3'],
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

const MiddleAndRightMain = ({ children }) => (
  <Box
    sx={{
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '48px',
      rowGap: 0,
    }}
  >
    <Box
      sx={{
        gridColumnStart: '1',
        gridColumnEnd: ['span 3', 'span 3', 'span 2'],
      }}
    >
      {children}
    </Box>
  </Box>
)

const Right = ({ children }) => (
  <Box
    sx={{
      gridColumnStart: ['1', '1', '4'],
      gridColumnEnd: ['span 4', 'span 4', 'span 1'],
    }}
  >
    {children}
  </Box>
)

Layout.FullSpan = FullSpan
Layout.Left = Left
Layout.Middle = Middle
Layout.Right = Right
Layout.MiddleAndRight = MiddleAndRight
Layout.MiddleAndRightMain = MiddleAndRightMain

export default Layout
