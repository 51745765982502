import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TextFieldComponent } from 'components/Fields/TextFieldComponent'
import { Field as FormikField } from 'formik'
import { requiredValidator } from 'utils/forms/validators'

export const Password = (props) => (
  <FormikField
    component={TextFieldComponent}
    data-testid="formFieldPassword"
    name="password"
    type="password"
    label={<FormattedMessage id="fields.password" />}
    validate={requiredValidator}
    {...props}
  />
)
