import React from 'react'
import { Flex } from 'components/structural'
import { Typography } from '@mui/material'
import { useLoans } from 'context/Loans'
import { ROUTES } from 'constants/routes'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'

type NameAndEmailProps = {
  name: string
  email?: string
}

export const NameAndEmail = (props: NameAndEmailProps) => {
  const { currentLoan, loans } = useLoans()
  const history = useHistory()
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-end"
      pr={4}
      // @ts-expect-error Property 'borders' does not exist on type 'Theme'
      sx={{ borderRight: (theme) => theme.borders.primary }}
    >
      {/** Links to the user's dashboard if they have any active loans */}
      <Typography
        variant="body1"
        color="text.link.main"
        {...(loans?.length > 0 && {
          sx: { cursor: 'pointer', textDecoration: 'underline' },
        })}
        onClick={() =>
          loans &&
          currentLoan?.id &&
          history.push(
            generatePath(ROUTES.MERCHANT_PORTAL, {
              id: currentLoan?.id,
            }),
          )
        }
      >
        {props.name.toLowerCase().includes('no name') ? '' : props.name}
      </Typography>
      {props.email && (
        <Typography variant="caption" color="text.subtle.main">
          {props.email}
        </Typography>
      )}
    </Flex>
  )
}
