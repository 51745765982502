import React from 'react'
import { Button, ButtonProps } from '@mui/material'

import { ReactComponent as OnIcon } from 'images/icons/hint-on.svg'
import { ReactComponent as OffIcon } from 'images/icons/hint-off.svg'

type HintButtonProps = Omit<ButtonProps, 'sx'> & {
  on: boolean
}

export const HintButton = (props: HintButtonProps) => {
  const { on, ...rest } = props

  const Icon = on ? OffIcon : OnIcon

  return (
    <Button
      sx={{
        color: 'button.labelA.main',
        backgroundColor: 'input.icoOff.main',
        minWidth: 'auto',
        padding: '0px',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        '&:hover': {
          backgroundColor: 'input.ico.main',
        },
      }}
      {...rest}
    >
      <Icon style={{ height: '20px', width: '20px' }} />
    </Button>
  )
}
