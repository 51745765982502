import React, { useEffect, useCallback, useState, useMemo } from 'react'
import Layout from 'components/Layout'
import { useAccount } from 'context/Account'
import { ViewHeader } from 'components/headers'
import { ROUTES } from 'constants/routes'
import { ERROR_MESSAGE } from 'constants/error'
import { useNavigation } from 'routing/hooks/useNavigation'
import { useLoanApplication } from 'context/LoanApplication'
import { useOwners } from 'context/Owners'
import { useAuth } from 'context/Auth'
import { useLoanOffer } from 'context/LoanOffer'
import { CUSTOMER_PROGRESS } from 'constants/loanApplication'
import { noop } from 'lodash'
import { useCustomerProgress } from 'routing/hooks/useCustomerProgress'
import { SignUpForm } from './components/SignUpForm'

const DEFAULT_ADDRESS = {
  address: {
    country: 'DEU',
  },
}

type SignupFormFields = {
  email: string
  businessDetails: Record<string, any>
  dateOfBirth: string
  firstName: string
  lastName: string
  legalRepresentative: boolean
  optinAccount: boolean
  optinTax: boolean
  optinTerms: boolean
  title: string
  tenantSpecific: Record<string, any>
  password: string
  passwordConfirmation: string
}

const SignUp = () => {
  const { logIn, token } = useAuth()
  const { createAccount, setIsSignUp } = useAccount()
  const { navigateTo } = useNavigation()
  const {
    applicationForm,
    saveLoanOffer,
    selectedOffer,
    isVoucherValid,
    voucherCode,
    discountType,
    discountValue,
    discountReason,
    revenue,
  } = useLoanOffer()
  const {
    createLoanApplication,
    updateCurrentMerchant,
    fetchLoanApplications,
    fetchLoanApplicationById,
    currentLoanApplication,
  } = useLoanApplication()
  const { createOwner } = useOwners()
  const customerProgress = useCustomerProgress()
  const [signUpComplete, setSignUpComplete] = useState(false)

  const [formValues, setFormValues] = useState<SignupFormFields>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!applicationForm) {
      navigateTo(ROUTES.ROOT)
      return
    }

    if (!selectedOffer) {
      navigateTo(ROUTES.CUSTOMIZE_LOAN_OFFER)
    }
  }, [applicationForm, navigateTo, selectedOffer])

  // creates an account and logs the user in with the passed values from the form
  const createAccountAndLogin = useCallback(
    async (values: SignupFormFields) => {
      // sets the form values to state so we can access them later once a token has been retrieved and set to the auth state
      setFormValues(values)
      const { email, password, passwordConfirmation } = values
      await createAccount({
        email,
        password,
        passwordConfirmation,
      })
      await logIn({ email, password })
    },
    [createAccount, logIn],
  )

  // Declares a function to progress with the loan application that is invoked once an auth token is present in the authState
  // We use the formValues that are set to state when form has been submitted
  useEffect(() => {
    const progressApplication = async () => {
      const {
        email,
        businessDetails,
        dateOfBirth,
        firstName,
        lastName,
        legalRepresentative,
        optinAccount,
        optinTax,
        optinTerms,
        title,
        tenantSpecific,
      } = formValues

      const tenantSpecificOptions = {
        ...tenantSpecific,
        ...applicationForm?.tenantSpecific,
        ...(voucherCode && {
          voucher: voucherCode,
          discountType,
          discountValue,
          discountReason,
        }),
      }

      const newLoanApplication = await createLoanApplication({
        ...applicationForm,
        customerProgress: CUSTOMER_PROGRESS.LOAN_OFFERS,
        businessDetails: {
          ...(applicationForm?.businessDetails || DEFAULT_ADDRESS),
          legalForm: businessDetails?.legalForm,
        },
        tenantSpecific: tenantSpecificOptions,
      })

      await Promise.all([
        saveLoanOffer({
          loanApplicationId: newLoanApplication?.id,
        }),
        updateCurrentMerchant({
          optinAccount,
          optinTax,
          optinTerms,
        }),
        createOwner({
          loanApplicationId: newLoanApplication?.id,
          owner: {
            applicantOwner: true,
            dateOfBirth,
            email,
            firstName,
            lastName,
            legalRepresentative,
            title,
          },
        }),
      ])

      await Promise.all([
        fetchLoanApplicationById(newLoanApplication?.id),
        fetchLoanApplications(),
      ])

      setSignUpComplete(true)
    }

    // When the data becomes available after a signup and login, allow the creation of a loan and additional steps to proceed
    if (token && formValues && !currentLoanApplication && !signUpComplete) {
      progressApplication().catch(noop)
    }

    // Perform a redirect once this precursor steps are finished
    if (signUpComplete && customerProgress?.route) {
      navigateTo(customerProgress?.route)
      setIsLoading(false)
      setIsSignUp(false)
    }
  }, [
    token,
    setIsSignUp,
    signUpComplete,
    currentLoanApplication,
    customerProgress,
    navigateTo,
    formValues,
    applicationForm,
    createLoanApplication,
    createOwner,
    fetchLoanApplicationById,
    fetchLoanApplications,
    isVoucherValid,
    selectedOffer,
    updateCurrentMerchant,
    voucherCode,
    discountType,
    discountValue,
    discountReason,
    revenue,
    saveLoanOffer,
  ])

  /** Calls the signup process which does the following:
   * - Sets the isSignUp state to true, preventing the redirect behaviour in routing/index
   * - Sets the signup isLoading flag
   * - Initiates the sign up by calling createAccountAndLogin with the form values passed to this function.
   * - createAccountAndLogin calls the signup and login endpoints, then updates the form values in the state
   * - A useEffect runs when the form values are set which calls the progressApplication function
   * - this function creates a new loan application, selects a loan offer, updates the current merchant, creates an owner and fetches the loan application
   * - A redirect is then called to route the user to the post signup step
   */
  const handleSignUp = async (values, formik) => {
    try {
      setIsSignUp(true)
      setIsLoading(true)
      await createAccountAndLogin(values)
    } catch (error) {
      setIsLoading(false)
      if (error.message === ERROR_MESSAGE.EMAIL_TAKEN) {
        formik.setFieldError('email', error.message)
      } else {
        navigateTo(ROUTES.ERROR)
      }
    }
  }

  const initialValues = useMemo(
    () => ({
      email: applicationForm?.merchant?.email || '',
      password: '',
      passwordConfirmation: '',
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      optinTax: false,
      optinTerms: false,
      optinAccount: false,
      legalRepresentative: false,
    }),
    [applicationForm?.merchant?.email],
  )

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="signUp.header"
          subId="expressApplicationSignUp.subheading"
        />
      }
    >
      <Layout.MiddleAndRight>
        <SignUpForm
          isLoading={isLoading}
          handleSignUp={handleSignUp}
          initialValues={initialValues}
        />
      </Layout.MiddleAndRight>
    </Layout>
  )
}

export default SignUp
