import React from 'react'
import { CircularProgress } from '@mui/material'
import { ReactComponent as ArrowIcon } from 'images/icons/arrow-right.svg'
import { ButtonBase } from '../ButtonBase/ButtonBase'

const Loading = () => <CircularProgress color="inherit" size={24} />

export const Button = ({
  children,
  loading,
  withArrow,
  disabled,
  sx,
  ...restProps
}) => {
  const icon = withArrow ? <ArrowIcon /> : null
  return (
    <ButtonBase
      sx={{
        '& svg g': {
          stroke: (theme) =>
            disabled
              ? theme.palette.button.labelA.dark
              : theme.palette.button.labelA.main,
        },
        ...sx,
      }}
      icon={withArrow && loading ? <Loading /> : icon}
      disabled={disabled}
      {...restProps}
    >
      {!withArrow && loading ? <Loading /> : children}
    </ButtonBase>
  )
}

export default Button
