import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TextField } from './TextField'

export const ApproximateSales = (props) => (
  <TextField
    inputWrapperSx={{ pr: 3, height: ['100px', '78px', '78px'] }}
    textFieldStyles={{ width: '100%', height: '100%' }}
    name="approximateSales"
    label={<FormattedMessage id="fields.approximateSales" />}
    hint={
      <FormattedMessage id="expressApplication.fields.approximateSales.hint" />
    }
    {...props}
  />
)
