import {
  calculateDisplayOffer,
  calculateRate,
  moneyFromNumber,
  numberFromMoney,
  PossibleTerms,
  recalculateTotalAmount,
} from '@banxware/offer-generation'
import { moneyDtoToMoney } from '@banxware/offer-generation/utils/money'
import activeTenant, {
  isTeleCash,
  isBanxy,
  isDynamicRepayment,
} from 'setup/tenant'
import { useLoanApplication } from 'context/LoanApplication'
import { useLoanOffer } from 'context/LoanOffer'
import { Tenant } from '@banxware/offer-generation/utils/tenant'
import { useState } from 'react'

const calculateDiscountedOffer = (
  discountType: string,
  discountValue: number,
  fee: number,
  payoutAmount: number,
  rate: string,
  term: number,
) => {
  let discountedFee = fee

  if (discountType === 'percentageFeeDiscount') {
    discountedFee = ((100 - discountValue) * fee) / 100
  } else if (discountType === 'fixedFeeDiscount') {
    discountedFee = Math.round(discountValue / 100)
  }

  const discountedAmount = payoutAmount + discountedFee
  const discountedRate = isDynamicRepayment
    ? rate
    : Number((discountedAmount / term).toFixed(2))

  return { discountedAmount, discountedFee, discountedRate }
}

export const useCalculations = () => {
  const {
    offer,
    revenue,
    applicationForm,
    discountType,
    discountValue,
    isVoucherValid,
  } = useLoanOffer()
  const { currentLoanApplication } = useLoanApplication()
  const [requestedPayoutAmount, setRequestedPayoutAmount] = useState(() =>
    numberFromMoney(moneyDtoToMoney(offer?.maxAmount)),
  )
  const [selectedLoanTerm, setSelectedLoanTerm] = useState(() => offer?.term)
  const { maxAmount, minAmount } = offer

  const mccCode = isTeleCash
    ? applicationForm?.tenantSpecific?.mcc ||
      currentLoanApplication?.tenantSpecific?.mcc
    : applicationForm?.tenantSpecific?.industry ||
      currentLoanApplication?.tenantSpecific?.industry

  const loanPurpose = isBanxy ? 'Inventory' : (undefined as any)

  const calculatedOffer = calculateDisplayOffer({
    tenantCode: activeTenant.tenantCode as Tenant,
    maxAmount,
    minAmount,
    term: selectedLoanTerm,
    revenue,
    requestedPayoutAmount,
    mccCode,
    loanPurpose,
    format: 'raw',
  })

  const revenueInMoney = moneyDtoToMoney(revenue)
  const amountInMoney = moneyFromNumber(calculatedOffer.payoutAmount * 100)

  const adjustedTotalAmountInMoney = recalculateTotalAmount(
    activeTenant.tenantCode as Tenant,
    amountInMoney,
    selectedLoanTerm,
    loanPurpose,
  )

  const percentageRate = calculateRate({
    totalAmount: adjustedTotalAmountInMoney,
    term: selectedLoanTerm,
    revenue: revenueInMoney,
    tenantCode: activeTenant.tenantCode as Tenant,
    mccCode: mccCode as never,
  })

  const discountedOffer =
    isVoucherValid &&
    calculateDiscountedOffer(
      discountType,
      discountValue,
      calculatedOffer.feeInEuro,
      calculatedOffer.payoutAmount,
      calculatedOffer.rate as unknown as string,
      selectedLoanTerm,
    )

  const calculatedOfferValues = {
    feeInEuro: discountedOffer
      ? discountedOffer.discountedFee
      : calculatedOffer.feeInEuro,
    payoutAmount: calculatedOffer.payoutAmount,
    monthlyRepayment: discountedOffer
      ? discountedOffer.discountedRate
      : calculatedOffer.rate,
    originalFee: calculatedOffer.feeInEuro,
    percentageRate,
  }

  return {
    term: selectedLoanTerm,
    setSelectedLoanTerm,
    setRequestedPayoutAmount,
    maxAmount: numberFromMoney(moneyDtoToMoney(maxAmount)) / 100,
    minAmount: numberFromMoney(moneyDtoToMoney(minAmount)) / 100,
    possibleLoanTerms: calculatedOffer.possibleLoanTerms as PossibleTerms,
    finalTotalLoanAmount: calculatedOffer.finalTotalLoanAmount,
    ...calculatedOfferValues,
  }
}
