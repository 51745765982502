export const ERROR_MESSAGE = Object.freeze({
  ACCOUNT_EXISTS: 'errorMessage.accountExists',
  EMAIL_TAKEN: 'errorMessage.api.merchantEmailTaken',
  GENERIC: 'errorMessage.generic',
  INVALID_URL: 'errorMessage.invalidUrl',
  NO_APPLICATION: 'errorMessage.noApplication',
  NON_EDITABLE: 'errorMessage.nonEditable',
  SHOULD_BE_VALID_SHARES_AMOUNT: 'validators.shouldBeValidSharesAmount',
  UNAUTHORIZED: 'errorMessage.unauthorized',
})

export const RESPONSE_ERROR = Object.freeze({
  401: ERROR_MESSAGE.UNAUTHORIZED,
  422: ERROR_MESSAGE.INVALID_URL,
  404: null,
})

export const API_ERROR = Object.freeze({
  'API_VALIDATION.LOAN_APPLICATION.NON_EDITABLE': ERROR_MESSAGE.NON_EDITABLE,
  'API_VALIDATION.BUSINESSDETAILS.WEBSITE': 'errorMessage.api.websiteInvalid',
  'API_VALIDATION.MERCHANTUSER.EMAIL': ERROR_MESSAGE.EMAIL_TAKEN,
  'API_VALIDATION.OWNER.SHARES_PERCENT':
    ERROR_MESSAGE.SHOULD_BE_VALID_SHARES_AMOUNT,
  SYSTEM: ERROR_MESSAGE.GENERIC,
})
