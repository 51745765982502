import { ROUTES } from 'constants/routes'
import { ExpressApplicationPhase1Submitted } from 'views/LoanApplication/ExpressApplicationPhase1Submitted'
import { ApplicationFinalSubmitted } from 'views/LoanApplication/ApplicationFinalSubmitted'
import { ApplicationSummary } from 'views/LoanApplication/ApplicationSummary'
import { BankLinking } from 'views/LoanApplication/BankLinking'
import { BusinessAddress } from 'views/LoanApplication/BusinessAddress'
import { BusinessDetails } from 'views/LoanApplication/BusinessDetails'
import BusinessLegal from 'views/LoanApplication/BusinessLegal'
import { CompanySearch } from 'views/LoanApplication/CompanySearch'
import { ConnectShoppingPlatform } from 'views/LoanApplication/ConnectShoppingPlatform'
import LoanRejectionCreditScore from 'views/LoanRejection/CreditScore'
import LoanClosed from 'views/LoanRejection/Closed'
import { CheckCustomizeLoanOffer } from 'views/LoanApplication/CustomizeLoanOffer'
import DocumentsUpload from 'views/LoanApplication/DocumentsUpload'
import TimeOfRunBusinessEligibility from 'views/LoanRejection/Eligibility/TimeOfRunBusiness'
import RevenueEligibility from 'views/LoanRejection/Eligibility/Revenue'
import LegalFormEligibility from 'views/LoanRejection/Eligibility/LegalForm'
import LoanRejectionRegion from 'views/LoanRejection/Region'
import MerchantIntegrationError from 'views/LoanRejection/MerchantIntegrationError'
import LinkTimedOut from 'views/LoanRejection/LinkTimedOut'
import LinkWithoutBlob from 'views/LoanRejection/LinkWithoutBlob'
import { Error as ErrorComponent } from 'views/Error'
import Imprint from 'views/Imprint'
import HomePageLoan from 'views/HomePageLoan'
import IdentityVerification from 'views/LoanApplication/IdentityVerification'
import { LoanProcessOverview } from 'views/LoanApplication/LoanProcessOverview'
import { Login } from 'views/Login'
import { OptinMerchant } from 'views/LoanApplication/OptinMerchant'
import { OwnerDetails } from 'views/LoanApplication/OwnerDetails'
import { ResetPassword } from 'views/ResetPassword'
import SignUp from 'views/LoanApplication/SignUp'
import { Stakeholders } from 'views/LoanApplication/Stakeholders'
import { MerchantPortal } from 'views/MerchantPortal'
import { AdditionalInformation } from 'views/LoanApplication/Additionalnformation'
import { isLinkIntegration } from 'setup/tenant'
import { LoanOffersView } from 'views/LoanApplication/LoanOffers'

export const PROTECTED_LOAN_APPLICATION_ROUTES = [
  {
    path: ROUTES.APPLICATION_REVIEW,
    component: ApplicationFinalSubmitted,
  },
  {
    path: ROUTES.EXPRESS_APPLICATION_REVIEW,
    component: ExpressApplicationPhase1Submitted,
  },
  {
    path: ROUTES.EXPRESS_APPLICATION_SUBMITTED,
    component: ApplicationFinalSubmitted,
  },
  {
    path: ROUTES.APPLICATION_SUMMARY,
    component: ApplicationSummary,
  },
  {
    path: ROUTES.ADDITIONAL_INFORMATION,
    component: AdditionalInformation,
  },
  {
    path: ROUTES.BANK_LINKING,
    component: BankLinking,
  },
  {
    path: ROUTES.BUSINESS_ADDRESS,
    component: BusinessAddress,
  },
  {
    path: ROUTES.BUSINESS_DETAILS,
    component: BusinessDetails,
  },
  {
    path: ROUTES.BUSINESS_LEGAL,
    component: BusinessLegal,
  },
  {
    path: ROUTES.COMPANY_SEARCH,
    component: CompanySearch,
  },
  {
    path: ROUTES.CONNECT_SHOPPING_PLATFORM,
    component: ConnectShoppingPlatform,
  },
  {
    path: ROUTES.DOCUMENTS_UPLOAD,
    component: DocumentsUpload,
  },
  {
    path: ROUTES.CONNECT_SHOPPING_PLATFORM_DOCUMENTS,
    component: DocumentsUpload,
  },
  {
    path: ROUTES.MANUAL_REPORT_UPLOAD,
    component: DocumentsUpload,
  },
  {
    path: ROUTES.AFTERBUY,
    component: DocumentsUpload,
  },
  {
    path: ROUTES.EBAY,
    component: DocumentsUpload,
  },
  {
    path: ROUTES.GAMBIO,
    component: DocumentsUpload,
  },
  {
    path: ROUTES.GIROCARD,
    component: DocumentsUpload,
  },
  {
    path: ROUTES.HOOD,
    component: DocumentsUpload,
  },
  {
    path: ROUTES.IDENTITY_VERIFICATION,
    component: IdentityVerification,
  },
  {
    path: ROUTES.LOAN_PROCESS_OVERVIEW,
    component: LoanProcessOverview,
  },
  {
    path: ROUTES.OPTIN_MERCHANT,
    component: OptinMerchant,
  },
  {
    path: ROUTES.OWNER_DETAILS,
    component: OwnerDetails,
  },
  {
    path: ROUTES.STAKEHOLDERS,
    component: Stakeholders,
  },
]

export const AUTH_ROUTES = [
  {
    path: ROUTES.LOGIN,
    component: Login,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    component: ResetPassword,
  },
  {
    path: ROUTES.RESET_PASSWORD_NEW,
    component: ResetPassword,
  },
]

export const MERCHANT_PORTAL_ROUTES = [
  {
    path: ROUTES.MERCHANT_PORTAL,
    component: MerchantPortal,
  },
  {
    path: ROUTES.MERCHANT,
    component: MerchantPortal,
  },
]

export const DEFAULT_LOAN_APPLICATION_ROUTES = [
  {
    path: ROUTES.ROOT,
    component: HomePageLoan,
  },
  {
    path: ROUTES.CUSTOMIZE_LOAN_OFFER_GRL,
    component: CheckCustomizeLoanOffer,
  },
  {
    path: ROUTES.CUSTOMIZE_LOAN_OFFER_BXW,
    component: CheckCustomizeLoanOffer,
  },
  {
    path: ROUTES.CUSTOMIZE_LOAN_OFFER_PAY,
    component: CheckCustomizeLoanOffer,
  },
  {
    path: ROUTES.CUSTOMIZE_LOAN_OFFER_TLC,
    component: CheckCustomizeLoanOffer,
  },
  {
    path: ROUTES.CUSTOMIZE_LOAN_OFFER_TEST,
    component: CheckCustomizeLoanOffer,
  },
  {
    path: ROUTES.CUSTOMIZE_LOAN_OFFER_BXY,
    component: CheckCustomizeLoanOffer,
  },
  {
    path: ROUTES.ERROR,
    component: ErrorComponent,
  },
  {
    path: ROUTES.IMPRINT,
    component: Imprint,
  },
  {
    path: ROUTES.LOAN_OFFERS,
    component: LoanOffersView,
  },
  {
    path: ROUTES.LOAN_REJECTION_CREDIT_SCORE,
    component: LoanRejectionCreditScore,
  },
  {
    path: ROUTES.CLOSED,
    component: LoanClosed,
  },
  {
    path: ROUTES.LOAN_REJECTION_TIME_OF_RUN_BUSINESS,
    component: TimeOfRunBusinessEligibility,
  },
  {
    path: ROUTES.LOAN_REJECTION_REVENUE,
    component: RevenueEligibility,
  },
  {
    path: ROUTES.LOAN_REJECTION_LEGALFORM,
    component: LegalFormEligibility,
  },
  {
    path: ROUTES.LOAN_REJECTION_REGION,
    component: LoanRejectionRegion,
  },
  {
    path: ROUTES.MERCHANT_INTEGRATION_ERROR,
    component: MerchantIntegrationError,
  },
  {
    path: ROUTES.LINK_TIMED_OUT,
    component: LinkTimedOut,
  },
  {
    path: ROUTES.LINK_WITHOUT_BLOB,
    component: LinkWithoutBlob,
  },
  {
    path: ROUTES.SIGN_UP,
    component: SignUp,
  },
]

// The user should not be able to ever reach these pages in a link integration application
const unavailableLinkIntegrationRoutes: typeof ROUTES[keyof typeof ROUTES][] = [
  ROUTES.ROOT,
  ROUTES.LOGIN,
  ROUTES.RESET_PASSWORD,
  ROUTES.RESET_PASSWORD_NEW,
]

export const LINK_INTEGRATION_ROUTES = DEFAULT_LOAN_APPLICATION_ROUTES.filter(
  (route: {
    path: typeof ROUTES[keyof typeof ROUTES]
    component: React.ReactNode
  }) => !unavailableLinkIntegrationRoutes.includes(route.path),
)

export const LOAN_APPLICATION_ROUTES = isLinkIntegration
  ? LINK_INTEGRATION_ROUTES
  : DEFAULT_LOAN_APPLICATION_ROUTES

export const PROTECTED_FROM_REROUTE_PATHS: string[] = [ROUTES.IMPRINT]
