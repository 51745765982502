import React, { useMemo, useState } from 'react'
import { CustomizeSlider } from 'components/CustomizeSlider'

type CustomizeLoanSliderProps = {
  minPayoutAmount: number
  maxPayoutAmount: number
  payoutAmount: number
  setPayoutAmount: (amount: number) => void
}

const CustomizeLoanSlider = ({
  payoutAmount,
  maxPayoutAmount,
  minPayoutAmount,
  setPayoutAmount,
}: CustomizeLoanSliderProps) => {
  const [sliderValue, setSliderValue] = useState<number>(payoutAmount)
  const marks = useMemo(
    () => generateSliderMarks(maxPayoutAmount, minPayoutAmount),
    [maxPayoutAmount, minPayoutAmount],
  )

  const handleSliderChange = (e: Event) => {
    const value = +(e.target as HTMLInputElement).value
    if (value === payoutAmount) return
    setSliderValue(value)
    setPayoutAmount(value)
  }

  return (
    <>
      {sliderValue && (
        <CustomizeSlider
          testId="customize-loan-amount-slider"
          value={sliderValue}
          onChange={handleSliderChange}
          min={minPayoutAmount}
          max={maxPayoutAmount}
          step={null}
          marks={marks}
          aria-label="Loan amount"
        />
      )}
    </>
  )
}

// generates Slider marks with restricted values as Slider steps
const roundToNth = (v: number, n: number) => Math.floor(v / n) * n

const calculateNextValue = (value: number) => {
  if (value < 500) return roundToNth(value, 50) + 50
  if (value < 5000) return roundToNth(value, 100) + 100
  if (value < 10_000) return roundToNth(value, 500) + 500
  return roundToNth(value, 1000) + 1000
}

const generateSliderMarks = (
  maxPayoutAmount: number,
  minPayoutAmount = 450,
  currValue: number = minPayoutAmount,
  marks: { value: number }[] = [],
) => {
  while (currValue <= maxPayoutAmount) {
    marks.push({ value: currValue })
    currValue = calculateNextValue(currValue)
  }
  // always have highest payout amount even if it's not rounded
  if (marks[marks.length - 1].value !== maxPayoutAmount)
    marks.push({ value: maxPayoutAmount })

  return marks
}

export default CustomizeLoanSlider
