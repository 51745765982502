import React from 'react'
import { Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { LoginIcon } from 'components/icons'
import { MenuButton as Button } from './MenuButton'

export const LoginButton = ({ onClick }) => (
  <Button
    {...{ onClick }}
    style={{
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    }}
  >
    <Box width="24px">
      <LoginIcon />
    </Box>
    <Box ml={3}>
      <Typography variant="body2" color="text.heading.main">
        <FormattedMessage id="header.login" />
      </Typography>
    </Box>
  </Button>
)
