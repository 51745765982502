import React from 'react'
import { styled } from '@mui/system'
import { CircularProgress } from '@mui/material'

const Spinner = styled('div')`
  position: fixed;
  z-index: 10;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;
`

export const GlobalSpinner = () => (
  <Spinner data-testid="spinner">
    <CircularProgress />
  </Spinner>
)
