import React from 'react'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { RadioButton } from 'components/buttons'

export const Switch = ({
  labelId,
  onYes,
  onNo,
  isYes,
  triggerHintComponent,
}) => (
  <Flex flex="1 1 auto" flexDirection={['column', 'row', 'row']}>
    <Flex
      alignItems="center"
      justifyContent="space-between"
      p={3}
      flex="1 1 auto"
      minHeight="80px"
      sx={{
        borderRight: (theme) => [
          'none',
          theme.borders.primary,
          theme.borders.primary,
        ],
        borderBottom: (theme) => [theme.borders.primary, 'none', 'none'],
        color: 'input.label.main',
      }}
    >
      <Typography variant="body2">
        <FormattedMessage id={labelId} />
      </Typography>
      <Box ml={3} flex="0 0 auto">
        {triggerHintComponent}
      </Box>
    </Flex>
    <Flex flex="0 0 auto" alignItems="stretch">
      <RadioButton
        testId={`${labelId}-switch-yes`}
        onClick={() => {
          if (onYes) onYes()
        }}
        selected={isYes === true}
      >
        <FormattedMessage id="common.yes" />
      </RadioButton>
      <Box
        sx={{
          width: '1px',
          backgroundColor: 'global.line.main',
          flex: '0 0 1px',
        }}
      />
      <RadioButton
        testId={`${labelId}-switch-no`}
        onClick={() => {
          if (onNo) onNo()
        }}
        selected={isYes === false}
      >
        <FormattedMessage id="common.no" />
      </RadioButton>
    </Flex>
  </Flex>
)
