import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BoxProps, Typography } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { Button, BackButton } from 'components/buttons'
import { ROUTES } from 'constants/routes'
import activeTenant from 'setup/tenant'
import { useNavigation } from 'routing/hooks/useNavigation'
import { Divider } from 'components/Divider'
import ButtonLink from 'components/buttons/ButtonLink/ButtonLink'
import { useLanguage } from 'context/Language'
import { createCurrencyFormatter } from 'utils/currency'
import VoucherValidation from 'views/LoanApplication/CustomizeLoanOffer/components/VoucherValidation'

type VoucherLoanOfferBoxProps = BoxProps & {
  amount?: number
  duration?: number
  discountedFee?: number
  originalFee?: number
  rate?: number
  onClick: () => Promise<void>
}

export const VoucherLoanOfferBox = (props: VoucherLoanOfferBoxProps) => {
  const {
    amount,
    rate,
    duration,
    onClick,
    discountedFee,
    originalFee,
    ...restProps
  } = props
  const { navigateTo } = useNavigation()
  const { locale } = useLanguage()
  const formatter = createCurrencyFormatter(locale)

  const handleNavigateToCustomizeLoanOffer = () => {
    navigateTo(activeTenant.customizeLoanOfferRoute)
  }

  return (
    <>
      <Flex
        {...restProps}
        flexDirection={['row', 'row', 'column']}
        justifyContent={['space-between', 'space-between', 'center']}
        mx={[0, 0, 4]}
        mb={[3, 3, 0]}
      >
        <Flex flexDirection="column">
          {amount && (
            <>
              <Flex justifyContent="center">
                <Typography sx={{ color: 'text.heading.main' }}>
                  <FormattedMessage id="loanOffer.payoutAmount" />
                </Typography>
              </Flex>
              <Flex
                justifyContent="center"
                sx={{
                  fontSize: ['60px', '60px', '60px'],
                  lineHeight: ['80px', '80px', '80px'],
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: 'text.normal.main',
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                  }}
                  data-testid="loanOfferAmount"
                >
                  {formatter.formatInt(amount)}
                </Typography>
              </Flex>
            </>
          )}
          <ButtonLink
            data-testid="customizeLoanOfferButton"
            Icon={null}
            onClick={handleNavigateToCustomizeLoanOffer}
            sx={{
              mb: 6,
            }}
          >
            <FormattedMessage id="loanOffers.customizeOffer.actionText" />
          </ButtonLink>

          {duration && (
            <Flex>
              <Box mb={[2, 2, '10px']} mr="5px">
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  {duration}
                </Typography>
              </Box>
              <Typography>
                <FormattedMessage id="misc.estimatedRepaymentTime" />
              </Typography>
            </Flex>
          )}
          <VoucherValidation
            discountedFee={discountedFee}
            originalFee={originalFee}
            payoutAmount={amount}
            rate={rate}
          />

          <Divider sx={{ display: ['none', 'none', 'block'] }} />

          <Flex my={5} mt="30px">
            <BackButton onClick={() => navigateTo(ROUTES.ROOT)} />
            <Button data-testid="loanOfferButton" withArrow onClick={onClick}>
              <FormattedMessage id="loanOffer.requestLoan" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
