import React, {
  ReactNode,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react'
import { Offer, SelectedOffer } from 'types/offers'
import { LoanApplication } from 'types/loanApplication'
import { LoanPurpose } from '@banxware/offer-generation'
import { usePersistedState } from '../utils/persist'
import { useError } from '../Error'
import { PERSIST_KEY } from '../constants'
import { useApiRequest } from '../utils/useApiRequest'
import { API_URL, API_PATH, PANTHER_SERVICES_URL } from '../../constants/api'
import { useAuth } from '../Auth'

type LoanOfferProviderProps = {
  children: ReactNode
}

type Revenue = {
  // @TODO type currency
  currency: string
  value: string
}

type MerchantInfo = {
  revenue: Revenue
  timeOfRunBusiness: number
  industry?: string
  loanPurpose?: LoanPurpose
}

export type ApplicationForm = Omit<LoanApplication, 'approximateSales'> & {
  approximateSales: number
}

type State = {
  applicationForm?: ApplicationForm
  isLoading?: boolean
  isError?: boolean
  offer?: Offer
  revenue?: Revenue
  selectedOffer?: SelectedOffer
  voucherCode?: string
  isVoucherValid?: boolean
  discountType?: string
  discountValue?: number
  discountReason?: string
  isHydrating?: boolean
  loanOffers?: any
}

export type VoucherValidationResponse = {
  valid: boolean
  discountType: string
  discountValue: number
  discountReason: string
}

export type LoanOfferContextState = State & {
  requestLoanOffer: (merchantInfo?: MerchantInfo) => Promise<null | Offer>
  setLoanOffer: (offer: Offer) => void
  setRevenue: (revenue: Revenue) => void
  selectLoanOffer: (prop: {
    loanOffer: SelectedOffer & { voucher?: string }
  }) => void
  saveLoanOffer: (prop: {
    loanApplicationId: string
    loanOffer?: SelectedOffer
  }) => Promise<void>
  updateSelectedLoanOffer: (props: {
    loanApplicationId: string
    loanOffer: SelectedOffer
    offerId: string
  }) => Promise<void>
  fetchSelectedLoanOffer: (loanApplicationId: string) => Promise<void>
  setApplicationForm: (applicationForm: Partial<ApplicationForm>) => void
  applyVoucher: (voucherCode: string) => Promise<boolean>
  setIsLoading: (isLoading: boolean) => void
}

export const LoanOfferContext = createContext<
  undefined | LoanOfferContextState
>(undefined)

const initialState: State = Object.freeze({
  applicationForm: null,
  isLoading: false,
  isError: false,
  offer: null,
  // expected to be an object like { currency: "EUR", value: "1500000" }
  revenue: null,
  selectedOffer: null,
  isVoucherValid: false,
  discountType: null,
  discountValue: null,
  discountReason: null,
  loanPurpose: null,
  isHydrating: false,
  voucherCode: undefined,
})

export function LoanOfferProvider(props: LoanOfferProviderProps) {
  const { token } = useAuth()
  const [state, setState] = usePersistedState<State>(
    PERSIST_KEY.LOAN_OFFER,
    initialState,
  )

  const {
    applicationForm,
    isLoading,
    isError,
    revenue,
    loanOffers,
    selectedOffer,
    voucherCode,
    isVoucherValid,
    discountType,
    discountValue,
    discountReason,
    isHydrating,
    offer,
  } = state

  useEffect(() => {
    if (!token) {
      setState({ ...initialState })
    }
  }, [token, setState])

  const { handleApiRequestError } = useError()
  const { getRequest, postRequest, putRequest } = useApiRequest()

  const setApplicationForm = useCallback(
    (newApplicationForm: ApplicationForm) => {
      setState({ applicationForm: newApplicationForm })
    },
    [setState],
  )

  const setIsLoading = useCallback(
    (loadingState: boolean) => {
      setState({ isLoading: loadingState })
    },
    [setState],
  )

  const requestLoanOffer = useCallback(
    async (merchantInfo: MerchantInfo) => {
      if (!merchantInfo) return null
      const { revenue: merchantRevenue } = merchantInfo
      try {
        setIsLoading(true)
        const returnedOffer = await postRequest<Offer>({
          url: `${PANTHER_SERVICES_URL}${API_PATH.OFFERS}`,
          body: merchantInfo,
        })
        setState({
          offer: returnedOffer,
          revenue: merchantRevenue,
        })
        return returnedOffer
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        handleApiRequestError(error)
        return null
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, postRequest, setState, handleApiRequestError],
  )

  const setLoanOffer = useCallback(
    (newOffer: Offer) => {
      setState((cState) => ({ ...cState, offer: newOffer }))
    },
    [setState],
  )

  const setRevenue = useCallback(
    (newRevenue: Revenue) => {
      setState((cState) => ({ ...cState, revenue: newRevenue }))
    },
    [setState],
  )

  const selectLoanOffer = useCallback(
    ({ loanOffer }: { loanOffer: SelectedOffer }) => {
      setState({ selectedOffer: loanOffer })
    },
    [setState],
  )

  const saveLoanOffer = useCallback(
    async ({
      loanApplicationId,
      loanOffer,
    }: {
      loanApplicationId: string
      loanOffer?: SelectedOffer
    }) => {
      if (!loanApplicationId) return
      try {
        setIsLoading(true)
        await postRequest<{
          selectedOffer: SelectedOffer
        }>({
          url: `${PANTHER_SERVICES_URL}/loan-applications/${loanApplicationId}/offers`,
          body: loanOffer || selectedOffer,
        })
      } catch (error: unknown) {
        handleApiRequestError(error)
        setState({ isError: true })
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, postRequest, selectedOffer, handleApiRequestError, setState],
  )

  const updateSelectedLoanOffer = useCallback(
    async ({
      loanApplicationId,
      loanOffer,
      offerId,
    }: {
      loanApplicationId: string
      loanOffer: SelectedOffer
      offerId: string
    }) => {
      if (!loanApplicationId) return
      try {
        setIsLoading(true)
        const updatedOffer = await putRequest<SelectedOffer>({
          url: `${PANTHER_SERVICES_URL}/loan-applications/${loanApplicationId}/offers/${offerId}`,
          body: loanOffer,
        })
        setState({ selectedOffer: updatedOffer })
      } catch (error) {
        handleApiRequestError(error)
        setState({ isError: true })
      } finally {
        setIsLoading(false)
      }
    },
    [handleApiRequestError, putRequest, setState, setIsLoading],
  )

  const fetchSelectedLoanOffer = useCallback(
    async (loanApplicationId) => {
      try {
        if (!token) return
        setIsLoading(true)
        const savedSelectedOffer = await getRequest<SelectedOffer>({
          url: `${PANTHER_SERVICES_URL}/loan-applications/${loanApplicationId}/offers`,
        })
        setState({ selectedOffer: savedSelectedOffer })
      } catch (error: unknown) {
        // @ts-expect-error Property 'cause' does not exist on type 'unknown'
        if (error.cause === 404) {
          setState({ selectedOffer: null })
        } else {
          handleApiRequestError(error)
          setState({ isError: true })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [getRequest, handleApiRequestError, setState, setIsLoading, token],
  )

  const applyVoucher = useCallback(
    async (newVoucherCode: string) => {
      const trimmedVoucherCode = newVoucherCode?.trim()

      // for empty vouchers just update state and return early
      if (!trimmedVoucherCode) {
        setState({
          voucherCode: trimmedVoucherCode,
          isVoucherValid: false,
          discountType: null,
          discountValue: null,
          discountReason: null,
        })

        return false
      }

      // If the voucher code is in state already, and the user re-enters the same voucher code,
      // we skip making the api call.
      if (trimmedVoucherCode === voucherCode) {
        return isVoucherValid
      }

      const saveDiscountResponse = await getRequest<VoucherValidationResponse>({
        url: `${API_URL}${API_PATH.VOUCHER}/${trimmedVoucherCode}`,
      })

      if (saveDiscountResponse.valid) {
        setState({
          voucherCode: trimmedVoucherCode,
          isVoucherValid: saveDiscountResponse.valid,
          discountType: saveDiscountResponse.discountType,
          discountValue: saveDiscountResponse.discountValue,
          discountReason: saveDiscountResponse.discountReason,
        })

        return false
      }
    },
    [getRequest, isVoucherValid, setState, voucherCode],
  )

  const contextValue = useMemo(
    () => ({
      applicationForm,
      isLoading,
      isError,
      offer,
      revenue,
      loanOffers,
      selectedOffer,
      voucherCode,
      isVoucherValid,
      discountType,
      discountValue,
      discountReason,
      requestLoanOffer,
      selectLoanOffer,
      updateSelectedLoanOffer,
      fetchSelectedLoanOffer,
      setLoanOffer,
      setRevenue,
      setApplicationForm,
      applyVoucher,
      setIsLoading,
      isHydrating,
      saveLoanOffer,
    }),
    [
      applicationForm,
      setIsLoading,
      isLoading,
      isError,
      offer,
      isHydrating,
      revenue,
      saveLoanOffer,
      loanOffers,
      selectedOffer,
      voucherCode,
      isVoucherValid,
      discountType,
      discountValue,
      discountReason,
      requestLoanOffer,
      selectLoanOffer,
      updateSelectedLoanOffer,
      fetchSelectedLoanOffer,
      setLoanOffer,
      setRevenue,
      setApplicationForm,
      applyVoucher,
    ],
  )

  return (
    <LoanOfferContext.Provider value={contextValue}>
      {props.children}
    </LoanOfferContext.Provider>
  )
}

export function useLoanOffer() {
  const context = useContext(LoanOfferContext)
  if (context === undefined) {
    throw new Error('useLoanOffer must be used within a LoanOfferProvider')
  }
  return context
}
