import React, { useState } from 'react'
import { Flex, Box } from 'components/structural'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Box as MuiBox,
} from '@mui/material'
import { Grid } from 'components/Grid'
import { ArrowDownIcon } from 'components/icons'
import { Summary } from './Summary'
import { Form } from './Form'
import { stakeholderValidator } from '../../utils'

export const Stakeholder = (props) => {
  const [expanded, setExpanded] = useState(false)
  const [animationEnded, setAnimationEnded] = useState(true)

  const handleSetExpanded = () => {
    setExpanded(!expanded)
  }

  const handleSubmit = (values) => {
    props.handleUpdateOwner(values)
    handleSetExpanded()
  }
  const { address, applicantOwner, ...initialValues } = props.owner || {}

  return (
    <Box mb={5}>
      <Accordion
        expanded={expanded}
        square
        TransitionProps={{
          onEnter: () => setAnimationEnded(false),
          onExited: () => setAnimationEnded(true),
        }}
        sx={{
          width: 'inherit',
          '& .MuiAccordionSummary-root': {
            padding: 0,
          },
        }}
      >
        <Grid columns={3}>
          <Grid.Column
            sx={{
              gridColumnEnd: ['span 3', 'span 3', 'span 2'],
              border: (theme) => theme.borders.primary,
              backgroundColor: 'global.bgB.main',
              '& .Mui-focusVisible': {
                backgroundColor: 'global.bgB.main',
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <IconButton onClick={handleSetExpanded}>
                  <Box sx={{ width: '24px', height: '24px' }}>
                    <ArrowDownIcon />
                  </Box>
                </IconButton>
              }
              sx={{
                cursor: 'default !important',
                width: 'inherit',
                '& .MuiAccordionSummary-expandIconWrapper': {
                  position: 'absolute',
                  top: 0,
                  right: 0,
                },
                '& .MuiAccordionSummary-root': {
                  padding: '0',
                },
                '& .MuiAccordionSummary-content': {
                  width: '100%',
                },
                '& .MuiIconButton-root': {
                  width: '24px',
                  height: '24px',
                  padding: '2px',
                  margin: 2,
                },
              }}
            >
              <Flex flexDirection="column" flexGrow="1">
                <Summary
                  handleUpdateOwner={props.handleUpdateOwner}
                  handleDeleteOwner={() =>
                    props.handleDeleteOwner(props.owner.id)
                  }
                  owner={props.owner}
                  showUbo={!expanded && animationEnded}
                />
              </Flex>
            </AccordionSummary>
          </Grid.Column>
        </Grid>
        <AccordionDetails sx={{ padding: 0 }}>
          <Form
            onCancel={handleSetExpanded}
            isApplicantOwner={applicantOwner}
            onSubmit={handleSubmit}
            initialValues={{
              ...initialValues,
              address: {
                address1: address.address1,
                address2: address.address2,
                city: address.city,
                country: address.country,
                zipCode: address.zipCode,
              },
            }}
            validate={stakeholderValidator}
            enableReinitialize
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
