import React from 'react'
import { FormattedMessage } from 'react-intl'

import { requiredValidator } from 'utils/forms/validators'

import { TextField } from './TextField'

export const Address1 = (props) => (
  <TextField
    name="address1"
    label={<FormattedMessage id="fields.address1" />}
    validate={requiredValidator}
    {...props}
  />
)
