import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { useWindowSizes } from 'hooks'
import { GlobalSpinner } from 'components/spinners'
import { CurrentValue, RangeLabels } from 'components/CustomizeSlider'
import { Layout } from 'components/Layout'
import { BREAKPOINTS } from 'constants/styles'
import { ROUTES } from 'constants/routes'
import { Button, BackButton } from 'components/buttons'
import { ViewHeader } from 'components/headers'
import { OffersIcon } from 'components/icons'
import activeTenant, { tenantCode } from 'setup/tenant'
import { createCurrencyFormatter } from 'utils/currency'
import { Grid } from 'components/Grid'
import {
  configuration,
  TenantConfig,
  Term,
} from '@banxware/offer-generation/configuration'
import { useNavigation } from 'routing/hooks/useNavigation'
import { useLanguage } from 'context/Language'
import { useLoanOffer } from 'context/LoanOffer'
import { useLoanApplication } from 'context/LoanApplication'
import { Divider } from 'components/Divider'
import { noop } from 'lodash'
import VoucherValidation from './components/VoucherValidation'
import SwitchTerms from './components/SwitchTerms'
import CustomizeLoanSlider from './components/CustomizeSlider'
import { useCalculations } from '../LoanOffers/useLoanOfferCalculations'
import { useHandleSelectedOffer } from './useHandleSelectedOffer'

// we have tentants in the FE that are not available in offer-generation
const tenantConfig = configuration[tenantCode] as undefined | TenantConfig
const availableTerms = tenantConfig?.possibleTerms

export const CheckCustomizeLoanOffer = () => {
  const { offer } = useLoanOffer()
  return offer ? <CustomizeLoanOffer /> : <GlobalSpinner />
}

export const CustomizeLoanOffer = () => {
  const { windowWidth } = useWindowSizes()
  const { locale } = useLanguage()
  const formatter = createCurrencyFormatter(locale)
  const { navigateTo } = useNavigation()
  const { fetchSelectedLoanOffer } = useLoanOffer()

  const { currentLoanApplication } = useLoanApplication()

  const {
    payoutAmount,
    term,
    feeInEuro,
    originalFee,
    monthlyRepayment,
    minAmount,
    maxAmount,
    setRequestedPayoutAmount,
    possibleLoanTerms,
    setSelectedLoanTerm,
    percentageRate,
  } = useCalculations()

  const handleSelectOffer = useHandleSelectedOffer({
    payoutAmount,
    term,
    feeInEuro,
    percentageRate,
  })

  useEffect(() => {
    if (currentLoanApplication?.id) {
      fetchSelectedLoanOffer(currentLoanApplication?.id).catch(noop)
    }
  }, [fetchSelectedLoanOffer, currentLoanApplication?.id])

  const handleTermChange = React.useCallback(
    (newTerm: Term) => {
      if (newTerm === undefined) return
      setSelectedLoanTerm(newTerm)
    },
    [setSelectedLoanTerm],
  )

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="customizeOffer.viewHeader.heading"
          icon={<OffersIcon />}
        />
      }
      withProgress
    >
      <Grid.Column start={2} span={2}>
        <Flex flexDirection="column">
          <CurrentValue
            label={<FormattedMessage id="loanOffer.payoutAmount" />}
            value={formatter.formatInt(payoutAmount)}
          />
          <RangeLabels
            min={{
              value: formatter.formatInt(minAmount),
            }}
            max={{
              value: formatter.formatInt(maxAmount),
            }}
          />
          <CustomizeLoanSlider
            maxPayoutAmount={maxAmount}
            minPayoutAmount={minAmount}
            payoutAmount={payoutAmount}
            setPayoutAmount={setRequestedPayoutAmount}
          />
          <Flex
            justifyContent="center"
            mt={4}
            mb={2}
            sx={{ color: 'text.heading.main' }}
          >
            <Typography variant="body1">
              <FormattedMessage id="loanOffer.term" />
            </Typography>
          </Flex>
          <Flex
            sx={{
              // @ts-expect-error Property 'borders' does not exist on type 'Theme'.
              border: (theme) => theme.borders.primary,
              mt: 4,
              mb: 2,
            }}
          >
            <SwitchTerms
              onChange={handleTermChange}
              availableTerms={availableTerms}
              possibleTermsForDesiredAmount={possibleLoanTerms as Term[]}
              term={term}
            />
          </Flex>
          <VoucherValidation
            discountedFee={feeInEuro}
            originalFee={originalFee}
            payoutAmount={payoutAmount}
            rate={monthlyRepayment as number}
          />
          <Divider />
          <Flex my={3}>
            <BackButton onClick={() => navigateTo(ROUTES.LOAN_OFFERS)} />
            <Button
              style={{ flex: '1 0 auto' }}
              onClick={handleSelectOffer}
              data-testid="loanOfferButton"
              withArrow
            >
              <FormattedMessage id="loanOffer.requestLoan" />
            </Button>
          </Flex>
          {windowWidth <= parseInt(BREAKPOINTS.desktop, 10) && (
            <Flex
              justifyContent="flex-start"
              my={3}
              sx={{ color: 'text.heading.main' }}
            >
              <Typography variant="h1">
                <FormattedMessage id="customizeOffer.notRightOffer.header" />
              </Typography>
            </Flex>
          )}
          {windowWidth >= parseInt(BREAKPOINTS.desktop, 10) && (
            <Flex
              justifyContent="flex-start"
              my={3}
              sx={{ color: 'text.heading.main' }}
            >
              <Typography variant="h1">
                <FormattedMessage id="loanOffers.customizeBox.header" />
              </Typography>
            </Flex>
          )}
          <Button
            onClick={() => {
              window.location.href = `mailto:${activeTenant.contactEmail}`
            }}
            style={{ marginTop: '32px' }}
            sx={{
              color: 'button.labelB.main',
              backgroundColor: 'button.bgB.main',
              borderColor: 'input.stroke.main',
              borderWidth: '1px',
              borderStyle: 'solid',
              ':hover': {
                backgroundColor: 'button.bgB.dark',
              },
            }}
          >
            <FormattedMessage id="customizeOffer.contactUs" />
          </Button>
          <Box my={5}>
            <Typography variant="caption">
              <FormattedMessage id="customizeOffer.endCopy" />
            </Typography>
          </Box>
        </Flex>
      </Grid.Column>
    </Layout>
  )
}
