import React from 'react'
import { Formik, Form } from 'formik'
import { Flex } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Grid } from 'components/Grid'
import { Button } from 'components/buttons'
import { Email } from 'components/Fields'

type RequestResetProps = {
  handleRequestReset: (args: { email: string }) => void
  isLoading: boolean
}

export function RequestReset(props: RequestResetProps) {
  return (
    <Formik
      onSubmit={props.handleRequestReset}
      initialValues={{
        email: '',
      }}
    >
      {({ isValid, submitCount }) => (
        <Form data-testid="pantherResetPasswordForm">
          <Flex flexDirection="column" flex="1 0 auto" width="100%">
            <Email
              name="email"
              label={<FormattedMessage id="fields.email" />}
            />
            <Grid columns={3}>
              <Grid.Column
                sx={{
                  gridColumnEnd: ['span 3', 'span 3', 'span 2'],
                }}
              >
                <Button
                  type="submit"
                  loading={props.isLoading}
                  disabled={!isValid && submitCount > 0}
                  style={{
                    marginTop: '24px',
                    alignSelf: 'center',
                    borderRadius: 0,
                    width: '100%',
                  }}
                >
                  <FormattedMessage id="panther2Go.resetPassword.buttonText" />
                </Button>
              </Grid.Column>
            </Grid>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
