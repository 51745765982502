import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { acceptanceValidator } from 'utils/forms/validators'
import { Field as FormikField } from 'formik'
import { CheckboxComponent } from './CheckboxComponent'

export const LegalRepresentative = (props) => (
  <FormikField
    component={CheckboxComponent}
    data-testid="formFieldLegalRepresentative"
    name={props.name}
    label={
      <Typography variant="body2" style={{ lineHeight: '160%' }}>
        <FormattedMessage id="fields.legalRepresentative" />
      </Typography>
    }
    validate={acceptanceValidator}
    hint={<FormattedMessage id="fields.legalRepresentative.hint" />}
    inputProps={{
      'data-testid': 'legalRepresentative',
    }}
    {...props}
  />
)
