import React from 'react'
import DesktopDatePicker, {
  DesktopDatePickerProps,
} from '@mui/lab/DesktopDatePicker'
import { TextField, Box } from '@mui/material'
import { FormikTouched } from 'formik'
import { CalendarIcon } from './icons'
import { useLanguage } from '../../context/Language'

const placeholder = {
  en: 'dd/mm/yyyy',
  de: 'tt/mm/jjjj',
} as const

type DatePickerProps = Partial<DesktopDatePickerProps<Date>> & {
  name: string
  testId?: string
  setTouched: (touched: FormikTouched<any>, shouldValidate?: boolean) => void
  touched: FormikTouched<any>
}

export const DatePicker = (props: DatePickerProps) => {
  const {
    label,
    name,
    setTouched,
    touched,
    onChange,
    InputProps,
    value,
    testId,
  } = props
  const { locale } = useLanguage()

  return (
    <DesktopDatePicker
      inputFormat="dd/MM/yyyy"
      label={label}
      value={value}
      onChange={onChange}
      InputProps={InputProps}
      renderInput={(renderInputProps) => (
        <TextField
          {...renderInputProps}
          variant="standard"
          placeholder={placeholder?.[locale] || placeholder.en}
          name={name}
          onBlur={() => setTouched?.({ ...touched, [name]: true })}
          data-testid={testId}
          sx={{
            width: '100%',
            '.MuiInputAdornment-root': {
              height: '100%',
              paddingRight: '8px',
              paddingLeft: '8px',
              marginRight: 0,
              borderRight: '1px solid #CAD2DC',
              '.MuiIconButton-root': {
                marginLeft: 0,
              },
            },
            '.MuiInputLabel-root': {
              paddingLeft: '68px',
            },
          }}
        />
      )}
      components={{
        OpenPickerIcon: () => (
          <Box width="24px" height="24px">
            <CalendarIcon />
          </Box>
        ),
      }}
      InputAdornmentProps={{
        position: 'start',
      }}
    />
  )
}
