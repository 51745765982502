// TODO: check if useHint and useHintRaw can be combined more reusably into one thing, or maybe split into two more logical components
import React, { useState, useEffect, useMemo } from 'react'
import { Typography, FormHelperText } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import AnimateHeight from 'react-animate-height'

// to avoid circular dependency not importing from components/buttons directly ¯\_(ツ)_/¯
import { HintButton } from 'components/buttons/HintButton/HintButton'

const Component = ({ hint, error, errorId, height }) => (
  <AnimateHeight duration={200} {...{ height }}>
    <Box
      sx={{
        border: 'none',
        borderTop: ['none', 'none', 'none'],
        borderBottom: 'none',
        pt: 3,
        px: [3, 3, 0],
        pb: [3, 3, 0],
        backgroundColor: ['global.bgB.main', 'global.bgB.main', 'transparent'],
      }}
    >
      {error && (
        <Flex flexDirection="column">
          <Box mb={height !== 0 ? 2 : 0}>
            <FormHelperText error>
              <FormattedMessage id={errorId} />
            </FormHelperText>
          </Box>
        </Flex>
      )}
      <Flex flexDirection="column">
        <Typography variant="body2">{hint}</Typography>
      </Flex>
    </Box>
  </AnimateHeight>
)

export const useHintRaw = ({ hint, error, errorId, boxed = false }) => {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    setHeight(error ? 'auto' : 0)
  }, [error])

  const hintComponent = (
    <Component {...{ hint, error, errorId, boxed, height }} />
  )

  const TriggerComponent = useMemo(
    () => (props) =>
      hint ? (
        <Box
          {...props}
          aria-expanded={height !== 0}
          onClick={(e) => {
            e.stopPropagation()
            setHeight((prev) => (prev === 'auto' ? 0 : 'auto'))
          }}
        >
          <Flex
            flexDirection="color"
            justifyContent="space-between"
            style={{ cursor: 'pointer' }}
          >
            {props.children}
            <HintButton on={height !== 0} />
          </Flex>
        </Box>
      ) : null,
    [hint, height],
  )

  return {
    hintComponent,
    TriggerComponent,
  }
}
