import React from 'react'
import { Box } from 'components/structural'
import { ReactComponent as Done } from 'images/bullets/done.svg'
import { ReactComponent as On } from 'images/bullets/on.svg'

export const OnIcon = () => (
  <Box
    sx={{
      svg: {
        circle: {
          fill: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <On />
  </Box>
)

export const DoneIcon = () => (
  <Box
    sx={{
      svg: {
        circle: {
          fill: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Done />
  </Box>
)
