import React from 'react'
import { Button } from '@mui/material'
import { Box } from 'components/structural'
import { ReactComponent as ArrowIcon } from 'images/icons/arrow-right.svg'

export const BackButton = ({ onClick, ...rest }) => (
  <Button
    variant="contained"
    onClick={onClick}
    data-testid="backButton"
    {...rest}
    sx={{
      color: 'button.labelB.main',
      backgroundColor: 'button.bgB.main',
      borderColor: 'input.stroke.main',
      borderWidth: '1px',
      borderStyle: 'solid',
      height: 80,
      width: 80,
      marginRight: 3,
      '& svg': {
        transform: 'rotate(180deg)',
      },
      '& svg g': {
        stroke: (theme) => theme.palette.button.labelB.main,
      },
      '&:hover': {
        backgroundColor: 'button.bgB.dark',
      },
    }}
  >
    <Box width="24px" height="24px">
      <ArrowIcon />
    </Box>
  </Button>
)

export default BackButton
