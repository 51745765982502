import React from 'react'
import { Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'

type CaptionProps = {
  id: string
  children: React.ReactNode | string
}

export const Caption = ({ id, children }: CaptionProps) => (
  <>
    <Box sx={{ color: 'text.subtle.main' }} mb={1}>
      <Typography variant="caption">
        <FormattedMessage id={id} />
      </Typography>
    </Box>
    <Box sx={{ color: 'text.normal.main' }}>
      <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
        {children}
      </Typography>
    </Box>
  </>
)
