import banxwareApple from 'images/favicons/banxware/apple-touch-icon.png'
import banxware32 from 'images/favicons/banxware/favicon-32x32.png'
import banxware16 from 'images/favicons/banxware/favicon-16x16.png'
import banxwarePin from 'images/favicons/banxware/safari-pinned-tab.svg'
import banxwareManifest from 'images/favicons/banxware/site.webmanifest'

import '!file-loader?name=banxware-[name].[ext]!../images/favicons/banxware/android-chrome-192x192.png'
import '!file-loader?name=banxware-[name].[ext]!../images/favicons/banxware/android-chrome-512x512.png'

import { ReactComponent as BanxwareLogo } from 'images/logos/banxware-no-r.svg'
import { EXPRESS_FLOW, ROUTES } from 'constants/routes'

import { TenantConfig, TenantPalette, TenantShape } from '.'

const palette: TenantPalette = {
  global: {
    bgA: '#FFFFFF',
    bgB: '#FAF9FF',
    line: '#344054',
    icoA: '#5325B8',
    icoB: '#666666',
    icoOff: '#E0E0E0',
    errorRed: '#DF3A5A',
    okGreen: '#68AC57',
    onDark: '#FFFFFF',
  },
  header: {
    bg: '#FFFFFE',
    title: '#0D0D0D',
    text: '#0D0D0D',
    link: '#5325B8',
  },
  input: {
    bgA: '#FFFFFF',
    bgB: '#FFFFFF',
    stroke: '#E0E0E0',
    placeholder: '#E0E0E0',
    label: '#5325B8',
    value: '#2D2D2D',
    ico: '#5325B8',
    icoOff: '#344054',
  },
  text: {
    normal: '#0D0D0D',
    heading: '#666666',
    link: '#5325B8',
    subtle: '#666666',
    off: '#E0E0E0',
  },
  button: {
    bgA: '#5325B8',
    bgB: '#DBD4EF',
    bgC: '#EEF4FB',
    labelA: '#FFFFFF',
    labelB: '#344054',
    labelC: '#666666',
  },
}

const shape: TenantShape = {
  borderRadius: 12,
  fieldBorderRadius: '12px',
}

const banxwareTenantConfig: TenantConfig = {
  tenantCode: 'BXW',
  name: 'Banxware',
  title: 'Banxware',
  logo: {
    component: BanxwareLogo,
    width: 220,
  },
  iconSet: {
    appleTouch: banxwareApple,
    favicon16: banxware16,
    favicon32: banxware32,
    manifest: banxwareManifest,
    pin: banxwarePin,
    tileColor: '#603cba',
  },
  supportContactNumber: '00 49 30 311 937 03',
  contactEmail: 'service@banxware.com',
  faqLink: 'https://help.banxware.com/de/support/solutions/80000459538',
  imprintLink: '/imprint',
  termsOfService: 'https://docs.banxware.com/AGB%20Banxware%20MCA.pdf',
  privacyPolicy:
    'https://docs.banxware.com/Datenschutzinfo%20Banxware%20MCA.pdf',
  tenantOverrides: {
    translationOverrideSuffix: 'bxw',
  },
  theme: {
    palette,
    shape,
  },
  gtmId: 'GTM-N7W4QN4',
  loanApplicationFlow: EXPRESS_FLOW,
  customizeLoanOfferRoute: ROUTES.CUSTOMIZE_LOAN_OFFER_BXW,
}

export default banxwareTenantConfig
