import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Box } from 'components/structural'
import { Form } from 'components/Form'
import {
  Register,
  Website,
  ResponsiblePublicAuthority,
  IndustryParentCode,
  IndustryChildCode,
  Country,
  NumberOfEmployees,
} from 'components/Fields'
import { Field as FormikField } from 'formik'
import { DatePickerComponent } from 'components/Fields/DatePickerComponent'
import { SwitchComponent } from 'components/Fields/SwitchComponent'
import { TextFieldComponent } from 'components/Fields/TextFieldComponent'
import {
  isGetRaoul,
  isBanxware,
  isPayOne,
  isTeleCash,
  isLinkIntegration,
} from 'setup/tenant'
import { LEGAL_FORMS } from 'constants/loanApplication'
import {
  composeValidators,
  numberOfEmployeesFormatValidator,
  numberOfEmployeesRangeValidator,
  registrationNumberPatternValidator,
  requiredValidator,
  dateFormatValidator,
  dateInFutureValidator,
  minFoundationDateValidator,
  companyTaxNumberValidator,
  booleanRequiredValidator,
  companyVatIdValidator,
} from 'utils/forms/validators'
import { useLanguage } from 'context/Language'

export const DefaultForm = ({
  initialValues,
  handleSubmit,
  handleGoBack,
  legalForm,
  error,
}) => {
  const { language } = useLanguage()
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      onBack={handleGoBack}
      withBack={!(isGetRaoul || isPayOne || isLinkIntegration || isBanxware)}
    >
      {({ values }) => (
        <Box mb={error ? 3 : 5}>
          <Box mb={5}>
            <Typography variant="h5">
              <FormattedMessage id="businessDetails.info.header" />
            </Typography>
          </Box>
          {(legalForm === LEGAL_FORMS.GBR ||
            legalForm === LEGAL_FORMS.SOLEPROPRIETOR) && (
            <ResponsiblePublicAuthority name="businessDetails.responsiblePublicAuthority" />
          )}
          {![
            LEGAL_FORMS.SOLEPROPRIETOR,
            LEGAL_FORMS.FREELANCER,
            LEGAL_FORMS.GBR,
          ].includes(legalForm) && (
            <>
              <FormikField
                component={TextFieldComponent}
                data-testid="formFieldRegistrationNumber"
                name="businessDetails.registrationNumber"
                label={<FormattedMessage id="fields.registrationNumber" />}
                validate={composeValidators(
                  requiredValidator,
                  registrationNumberPatternValidator,
                )}
                hint={
                  <FormattedMessage id="validators.registrationNumberPatternIsWrong" />
                }
              />
              <Register name="businessDetails.register" />
            </>
          )}
          <Country
            data-testid="formFieldRegistrationCountry"
            name="businessDetails.address.country"
            label={<FormattedMessage id="fields.registrationCountry" />}
          />
          <FormikField
            component={DatePickerComponent}
            testId="formFieldFoundationDate"
            label={<FormattedMessage id="fields.foundationDate" />}
            name="businessDetails.foundationDate"
            validate={composeValidators(
              requiredValidator,
              dateFormatValidator,
              dateInFutureValidator,
              minFoundationDateValidator,
            )}
            hint={<FormattedMessage id="fields.foundationDate.hint" />}
          />
          <FormikField
            component={TextFieldComponent}
            data-testid="formFieldLoanPurpose"
            label={<FormattedMessage id="fields.loanPurpose" />}
            name="loanPurpose"
            validate={requiredValidator}
            hint={<FormattedMessage id="fields.loanPurpose.hint" />}
          />
          <IndustryParentCode />
          <IndustryChildCode industryParentCode={values?.industryParentCode} />
          <FormikField
            component={TextFieldComponent}
            name="businessDetails.taxNumber"
            data-testid="formFieldTaxNumber"
            label={<FormattedMessage id="fields.companyTaxNumber" />}
            hint={<FormattedMessage id="fields.companyTaxNumber.hint" />}
            validate={composeValidators(
              requiredValidator,
              companyTaxNumberValidator,
            )}
          />
          <FormikField
            component={SwitchComponent}
            labelId="fields.subjectToVat"
            validate={booleanRequiredValidator}
            name="businessDetails.subjectToVat"
          />
          {values?.businessDetails?.subjectToVat && (
            <FormikField
              component={TextFieldComponent}
              data-testid="formFieldCompanyVatId"
              name="businessDetails.vatId"
              label={<FormattedMessage id="fields.companyVatId" />}
              validate={composeValidators(
                requiredValidator,
                companyVatIdValidator,
              )}
              hint={<FormattedMessage id="fields.companyVatId.hint" />}
            />
          )}
          <NumberOfEmployees
            hint={<FormattedMessage id="fields.numberOfEmployees.hint" />}
            validate={composeValidators(
              requiredValidator,
              numberOfEmployeesFormatValidator(language),
              numberOfEmployeesRangeValidator,
            )}
          />
          <Website name="businessDetails.website" />
          {isTeleCash && (
            <FormikField
              component={TextFieldComponent}
              data-testid="formFieldAgentNumber"
              label={<FormattedMessage id="fields.agentNumber" />}
              name="tenantSpecific.agentNumber"
              hint={<FormattedMessage id="fields.agentNumber.hint" />}
            />
          )}
        </Box>
      )}
    </Form>
  )
}
