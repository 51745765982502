import React, { useCallback, useState } from 'react'
import { PLATFORM_NAME } from 'views/LoanApplication/ConnectShoppingPlatform/constants'
import { lowerCaseDeep } from 'utils'
import { useError } from 'context/Error'
import { useApiRequest } from 'context/utils/useApiRequest'
import { API_URL, API_PATH } from 'constants/api'

type Platform = { redirectUrl: string; status: string }

type ConnectedPlatforms = Record<keyof typeof PLATFORM_NAME, Platform>

export const usePlatformConnect = () => {
  const { handleApiRequestError } = useError()
  const { getRequest, postRequest } = useApiRequest()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null)
  const [connectedPlatforms, setConnectedPlatforms] =
    useState<ConnectedPlatforms | null>(null)

  const fetchConnectedPlatforms = useCallback(async () => {
    try {
      setIsLoading(true)
      const { connections } = await getRequest<{
        connections: ConnectedPlatforms
      }>({
        url: `${API_URL}${API_PATH.CODAT_CONNECTIONS}`,
      })
      // panther core always returns the enabled integrations as a lowercase string but codat can sometimes return the linked connection names as space seperated which we convert to a camel case in the api fetching
      // This is a one time case where we need to have the object keys accesible in lower case so they can be referenced against the available codatIntegrations list on the configuration object
      setConnectedPlatforms(lowerCaseDeep(connections) as ConnectedPlatforms)
    } catch (error) {
      handleApiRequestError(error)
    } finally {
      setIsLoading(false)
    }
  }, [getRequest, handleApiRequestError, setConnectedPlatforms])

  const performPlatformLinking = useCallback(
    async (platform: string) => {
      try {
        setIsLoading(true)
        const response = await postRequest<{ redirectUrl: string }>({
          url: `${API_URL}${API_PATH.CODAT_LINKING}/${platform}`,
        })
        setRedirectUrl(response.redirectUrl)
      } catch (error) {
        handleApiRequestError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [handleApiRequestError, postRequest, setRedirectUrl],
  )

  return {
    isLoading,
    redirectUrl,
    connectedPlatforms,
    fetchConnectedPlatforms,
    performPlatformLinking,
  }
}
