import React from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { ArrowIcon } from './icons'

export const NavigationButton = ({ link, children }) => {
  const NavigationLink = styled('a')``

  return (
    <NavigationLink
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      sx={{
        width: '100%',
        flexGrow: 1,
        py: 4,
        color: 'button.labelA.main',
        backgroundColor: 'button.bgA.main',
        '&:hover': {
          backgroundColor: 'buttonBgAHover.main',
        },
        '&:disabled': {
          backgroundColor: 'buttonBgADisabled.main',
          cursor: 'not-allowed',
          boxShadow: 'inset 0 0 0 1px #CAD2DC',
        },
        textDecoration: 'none',
        display: 'inline-flex !important',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5" sx={{ color: 'button.labelA.main' }}>
        {children}
      </Typography>
      <ArrowIcon />
    </NavigationLink>
  )
}

export default NavigationButton
