import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field as FormikField } from 'formik'
import {
  requiredValidator,
  dateFormatValidator,
  dateInFutureValidator,
  is18Validator,
  composeValidators,
  minDobDateValidator,
} from 'utils/forms/validators'
import { DatePickerComponent } from './DatePickerComponent/DatePickerComponent'

export const DateOfBirth = (props) => (
  <FormikField
    component={DatePickerComponent}
    testId="formFieldDateOfBirth"
    name="dateOfBirth"
    label={<FormattedMessage id="fields.dateOfBirth" />}
    validate={composeValidators(
      requiredValidator,
      dateFormatValidator,
      dateInFutureValidator,
      is18Validator,
      minDobDateValidator,
    )}
    {...props}
  />
)
