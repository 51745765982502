import { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import {
  LOAN_APPLICATION_STATUS,
  CUSTOMER_PROGRESS,
} from 'constants/loanApplication'
import { useLoanApplication } from 'context/LoanApplication'
import { useLoanOffer } from 'context/LoanOffer'
import { useLoans } from 'context/Loans'
import { useFlowNavigation } from './useFlowNavigation'

const CUSTOMER_PROGRESS_ROUTES = {
  [CUSTOMER_PROGRESS.APPLICATION_REVIEW]: ROUTES.APPLICATION_REVIEW,
  [CUSTOMER_PROGRESS.ADDITIONAL_INFORMATION]: ROUTES.ADDITIONAL_INFORMATION,
  [CUSTOMER_PROGRESS.BANK_LINKING]: ROUTES.BANK_LINKING,
  [CUSTOMER_PROGRESS.BUSINESS_DETAILS]: ROUTES.BUSINESS_DETAILS,
  [CUSTOMER_PROGRESS.COMPANY_SEARCH]: ROUTES.COMPANY_SEARCH,
  [CUSTOMER_PROGRESS.CONNECT_SHOPPING_PLATFORM]:
    ROUTES.CONNECT_SHOPPING_PLATFORM,
  [CUSTOMER_PROGRESS.DOCUMENTS_UPLOAD]: ROUTES.DOCUMENTS_UPLOAD,
  [CUSTOMER_PROGRESS.EXPRESS_APPLICATION_REVIEW]:
    ROUTES.EXPRESS_APPLICATION_REVIEW,
  [CUSTOMER_PROGRESS.EXPRESS_APPLICATION_SUBMITTED]:
    ROUTES.EXPRESS_APPLICATION_SUBMITTED,
  [CUSTOMER_PROGRESS.IDENTITY_VERIFICATION]: ROUTES.IDENTITY_VERIFICATION,
  [CUSTOMER_PROGRESS.LOAN_OFFERS]: ROUTES.LOAN_OFFERS,
  [CUSTOMER_PROGRESS.LOAN_PROCESS_OVERVIEW]: ROUTES.LOAN_PROCESS_OVERVIEW,
  [CUSTOMER_PROGRESS.MERCHANT_PORTAL]: ROUTES.MERCHANT_PORTAL,
  [CUSTOMER_PROGRESS.OWNER_DETAILS]: ROUTES.OWNER_DETAILS,
  [CUSTOMER_PROGRESS.ROOT]: ROUTES.ROOT,
  [CUSTOMER_PROGRESS.STAKEHOLDERS]: ROUTES.STAKEHOLDERS,
  [CUSTOMER_PROGRESS.APPLICATION_SUMMARY]: ROUTES.APPLICATION_SUMMARY,
  [LOAN_APPLICATION_STATUS.KYC]: ROUTES.IDENTITY_VERIFICATION,
  [LOAN_APPLICATION_STATUS.APPROVAL]: ROUTES.IDENTITY_VERIFICATION,
  [LOAN_APPLICATION_STATUS.APPROVED]: ROUTES.MERCHANT_PORTAL,
  [LOAN_APPLICATION_STATUS.REJECTED]: ROUTES.LOAN_REJECTION_CREDIT_SCORE,
  [LOAN_APPLICATION_STATUS.CLOSED]: ROUTES.CLOSED,
} as const

type CustomerProgressStage = keyof typeof CUSTOMER_PROGRESS_ROUTES

type CustomerProgressData = {
  stage: CustomerProgressStage
  route: string
}

const createCustomerProgressData = (
  stage: CustomerProgressStage,
  nextRoute: string,
  loanApplicationId: string,
) => ({
  stage,
  route: generatePath(nextRoute, {
    id: loanApplicationId,
  }),
})

/** Returns the customer progress stage and route to render
 * @returns {CustomerProgressData | null} - The customer progress stage and route to render
 */
export const useCustomerProgress: () => CustomerProgressData | null = () => {
  const { currentLoanApplication, shouldAllowNewApplication } =
    useLoanApplication()
  const { applicationForm } = useLoanOffer()
  const { allRoutes } = useFlowNavigation()
  const { currentLoan } = useLoans()

  const currentLoanApplicationCompleted =
    currentLoanApplication?.status === 'closed' ||
    currentLoanApplication?.status === 'rejected'
  const hasActiveLoan = currentLoan && currentLoan?.status

  // The current customer progress stage, determined in the API response,
  // Unfortunately, The APPLICATION_SUMMARY stage is returned as the final progress state which is tracked in the API
  // however, this doesn't encalpsulate progress past the application summary step, so we can check against the loan status after this step to differentiate between the subsequent stages, i.e. KYC, APPROVED, REJECTED, CLOSED
  const isPostSubmission = useMemo(
    () =>
      currentLoanApplication?.status?.match(
        /kyc|approved|approval|rejected|closed/g,
      ),
    [currentLoanApplication],
  )

  const progressStage = useMemo(() => {
    if (currentLoanApplicationCompleted && hasActiveLoan) {
      return CUSTOMER_PROGRESS.MERCHANT_PORTAL
    }

    if (isPostSubmission) {
      return currentLoanApplication?.status
    }

    // In the first loan application flow, the customerProgress routing logic only kicks in post the signup
    // due to the check against the token inside the useLoanApplicationRedirect, so we must account for this here
    if (shouldAllowNewApplication) {
      return (
        currentLoanApplication?.customerProgress ||
        (applicationForm?.approximateSales && CUSTOMER_PROGRESS.ROOT)
      )
    }

    return currentLoanApplication?.customerProgress
  }, [
    currentLoanApplicationCompleted,
    hasActiveLoan,
    isPostSubmission,
    currentLoanApplication,
    applicationForm,
    shouldAllowNewApplication,
  ]) as CustomerProgressStage

  // The route to render based on the customer's progress
  const nextRoute = useMemo(() => {
    const customerProgressRouteIndex = allRoutes.findIndex(
      (route) => route === CUSTOMER_PROGRESS_ROUTES[progressStage],
    )

    if (isPostSubmission) {
      return CUSTOMER_PROGRESS_ROUTES[progressStage]
    }

    return (
      customerProgressRouteIndex >= 0 &&
      allRoutes[Math.min(customerProgressRouteIndex + 1, allRoutes.length - 1)]
    )
  }, [allRoutes, progressStage, isPostSubmission])

  const customerProgress = useMemo(() => {
    if (nextRoute && currentLoanApplication?.id) {
      return createCustomerProgressData(
        progressStage,
        nextRoute,
        currentLoanApplication.id,
      )
    }

    return {
      stage: null,
      route: null,
    }
  }, [nextRoute, currentLoanApplication, progressStage])

  return customerProgress
}
