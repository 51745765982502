import React from 'react'
import CircularProgressbar from './CircularProgressbar'

function CircularProgressbarWithChildren(props) {
  const { children, ...circularProgressbarProps } = props
  return (
    <div data-test-id="CircularProgressbarWithChildren">
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <CircularProgressbar {...circularProgressbarProps} />
        {props.children ? (
          <div
            data-test-id="CircularProgressbarWithChildren__children"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              marginTop: '-100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {props.children}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CircularProgressbarWithChildren
