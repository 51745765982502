import { LEGAL_FORMS } from 'constants/loanApplication'
import { ROUTES } from 'constants/routes'
import activeTenant, { isLinkIntegration } from 'setup/tenant'

export const determineOperationalFirstStep = ({
  legalForm,
  codatEnabled,
  isPhase1,
}) => {
  if (isPhase1 && codatEnabled) return ROUTES.CONNECT_SHOPPING_PLATFORM
  if (
    !isPhase1 &&
    [
      LEGAL_FORMS.GMBH,
      LEGAL_FORMS.OHG,
      LEGAL_FORMS.GBR,
      LEGAL_FORMS.UG,
      LEGAL_FORMS.GMBH_COKG,
    ].includes(legalForm)
  )
    return ROUTES.STAKEHOLDERS
  if (isPhase1) return ROUTES.BANK_LINKING
  return null
}

export const generateSteps = ({
  firmenwissenEnabled,
  legalForm,
  codatEnabled,
  isPhase1,
}) => [
  ...(isLinkIntegration
    ? []
    : [
        {
          id: 'applicationProgress.general',
          linksTo: isPhase1 ? ROUTES.ROOT : null,
          routes: [ROUTES.BUSINESS_LEGAL, ROUTES.ROOT],
        },
      ]),
  {
    id: 'applicationProgress.offers',
    linksTo: isPhase1 ? ROUTES.LOAN_OFFERS : null,
    routes: [
      ROUTES.LOAN_OFFERS,
      activeTenant.customizeLoanOfferRoute,
      ROUTES.OPTIN_MERCHANT,
      ROUTES.LOAN_PROCESS_OVERVIEW,
    ],
  },
  {
    id: 'applicationProgress.about',
    linksTo:
      firmenwissenEnabled && isPhase1
        ? ROUTES.COMPANY_SEARCH
        : ROUTES.BUSINESS_DETAILS,
    routes: [
      ROUTES.BUSINESS_DETAILS,
      ROUTES.BUSINESS_ADDRESS,
      ROUTES.OWNER_DETAILS,
      ROUTES.DOCUMENTS_UPLOAD,
      ROUTES.COMPANY_SEARCH,
      ROUTES.ADDITIONAL_INFORMATION,
    ],
  },
  {
    id: 'applicationProgress.operational',
    linksTo: determineOperationalFirstStep({
      legalForm,
      codatEnabled,
      isPhase1,
    }),
    routes: [
      ROUTES.STAKEHOLDERS,
      ROUTES.CONNECT_SHOPPING_PLATFORM,
      ROUTES.BANK_LINKING,
    ],
  },
  {
    id: 'applicationProgress.review',
    routes: [ROUTES.APPLICATION_SUMMARY, ROUTES.APPLICATION_REVIEW],
  },
  {
    id: 'applicationProgress.verification',
    routes: [ROUTES.IDENTITY_VERIFICATION],
  },
]
