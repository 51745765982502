import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { InputAdornment, IconButton } from '@mui/material'
import { Eye, EyeSlash } from 'react-bootstrap-icons'
import { Grid } from 'components/Grid'
import { Button } from 'components/buttons'
import { Email, Password } from 'components/Fields'
import { ROUTES } from 'constants/routes'
import { useNavigation } from 'routing/hooks/useNavigation'

export function LoginForm({ onSubmit, loading }) {
  const { navigateTo } = useNavigation()
  const [showPassword, setShowPassword] = useState(false)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        email: '',
        password: '',
      }}
    >
      {({ isValid, submitCount }) => (
        <Form style={{ width: '100%' }} data-testid="login-form">
          <Flex
            flexDirection="column"
            flex="1 0 auto"
            width="100%"
            alignItems="center"
            pb="100px"
          >
            <Flex flexDirection="column" style={{ width: '100%' }}>
              <Email
                name="email"
                data-testid="login-form-email"
                label={<FormattedMessage id="fields.email" />}
              />
              <Password
                data-testid="login-form-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Eye /> : <EyeSlash />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid columns={3}>
                <Grid.Column
                  sx={{
                    gridColumnEnd: ['span 3', 'span 3', 'span 2'],
                  }}
                >
                  <Flex>
                    <Box mr={2} flex="1 1 50%">
                      <Button
                        sx={{
                          height: ['90px', '78px', '78px'],
                          width: '100%',
                        }}
                        onClick={() => {
                          navigateTo(ROUTES.RESET_PASSWORD)
                        }}
                      >
                        <FormattedMessage id="login.forgotPass" />
                      </Button>
                    </Box>
                    <Box ml={2} flex="1 1 50%">
                      <Button
                        type="submit"
                        sx={{
                          height: ['90px', '78px', '78px'],
                          width: '100%',
                        }}
                        disabled={!isValid && submitCount > 0}
                        data-testid="login-form-submit"
                        loading={loading}
                      >
                        <FormattedMessage id="login.buttonText" />
                      </Button>
                    </Box>
                  </Flex>
                </Grid.Column>
              </Grid>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
