import React from 'react'
import activeTenant from 'setup/tenant'
import { Box } from 'components/structural'
import { BoxProps } from '@mui/material/Box'

export type InputWrapperProps = BoxProps & {
  boxed?: boolean
  error?: boolean
  focus?: boolean
  anchorRef?: React.Ref<unknown>
}

export const InputWrapper = (props: InputWrapperProps) => {
  const { children, sx, error, boxed, anchorRef, focus, ...rest } = props

  const borderColor = focus ? 'input.ico.main' : 'global.line.main'

  const borderRadius = activeTenant.theme.shape.fieldBorderRadius
  const borderTopDesktopError = boxed ? borderColor : 'global.errorRed.main'
  const borderBottomDesktopError = boxed ? 'global.errorRed.main' : borderColor

  return (
    <Box
      ref={anchorRef}
      sx={{
        backgroundColor: 'input.bgA.main',
        border: '1px solid',
        borderColor,
        borderRadius,
        borderTopColor: [
          borderColor,
          borderColor,
          error ? borderTopDesktopError : borderColor,
        ],
        borderBottomColor: [
          error ? 'global.errorRed.main' : borderColor,
          error ? 'global.errorRed.main' : borderColor,
          error ? borderBottomDesktopError : borderColor,
        ],
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}
