import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box } from 'components/structural'
import { Typography } from '@mui/material'

export const InfoBox = () => (
  <Box
    width="100%"
    backgroundColor="global.bgA.main"
    px={3}
    py={4}
    mb={5}
    mt={3}
  >
    <Typography variant="body1" color="text.subtle.main" border="1px">
      <FormattedMessage id="documentsUpload.types.infobox" />
    </Typography>
  </Box>
)
