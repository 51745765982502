import React from 'react'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as LockedIcon } from 'images/icons/locked-b.svg'
import { ButtonLink } from 'components/buttons'
import { ROUTES } from 'constants/routes'
import { useNavigation } from 'routing/hooks/useNavigation'
import { ERROR_MESSAGE } from '../../constants/error'

export const GenericError = ({ mb, errorMessage }) => {
  const { navigateTo } = useNavigation()

  const handleNavigateToLogin = () => {
    navigateTo(ROUTES.LOGIN)
  }

  return (
    <Flex
      data-testid="generic-error"
      px={3}
      mb={3}
      backgroundColor="global.errorRed.light"
      alignItems="center"
      sx={{ border: (theme) => theme.borders.globalError }}
      flexWrap="wrap"
      {...mb}
    >
      <Flex alignItems="center" my={3} flex="0 1 auto" mr="auto">
        <Box
          sx={{
            flexShrink: 0,
            width: '24px',
            mr: 3,
            svg: {
              path: {
                stroke: (theme) => theme.palette.global.errorRed.main,
              },
            },
          }}
        >
          <LockedIcon />
        </Box>
        <Typography color="global.errorRed.main" style={{ flex: '1 1 auto' }}>
          <FormattedMessage id={errorMessage} />
        </Typography>
      </Flex>

      {/* TODO: this never seems to happen, should it be API_VALIDATION.MERCHANTUSER.EMAIL instead? */}
      {errorMessage === ERROR_MESSAGE.ACCOUNT_EXISTS && (
        <Box my={3}>
          <ButtonLink onClick={handleNavigateToLogin} variant="body1" error>
            <FormattedMessage id="signUp.modal.buttonText" />
          </ButtonLink>
        </Box>
      )}
    </Flex>
  )
}
