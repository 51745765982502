import tenantConfigs, { getSelectedTenantCode } from 'tenants/index'
export const tenantCode = getSelectedTenantCode()
const activeTenant = tenantConfigs[tenantCode]

export const isPanther = tenantCode === 'PNT'
export const isGetRaoul = tenantCode === 'GRL'
export const isBanxy = tenantCode === 'BXY'
export const isTeleCash = tenantCode === 'TLC'
export const isPayOne = tenantCode === 'PAY'
export const isTestTenant = tenantCode === 'TEST'
export const isBanxware = tenantCode === 'BXW'

export const isMca =
  isGetRaoul || isTeleCash || isBanxy || isTestTenant || isPayOne || isBanxware
export const isMonthlyRevenueUploadGroup = isGetRaoul || isBanxware
export const isMcaAdjustedPage1Group =
  isGetRaoul || isBanxy || isTeleCash || isPayOne || isBanxware
export const isMcaAdjustedPage1GroupEligibilityRejection =
  isGetRaoul || isBanxy || isPayOne || isBanxware
export const isRateOfRepaymentGroup = isBanxy || isPanther || isTeleCash
export const isMonthlyRepaymentGroup = isGetRaoul || isPayOne || isBanxware
export const isHavingIndustry = isPayOne || isTeleCash
export const isLinkIntegration = isTeleCash || isPayOne
export const isDynamicRepayment = isBanxy || isTeleCash
export const isCodatEnabled = isBanxy || isTestTenant

export default activeTenant
