import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { GlobalSpinner } from 'components/spinners'
import { useLoanApplication } from 'context/LoanApplication'
import { useAuth } from 'context/Auth'
import { useAccount } from 'context/Account'
import { noop } from 'lodash'
import { isPostApplicationSubmission } from 'types/loanApplication'
import { ROUTES } from 'constants/routes'
import { useLoans } from 'context/Loans'
import { NotFoundPage } from 'views/NotFoundPage'
import activeTenant from 'setup/tenant'
import {
  AUTH_ROUTES,
  LOAN_APPLICATION_ROUTES,
  MERCHANT_PORTAL_ROUTES,
  PROTECTED_LOAN_APPLICATION_ROUTES,
} from './routes'
import { useMerchantIntegration } from './hooks/useMerchantIntegration'
import { useInitializeLoanApplication } from './hooks/useInitializeLoanApplication'
import { useInitializeApplication } from './hooks/useInitializeApplication'
import { useLoanApplicationRedirect } from './hooks/useLoanApplicationRedirect'
import { useCustomerProgress } from './hooks/useCustomerProgress'

const useRouteInitialisation = () => {
  const { token } = useAuth()
  const { isSignUp } = useAccount()
  const { currentLoanApplication, shouldAllowNewApplication } =
    useLoanApplication()
  const { loans } = useLoans()
  const { initializeLoanApplication, isLoanApplicationInitialized } =
    useInitializeLoanApplication()
  const { initializeApplication, isApplicationInitialized } =
    useInitializeApplication()
  const loanApplicationRedirect = useLoanApplicationRedirect()
  const customerProgress = useCustomerProgress()

  const { isInitializing: isInitializingMerchantIntegration } =
    useMerchantIntegration()

  useEffect(() => {
    if (!isApplicationInitialized) {
      initializeApplication().catch(noop)
    }
  }, [initializeApplication, isApplicationInitialized])

  useEffect(() => {
    if (!isSignUp && token && isApplicationInitialized) {
      initializeLoanApplication().catch(noop)
    }
  }, [token, isApplicationInitialized, initializeLoanApplication, isSignUp])

  const isLoading = useMemo(
    () =>
      (!token && !isApplicationInitialized) ||
      (token && !isSignUp && !isLoanApplicationInitialized) ||
      isInitializingMerchantIntegration,
    [
      token,
      isSignUp,
      isApplicationInitialized,
      isInitializingMerchantIntegration,
      isLoanApplicationInitialized,
    ],
  )

  // Determines whether the user can access the loan application routes
  // Initial application routes should only be accessible if application hasn't been submitted
  const renderLoanApplicationRoutes = useMemo(
    () =>
      customerProgress.stage === 'rejected' ||
      currentLoanApplication?.status === 'closed' ||
      (loans?.length > 0
        ? shouldAllowNewApplication &&
          !isPostApplicationSubmission(currentLoanApplication)
        : !isPostApplicationSubmission(currentLoanApplication)),
    [
      loans,
      shouldAllowNewApplication,
      currentLoanApplication,
      customerProgress,
    ],
  )

  return {
    loanApplicationRedirect:
      !isLoading &&
      !isSignUp &&
      isApplicationInitialized &&
      loanApplicationRedirect,
    isLoading,
    renderLoanApplicationRoutes,
  }
}

export const Routes = () => {
  const { isLoading, loanApplicationRedirect, renderLoanApplicationRoutes } =
    useRouteInitialisation()

  return (
    <>
      {isLoading ? (
        <GlobalSpinner />
      ) : (
        <>
          {activeTenant.isOnlyMerchantPortal && <Redirect to="/login" />}
          {loanApplicationRedirect && <Redirect to={loanApplicationRedirect} />}
          <Switch>
            {AUTH_ROUTES.map((route) => (
              <Route key={route.path} exact {...route} />
            ))}
            {MERCHANT_PORTAL_ROUTES.map((route) => (
              <ProtectedRoute key={route.path} exact {...route} />
            ))}
            {PROTECTED_LOAN_APPLICATION_ROUTES.map((route) => (
              <ProtectedRoute key={route.path} exact {...route} />
            ))}
            {renderLoanApplicationRoutes &&
              !activeTenant.isOnlyMerchantPortal &&
              LOAN_APPLICATION_ROUTES.map((route) => (
                <Route key={route.path} exact {...route} />
              ))}
            {activeTenant.isOnlyMerchantPortal ? (
              <Redirect to={ROUTES.LOGIN} />
            ) : (
              <Route key="*" exact path="*" component={NotFoundPage} />
            )}
          </Switch>
        </>
      )}
    </>
  )
}

const ProtectedRoute = (props: RouteProps) => {
  const { token } = useAuth()

  return token ? <Route {...props} /> : <Redirect to={ROUTES.ROOT} />
}
