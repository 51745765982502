import type { LoanApplicationFlow } from 'types/routes'

export const ROUTES = {
  APPLICATION_REVIEW: '/loan-application/:id/application-review',
  ADDITIONAL_INFORMATION: '/loan-application/:id/additional-information',
  APPLICATION_SUMMARY: '/loan-application/:id/application-summary',
  BANK_LINKING_FAILED: '/loan-application/:id/bank-linking-failed',
  BANK_LINKING_SUCCESS: '/loan-application/:id/bank-linking-success',
  BANK_LINKING: '/loan-application/:id/bank-linking',
  BUSINESS_ADDRESS: '/loan-application/:id/business-address',
  BUSINESS_DETAILS: '/loan-application/:id/business-details',
  BUSINESS_LEGAL: '/loan-application/:id/business-legal',
  COMPANY_SEARCH: '/loan-application/:id/company-search',
  CONNECT_SHOPPING_PLATFORM_DOCUMENTS:
    '/loan-application/:id/connect-shopping-platform/documents-upload',
  MANUAL_REPORT_UPLOAD:
    '/loan-application/:id/connect-shopping-platform/manual-report-upload',
  AFTERBUY: '/loan-application/:id/connect-shopping-platform/afterbuy',
  EBAY: '/loan-application/:id/connect-shopping-platform/ebay',
  GAMBIO: '/loan-application/:id/connect-shopping-platform/gambio',
  GIROCARD: '/loan-application/:id/connect-shopping-platform/girocard',
  HOOD: '/loan-application/:id/connect-shopping-platform/hood',
  CONNECT_SHOPPING_PLATFORM: '/loan-application/:id/connect-shopping-platform',
  CUSTOMIZE_LOAN_OFFER_BXY: '/bxy/customize-loan-offer',
  CUSTOMIZE_LOAN_OFFER_GRL: '/grl/customize-loan-offer',
  CUSTOMIZE_LOAN_OFFER_BXW: '/bxw/customize-loan-offer',
  CUSTOMIZE_LOAN_OFFER_PAY: '/pay/customize-loan-offer',
  CUSTOMIZE_LOAN_OFFER_TLC: '/tlc/customize-loan-offer',
  CUSTOMIZE_LOAN_OFFER_TEST: '/test/customize-loan-offer',
  CUSTOMIZE_LOAN_OFFER: '/customize-loan-offer',
  DOCUMENTS_UPLOAD: '/loan-application/:id/documents-upload',
  ERROR: '/error',
  EXPRESS_APPLICATION_REVIEW:
    '/loan-application/:id/express-application-review',
  EXPRESS_APPLICATION_SUBMITTED: '/loan-application/:id/application-submitted',
  IDENTITY_VERIFICATION: '/loan-application/:id/identity-verification',
  LOAN_APPLICATION: '/loan-application/:id',
  LOAN_OFFERS: '/offers',
  LOAN_PROCESS_OVERVIEW: '/loan-application/:id/loan-process-overview',
  CLOSED: '/loan-application/closed',
  LOAN_REJECTION_CREDIT_SCORE: '/loan-rejection/threshold',
  LOAN_REJECTION_TIME_OF_RUN_BUSINESS: '/loan-rejection/eligibilitytime',
  LOAN_REJECTION_REVENUE: '/loan-rejection/eligibilityrev',
  LOAN_REJECTION_LEGALFORM: '/loan-rejection/eligibilitylegalform',
  LOAN_REJECTION_REGION: '/loan-rejection/region',
  MERCHANT_INTEGRATION_ERROR: '/loan-rejection/merchant-integration-error',
  LINK_TIMED_OUT: '/loan-rejection/link-timed-out',
  LINK_WITHOUT_BLOB: '/loan-rejection/wrong-origin',
  LOGIN: '/login',
  MERCHANT_PORTAL: '/merchant/:id',
  MERCHANT: '/merchant',
  OPTIN_MERCHANT: '/loan-application/:id/optin-merchant',
  OWNER_DETAILS: '/loan-application/:id/owner-details',
  REPAYMENT_PROGRESS: '/repayment-progress',
  RESET_PASSWORD_NEW: '/new-password',
  RESET_PASSWORD: '/reset-password',
  ROOT: '/',
  SIGN_UP: '/sign-up',
  STAKEHOLDERS: '/loan-application/:id/stakeholders',
  IMPRINT: '/imprint',
} as const

export const EXPRESS_FLOW: LoanApplicationFlow = [
  ROUTES.ROOT,
  ROUTES.LOAN_OFFERS,
  ROUTES.ADDITIONAL_INFORMATION,
  ROUTES.COMPANY_SEARCH,
  ROUTES.CONNECT_SHOPPING_PLATFORM,
  ROUTES.BANK_LINKING,
  ROUTES.EXPRESS_APPLICATION_REVIEW,
  ROUTES.BUSINESS_DETAILS,
  ROUTES.OWNER_DETAILS,
  ROUTES.DOCUMENTS_UPLOAD,
  ROUTES.STAKEHOLDERS,
  ROUTES.APPLICATION_SUMMARY,
  ROUTES.EXPRESS_APPLICATION_SUBMITTED,
  ROUTES.IDENTITY_VERIFICATION,
  ROUTES.MERCHANT_PORTAL,
]

export const DEFAULT_FLOW: LoanApplicationFlow = [
  ROUTES.ROOT,
  ROUTES.LOAN_OFFERS,
  ROUTES.LOAN_PROCESS_OVERVIEW,
  ROUTES.COMPANY_SEARCH,
  ROUTES.BUSINESS_DETAILS,
  ROUTES.OWNER_DETAILS,
  ROUTES.DOCUMENTS_UPLOAD,
  ROUTES.STAKEHOLDERS,
  ROUTES.CONNECT_SHOPPING_PLATFORM,
  ROUTES.BANK_LINKING,
  ROUTES.APPLICATION_SUMMARY,
  ROUTES.APPLICATION_REVIEW,
  ROUTES.IDENTITY_VERIFICATION,
  ROUTES.MERCHANT_PORTAL,
]
