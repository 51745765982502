import React from 'react'
import { TextFieldComponent } from 'components/Fields/TextFieldComponent'
import { Field as FormikField } from 'formik'

import {
  requiredValidator,
  emailValidator,
  composeValidators,
} from 'utils/forms/validators'

export const Email = (props) => (
  <FormikField
    component={TextFieldComponent}
    name="email"
    data-testid="formFieldEmail"
    label={props.label}
    validate={composeValidators(requiredValidator, emailValidator)}
    {...props}
  />
)
