import React from 'react'
import { GlobalStyles as MuiGlobalStyles } from '@mui/material'
import { typographyStyles } from 'components/Typography'

export const GlobalStyles = () => (
  <MuiGlobalStyles
    styles={`
      html,
      body {
        margin: 0;
        height: 100%;
        width: 100%;
      }

      body {
        -webkit-font-smoothing: antialiased;
        font-family: 'Open Sans', sans-serif;
      }

      #app {
        background-color: #ffffff;
        min-height: 100%;
        min-width: 100%;
      }

      p,
      label {
        font-family: Georgia, Times, 'Times New Roman', serif;
        line-height: 1.5em;
      }

      ${typographyStyles};
    `}
  />
)
