import React from 'react'
import { Box } from 'components/structural'

import { ReactComponent as Calendar } from 'images/icons/calendar.svg'

export const CalendarIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          fill: (theme) => theme.palette.input.ico.main,
        },
      },
    }}
  >
    <Calendar />
  </Box>
)
