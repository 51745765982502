import React, { useState } from 'react'
import * as Sentry from '@sentry/browser'
import { Form, Formik } from 'formik'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Grid } from 'components/Grid'
import { Button } from 'components/buttons'
import { Email } from 'components/Fields'
import { SubmitSuccessful } from 'components/Fields/SubmitSuccessful'
import { ReactComponent as ErrorIcon } from 'images/bullets/error.svg'
import { API_URL, API_PATH } from 'constants/api'
import { useApiRequest } from 'context/utils/useApiRequest'

type Props = {
  loading?: boolean
}

export const EmailSubscriptionForm = ({ loading }: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isError, setError] = useState(false)
  const { postRequest } = useApiRequest()
  const emailSubscription = async (payload) => {
    try {
      await postRequest({
        url: `${API_URL}${API_PATH.EMAIL_SUBSCRIPTIONS}`,
        body: payload,
      })

      Sentry.captureMessage(
        `Email related to INVALID_MERCHANT_INFO: ${payload.email}`,
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({ error })
      setError(true)
    }
  }

  const onSubmit = async ({ email }) => {
    if (email) {
      const payload = {
        email,
      }
      await emailSubscription(payload)
      setIsSubmitted(true)
    }
  }

  return (
    <>
      {isError && (
        <Flex
          flexDirection="row"
          bgcolor="global.errorRed.light"
          flex="1 1 auto"
          py="28px"
          pl={3}
        >
          <Box width="23px" mr={2}>
            <ErrorIcon />
          </Box>
          <Typography variant="body2" color="error" style={{ fontWeight: 500 }}>
            <FormattedMessage id="errorMessage.generic" />
          </Typography>
        </Flex>
      )}

      {!isSubmitted && (
        <Grid columns={3}>
          <Grid.Column
            sx={{
              gridColumnEnd: ['span 3', 'span 3', 'span 2'],
            }}
          >
            <Typography variant="h5">
              <FormattedMessage id="rejection.emailText" />
            </Typography>
          </Grid.Column>
        </Grid>
      )}
      {!isSubmitted && (
        <Formik
          initialValues={{
            email: '',
          }}
          {...{ onSubmit }}
        >
          {({ isValid, submitCount }) => (
            <Form style={{ width: '100%' }}>
              <Flex
                flexDirection="column"
                flex="1 0 auto"
                width="100%"
                alignItems="center"
                pt="10px"
                pb="10px"
              >
                <Flex flexDirection="column" style={{ width: '100%' }}>
                  <Email
                    name="email"
                    label={<FormattedMessage id="fields.email" />}
                  />
                  <Grid columns={3}>
                    <Grid.Column
                      sx={{
                        gridColumnEnd: ['span 3', 'span 3', 'span 2'],
                      }}
                    >
                      <Button
                        type="submit"
                        style={{
                          width: '100%',
                        }}
                        withArrow
                        disabled={!isValid && submitCount > 0}
                        {...{ loading }}
                      >
                        <Typography
                          variant="h5"
                          sx={{ color: 'button.labelA.main' }}
                        >
                          <FormattedMessage id="rejection.submitText" />
                        </Typography>
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
      <Grid columns={3}>
        <Grid.Column
          sx={{
            gridColumnEnd: ['span 3', 'span 3', 'span 2'],
          }}
          pb="30px"
        >
          {isSubmitted && <SubmitSuccessful />}
        </Grid.Column>
      </Grid>
    </>
  )
}

export default EmailSubscriptionForm
