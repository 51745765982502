import React, { useEffect, useMemo } from 'react'
import { Typography } from '@mui/material'
import { ApplicationGirlImage } from 'components/icons'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { ReactComponent as CompleteIcon } from 'images/complete.svg'
import { ReactComponent as ValidGreenIcon } from 'images/icons/valid-green.svg'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { BREAKPOINTS } from 'constants/styles'
import { useWindowSizes } from 'hooks'
import {
  CUSTOMER_PROGRESS,
  LOAN_APPLICATION_STATUS,
} from 'constants/loanApplication'
import { useLoanApplication } from 'context/LoanApplication'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'

export const ExpressApplicationPhase1Submitted = () => {
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const { goToNextPage } = useFlowNavigation()
  const { windowWidth } = useWindowSizes()
  const isExpressEligible = useMemo(
    () =>
      currentLoanApplication.status ===
      LOAN_APPLICATION_STATUS.EXPRESS_ELIGIBLE,
    [currentLoanApplication.status],
  )

  useEffect(() => {
    if (isExpressEligible) {
      updateLoanApplicationById({
        loanApplicationId: currentLoanApplication.id,
        loanApplication: {
          customerProgress: CUSTOMER_PROGRESS.EXPRESS_APPLICATION_REVIEW,
        },
      })
      goToNextPage()
    }
  }, [
    isExpressEligible,
    goToNextPage,
    updateLoanApplicationById,
    currentLoanApplication,
  ])

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="applicationReview.header"
          subId="expressApplicationExpressSubmitted.subheading"
          icon={<CompleteIcon />}
        />
      }
      withProgress
    >
      <Layout.Middle>
        <Box
          backgroundColor="global.okGreen.light"
          px="28px"
          py="28px"
          data-testid="processComplete"
        >
          <Flex alignItems="center" sx={{ color: 'global.okGreen.main' }}>
            <Box width="24px" height="24px" mr={4}>
              <ValidGreenIcon />
            </Box>
            <Typography variant="h5">
              <FormattedMessage id="expressApplicationReview.processComplete" />
            </Typography>
          </Flex>
        </Box>
        <Box mt={[4, 4, 6]} mb={[6, 6, 0]}>
          <Typography variant="body2">
            <FormattedMessage id="expressApplicationReview.description" />
          </Typography>
        </Box>
      </Layout.Middle>
      <Layout.Right>
        {windowWidth <= parseInt(BREAKPOINTS.desktop, 10) ? (
          <Flex justifyContent="center">
            <Box width="200px" height="350px">
              <ApplicationGirlImage />
            </Box>
          </Flex>
        ) : (
          <ApplicationGirlImage />
        )}
      </Layout.Right>
    </Layout>
  )
}
