import React from 'react'
import { Button } from 'components/buttons'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { BREAKPOINTS } from 'constants/styles'
import { useWindowSizes } from 'hooks'
import { Grid } from 'components/Grid'
import { useNavigation } from 'routing/hooks/useNavigation'
import activeTenant from 'setup/tenant'

export const CustomizeLoanOfferBox = () => {
  const { navigateTo } = useNavigation()
  const { windowWidth } = useWindowSizes()

  const handleNavigateToCustomizeLoanOffer = () => {
    navigateTo(activeTenant.customizeLoanOfferRoute)
  }

  return (
    <Grid.Column start={2} span={2}>
      <Flex flexDirection="column">
        {windowWidth >= parseInt(BREAKPOINTS.desktop, 10) && (
          <>
            <Flex
              justifyContent="flex-start"
              sx={{ color: 'text.heading.main', mt: '15px', mb: '40px' }}
            >
              <Typography variant="h1">
                <FormattedMessage id="loanOffers.customizeBox.header" />
              </Typography>
            </Flex>
            <Button
              style={{ flex: '1 0 auto' }}
              sx={{
                color: 'button.labelB.main',
                backgroundColor: 'button.bgB.main',
                borderColor: 'input.stroke.main',
                borderWidth: '1px',
                borderStyle: 'solid',
                ':hover': {
                  backgroundColor: 'button.bgB.dark',
                },
              }}
              onClick={handleNavigateToCustomizeLoanOffer}
              data-testid="customizeLoanOfferButton"
            >
              <FormattedMessage id="loanOffers.customizeOffer.actionText" />
            </Button>
          </>
        )}
        {windowWidth <= parseInt(BREAKPOINTS.desktop, 10) && (
          <>
            <Flex
              justifyContent="flex-start"
              sx={{ color: 'text.heading.main', mt: '15px', mb: '40px' }}
            >
              <Typography variant="h1">
                <FormattedMessage id="customizeOffer.notRightOffer.header" />
              </Typography>
            </Flex>
            <Button
              sx={{
                flex: '1 0 auto',
                color: 'button.labelB.main',
                backgroundColor: 'button.bgB.main',
                borderColor: 'input.stroke.main',
                borderWidth: '1px',
                borderStyle: 'solid',
                ':hover': {
                  backgroundColor: 'button.bgB.dark',
                },
              }}
              onClick={handleNavigateToCustomizeLoanOffer}
              data-testid="customizeLoanOfferButton"
            >
              <FormattedMessage id="loanOffers.customizeOffer.actionText" />
            </Button>
          </>
        )}

        <Box sx={{ my: 5 }}>
          <Typography variant="caption">
            <FormattedMessage id="customizeOffer.endCopy" />
          </Typography>
        </Box>
      </Flex>
    </Grid.Column>
  )
}
