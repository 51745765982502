import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { Owner } from 'types/loanApplication'
import { API_URL, API_PATH } from 'constants/api'
import { useAuth } from 'context/Auth'
import { useError } from 'context/Error'
import { useSetState } from '../utils/hooks'
import { useApiRequest } from '../utils/useApiRequest'

type OwnersProviderProps = {
  children: React.ReactNode
}

type State = {
  isLoading?: boolean
  isError?: boolean
  owners?: Owner[]
}

type OwnersContextState = State & {
  createOwner: (props: {
    loanApplicationId: string
    owner: Partial<Owner>
  }) => Promise<void>
  deleteOwner: (props: {
    loanApplicationId: string
    ownerId: string
  }) => Promise<void>
  updateOwner: (props: {
    loanApplicationId: string
    owner: Owner
  }) => Promise<void>
  fetchOwnersById: (loanApplicationId: string) => Promise<void>
}

export const OwnersContext = createContext<undefined | OwnersContextState>(
  undefined,
)

const initialState = Object.freeze({
  isLoading: false,
  isError: false,
  owners: null,
})

export function OwnersProvider(props: OwnersProviderProps) {
  const { children } = props

  const { token } = useAuth()
  const [state, setState] = useSetState<State>({ ...initialState })
  const { handleApplicationError } = useError()
  const { getRequest, postRequest, deleteRequest, patchRequest } =
    useApiRequest()

  useEffect(() => {
    if (!token) {
      setState({ ...initialState })
    }
  }, [setState, token])

  const fetchOwnersById = useCallback(
    async (loanApplicationId: string) => {
      try {
        setState({ isLoading: true })
        const { owners } = await getRequest<{ owners: Owner[] }>({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}/owners`,
        })
        setState({ owners })
      } catch (error: unknown) {
        handleApplicationError(error)
        setState({ isError: true })
      } finally {
        setState({ isLoading: false })
      }
    },
    [getRequest, handleApplicationError, setState],
  )

  const createOwner = useCallback(
    async ({
      loanApplicationId,
      owner,
    }: {
      loanApplicationId: string
      owner: Owner & {
        addressAttributes: {
          address1: string
          address2: string
          city: string
          zipCode: string
          country: string
        }
      }
    }) => {
      try {
        setState({ isLoading: true })
        await postRequest({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}/owners`,
          body: {
            ...owner,
          },
        })
      } catch (error: unknown) {
        handleApplicationError(error)
        setState({ isError: true })
      } finally {
        setState({ isLoading: false })
      }
    },
    [handleApplicationError, postRequest, setState],
  )

  const deleteOwner = useCallback(
    async ({
      loanApplicationId,
      ownerId,
    }: {
      loanApplicationId: string
      ownerId: string
    }) => {
      try {
        setState({ isLoading: true })
        await deleteRequest({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}/owners/${ownerId}`,
        })
      } catch (error: unknown) {
        handleApplicationError(error)
        setState({ isError: true })
      } finally {
        setState({ isLoading: false })
      }
    },
    [deleteRequest, handleApplicationError, setState],
  )

  const updateOwner = useCallback(
    async ({
      loanApplicationId,
      owner,
    }: {
      loanApplicationId: string
      owner: Owner
    }) => {
      try {
        setState({ isLoading: true })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, merchantId, ...rest } = owner
        await patchRequest({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}/owners/${id}`,
          body: { ...rest },
        })
      } catch (error: unknown) {
        handleApplicationError(error)
        setState({ isError: true })
      } finally {
        setState({ isLoading: false })
      }
    },
    [handleApplicationError, patchRequest, setState],
  )

  const { isError, isLoading, owners } = state

  const contextValue = useMemo(
    () => ({
      isError,
      isLoading,
      owners,
      createOwner,
      deleteOwner,
      updateOwner,
      fetchOwnersById,
    }),
    [
      isError,
      isLoading,
      owners,
      createOwner,
      deleteOwner,
      updateOwner,
      fetchOwnersById,
    ],
  )

  return (
    <OwnersContext.Provider value={contextValue}>
      {children}
    </OwnersContext.Provider>
  )
}

export function useOwners() {
  const context = useContext(OwnersContext)
  if (context === undefined) {
    throw new Error('useOwners must be used within a OwnersProvider')
  }
  return context
}
