import React from 'react'
import activeTenant from 'setup/tenant'
import { useField } from 'formik'
import { Flex, Box } from 'components/structural'
import { Switch } from 'components/Switch'

type PrivateAddressToggleProps = {
  isSameAddress: boolean
  mb: number
  setIsSameAddress: (isSameAddress: boolean) => void
}

export const PrivateAddressToggle = ({
  isSameAddress,
  mb,
  setIsSameAddress,
}: PrivateAddressToggleProps) => {
  const field = useField('isSameAddress')
  const helpers = field[2]
  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '48px',
        rowGap: 0,
      }}
      mb={mb}
    >
      <Box
        sx={{
          gridColumnStart: '1',
          gridColumnEnd: ['span 3', 'span 3', 'span 2'],
        }}
      >
        <Flex
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
          sx={{
            border: (theme: any) => theme.borders.primary,
            borderRadius: activeTenant.theme.shape.fieldBorderRadius,
          }}
        >
          <Switch
            labelId="bussinesOwner.isYourPrivateAddress"
            onYes={() => {
              setIsSameAddress(true)
              helpers.setValue(true)
            }}
            onNo={() => {
              setIsSameAddress(false)
              helpers.setValue(false)
            }}
            isYes={isSameAddress}
          />
        </Flex>
      </Box>
    </Box>
  )
}
