/* eslint-disable no-dupe-keys */
import React from 'react'
import { TextField, Typography } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { InputGrid, InputWrapper } from 'components/Input'
import { useHint } from 'hooks'
import { styled } from '@mui/system'

const Label = styled('label')`
  position: relative;
  display: inline-block;
  height: 18px;
  width: 40px;
  flex: 0 0 40px;
`

const Toggle = styled('span')`
  position: absolute;
  cursor: pointer;
  border-radius: 5px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    border-radius: 2px;
    background-color: white;
    transition: 0.4s;
    transform: ${(props) => (props.checked ? 'translateX(22px)' : 'none')};
  }
`

const Input = styled('input')`
  opacity: 0;
  width: 0;
  height: 0;
`

export const UltimateBeneficialOwner = ({
  field: { name, value },
  form: {
    touched,
    errors,
    submitCount,
    setFieldValue,
    handleBlur,
    values,
    setTouched,
  },
  hint,
  mb = 3,
  inputContainerSx = {},
  inputWrapperSx = {},
  boxed,
  hintContainerSx = {},
  dataCy,
}) => {
  const cleanedErrorObject = JSON.parse(JSON.stringify(errors))
  const submitted = submitCount > 0
  const fieldTouched = touched?.[name]
  const fieldError = cleanedErrorObject?.[name]
  const sharesValue = values?.sharesPercent
  const sharesTouched = touched?.sharesPercent
  const sharesError = cleanedErrorObject?.sharesPercent
  const showUboError =
    (fieldTouched && !!fieldError) || (submitted && !!fieldError)
  const showSharesError =
    (value && sharesTouched && !!sharesError) ||
    (value && submitted && !!sharesError)
  const showError = showUboError || showSharesError

  const { hintComponent, TriggerComponent } = useHint({
    hint,
    boxed,
    error: showError,
    errorId: fieldError || sharesError,
    triggerStyles: {
      marginLeft: 0,
    },
  })

  return (
    <InputGrid mb={mb}>
      <InputGrid.Input sx={inputContainerSx} boxed={boxed}>
        <InputWrapper error={showError} sx={inputWrapperSx} boxed={boxed}>
          <Flex>
            <Box flex="1 1 450px">
              <Flex flex="1 1 auto" sx={{ height: '80px' }}>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  px={3}
                  flex="1 1 auto"
                  sx={{ color: 'input.label.main' }}
                >
                  <Typography variant="body2">
                    <FormattedMessage id="fields.ultimateBeneficialOwner" />
                  </Typography>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    flex="0 0 72px"
                  >
                    <TriggerComponent />
                    <Label sx={{ ml: 2 }}>
                      <Input
                        type="checkbox"
                        onChange={() => {
                          setTouched({ ...touched, [name]: true })
                          setFieldValue(name, !value)
                        }}
                      />
                      <Toggle
                        checked={value}
                        sx={{
                          backgroundColor: value
                            ? 'button.bgA.main'
                            : 'global.line.main',
                        }}
                        data-cy={dataCy}
                      />
                    </Label>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
            <Box
              flex="1 1 140px"
              sx={{
                borderLeft: (theme) => theme.borders.primary,
                color: (theme) => theme.palette.global.icoA.main,
                paddingRight: '10px',
              }}
            >
              <TextField
                name="sharesPercent"
                variant="standard"
                error={showSharesError}
                disabled={!value}
                onBlur={handleBlur}
                onFocus={() => {
                  setFieldValue('sharesPercent', null)
                }}
                onChange={(event) => {
                  setFieldValue(
                    'sharesPercent',
                    Math.min(event.target.value, 100),
                  )
                }}
                label={<FormattedMessage id="fields.sharesInline" />}
                value={sharesValue || ''}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: 'inherit',
                    color: 'currentColor',
                    textAlign: 'end',
                    lineHeight: '160%',
                    fontSize: ['14px', '18px', '18px'],
                    paddingRight: (theme) => theme.spacing(1),
                    input: {
                      padding: '0 5px 0 0',
                      textAlign: 'right',
                    },
                  },
                  endAdornment: (
                    <Typography
                      sx={{
                        fontSize: ['14px', '18px', '18px'],
                        color: 'currentColor',
                        fontSize: 'inherit',
                        lineHeight: '160%',
                        fontWeight: 400,
                        transition:
                          'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                        color: value ? 'button.bgA.main' : 'text.off.main',
                        cursor: value ? 'text' : 'not-allowed',
                      }}
                    >
                      %
                    </Typography>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    root: {
                      paddingRight: 0,
                    },
                  },
                }}
              />
            </Box>
          </Flex>
        </InputWrapper>
      </InputGrid.Input>
      <InputGrid.Hint sx={hintContainerSx} boxed={boxed}>
        {hintComponent}
      </InputGrid.Hint>
    </InputGrid>
  )
}
