import React from 'react'
import { Typography, Button } from '@mui/material'
import { Box } from 'components/structural'
import { BUTTON_HEIGHT } from 'constants/styles'

export const ButtonBase = ({ children, icon, sx, bg, ...restProps }) => (
  <Button
    sx={{
      flexGrow: 1,
      height: BUTTON_HEIGHT,
      px: 4,
      py: 2,
      color: 'button.labelA.main',
      backgroundColor: bg || 'button.bgA.main',
      '&:hover': {
        backgroundColor: 'button.bgA.dark',
      },
      '&:disabled': {
        backgroundColor: 'global.icoOff.main',
        cursor: 'not-allowed',
        boxShadow: 'inset 0 0 0 1px #CAD2DC',
      },
      ...sx,
    }}
    {...restProps}
  >
    <Typography variant="button">{children}</Typography>
    {icon && <Box sx={{ width: '24px', height: '24px', ml: 3 }}>{icon}</Box>}
  </Button>
)
