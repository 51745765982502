import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useAuth } from 'context/Auth'
import { API_URL, API_PATH } from 'constants/api'
import { useError } from 'context/Error'
import { useSetState } from '../utils/hooks'
import { useApiRequest } from '../utils/useApiRequest'

type AccountProviderProps = {
  children: React.ReactNode
}

type State = {
  isLoading?: boolean
  isError?: boolean
  isSignUp?: boolean
}

type AccountContextState = State & {
  createAccount: (props: {
    email: string
    password: string
    passwordConfirmation: string
  }) => Promise<void>
  setIsSignUp: (isSignUp: boolean) => void
}

export const AccountContext = createContext<undefined | AccountContextState>(
  undefined,
)

const initialState = Object.freeze({
  isLoading: false,
  isError: false,
  isSignUp: false,
})

export function AccountProvider(props: AccountProviderProps) {
  const { children } = props

  const [state, setState] = useSetState<State>({ ...initialState })
  const { token } = useAuth()
  const { handleApplicationError } = useError()
  const { postRequest } = useApiRequest()

  useEffect(() => {
    if (!token) {
      setState({ ...initialState })
    }
  }, [setState, token])

  /** Calls the signup endpoint */
  const createAccount = useCallback(
    async (account: {
      email: string
      password: string
      passwordConfirmation: string
    }) => {
      const { email, password, passwordConfirmation } = account
      try {
        setState({ isLoading: true })
        await postRequest({
          url: `${API_URL}${API_PATH.SIGN_UP}`,
          body: {
            merchantUser: {
              email,
              password,
              passwordConfirmation,
            },
          },
        })
      } catch (error) {
        handleApplicationError(error)
        setState({ isError: true })
        throw error
      } finally {
        setState({ isLoading: false })
      }
    },
    [handleApplicationError, postRequest, setState],
  )

  const setIsSignUp = useCallback(
    (isSignUp: boolean) => {
      setState({ isSignUp })
    },
    [setState],
  )

  const { isError, isLoading, isSignUp } = state

  const contextValue = useMemo(
    () => ({
      isError,
      isLoading,
      isSignUp,
      createAccount,
      setIsSignUp,
    }),
    [isError, isLoading, isSignUp, createAccount, setIsSignUp],
  )

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  )
}

export function useAccount() {
  const context = useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider')
  }
  return context
}
