import React, { useEffect, useState, useMemo } from 'react'
import activeTenant from 'setup/tenant'
import { Skeleton } from '@mui/material'
import { useIntl } from 'react-intl'
import { HubspotFormStyles } from './styles'

type HubspotEmbedParams = {
  portalId: string
  formId: string
  css?: string
  target?: string
  onFormSubmit?: () => void
}

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (params: HubspotEmbedParams) => void
      }
    }
    jQuery: () => {
      change: () => void
      trigger: () => void
    }
  }
}

type HubspotFormEmbedProps = Pick<HubspotEmbedParams, 'formId'> & {
  /** A css class to embed the form into */
  targetClass: string
  /** Returns any hidden fields to be populated */
  onHiddenFields?: (hiddenFields: NodeListOf<HTMLInputElement>) => void
}

// The hubspot form library requires jQuery in some cases, but we don't need to load it. However, not having it avaiulable throws an error when trying to use the onFormSubmit method on window.hbspt.forms.create
// - this change mocks jQuery as per this workaround https://community.hubspot.com/t5/APIs-Integrations/Form-callback-throws-unrelated-jquery-error/m-p/232127/highlight/true#M11023
window.jQuery = () => ({
  // these are all methods required by HubSpot
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  change: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  trigger: () => {},
})

/** Renders a hubspot form using the hubspot forms library */
export const HubspotFormEmbed = ({
  targetClass,
  formId,
  onHiddenFields,
}: HubspotFormEmbedProps) => {
  const target = useMemo(() => `${targetClass}-embed`, [targetClass])
  const [isLoadingForm, setIsLoadingForm] = useState(true)
  const [formMounted, setFormMounted] = useState(false)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const createForm = () => {
      window.hbspt.forms.create({
        portalId: activeTenant.hubspotPortalId,
        formId,
        css: '',
        target: `.${target}`,
        onFormSubmit: () => {
          if (onHiddenFields) {
            const hiddenFields = document.querySelectorAll<HTMLInputElement>(
              `.hs-input[type="hidden"]`,
            )
            onHiddenFields(hiddenFields)
          }
        },
      })
    }

    // On initial render, attaches the hubspot script to the window and renders a form using the createForm function
    if (!formMounted) {
      const script = document.createElement('script')
      script.src = 'https://js.hsforms.net/forms/v2.js'
      document.body.appendChild(script)

      script.addEventListener('load', () => {
        if (window.hbspt) {
          createForm()
        }
      })

      // Sets a loading state until the form has rendered on the page
      const loadingInterval = window.setInterval(() => {
        if (document.querySelector(`.${target} form`)) {
          window.clearInterval(loadingInterval)
          setIsLoadingForm(false)
          setFormMounted(true)
        }
      }, 200)

      return () => {
        window.clearInterval(loadingInterval)
      }
    }

    // If the form mounting has happened once, the script is already available so we jsut want to clear the old form and render a new one
    // this is relevant in a multi-lingual form when the locale switch happens
    document.querySelector(`.${target} form`)?.remove()
    createForm()
  }, [formId, target, formMounted, onHiddenFields])

  return (
    <div>
      {isLoadingForm && <Skeleton />}
      <HubspotFormStyles
        isLoading={isLoadingForm}
        className={`${target} sbs-form`}
      ></HubspotFormStyles>
    </div>
  )
}

type HubspotMultiLingualFormEmbedProps = {
  /** An array of all a multi lingual form's alternatives  */
  forms: Array<{ language: string; formId: string }>
} & Omit<HubspotFormEmbedProps, 'formId'>

/** Accepts an array of Hubspot multilingual forms and renders the correct form for the selected locale -
 * as per the hubspot docs, a seperate form must be created per language required: https://knowledge.hubspot.com/forms/create-forms-in-multiple-languages
 */
export const HubspotMultiLingualFormEmbed = ({
  forms,
  targetClass,
  ...props
}: HubspotMultiLingualFormEmbedProps) => {
  const { locale } = useIntl()

  // determine the correct form to render
  const activeForm = useMemo(
    () => forms.find((f) => f.language === locale),
    [forms, locale],
  )

  return (
    <HubspotFormEmbed
      targetClass={targetClass}
      formId={activeForm.formId}
      {...props}
    />
  )
}
