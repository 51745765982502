import React, { useState, useEffect, useMemo } from 'react'
import { Typography, FormHelperText } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import AnimateHeight from 'react-animate-height'

// to avoid circular dependency not importing from components/buttons directly ¯\_(ツ)_/¯
import { HintButton } from 'components/buttons/HintButton/HintButton'

const Component = ({ hint, error, errorId, boxed, height, whiteSpace }) => {
  const borderTopDesktop = boxed ? 'none' : 'primary'
  const borderTopDesktopError = boxed ? 'none' : 'error'
  const backgroundDesktop = boxed ? 'global.bgB.main' : 'transparent'
  const backgroundErrorMobile = boxed
    ? 'global.bgB.main'
    : 'global.errorRed.light'
  const backgroundMobile = boxed ? 'global.bgB.main' : 'global.bgA.main'
  return (
    <AnimateHeight duration={200} {...{ height }}>
      <Box
        sx={{
          border: (theme) =>
            boxed ? theme.borders.primary : theme.borders.none,
          borderTop: (theme) => [
            'none',
            'none',
            error
              ? theme.borders[borderTopDesktopError]
              : theme.borders[borderTopDesktop],
          ],
          borderBottom: 'none',
          pt: boxed ? 0 : 3,
          px: [3, 3, boxed ? 3 : 0],
          pb: [3, 3, boxed ? 3 : 0],
          backgroundColor: [
            error ? backgroundErrorMobile : backgroundMobile,
            error ? backgroundErrorMobile : backgroundMobile,
            backgroundDesktop,
          ],
        }}
      >
        {error && (
          <Flex flexDirection="column">
            <Box mb={height !== 0 ? 2 : 0}>
              <FormHelperText error>
                <FormattedMessage id={errorId} />
              </FormHelperText>
            </Box>
          </Flex>
        )}
        <Flex flexDirection="column">
          <Typography variant="body2" style={{ whiteSpace }}>
            {hint}
          </Typography>
        </Flex>
      </Box>
    </AnimateHeight>
  )
}

const useHint = ({
  hint,
  error,
  errorId,
  boxed = false,
  whiteSpace = 'normal',
}) => {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    setHeight(error ? 'auto' : 0)
  }, [error])

  const hintComponent = (
    <Component {...{ hint, error, errorId, boxed, height, whiteSpace }} />
  )

  const TriggerComponent = useMemo(
    () => (props) =>
      hint ? (
        <Box {...props} flex="0 0 24px">
          <HintButton
            on={height !== 0}
            aria-expanded={height !== 0}
            onClick={(e) => {
              e.stopPropagation()
              setHeight((prev) => (prev === 'auto' ? 0 : 'auto'))
            }}
          />
        </Box>
      ) : null,
    [hint, height],
  )

  return {
    hintComponent,
    TriggerComponent,
  }
}

export default useHint
