import React, { useEffect } from 'react'
import { useLoans } from 'context/Loans'
import { useAuth } from 'context/Auth'
import { Dashboard } from './views/Dashboard'

export const MerchantPortal = () => {
  const { token } = useAuth()
  const { initMerchant } = useLoans()
  useEffect(() => {
    if (token) initMerchant()
  }, [initMerchant, token])

  return <Dashboard />
}
