import React from 'react'
import { Box } from 'components/structural'

import { ReactComponent as Arrow } from 'images/icons/arrow-right.svg'

export const ArrowIcon = ({ error, ...rest }) => (
  <Box
    {...rest}
    sx={{
      svg: {
        g: {
          stroke: (theme) =>
            error
              ? theme.palette.global.errorRed.main
              : theme.palette.text.link.main,
        },
      },
    }}
  >
    <Arrow />
  </Box>
)
