import React from 'react'
import { Box } from 'components/structural'
import { ReactComponent as CheckboxChecked } from 'images/icons/checkbox-checked.svg'
import { ReactComponent as CheckboxUnchecked } from 'images/icons/checkbox-unchecked.svg'

export const CheckboxCheckedIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          '&:first-of-type': {
            fill: (theme) => theme.palette.input.ico.main,
          },
        },
      },
      fontSize: 0,
    }}
    width="24px"
    height="24px"
  >
    <CheckboxChecked />
  </Box>
)

export const CheckboxUncheckedIcon = () => (
  <Box width="24px" height="24px" sx={{ fontSize: 0 }}>
    <CheckboxUnchecked />
  </Box>
)
