import { CUSTOMER_PROGRESS } from 'constants/loanApplication'
import { ROUTES } from 'constants/routes'
import { useAuth } from 'context/Auth'
import { useLoanApplication } from 'context/LoanApplication'
import { useLoanOffer } from 'context/LoanOffer'
import { useCallback, useMemo } from 'react'
import { useNavigation } from 'routing/hooks/useNavigation'
import { isLinkIntegration, isTeleCash } from 'setup/tenant'
import { SelectedOffer } from 'types/offers'

export const useHandleSelectedOffer = ({
  payoutAmount,
  term,
  feeInEuro,
  percentageRate,
}) => {
  const { navigateTo } = useNavigation()
  const {
    selectLoanOffer,
    selectedOffer,
    saveLoanOffer,
    updateSelectedLoanOffer,
  } = useLoanOffer()
  const { token } = useAuth()
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()

  const handleSelectOffer = useCallback(async () => {
    const latestSelectedOffer: SelectedOffer = {
      id: selectedOffer ? selectedOffer.id : null,
      payoutAmount: {
        value: String(payoutAmount * 100),
        currency: 'EUR',
      },
      term,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      rate: percentageRate.toString(),
      fee: {
        value: String(feeInEuro * 100),
        currency: 'EUR',
      },
    }

    selectLoanOffer({
      loanOffer: latestSelectedOffer,
    })

    if (isLinkIntegration && !currentLoanApplication?.customerProgress) {
      await updateLoanApplicationById({
        loanApplicationId: currentLoanApplication?.id,
        loanApplication: {
          customerProgress: CUSTOMER_PROGRESS.LOAN_OFFERS,
        },
      })
    }

    if (selectedOffer && currentLoanApplication?.id) {
      await updateSelectedLoanOffer({
        loanApplicationId: currentLoanApplication?.id,
        loanOffer: latestSelectedOffer,
        offerId: latestSelectedOffer?.id,
      })
    }

    if (!selectedOffer) {
      await saveLoanOffer({
        loanApplicationId: currentLoanApplication?.id,
        loanOffer: latestSelectedOffer,
      })
    }

    if (isTeleCash) {
      navigateTo(ROUTES.ADDITIONAL_INFORMATION)
    } else if (isLinkIntegration) {
      navigateTo(ROUTES.COMPANY_SEARCH)
    } else {
      navigateTo(token ? ROUTES.COMPANY_SEARCH : ROUTES.SIGN_UP)
    }
  }, [
    currentLoanApplication?.customerProgress,
    currentLoanApplication?.id,
    feeInEuro,
    navigateTo,
    payoutAmount,
    percentageRate,
    saveLoanOffer,
    selectLoanOffer,
    selectedOffer,
    term,
    token,
    updateLoanApplicationById,
    updateSelectedLoanOffer,
  ])

  return handleSelectOffer
}
