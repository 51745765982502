import React from 'react'
import { Box, Flex } from 'components/structural'
import { useIntl } from 'react-intl'
import { SignOutIcon } from './icons'
import { ButtonRaw as Button } from '../ButtonRaw/ButtonRaw'

export const LogoutButton = (props) => {
  const intl = useIntl()
  return (
    <Flex ml={4} alignItems="center">
      <Button
        onClick={props.logout}
        title={intl.formatMessage({ id: 'logout' })}
        data-testid="logoutButton"
        data-cy="logoutButton"
      >
        <Box width="24px">
          <SignOutIcon />
        </Box>
      </Button>
    </Flex>
  )
}
