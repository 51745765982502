import React from 'react'
import { useNavigation } from 'routing/hooks/useNavigation'
import activeTenant from 'setup/tenant'
import type { BusinessDetails, LoanApplication } from 'types/loanApplication'
import type { SelectedOffer } from 'types/offers'
import { EditableBox } from './EditableBox'
import { TenantSpecificLoanDetails } from './TenantSpecificLoanDetails'

type LoanDetailsProps = {
  selectedOffer: SelectedOffer
  businessDetails: BusinessDetails
} & Pick<LoanApplication, 'approximateSales'>

export const LoanDetails = ({
  selectedOffer,
  businessDetails,
  approximateSales,
}: LoanDetailsProps) => {
  const { navigateTo } = useNavigation()

  const handleNavigateToCustomizeLoanOffer = () => {
    navigateTo(activeTenant.customizeLoanOfferRoute)
  }

  return (
    <>
      <EditableBox id="applicationSummary.loanDetails.heading">
        <TenantSpecificLoanDetails
          selectedOffer={selectedOffer}
          businessDetails={businessDetails}
          approximateSales={approximateSales}
        />
      </EditableBox>
    </>
  )
}
