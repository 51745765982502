import { LoanPurpose } from '@banxware/offer-generation'
import { ApplicationForm } from 'context/LoanOffer'
import localforage from 'localforage'
import { capitalize, noop } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import activeTenant from 'setup/tenant'
import { convertEuroToEuroCents } from 'utils/offers'
import useQueryParams from './useQueryParams'

/** Picks loan application data from the url query params and makes them usable to generate an offer */
export const useQueryStringApplication = () => {
  const { query, clear } = useQueryParams()

  const [queryStringApplication, setQueryStringApplication] =
    useState<Partial<ApplicationForm>>(null)

  useEffect(() => {
    const requiredLoanQueries =
      activeTenant.tenantCode === 'BXY'
        ? ['revenue', 'timeOfRunBusiness', 'loanPurpose']
        : ['revenue', 'timeOfRunBusiness']

    const getQueryStringApplication = async () => {
      const hasQueryStringApplication = requiredLoanQueries?.every(
        (i: string) => Object.keys(query).includes(i),
      )
      const hasPartnerParam = !!query?.partner
      if (!hasPartnerParam && !hasQueryStringApplication) return

      await localforage.clear()
      const applicationParams: ApplicationForm = {
        tenantSpecific: {},
      } as ApplicationForm

      if (hasQueryStringApplication) {
        applicationParams.approximateSales = convertEuroToEuroCents(
          Number(query.revenue),
        )
        applicationParams.timeOfRunBusiness = query.timeOfRunBusiness as string
        if (requiredLoanQueries.includes('loanPurpose')) {
          applicationParams.tenantSpecific.selectedLoanPurpose =
            query?.loanPurpose &&
            (capitalize(query.loanPurpose as string) as LoanPurpose)
        }
      }

      if (hasPartnerParam) {
        applicationParams.tenantSpecific.partner = query?.partner as string
      }

      setQueryStringApplication(applicationParams)
    }

    if (query) {
      getQueryStringApplication().catch(noop)
    }
  }, [query])

  const clearQueryStringApplication = useCallback(() => {
    clear()
    setQueryStringApplication(null)
  }, [clear, setQueryStringApplication])

  return { queryStringApplication, clear: clearQueryStringApplication }
}
