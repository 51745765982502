import isURL from 'validator/lib/isURL'
import isInt from 'validator/lib/isInt'
import isFloat from 'validator/lib/isFloat'
import isEmail from 'validator/lib/isEmail'
import isPostalCode, { locales } from 'validator/lib/isPostalCode'
import isValidDate from 'date-fns/isValid'
import isFuture from 'date-fns/isFuture'
import differenceInYears from 'date-fns/differenceInYears'
import i18nCountries from 'i18n-iso-countries'
import { MIN_DATE } from 'constants/format'

export const composeValidators =
  (...args) =>
  (value) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const validator of args) {
      const error = validator(value)
      if (error) return error
    }
    return undefined
  }

export const VALIDATOR_MESSAGE_ID = {
  IS_REQUIRED: 'validators.isRequired',
  ACCEPTANCE_REQUIRED: 'validators.acceptanceRequired',
  REGISTRATION_NUMBER_PATTERN_IS_WRONG:
    'validators.registrationNumberPatternIsWrong',
  DATE_SHOULD_NOT_BE_FUTURE: 'validators.dateShouldNotBeFuture',
  SHOULD_BE_VALID_DATE: 'validators.shouldBeValidDate',
  SHOULD_BE_POSITIVE_INTEGER: 'validators.shouldBePositiveInteger',
  SHOULD_BE_NON_NEGATIVE_INTEGER: 'validators.shouldBeNonNegativeInteger',
  SHOULD_BE_VALID_URL: 'validators.shouldBeValidUrl',
  SHOULD_BE_VALID_TAX_ID: 'validators.shouldBeValidTaxId',
  SHOULD_BE_VALID_TAX_NUMBER: 'validators.shouldBeValidTaxNumber',
  SHOULD_BE_VALID_VAT_ID: 'validators.shouldBeValidVatId',
  SHOULD_BE_VALID_DOCTOR_NUMBER: 'validators.shouldBeValidDoctorNumber',
  SHOULD_BE_VALID_DOCTOR_REG_NUMBER: 'validators.shouldBeValidDoctorRegNumber',
  SHOULD_BE_VALID_DOCTOR_TRAINING_NUMBER:
    'validators.shouldBeValidDoctorTrainingNumber',
  SHOULD_BE_VALID_ZIP_CODE: 'validators.shouldBeValidZipCode',
  SHOULD_BE_VALID_EMAIL: 'validators.shouldBeValidEmail',
  SHOULD_BE_VALID_PERCENT_AMOUNT: 'validators.shouldBeValidPercentAmount',
  SHOULD_BE_VALID_SHARES_AMOUNT: 'validators.shouldBeValidSharesAmount',
  SHOULD_BE_OVER_18: 'validators.shouldBeOver18',
  PASSWORD_IS_TOO_WEAK: 'validators.passwordIsTooWeak',
  SHOULD_BE_VALID_DOB_DATE: 'validators.shouldBeValidDobDate',
  SHOULD_BE_VALID_FOUNDATION_DATE: 'validators.shouldBeValidFoundationDate',
  LEGAL_REP_OR_UBO_REQUIRED: 'validators.legalRepOrUBORequired',
  SHOULD_BE_VALID_NUMBER_OF_EMPLOYEES:
    'validators.shouldBeValidNumberOfEmployees',
  SHOULD_BE_VALID_PHONE_NUMBER: 'validators.shouldBeValidPhoneNumber',
}

export const requiredValidator = (value) =>
  !value || (typeof value === 'string' && !value?.trim())
    ? VALIDATOR_MESSAGE_ID.IS_REQUIRED
    : undefined

export const booleanRequiredValidator = (value) =>
  typeof value !== 'boolean' ? VALIDATOR_MESSAGE_ID.IS_REQUIRED : undefined

export const acceptanceValidator = (value) =>
  !value ? VALIDATOR_MESSAGE_ID.ACCEPTANCE_REQUIRED : undefined

export const registrationNumberPatternValidator = (value) =>
  value && !/^HR[AB] ?[0-9]{4,7}( ?[a-zA-Z])?$/.test(value)
    ? VALIDATOR_MESSAGE_ID.REGISTRATION_NUMBER_PATTERN_IS_WRONG
    : undefined

export const registrationNumberPatternValidatorPartG = (value) =>
  value &&
  !/^HR[AB] ?[0-9]{4,7}( ?[a-zA-Z])?$/.test(value) &&
  !/^PR ?[0-9]{4,7}$/.test(value)
    ? VALIDATOR_MESSAGE_ID.REGISTRATION_NUMBER_PATTERN_IS_WRONG
    : undefined

export const positiveIntegerValidator = (value) =>
  value && !(isInt(String(value)) && value > 0)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_POSITIVE_INTEGER
    : undefined

export const nonNegativeIntegerValidator = (value) =>
  value && !(isInt(String(value)) && value >= 0)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_NON_NEGATIVE_INTEGER
    : undefined

export const urlValidator = (value) =>
  value && !isURL(value, { disallow_auth: true })
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_URL
    : undefined

const TAX_NUMBER_VALIDATOR_REGEX =
  /(^\d{10,11}$)|(^\d{13}$)|(^\d{2,3}\/?\d{3}\/\d{5}$)|(^\d{3}\/\d{4}\/\d{4})$/

export const taxIdValidator = (value) =>
  value && !TAX_NUMBER_VALIDATOR_REGEX.test(value)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_TAX_ID
    : undefined

export const companyTaxNumberValidator = (value) =>
  value && !TAX_NUMBER_VALIDATOR_REGEX.test(value)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_TAX_NUMBER
    : undefined

export const companyVatIdValidator = (value) =>
  value && !/DE ?[0-9]{9}$/.test(value)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_VAT_ID
    : undefined

export const zipCodeValidator = (value) =>
  value && !isPostalCode(String(value), 'any')
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_ZIP_CODE
    : undefined

export const zipCodeValidatorDE = (value) =>
  value && !isPostalCode(String(value), 'DE')
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_ZIP_CODE
    : undefined

export const zipCodeDynamicValidator = (alpha3) => (value) =>
  value &&
  !isPostalCode(
    String(value),
    locales.includes(i18nCountries.alpha3ToAlpha2(alpha3))
      ? i18nCountries.alpha3ToAlpha2(alpha3)
      : 'any',
  )
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_ZIP_CODE
    : undefined

export const emailValidator = (value) =>
  value && !isEmail(value)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_EMAIL
    : undefined

export const percentAmountValidator = (value) =>
  parseInt(value, 10) <= 0 || parseInt(value, 10) > 100 || !isInt(String(value))
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_PERCENT_AMOUNT
    : undefined

export const sharesAmountValidator = (value) =>
  parseInt(value, 10) <= 0 || parseInt(value, 10) > 100 || !isInt(String(value))
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_SHARES_AMOUNT
    : undefined

export const dateFormatValidator = (value) =>
  value && !isValidDate(new Date(value))
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_DATE
    : undefined

export const dateInFutureValidator = (value) =>
  value && isFuture(new Date(value))
    ? VALIDATOR_MESSAGE_ID.DATE_SHOULD_NOT_BE_FUTURE
    : undefined

export const is18Validator = (value) =>
  value && differenceInYears(new Date(), new Date(value)) < 18
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_OVER_18
    : undefined

export const doctorNumberValidator = (value) =>
  value && !/^[0-9]{9}$/.test(value)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_DOCTOR_NUMBER
    : undefined

export const doctorRegNumberValidator = (value) =>
  value && !/^[0-9]{9}$/.test(value)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_DOCTOR_REG_NUMBER
    : undefined

export const doctorTrainingNumberValidator = (value) =>
  value && !/^[0-9]{2}(276)[0-9]{10}$/.test(value)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_DOCTOR_TRAINING_NUMBER
    : undefined

// Password validators
export const passwordValidators = {
  hasEnoughCharacters: /(?=.{8,})/,
  hasNumber: /(?=.*[0-9])/,
  hasUppercase: /(?=.*[A-Z])/,
  hasLowercase: /(?=.*[a-z])/,
}

export const passwordStrengthValidator = (value) =>
  value && !Object.values(passwordValidators).every((rule) => rule.test(value))
    ? VALIDATOR_MESSAGE_ID.PASSWORD_IS_TOO_WEAK
    : undefined

export const minDobDateValidator = (value) =>
  value && new Date(MIN_DATE) - new Date(value) > 0
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_DOB_DATE
    : undefined

export const minFoundationDateValidator = (value) =>
  value && new Date(MIN_DATE) - new Date(value) > 0
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_FOUNDATION_DATE
    : undefined

export const numberOfEmployeesFormatValidator = (locale) => (value) =>
  value && !(isInt(value) || isFloat(value, { locale }))
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_NUMBER_OF_EMPLOYEES
    : undefined

export const numberOfEmployeesRangeValidator = (value) => {
  const parsed = String(parseFloat(value.replace(',', '.')))
  return parsed && !(parsed >= 1 && parsed <= 1000)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_NUMBER_OF_EMPLOYEES
    : undefined
}

const PHONE_NUMBER_VALIDATOR_REGEX = /(^[0][0-9]{4,}|^[+][0-9]{5,})$/

export const phoneNumberValidator = (value) =>
  value && !PHONE_NUMBER_VALIDATOR_REGEX.test(value)
    ? VALIDATOR_MESSAGE_ID.SHOULD_BE_VALID_PHONE_NUMBER
    : undefined
