import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { ButtonRaw } from 'components/buttons'
import { ReactComponent as LinkC } from 'images/icons/link-c.svg'
import { ReactComponent as ErrorIcon } from 'images/bullets/error.svg'
import { ReactComponent as RetryIcon } from 'images/icons/refresh.svg'

type LinkedPlatformBoxProps = {
  companyName: string
  handleRetry: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>
  name: string
  status: string
  isError?: boolean
}

export const LinkedPlatformBox = (props: LinkedPlatformBoxProps) => (
  <Flex flexDirection="column">
    <Flex
      flex="1 1 auto"
      p={3}
      sx={{
        // @ts-expect-error Property 'borders' does not exist on type 'Theme'
        borderTop: (theme) => theme.borders.primary,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        width="24px"
        mr={3}
        alignSelf="center"
        sx={{
          svg: {
            g: {
              // @ts-expect-error Property 'borders' does not exist on type 'Theme'
              stroke: (theme) => theme.palette.global.icoA.main,
            },
          },
        }}
      >
        {props.isError ? <ErrorIcon /> : <LinkC />}
      </Box>
      <Box alignSelf="center">
        <Typography variant="body2">{props.name}</Typography>
      </Box>
      <Flex ml="auto" justifyContent="right" width="auto">
        {!props.isError && (
          <Flex flexDirection="row" width="100%">
            <Flex
              justifyContent="left"
              flexDirection="column"
              minWidth="60px"
              sx={{ marginRight: '10px' }}
            >
              <Typography variant="caption" color="text.subtle.main">
                <FormattedMessage id="connectShoppingPlatform.linked.company" />
              </Typography>
              <Typography variant="caption" color="text.subtle.main">
                <FormattedMessage id="connectShoppingPlatform.linked.status" />
              </Typography>
            </Flex>
            <Flex justifyContent="left" minWidth="60px" flexDirection="column">
              <Typography variant="caption">{props.companyName}</Typography>
              <Typography variant="caption">{props.status}</Typography>
            </Flex>
          </Flex>
        )}
        {props.isError && (
          <ButtonRaw name={props.name} onClick={props.handleRetry}>
            <Flex flexDirection="row" justifyContent="space-between">
              <Box
                mt={1}
                width="24px"
                mr={2}
                alignSelf="center"
                sx={{
                  svg: {
                    g: {
                      // @ts-expect-error Property 'borders' does not exist on type 'Theme'
                      stroke: (theme) => theme.palette.global.icoA.main,
                    },
                  },
                }}
              >
                <RetryIcon />
              </Box>
              <Typography variant="button" color="text.link.main">
                <FormattedMessage id="connectShoppingPlatform.retry" />
              </Typography>
            </Flex>
          </ButtonRaw>
        )}
      </Flex>
    </Flex>
  </Flex>
)
