import React, { Component, ErrorInfo, ReactNode } from 'react'
import * as Sentry from '@sentry/browser'
import { Flex, Box } from 'components/structural'
import { Logo } from 'components/Logo'
import { ReactComponent as ErrorMan } from 'images/man.svg'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line
    console.error('ErrorBoundary caught:', error, errorInfo)

    Sentry.captureException(error, {
      extra: {
        message: 'React error boundary catch',
        errorInfo,
      },
    })
  }

  // This render method cannot use anything from any providers, since the ErrorProvider
  // is the topmost one. This includes intl and theming, that's why we recreate a basic
  // layout here with hardcoded text in English. It's a last resort that should hopefully
  // never be encountered by the user.
  render() {
    if (this.state.hasError) {
      return (
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          style={{ minHeight: '100vh', overflow: 'hidden' }}
        >
          <Flex flexDirection="column" alignItems="center" m="3">
            <Box sx={{ alignSelf: 'flex-start' }}>
              <Logo />
            </Box>
            <Box sx={{ pt: '2', pb: '4' }}>
              <h1 style={{ fontFamily: 'sans-serif' }}>
                Application does not respond
              </h1>
              <p style={{ fontFamily: 'sans-serif' }}>
                Our technical team has been informed. Please refresh the window
                and try again.
              </p>
            </Box>
            <ErrorMan
              style={{
                width: '300px',
              }}
            />
          </Flex>
        </Flex>
      )
    }

    return this.props.children
  }
}
