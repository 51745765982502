import React from 'react'
import LinkError from '../LinkError'

const LinkWithoutBlob = () => (
  <LinkError
    header="linkWithoutBlob.header"
    content="linkWithoutBlob.content"
  />
)

export default LinkWithoutBlob
