import React from 'react'
import { Button } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('tablet')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
}))

export const MenuButton = ({ children, ...restProps }) => {
  const classes = useStyles()
  return (
    <Button {...{ classes }} {...restProps}>
      {children}
    </Button>
  )
}
