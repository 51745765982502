import React from 'react'
import { Helmet } from 'react-helmet'
import activeTenant from 'setup/tenant'

const { favicon16, favicon32, manifest, pin, appleTouch, tileColor } =
  activeTenant.iconSet

export const DocumentHead = () => (
  <Helmet>
    <title>{activeTenant.title}</title>
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouch} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
    <link rel="manifest" href={manifest} />
    <link rel="mask-icon" href={pin} color="#5bbad5" />
    <meta name="msapplication-TileColor" content={tileColor} />
    <meta name="theme-color" content="#ffffff" />
  </Helmet>
)
