import React from 'react'
import { Typography } from '@mui/material'
import { Box, Flex } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import {
  TIME_OF_RUN_BUSINESS,
  LEGAL_FORMS,
  CUSTOMER_PROGRESS,
} from 'constants/loanApplication'
import { ROUTES } from 'constants/routes'
import { parseTitle, parseAddress, hasAnyPartOfAddress } from 'utils'
import { useDocuments } from 'context/Documents'
import { OwnerBox } from 'components/OwnerBox'
import activeTenant, { isGetRaoul, isPayOne, isBanxware } from 'setup/tenant'
import { ShareholdersIcon } from 'components/icons'
import { getDocumentTypeTranslationProps } from 'utils/documents'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { useLanguage } from 'context/Language'
import { useLoanOffer } from 'context/LoanOffer'
import { useLoanApplication } from 'context/LoanApplication'
import { useNavigation } from 'routing/hooks/useNavigation'
import { Divider } from 'components/Divider'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { includes, noop } from 'lodash'
import { LoanDetails } from './components/LoanDetails'
import { EditableBox } from './components/EditableBox'
import { EditableBoxFiles } from './components/EditableBoxFiles'
import { Caption } from './components/Caption'
import { LoanOptin } from './components/LoanOptin'
import type { LoanOptinFormValues } from './components/LoanOptin'
import { PdfIcon } from './icons'

export const ApplicationSummary = () => {
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const { selectedOffer } = useLoanOffer()
  const { navigateTo } = useNavigation()
  const { goToPrevPage, goToNextPage } = useFlowNavigation()
  const { documents } = useDocuments()
  const { locale, dateLocale } = useLanguage()

  const soleTraderFreelancerFlow = includes(
    [LEGAL_FORMS.SOLEPROPRIETOR, LEGAL_FORMS.FREELANCER],
    currentLoanApplication?.businessDetails?.legalForm,
  )

  const owner = currentLoanApplication.merchant?.owners?.[0]
  const address = Object.keys(owner.address || {}).length
    ? owner.address
    : currentLoanApplication?.businessDetails?.address

  const initialValues: LoanOptinFormValues = {
    optinPep: currentLoanApplication.optinPep,
    optinSchufa: currentLoanApplication.optinSchufa,
    optinTerms: currentLoanApplication.optinTerms,
    optinFacta: currentLoanApplication.optinFacta,
    ...(soleTraderFreelancerFlow && {
      optinOwnInitiative: currentLoanApplication.optinOwnInitiative,
    }),
  }

  const submitLoanApplication = (values: LoanOptinFormValues) => {
    updateLoanApplicationById({
      loanApplicationId: currentLoanApplication.id,
      loanApplication: {
        ...currentLoanApplication,
        ...values,
        customerProgress: CUSTOMER_PROGRESS.APPLICATION_SUMMARY,
        submitted: true,
      },
    }).catch(noop)
    goToNextPage()
  }

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="applicationSummary.header"
          subId="expressApplicationReviewPage.subheading"
          icon={<ShareholdersIcon />}
        />
      }
      withProgress
    >
      <Layout.Middle>
        <Box mb={6}>
          <Typography variant="h5">
            <FormattedMessage id="applicationSummary.heading" />
          </Typography>
        </Box>
        <Divider />
        <EditableBox id="applicationSummary.applicant.heading">
          <Flex>
            <Box mr={6} flex="0 1 auto">
              <Caption id="applicationSummary.person">
                <FormattedMessage id={parseTitle(owner?.title)} />{' '}
                {owner?.firstName} {owner?.lastName}
                {'\n'}
                {owner?.email}
              </Caption>
            </Box>
            <Box flex="0 1 176px">
              <Caption id="applicationSummary.address">
                {hasAnyPartOfAddress(address)
                  ? parseAddress(address, locale, ', ')
                  : '-'}
              </Caption>
            </Box>
          </Flex>
        </EditableBox>
        <Divider />
        <EditableBox
          id="applicationSummary.owners.heading"
          onClick={() => {
            const route = includes(
              [
                LEGAL_FORMS.GMBH,
                LEGAL_FORMS.OHG,
                LEGAL_FORMS.GMBH_COKG,
                LEGAL_FORMS.GBR,
                LEGAL_FORMS.UG,
              ],
              currentLoanApplication.businessDetails.legalForm,
            )
              ? ROUTES.STAKEHOLDERS
              : ROUTES.OWNER_DETAILS
            navigateTo(route)
          }}
        >
          {currentLoanApplication.merchant.owners.map((applicantOwner) => (
            <Box
              key={applicantOwner.id}
              mb={3}
              sx={{
                // @ts-expect-error Property 'borders' does not exist on type 'Theme'
                border: (theme) => theme.borders.primary,
                borderRadius: activeTenant.theme.shape.fieldBorderRadius,
              }}
            >
              <OwnerBox {...applicantOwner} />
            </Box>
          ))}
        </EditableBox>
        <Divider />
        <LoanDetails
          selectedOffer={selectedOffer}
          businessDetails={currentLoanApplication.businessDetails}
          approximateSales={currentLoanApplication.approximateSales}
        />
        <Divider />
        <EditableBox
          id="applicationSummary.businessDetails.heading"
          onClick={() => navigateTo(ROUTES.BUSINESS_DETAILS)}
        >
          <Flex>
            <Box flex="0 1 176px" mr={6}>
              <Caption id="applicationSummary.name">
                {currentLoanApplication.businessDetails?.name}
              </Caption>
            </Box>
            <Box flex="0 1 176px" mr={6}>
              <Caption id="applicationSummary.responsiblePublicAuthority">
                {currentLoanApplication.businessDetails
                  ?.responsiblePublicAuthority || '-'}
              </Caption>
            </Box>
            <Box flex="0 1 176px">
              <Caption id="applicationSummary.numberOfEmployees">
                {currentLoanApplication.businessDetails?.numberOfEmployees ||
                  '-'}
              </Caption>
            </Box>
          </Flex>
          <Flex mt={4}>
            <Box flex="0 1 176px" mr={6}>
              <Caption id="applicationSummary.registrationNumber">
                {currentLoanApplication.businessDetails?.registrationNumber ||
                  '-'}
              </Caption>
            </Box>
            <Box flex="0 1 176px" mr={6}>
              <Caption id="applicationSummary.register">
                {currentLoanApplication.businessDetails?.register || '-'}
              </Caption>
            </Box>
            <Box flex="0 1 176px">
              <Caption id="applicationSummary.address">
                {parseAddress(
                  currentLoanApplication.businessDetails?.address,
                  locale,
                  ', ',
                )}
              </Caption>
            </Box>
          </Flex>
          <Flex mt={4}>
            <Box flex="0 1 176px" mr={6}>
              <Caption id="applicationSummary.foundationDate">
                {currentLoanApplication.businessDetails?.foundationDate}
              </Caption>
            </Box>
            <Box flex="0 1 176px" mr={6}>
              <Caption id="applicationSummary.website">
                {currentLoanApplication.businessDetails?.website || '-'}
              </Caption>
            </Box>
          </Flex>
        </EditableBox>
        <Divider />
        {documents?.length > 0 &&
          [
            LEGAL_FORMS.SOLEPROPRIETOR ===
              currentLoanApplication.businessDetails.legalForm,
            LEGAL_FORMS.GBR ===
              currentLoanApplication.businessDetails.legalForm,
            // all GetRaoul and Banxware tenants need to upload six documents with revenue
            isGetRaoul,
            isBanxware,
            isPayOne,
          ].some(Boolean) && (
            <>
              <EditableBoxFiles
                id="applicationSummary.attachedFiles.heading"
                onClick={() => navigateTo(ROUTES.DOCUMENTS_UPLOAD)}
              >
                <Flex flexDirection="column">
                  {documents?.map((document, index) => {
                    const documentTypeTranslationProps =
                      getDocumentTypeTranslationProps({
                        documentType: document.documentType,
                        index,
                        locale: dateLocale,
                      })

                    return (
                      <Flex
                        key={document.id}
                        mb={index < documents.length - 1 ? 4 : 0}
                      >
                        <Box flex="0 0 32px" mr={3}>
                          <PdfIcon />
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            color="text.subtle.main"
                          >
                            <FormattedMessage
                              {...documentTypeTranslationProps}
                            />
                          </Typography>
                          <Typography variant="body2">
                            {document.filename}
                          </Typography>
                        </Box>
                      </Flex>
                    )
                  })}
                </Flex>
              </EditableBoxFiles>
              <Divider />
            </>
          )}
      </Layout.Middle>
      <Layout.MiddleAndRight mb="30px">
        <LoanOptin
          goToPrevPage={goToPrevPage}
          initialValues={initialValues}
          submitLoanApplication={submitLoanApplication}
        />
      </Layout.MiddleAndRight>
    </Layout>
  )
}
