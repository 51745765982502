import { useCallback } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { useLoans } from 'context/Loans'
import { useLoanApplication } from 'context/LoanApplication'

export function useNavigation() {
  const { currentLoanApplication } = useLoanApplication()
  const { loans, defaultLoanId } = useLoans() || {}
  const history = useHistory()

  const navigateTo = useCallback(
    (route: typeof ROUTES[keyof typeof ROUTES] | string) => {
      const path: string = currentLoanApplication?.id
        ? generatePath(route, {
            id: loans?.length ? defaultLoanId : currentLoanApplication?.id,
          })
        : route
      history.push(path)
    },
    [currentLoanApplication, defaultLoanId, history, loans],
  )

  return {
    navigateTo,
  }
}
