import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { Grid } from 'components/Grid'
import { Button, BackButton } from 'components/buttons'
import { ViewHeader } from 'components/headers'
import Layout from 'components/Layout'
import { PersonalIcon } from 'components/icons'
import { useDocuments } from 'context/Documents'
import {
  getRequiredDocs,
  getDocumentTypeTranslationProps,
  getRevenueDocs,
  getAfterbuyRevenueDoc,
  getEbayRevenueDoc,
  getGambioRevenueDoc,
  getGirocardRevenueDoc,
  getManualReportRevenueDoc,
  getHoodRevenueDoc,
} from 'utils/documents'
import activeTenant, {
  isGetRaoul,
  isBanxware,
  isMonthlyRevenueUploadGroup,
  isBanxy,
  isTeleCash,
  isCodatEnabled,
} from 'setup/tenant'
import { useHintRaw } from 'hooks'
import { DOCUMENT_TYPES } from 'constants/documentTypes'
import { LEGAL_FORMS, CUSTOMER_PROGRESS } from 'constants/loanApplication'
import { ROUTES } from 'constants/routes'
import { useNavigation } from 'routing/hooks/useNavigation'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useLanguage } from 'context/Language'
import { useLoanApplication } from 'context/LoanApplication'
import { ReactComponent as PersonalTeleCashIcon } from 'images/personal-telecash.svg'
import UploadBox from './UploadBox'
import { InfoBox } from './InfoBox'

const DocumentsUpload = () => {
  const { documents } = useDocuments()
  const { goToPrevPage, goToNextPage } = useFlowNavigation()
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const { dateLocale } = useLanguage()
  const { navigateTo } = useNavigation()
  const { pathname } = useLocation()
  const isAfterbuy = useMemo(
    () => ROUTES.AFTERBUY.includes(pathname.split('/').splice(-2).join('/')),
    [pathname],
  )
  const isEbay = useMemo(
    () => ROUTES.EBAY.includes(pathname.split('/').splice(-2).join('/')),
    [pathname],
  )
  const isGambio = useMemo(
    () => ROUTES.GAMBIO.includes(pathname.split('/').splice(-2).join('/')),
    [pathname],
  )
  const isGirocard = useMemo(
    () => ROUTES.GIROCARD.includes(pathname.split('/').splice(-2).join('/')),
    [pathname],
  )
  const isManualReportUpload = useMemo(
    () =>
      ROUTES.MANUAL_REPORT_UPLOAD.includes(
        pathname.split('/').splice(-2).join('/'),
      ),
    [pathname],
  )
  const isHoodUpload = useMemo(
    () => ROUTES.HOOD.includes(pathname.split('/').splice(-2).join('/')),
    [pathname],
  )

  const isMultipleDocumentUpload = useMemo(
    () =>
      ROUTES.CONNECT_SHOPPING_PLATFORM_DOCUMENTS.includes(
        pathname.split('/').splice(-1).join('/'),
      ),
    [pathname],
  )
  const hintId = {
    afterbuy: 'documentsUpload.hint.afterbuy',
    ebay: 'documentsUpload.hint.ebay',
    gambio: 'documentsUpload.hint.gambio',
    girocard: 'documentsUpload.hint.girocard',
    hood: 'documentsUpload.hint.hood',
    'manual-report-upload': 'documentsUpload.hint.manualReport',
    'documents-upload': 'documentsUpload.hint',
  }

  const { hintComponent, TriggerComponent } = useHintRaw({
    hint: (
      <FormattedMessage id={hintId[pathname.split('/').splice(-1).join('/')]} />
    ),
    whiteSpace: 'pre-line',
  })

  const isCodatIntegrationStep =
    isCodatEnabled &&
    currentLoanApplication.status === 'eligible' &&
    (isMultipleDocumentUpload ||
      isManualReportUpload ||
      isHoodUpload ||
      isAfterbuy ||
      isEbay ||
      isGambio ||
      isGirocard)

  const legalForm = currentLoanApplication?.businessDetails.legalForm
  let requiredDocs
  if (isMultipleDocumentUpload && isCodatEnabled && isCodatIntegrationStep) {
    requiredDocs = getRevenueDocs(DOCUMENT_TYPES.CODAT_REVENUE_REPORT)
  } else if (isAfterbuy) {
    requiredDocs = getAfterbuyRevenueDoc()
  } else if (isEbay) {
    requiredDocs = getEbayRevenueDoc()
  } else if (isGambio) {
    requiredDocs = getGambioRevenueDoc()
  } else if (isGirocard) {
    requiredDocs = getGirocardRevenueDoc()
  } else if (isManualReportUpload) {
    requiredDocs = getManualReportRevenueDoc()
  } else if (isHoodUpload) {
    requiredDocs = getHoodRevenueDoc()
  } else requiredDocs = getRequiredDocs(legalForm)

  const areDocsUploaded = useMemo(
    () =>
      requiredDocs?.reduce(
        (acc, curr) =>
          documents?.find((doc) => doc.documentType === curr) ? acc : false,
        true,
      ),
    [requiredDocs, documents],
  )

  const handleGoToPrevPage = () => {
    if (isCodatIntegrationStep) {
      navigateTo(ROUTES.CONNECT_SHOPPING_PLATFORM)
      return
    }
    goToPrevPage()
  }

  const handleGoToNextPage = () => {
    updateLoanApplicationById({
      loanApplicationId: currentLoanApplication.id,
      loanApplication: {
        customerProgress: isCodatIntegrationStep
          ? CUSTOMER_PROGRESS.CONNECT_SHOPPING_PLATFORM
          : CUSTOMER_PROGRESS.DOCUMENTS_UPLOAD,
      },
    })
    if (isCodatIntegrationStep) {
      navigateTo(ROUTES.BANK_LINKING)
      return
    }
    goToNextPage()
  }
  if (!currentLoanApplication || !requiredDocs.length) return null
  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="documentsUpload.header"
          subId={
            (!(isMonthlyRevenueUploadGroup || isBanxy) &&
              'documentsUpload.description',
            'expressApplicationDocumentUpload.subheading')
          }
          icon={isTeleCash ? <PersonalTeleCashIcon /> : <PersonalIcon />}
        />
      }
      withProgress
    >
      <Grid.Column start={2} span={2}>
        {(isGetRaoul || isBanxware) && (
          <>
            <Flex justifyContent="space-between">
              <Typography variant="h5">
                <FormattedMessage id="documentsUpload.description" />
              </Typography>
            </Flex>
            <Box
              width="100%"
              backgroundColor="global.bgB.main"
              p={3}
              mt={3}
              mb={3}
            >
              {!isBanxware && (
                <TriggerComponent>
                  <Box sx={{ whiteSpace: 'pre-line' }}>
                    <Typography variant="body1">
                      {isGetRaoul && (
                        <FormattedMessage id="documentsUpload.hint.title.grl" />
                      )}
                    </Typography>
                  </Box>
                </TriggerComponent>
              )}

              {isBanxware && (
                <Box sx={{ whiteSpace: 'pre-line' }}>
                  <Typography variant="body1">
                    <FormattedMessage
                      id="documentsUpload.hint.title.bxw"
                      values={{
                        faqLink: (
                          <Box
                            component="a"
                            href={activeTenant.faqLink}
                            sx={{
                              color: 'global.icoA.main',
                              textDecoration: 'none',
                              fontWeight: 500,
                            }}
                          >
                            <FormattedMessage id="loanApplicationApproved.inOurFaq" />
                          </Box>
                        ),
                      }}
                    />
                  </Typography>
                </Box>
              )}

              <Box sx={{ whiteSpace: 'pre-line' }}>{hintComponent}</Box>
            </Box>
          </>
        )}
        {(isManualReportUpload ||
          isHoodUpload ||
          isAfterbuy ||
          isEbay ||
          isGambio ||
          isGirocard) && (
          <>
            <Box
              width="100%"
              backgroundColor="global.bgB.main"
              p={3}
              mt={3}
              mb={3}
            >
              <TriggerComponent>
                <Box sx={{ whiteSpace: 'pre-line' }}>
                  <Typography variant="body1">
                    {isAfterbuy ? (
                      <FormattedMessage id="documentsUpload.hint.title.afterbuy" />
                    ) : isEbay ? (
                      <FormattedMessage id="documentsUpload.hint.title.ebay" />
                    ) : isGambio ? (
                      <FormattedMessage id="documentsUpload.hint.title.gambio" />
                    ) : isGirocard ? (
                      <FormattedMessage id="documentsUpload.hint.title.girocard" />
                    ) : isManualReportUpload ? (
                      <FormattedMessage id="documentsUpload.hint.title.manualReport" />
                    ) : (
                      <FormattedMessage id="documentsUpload.hint.title.hood" />
                    )}
                  </Typography>
                </Box>
              </TriggerComponent>
              <Box sx={{ whiteSpace: 'pre-line' }}>{hintComponent}</Box>
            </Box>
          </>
        )}
        {requiredDocs?.map((docType, index) => {
          const documentTypeTranslationProps = getDocumentTypeTranslationProps({
            documentType: docType,
            index,
            locale: dateLocale,
          })
          return (
            <UploadBox
              key={docType}
              title={<FormattedMessage {...documentTypeTranslationProps} />}
              documentType={docType}
              loanApplicationId={currentLoanApplication.id}
              {...((isMonthlyRevenueUploadGroup || isBanxy) && {
                acceptedFileTypes: [
                  'application/pdf',
                  'text/csv',
                  'text/comma-separated-values',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ],
              })}
            />
          )
        })}
        <Flex>
          <BackButton onClick={handleGoToPrevPage} />
          <Button
            disabled={
              (!isBanxware && !areDocsUploaded) ||
              (isBanxware && !documents.length)
            }
            onClick={handleGoToNextPage}
            withArrow
            data-testid="submitButton"
          >
            <FormattedMessage id="form.submit" />
          </Button>
        </Flex>
        {legalForm === LEGAL_FORMS.GBR && !isBanxy && <InfoBox />}
      </Grid.Column>
    </Layout>
  )
}

export default DocumentsUpload
