import _ from 'lodash'
import { countries as countriesData } from 'country-data'
import i18nCountries from 'i18n-iso-countries'

const countries = countriesData.all.filter((c) => c.status === 'assigned')

const kosovo = {
  alpha2: 'KO',
  alpha3: 'XXK',
  countryCallingCodes: ['+383'],
  currencies: ['EUR'],
  emoji: '🇽🇰',
  ioc: 'KOS',
  languages: ['sqi,srp,bos,tur,rom'],
  name: 'Kosovo',
  status: 'user assigned',
}
const allCountries = [...countries, kosovo]

const getCountries = (locale) =>
  _.uniqBy(allCountries, (e) => e.alpha3)
    .filter(({ alpha3 }) => alpha3)
    .map(({ alpha3, name }) => ({
      value: alpha3,
      message: i18nCountries.getName(alpha3, locale) || name,
    }))
    .filter(({ message }) => message)
    .sort(({ message: messageA }, { message: messageB }) =>
      messageA > messageB ? 1 : -1,
    )

export default getCountries
