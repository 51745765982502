import React from 'react'
import { Box } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { ReactComponent as ReloadIcon } from 'images/icons/refresh.svg'
import { ButtonRaw } from 'components/buttons'

export const ReloadButton = ({ onClick }) => (
  <>
    <Box mt={5}>
      <ButtonRaw onClick={onClick}>
        <Typography variant="button" color="text.link.main">
          <FormattedMessage id="applicationError.reload" />
        </Typography>
        <Box width="24px" ml={2}>
          <ReloadIcon />
        </Box>
      </ButtonRaw>
    </Box>
  </>
)
