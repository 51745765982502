import React from 'react'
import { styled } from '@mui/system'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { ReactComponent as ErrorIcon } from 'images/error.svg'
import RedirectToMerchantOnlinePortal from './components/RedirectToMerchantOnlinePortal'
import { ROUTES } from 'constants/routes'

type LinkErrorProps = {
  header: string;
  content: string;
}

const LinkError = (props: LinkErrorProps) => {
  const { header, content } = props

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id={header}
          subId={content}
          icon={<ErrorIcon style={{ position: 'relative', top: '-10px' }} />}
        />
      }
    >
      <Layout.Middle>
        <RedirectToMerchantOnlinePortal />
      </Layout.Middle>
    </Layout>
  )
}

export default LinkError
