import React from 'react'
import { Button, Typography } from '@mui/material'
import { Flex } from 'components/structural'

type RadioButtonProps = {
  onClick: (event: React.MouseEvent) => void
  children: React.ReactNode
  selected: boolean
  testId: string
}

export const RadioButton = (props: RadioButtonProps) => (
  <Flex
    sx={{
      boxShadow: (theme: any) => `
        0px 1px 0px ${
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          props.selected ? theme.palette.input.ico.main : 'transparent'
        },
        inset 0px -4px 0px ${
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          props.selected ? theme.palette.input.ico.main : 'transparent'
        }
      `,
      flex: '1 0 auto',
    }}
  >
    <Button
      data-testid={props.testId}
      onClick={props.onClick}
      variant="outlined"
      style={{
        minWidth: '80px',
        minHeight: '80px',
        height: 'auto',
        width: '100%',
        border: 'transparent',
      }}
    >
      <Typography variant="subtitle2">{props.children}</Typography>
    </Button>
  </Flex>
)
