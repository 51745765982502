import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { InfoBox } from 'components/InfoBox'
import { ViewHeader } from 'components/headers'
import { Layout } from 'components/Layout'
import { BusinessIcon } from 'components/icons'
import { ReactComponent as BusinessTeleCashIcon } from 'images/business-telecash.svg'
import { isMca, isTeleCash } from 'setup/tenant'
import { Logos } from './components/Logos'
import { LinkAccountWidget } from './components/LinkAccountWidget'

export const BankLinking = () => (
  <Layout
    viewHeader={
      <ViewHeader
        icon={
          isTeleCash ? (
            <BusinessTeleCashIcon />
          ) : (
            <BusinessIcon style={{ position: 'relative', top: '-10px' }} />
          )
        }
        id="loanApplication.steps.bankLinking"
        subId="expressApplicationBankLinking.subheading"
      />
    }
    withProgress
  >
    <Layout.Middle>
      <Flex flexDirection="column">
        <InfoBox mb="40px">
          <Flex flexDirection="column">
            <Box mb={3}>
              <Typography variant="h5">
                <FormattedMessage id="bankLinking.why.heading" />
              </Typography>
            </Box>
            <Typography variant="body2">
              <FormattedMessage id="bankLinking.why.description" />
            </Typography>
          </Flex>
        </InfoBox>
        <LinkAccountWidget />
        <Logos />
      </Flex>
    </Layout.Middle>
  </Layout>
)
