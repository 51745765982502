import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TextFieldComponent } from 'components/Fields/TextFieldComponent'
import { Field as FormikField } from 'formik'

export const NumberOfEmployees = ({
  name = 'businessDetails.numberOfEmployees',
  hint,
  validate,
  formatOnBlur,
}) => (
  <FormikField
    name={name}
    component={TextFieldComponent}
    data-testid="formFieldNumberOfEmployees"
    label={<FormattedMessage id="fields.numberOfEmployees.mandatory" />}
    hint={hint}
    validate={validate}
    formatOnBlur={formatOnBlur}
  />
)
