import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import getCountries from 'utils/countries'
import { requiredValidator } from 'utils/forms/validators'
import { Field as FormikField } from 'formik'
import { useLanguage } from '../../context/Language'
import { AutocompleteComponent } from './AutocompleteComponent'

export const Country = (props) => {
  const { locale } = useLanguage()
  const countriesList = useMemo(
    () =>
      getCountries(locale).map(({ value, message }) => ({
        value,
        message,
      })),
    [locale],
  )
  return (
    <FormikField
      component={AutocompleteComponent}
      name={props.name}
      id={props.name}
      label={<FormattedMessage id="fields.country" />}
      inputWrapperSx={{ pr: 3, height: ['100px', '78px', '78px'] }}
      validate={requiredValidator}
      options={countriesList}
      hint={<FormattedMessage id="fields.country.hint" />}
      {...props}
    />
  )
}
