import React from 'react'
import { styled } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { ReactComponent as ErrorIcon } from 'images/error.svg'
import activeTenant from 'setup/tenant'
import { EmailSubscriptionForm } from './components/EmailSubscriptionForm'

const NavigationLink = styled('a')`
  text-decoration: none;
`

const MerchantIntegrationError = () => (
  <Layout
    viewHeader={
      <ViewHeader
        id="jsonBlobError.header"
        subId="jsonBlobError.content"
        subValues={{
          faqs: (
            <NavigationLink
              href={activeTenant.faqLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="misc.faqs" />
            </NavigationLink>
          ),
        }}
        icon={<ErrorIcon style={{ position: 'relative', top: '-10px' }} />}
      />
    }
  >
    <Layout.MiddleAndRight>
      <EmailSubscriptionForm />
    </Layout.MiddleAndRight>
  </Layout>
)

export default MerchantIntegrationError
