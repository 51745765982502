import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  taxIdValidator,
  requiredValidator,
  composeValidators,
} from 'utils/forms/validators'

import { TextField } from './TextField'

export const TaxId = (props) => (
  <TextField
    name="taxNumber"
    label={<FormattedMessage id="fields.taxId.mandatory" />}
    validate={composeValidators(requiredValidator, taxIdValidator)}
    {...props}
  />
)
