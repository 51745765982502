export const DOCUMENT_TYPES = Object.freeze({
  ANNUAL_FINANCIAL_STATEMENTS_2018: 'annualFinancialStatements2018',
  ANNUAL_FINANCIAL_STATEMENTS_OR_BUSINESS_EVALUATION_2019:
    'annualFinancialStatementsOrBusinessEvaluation2019',
  ANNUAL_STATEMENT_OR_BWA_CURR_YEAR_AQUIRED_PRACTICE:
    'AnnualStatementOrBWACurrYearAquiredPractice',
  ANNUAL_STATEMENT_OR_EUR_CURRENT_YEAR: 'AnnualStatementOrEURCurrentYear',
  APPROBATIONSURKUNDE: 'approbationsurkunde',
  ARTICLES_OF_ASSOCIATION: 'articlesOfAssociation',
  BUSINESS_REGISTRATION: 'businessRegistration',
  BWA_CURRENT_YEAR_AQUIRED_PRACTICE: 'BWACurrentYearAquiredPractice',
  BWA_CURRENT_YEAR: 'BWACurrentYear',
  CODAT_REVENUE_REPORT: 'codatRevenueReport',
  COMBINED_REVENUE_REPORT: 'combinedRevenueReport',
  COST_ESTIMATE_OR_QUOTE_OF_PURCHASES: 'costEstimateOrQuoteOfPurchases',
  DOCTOR_REGISTER_OR_SPECIALIST_CERTIFICATE:
    'doctorRegisterOrSpecialistCertificate',
  DOCTOR_REGISTER: 'doctorRegister',
  INVESTMENT_OR_FINANCIAL_PLAN: 'investmentOrFinancialPlan',
  LAST_TAX_NOTICE_OR_DEBTOR_STATEMENT: 'lastTaxNoticeOrDebtorStatement',
  LIEFERANDO_REVENUE_REPORT: 'lieferandoRevenueReport',
  BANXWARE_REVENUE_REPORT: 'banxwareRevenueReport',
  PRACTICE_CONCEPT: 'practiceConcept',
  PURCHASE_CONTRACT: 'purchaseContract',
  QUARTER_STATEMENT_FIRST: 'firstQuarterStatement',
  QUARTER_STATEMENT_SECOND: 'secondQuarterStatement',
  QUARTER_STATEMENT_THIRD: 'thirdQuarterStatement',
  SECOND_TO_LAST_TAX_NOTICE_OR_DEBTOR_STATEMENT:
    'secondToLastTaxNoticeOrDebtorStatement',
  MANUAL_REPORT_REVENUE_REPORT: 'manualReportRevenueReport',
  AFTERBUY_REVENUE_REPORT: 'AfterbuyRevenueReport',
  EBAY_REVENUE_REPORT: 'EbayRevenueReport',
  GAMBIO_REVENUE_REPORT: 'GambioRevenueReport',
  GIROCARD_REVENUE_REPORT: 'GirocardRevenueReport',
})
