import { useCallback } from 'react'
import { API_URL, API_PATH } from 'constants/api'
import { useApiRequest } from '../../../../context/utils/useApiRequest'

export const useBankLinkingApiRequests = () => {
  const { postRequest, patchRequest, getRequest } = useApiRequest()

  const postRequestScoringProcess = useCallback(
    async (loanApplicationId) => {
      const url = `${API_URL}${API_PATH.SCORING_PROCESS}`
      const response = await postRequest({
        url,
        body: {
          scoringProcess: {
            loanApplicationId,
          },
        },
      })
      return response
    },
    [postRequest],
  )

  const patchRequestScoringProcess = useCallback(
    async (scoringProcessId, scoringProcessState) => {
      const url = `${API_URL}${API_PATH.SCORING_PROCESS}/${scoringProcessId}`
      const response = await patchRequest({
        url,
        body: {
          scoringProcess: {
            state: scoringProcessState,
          },
        },
      })
      return response
    },
    [patchRequest],
  )

  const getRequestLoanApplicationById = useCallback(
    async (loanApplicationId) => {
      const url = `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}`
      const response = await getRequest({ url })
      return response
    },
    [getRequest],
  )

  const patchRequestSetExpressFlow = useCallback(
    async (loanApplicationId) => {
      const url = `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}`
      const response = await patchRequest({
        url,
        body: {
          loanApplication: {
            expressFlow: true,
            merchant: {},
          },
        },
      })
      return response
    },
    [patchRequest],
  )

  const pollLoanApplication = useCallback(
    async (loanApplicationId) => {
      let count = 0
      const MAX_COUNT = 20
      const POLL_INTERVAL = 3000
      return (async function polling() {
        const response = await getRequestLoanApplicationById(loanApplicationId)
        if (response.loanApplication?.linkedBankAccount?.status === 'success') {
          return response
        }
        if (count === MAX_COUNT) {
          throw new Error('Error polling loan application ')
        }
        await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL))
        // eslint-disable-next-line no-plusplus
        count++
        return polling()
      })()
    },
    [getRequestLoanApplicationById],
  )

  return {
    postRequestScoringProcess,
    patchRequestScoringProcess,
    getRequestLoanApplicationById,
    patchRequestSetExpressFlow,
    pollLoanApplication,
  }
}
