import React, { useCallback } from 'react'
import { Box } from 'components/structural'
import activeTenant, { isGetRaoul, isLinkIntegration } from 'setup/tenant'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { useLoanApplicationRedirect } from 'routing/hooks/useLoanApplicationRedirect'

export const Logo = () => {
  const history = useHistory()
  const loanApplicationRedirect = useLoanApplicationRedirect()

  const handleLogoClick = useCallback(() => {
    if (isGetRaoul) return
    if (loanApplicationRedirect) {
      history.push(loanApplicationRedirect)
    } else if (!isLinkIntegration) {
      history.push(ROUTES.ROOT)
    }
  }, [history, loanApplicationRedirect])

  const LogoComponent = activeTenant.logo.component

  return (
    <Box width={activeTenant.logo.width} data-testid="header-logo">
      <LogoComponent onClick={handleLogoClick} style={{ cursor: 'pointer' }} />
    </Box>
  )
}
