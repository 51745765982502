import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createMuiTheme } from 'themes'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
  GlobalContextProviders,
  LoanApplicationContextProviders,
} from './context'

export const Providers = (props) => {
  const muiTheme = createMuiTheme()
  return (
    <BrowserRouter>
      <GlobalContextProviders>
        <LoanApplicationContextProviders>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {React.Children.only(props.children)}
              </LocalizationProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LoanApplicationContextProviders>
      </GlobalContextProviders>
    </BrowserRouter>
  )
}
