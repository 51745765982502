import React from 'react'
import { Box } from 'components/structural'

import { ReactComponent as Share } from 'images/icons/share-a.svg'
import { ReactComponent as Ok } from 'images/icons/ok.svg'
import { ReactComponent as Pending } from 'images/icons/pending.svg'

export const ShareIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Share />
  </Box>
)

export const OkIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Ok />
  </Box>
)

export const PendingIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Pending />
  </Box>
)
