import { styled } from '@mui/system'
import { ButtonRaw } from 'components/buttons'

export const Wrapper = styled(ButtonRaw)`
  cursor: pointer;
  position: relative;
  background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlPSIjMjkxMThiIiBzdHJva2Utd2lkdGg9IjIiPjxwYXRoIGQ9Im0xNSAxOGg0YzEuMTA0NiAwIDItLjg5NTQgMi0ydi0xMmMwLTEuMTA0NTctLjg5NTQtMi0yLTJoLThjLTEuMTA0NTcgMC0yIC44OTU0My0yIDJ2MiIvPjxwYXRoIGQ9Im0xNSAyMGMwIDEuMTA0Ni0uODk1NCAyLTIgMmgtNC4xNzE1N2MtLjUzMDQ0IDAtMS4wMzkxNC0uMjEwNy0xLjQxNDIyLS41ODU4bC0zLjgyODQyLTMuODI4NGMtLjM3NTA4LS4zNzUxLS41ODU3OS0uODgzOC0uNTg1NzktMS40MTQydi04LjE3MTZjMC0xLjEwNDU3Ljg5NTQzLTIgMi0yaDhjMS4xMDQ2IDAgMiAuODk1NDMgMiAyeiIvPjxwYXRoIGQ9Im05IDIydi00YzAtMS4xMDQ2LS44OTU0My0yLTItMmgtNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9nPjwvc3ZnPgo=');
  transition: background-image 0.15s ease-in-out;

  &:hover {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlPSIjOWU4NmU0IiBzdHJva2Utd2lkdGg9IjIiPjxwYXRoIGQ9Im0xNSAxOGg0YzEuMTA0NiAwIDItLjg5NTQgMi0ydi0xMmMwLTEuMTA0NTctLjg5NTQtMi0yLTJoLThjLTEuMTA0NTcgMC0yIC44OTU0My0yIDJ2MiIvPjxwYXRoIGQ9Im0xNSAyMGMwIDEuMTA0Ni0uODk1NCAyLTIgMmgtNC4xNzE1N2MtLjUzMDQ0IDAtMS4wMzkxNC0uMjEwNy0xLjQxNDIyLS41ODU4bC0zLjgyODQyLTMuODI4NGMtLjM3NTA4LS4zNzUxLS41ODU3OS0uODgzOC0uNTg1NzktMS40MTQydi04LjE3MTZjMC0xLjEwNDU3Ljg5NTQzLTIgMi0yaDhjMS4xMDQ2IDAgMiAuODk1NDMgMiAyeiIvPjxwYXRoIGQ9Im05IDIydi00YzAtMS4xMDQ2LS44OTU0My0yLTItMmgtNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9nPjwvc3ZnPgo=');
  }

  &::before {
    position: absolute;
    right: 0;
    top: 40px;
    color: #fff;
    content: attr(aria-label);
    text-transform: none;
    transition: opacity 0.15s ease-in-out;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.6;
  }

  &::after {
    position: absolute;
    bottom: -16px;
    right: 16px;
    border-left: 16px solid transparent;
    border-bottom: 16px solid;
    transition: opacity 0.15s ease-in-out;
    content: '';
    font-size: 0;
    line-height: 0;
    width: 0;
  }

  &::before,
  &::after {
    opacity: 0;
    pointer-events: none;
    text-align: center;
  }

  &:focus::before,
  &:focus::after,
  &:hover::before,
  &:hover::after {
    opacity: 1;
  }
`
