import { eachMonthOfInterval, sub, format } from 'date-fns'
import { MONTH_YEAR_DATE_FORMAT } from 'constants/format'
import { DOCUMENT_TYPES } from 'constants/documentTypes'
import { LEGAL_FORMS } from 'constants/loanApplication'
import { isGetRaoul, isBanxware } from 'setup/tenant'

export const getDocumentTypeTranslationProps = ({
  documentType,
  index,
  locale,
}) => {
  if (
    isGetRaoul &&
    documentType.includes(DOCUMENT_TYPES.LIEFERANDO_REVENUE_REPORT)
  ) {
    const revenueDate = sub(new Date(), { months: index + 1 })
    const id = `documentsUpload.types.${DOCUMENT_TYPES.LIEFERANDO_REVENUE_REPORT}`
    const values = {
      date: format(revenueDate, MONTH_YEAR_DATE_FORMAT, {
        locale,
      }),
    }

    return { id, values }
  }
  if (
    isBanxware &&
    documentType.includes(DOCUMENT_TYPES.BANXWARE_REVENUE_REPORT)
  ) {
    const revenueDate = sub(new Date(), { months: index + 1 })
    const id = `documentsUpload.types.${DOCUMENT_TYPES.BANXWARE_REVENUE_REPORT}`
    const values = {
      date: format(revenueDate, MONTH_YEAR_DATE_FORMAT, {
        locale,
      }),
    }

    return { id, values }
  }

  if (documentType.includes(DOCUMENT_TYPES.CODAT_REVENUE_REPORT)) {
    const revenueDate = sub(new Date(), { months: index + 1 })
    const id = `documentsUpload.types.${DOCUMENT_TYPES.CODAT_REVENUE_REPORT}`
    const values = {
      date: format(revenueDate, MONTH_YEAR_DATE_FORMAT, {
        locale,
      }),
    }
    return { id, values }
  }

  return { id: `documentsUpload.types.${documentType}` }
}

export const getRevenueDocs = (reportType = '') => {
  const requiredMonths = eachMonthOfInterval({
    start: sub(new Date(), { months: 6 }),
    end: sub(new Date(), { months: 1 }),
  })

  const revenueDocs = requiredMonths
    .reverse()
    .map(
      (requiredMonth) =>
        `${reportType}${format(requiredMonth, 'MMM')}${format(
          requiredMonth,
          'y',
        )}`,
    )
  return revenueDocs
}

export const getAfterbuyRevenueDoc = () => ['AfterbuyRevenueReport']
export const getEbayRevenueDoc = () => ['EbayRevenueReport']
export const getGambioRevenueDoc = () => ['GambioRevenueReport']
export const getGirocardRevenueDoc = () => ['GirocardRevenueReport']
export const getManualReportRevenueDoc = () => ['ManualReportRevenueReport']
export const getHoodRevenueDoc = () => ['HoodRevenueReport']

export const getRequiredDocs = (legalForm) => {
  const defaultTypes = []

  if (legalForm === LEGAL_FORMS.SOLEPROPRIETOR) {
    defaultTypes.push(DOCUMENT_TYPES.BUSINESS_REGISTRATION)
  }
  if (legalForm === LEGAL_FORMS.GBR)
    defaultTypes.push(
      DOCUMENT_TYPES.BUSINESS_REGISTRATION,
      DOCUMENT_TYPES.ARTICLES_OF_ASSOCIATION,
    )
  if (isGetRaoul) {
    defaultTypes.push(
      ...getRevenueDocs(DOCUMENT_TYPES.LIEFERANDO_REVENUE_REPORT),
    )
  }
  if (isBanxware) {
    defaultTypes.push(...getRevenueDocs(DOCUMENT_TYPES.BANXWARE_REVENUE_REPORT))
  }
  return defaultTypes
}

export const getBusinessDocs = (legalForm) => {
  const defaultTypes = []

  if (legalForm === LEGAL_FORMS.SOLEPROPRIETOR) {
    defaultTypes.push(DOCUMENT_TYPES.BUSINESS_REGISTRATION)
  }
  if (legalForm === LEGAL_FORMS.GBR)
    defaultTypes.push(
      DOCUMENT_TYPES.BUSINESS_REGISTRATION,
      DOCUMENT_TYPES.ARTICLES_OF_ASSOCIATION,
    )

  return defaultTypes
}
