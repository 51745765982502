import React from 'react'
import { FormattedMessage } from 'react-intl'

import { TextField } from './TextField'

export const Address2 = (props) => (
  <TextField
    name="address2"
    label={<FormattedMessage id="fields.address2" />}
    {...props}
  />
)
