import { styled } from '@mui/system'
import activeTenant from 'setup/tenant'

// In some cases some attributes are hard to overwrite
// alas, there's need for !important
// but we won't be overwriting it again
// so that's acceptable in this case

export const StyledWidget = styled('div')`
  position: relative;
  .xs2a-note {
    svg {
      display: none;
    }
    a,
    a:visited {
      color: ${(p) => p.theme.palette.text.link.main}
    }
  }

  .xs2a-abort {
    margin-bottom: 0 !important;
  }

  .xs2a-spinner {
    height: 30px;
    width: auto;
  }

  .xs2a-lightbox {
    box-shadow: 0px 40px 80px rgba(53, 89, 131, 0.4);
    top: 20%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    position: absolute;
    z-index: 10;
    width: 100%;
    a {
      outline: 0 !important;
      background-color: #fff !important;
    }
    .xs2a-lightbox-body {
      margin-top: 0 !important;
      width: inherit;
      left: 0;
      background: #fff !important;
      p {
        font-family: 'IBM Plex Sans';
      }
    }
    > div {
      box-sizing: border-box;
      width: inherit !important;
      padding: 0 !important;
      margin-top: 0 !important;
    }
  }

  .xs2a-dialog {
    width: 100%;
    padding: 20px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    border: 1px solid #cad2dc;
    box-shadow: 0px 40px 80px rgba(53, 89, 131, 0.4);
    position: absolute;
    .xs2a-dialog-button {
      height: 40px;
      text-decoration: none;
      padding: 5px;
      align-self: center;
      color: #fff !important;
      background-color: ${(p) => p.theme.palette.button.bgA.main} !important;
      margin: 0 auto !important;
      border: none !important;
      &:hover {
        color: ${(p) => p.theme.palette.button.bgA.main} !important;
        border: none !important;
        background-color: ${(p) => p.theme.palette.input.ico.main};
      }
    }
    p {
      font-family: 'IBM Plex Sans';
      font-size: 18px;
      margin-top: 16px !important;
      margin-bottom: 32px !important;
    }
  }

  .xs2a-dialog-toolbar {
    width: 100%;
    display: flex;

    a {
      height: 80px;
      display: flex !important;
      align-items: center;
      color: ${(p) => p.theme.palette.text.link.main} !important;
      border: 1px solid #cad2dc !important;
      justify-content: center;
      text-transform: lowercase, capitalize;
      padding: 28px;
      line-height: 24px;
      font-size: 18px !important;
      font-family: 'IBM Plex Sans';
      background-color: #fff !important;
      font-weight: 400 !important;
      text-align: center;
      outline: none;
      border: none;
      flex: 1 1 50%;
      border-radius: 0 !important;

      &:hover {
        background-color: #eef4fb !important;
        border: 1px solid ${(p) => p.theme.palette.text.link.main} !important;
      }

      &:first-of-type {
        margin-right: 8px;
      }

      &:nth-of-type(2) {
        color: ${(p) => p.theme.palette.text.link.main} !important;
        background-color: #eef4fb !important;
        border: 1px solid #cad2dc !important;
        margin-left: 8px;

        &:hover {
          background-color: #eef4fb !important;
          border: 1px solid ${(p) => p.theme.palette.text.link.main} !important;
        }
      }
    }
  }

  .xs2a-form-line {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &.xs2a-paragraph {
      h3 {
        font-family: 'Marr Sans Cond';
        font-size: 28px;
        line-height: 31px;
        font-weight: 600;
        color: ${(p) => p.theme.palette.text.heading.main}
        text-align: left;
        margin-top: 0;
        margin-bottom: 16px;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
        color: #000;
        font-weight: 400;
        line-height: 24px;
        font-size: 16px;
        font-family: 'IBM Plex Sans';

        a {
          text-decoration: none;
          color: ${(p) => p.theme.palette.text.link.main}
        }
      }
    }

    &.xs2a-error {
      position: relative;
      padding: 16px;
      padding-left: 56px;
      margin-bottom: 16px;
      background-color: #fcf1f3;
      border: 1px solid #f2c0d2;

      p {
        margin-bottom: 0;
        color: #d11f5f;
        font-family: 'IBM Plex Sans';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      &::before {
        position: absolute;
        left: 16px;
        width: 24px;
        height: 24px;
        content: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlPSIjZDExZjVmIiBzdHJva2Utd2lkdGg9IjIiPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjkiLz48cGF0aCBkPSJtOC40NjQ0OCAxNS41MzU2IDcuMDcxMDItNy4wNzEwMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+PC9nPjwvc3ZnPgo=');
      }
    }

    &.xs2a-none {
      /* display: none; */
    }

    &.xs2a-description {
      font-family: 'IBM Plex Sans';
      font-size: 16px;
      line-height: 150%;
    }

    .xs2a-spinner {
      position: absolute;
      right: 16px;
      transform: translateY(-50%);
      top: 50%;
    }

    .xs2a-logo-image {
      align-self: flex-end;
      &:hover {
        cursor: pointer;
      }
    }

    &.xs2a-submit-line {
      position: relative;
      flex-direction: row;
      margin-top: 48px;
      justify-content: flex-end;

      button {
        padding: 28px;
        height: 80px;
        width: calc(100% - 96px);
        text-transform: none;
        font-size: 18px;
        font-family: 'IBM Plex Sans';
        background-color: ${(p) => p.theme.palette.button.bgA.main};
        font-weight: 400;
        color: #fff;
        outline: none;
        border: none;
        border-radius: ${activeTenant.theme.shape.fieldBorderRadius};
        flex: 0 1 auto;
        cursor: pointer;
        &:hover {
          background-color: ${(p) => p.theme.palette.button.bgA.dark};
        }
        a:active {
          outline: none;
          border: none;
        }
      }

      button + button {
        margin-left: 8px;
      }

      .xs2a-back {
        width: 80px;
        height: 80px;
        flex: 0 0 80px;
        margin-right: 8px;
        color: #eef4fb;
        border: 1px solid #cad2dc;
        background-color: #eef4fb;
        font-size: 0;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjI3OTMgMTIuMjA3MUw1LjI3OTMgMTIuMjA3MSIgc3Ryb2tlPSIjMjkxMThCIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTAuMjc5NSAxNy4yMDcyTDUuMjc5NTQgMTIuMjA3MkwxMC4yNzk1IDcuMjA3MTUiIHN0cm9rZT0iIzI5MTE4QiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
        filter: grayscale(1);
        background-position: center;
        &::visited {
          text-decoration: none;
        }
        &:hover {
          background-color: #eef4fb;
          border: 1px solid ${(p) => p.theme.palette.button.bgA.main}
        }
      }
    }

    &.xs2a-restart-line {
      position: relative;
      flex-direction: row;
      margin-top: 32px;
      justify-content: flex-end;

      button {
        width: calc(100% - 90px);
        margin-bottom: 8px;
        text-transform: none;
        padding: 28px;
        line-height: 24px;
        font-size: 18px;
        font-family: 'IBM Plex Sans';
        background-color: ${(p) => p.theme.palette.button.bgA.main};
        font-weight: 400;
        color: #fff;
        outline: none;
        border: none;
        flex: 0 1 auto;
        cursor: pointer;

        &:hover {
          background-color: ${(p) => p.theme.palette.input.ico.main};
        }
      }
    }

    &.xs2a-abort-line {
      position: relative;
      flex-direction: row;
      margin-top: 32px;
      justify-content: flex-end;

      button {
        width: calc(100% - 90px);
        margin-bottom: 8px;
        text-transform: none;
        padding: 28px;
        line-height: 24px;
        font-size: 18px;
        font-family: 'IBM Plex Sans';
        background-color: ${(p) => p.theme.palette.button.bgA.main};
        font-weight: 400;
        color: #fff;
        outline: none;
        border: none;
        flex: 0 1 auto;
        cursor: pointer;

        &:hover {
          background-color: ${(p) => p.theme.palette.input.ico.main};
        }
      }
    }

    select {
      outline: 0;
      background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTUgOSA3IDcgNy03IiBzdHJva2U9IiM0MmEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+');
      background-repeat: no-repeat;
      background-position: right 16px top 50%;
      background-size: 24px auto;
    }

    input,
    label {
      cursor: text;
    }

    label,
    select,
    input,
    p {
      font-family: 'IBM Plex Sans';
      width: 100%;
      font-size: 18px;
      height: 100%;
    }

    p {
      margin: 0;
      font-size: 12px;
    }

    label {
      font-family: 'IBM Plex Sans';
      color: ${(p) => p.theme.palette.input.value.main};
      font-size: 14px;
      height: auto;
    }
  }

  select {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    border: 0;
    border-radius: 0;
    margin: 0;
    display: block;
    padding-top: 5px;
    min-width: 0;
    box-sizing: content-box;
  }

  .xs2a-select {
    position: relative;
    height: 80px;
    cursor: pointer;

    label {
      position: absolute;
      padding-top: 16px;
      padding-left: 16px;
      font-size: 12px;
      pointer-events: none;
    }

    select {
      outline: none;
      border: none;
      padding-top: 21px;
      padding-left: 16px;
      cursor: pointer;
      width: auto;
      border: 1px solid #cad2dc;
      border-radius: ${activeTenant.theme.shape.fieldBorderRadius};
      &:active,
      &:focus {
        border: 1px solid
          ${(p) => p.theme.palette.input.ico.main};
      }
    }
  }

  .xs2a-text {
    position: relative;
    height: 80px;

    label {
      position: absolute;
      padding-top: 16px;
      padding-left: 16px;
      font-size: 12px;
    }

    input {
      border: 1px solid #cad2dc;
      border-radius: ${activeTenant.theme.shape.fieldBorderRadius};
      outline: none;
      padding-top: 21px;
      padding-left: 16px;
      width: inherit;
      &:active,
      &:focus {
        border: 1px solid
          ${(p) => p.theme.palette.input.ico.main};
      }
    }

    .xs2a-completion-results {
      z-index: 1;
      background-color: #fff;
      border: 1px solid #cad2dc;
      border-bottom: none;
      border-top: none;
    }

    .xs2a-completion-result {
      padding: 16px;
      color: #000;
      font-family: 'IBM Plex Sans';
      font-weight: 400;
      font-size: 16px;
      border-bottom: 1px solid ${(p) => p.theme.palette.input.ico.main};

      strong {
        font-weight: 500;
      }

      &:hover {
        background-color: #eef4fb;
      }
    }

    & input:active ~ .xs2a-completion-results,
    & input:focus ~ .xs2a-completion-results {
      border-color: ${(p) => p.theme.palette.input.ico.main};
    }

    & input:active ~ .xs2a-completion-results .xs2a-completion-result,
    & input:focus ~ .xs2a-completion-results .xs2a-completion-result {
      border-color: ${(p) => p.theme.palette.input.ico.main};
    }
  }

  .xs2a-captcha {
    input {
      border: 1px solid #cad2dc;
      outline: none;
      padding-top: 21px;
      padding-bottom: 21px;
      padding-left: 16px;
      margin-top: 16px;

      &:active,
      &:focus {
        border-color: ${(p) => p.theme.palette.input.ico.main};
      }
    }
  }

  .xs2a-flicker {
    input {
      border: 1px solid #cad2dc;
      outline: none;
      padding-top: 21px;
      padding-bottom: 21px;
      padding-left: 16px;
      margin-top: 16px;

      &:active,
      &:focus {
        border-color: ${(p) => p.theme.palette.input.ico.main};
      }
    }
  }

  .xs2a-align-indent {
    flex-direction: row;
    justify-content: flex-start;
  }

  .xs2a-tabs {
    ul {
      display: flex;
      list-style-type: none;
      padding-left: 16px;
      color: ${(p) => p.theme.palette.button.bgA.main}};
      border-bottom: 1px solid ${(p) => p.theme.palette.button.bgA.main};
    }

    li {
      background-color: #fff;
      border: 1px solid #cad2dc;
      border-bottom: none;

      &.xs2a-selected {
        background-color: ${(p) => p.theme.palette.button.bgA.main};
        a {
          color: #fff;
        }
      }

      & + li {
        margin-left: 16px;
      }

      a {
        display: inline-block;
        padding: 12px 32px;
        font-family: 'IBM Plex Sans';
        font-size: 16px;
        text-decoration: none;
        color: ${(p) => p.theme.palette.text.link.main}
      }
    }
  }

  .xs2a-radio {
    div {
      color: ${(p) => p.theme.palette.text.link.main} !important;
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
      font-family: 'IBM Plex Sans';
      margin-bottom: 16px;
      position: relative;
      height: 38px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      label {
        margin-left: 48px;
        line-height: 18px;

        &::before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 32px;
          width: 32px;
          background-color: #eef4fb;
          border-radius: 50%;
          border: 1px solid #cad2dc;
        }

        &::after {
          content: '';
          position: absolute;
          display: none;
          top: 11px;
          left: 11px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: white;
        }
      }

      & input:checked ~ label::after {
        display: block;
      }

      & input:disabled {
        cursor: not-allowed;
      }

      & input:disabled ~ label {
        color: #cad2dc;
        cursor: not-allowed;
      }

      &:hover label::before {
        border-color: ${(p) => p.theme.palette.button.bgA.main}
      }

      & input:checked ~ label::before {
        background-color: ${(p) => p.theme.palette.button.bgA.main} !important;
      }

      &:hover input:checked ~ label::before {
        background-color: ${(p) => p.theme.palette.input.ico.main};
      }

      &:hover input:disabled ~ label::before {
        border: 1px solid #cad2dc;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }

  .xs2a-checkbox {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    min-height: 32px;

    label {
      color: #1b1831;
    }

    input {
      display: none;

      & + * {
        position: relative;
        padding-left: 48px;
      }

      & + *::before {
        position: absolute;
        left: 0;
        content: '';
        display: inline-block;
        vertical-align: bottom;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-style: solid;
        border-width: 1px;
        border-color: #cad2dc;
        border-radius: ${activeTenant.theme.shape.fieldBorderRadius};
      }

      &:checked + *::before {
        content: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTcgMTMgMyA0IDctMTAiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4K);
        text-align: center;
        background-color: ${(p) => p.theme.palette.input.ico.main};
      }
    }
  }
`
