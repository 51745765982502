import React from 'react'
import { Box } from 'components/structural'
import { ArrowDownIcon } from 'components/icons'
import { ReactComponent as Selected } from 'images/icons/list-ok.svg'
import { ReactComponent as Search } from 'images/icons/search.svg'

export const SelectedIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) => theme.palette.input.ico.main,
        },
      },
    }}
  >
    <Selected />
  </Box>
)

export const SearchIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.input.ico.main,
        },
      },
    }}
  >
    <Search />
  </Box>
)

export const PopupIcon = ({ disabled, ...rest }) => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) =>
            disabled
              ? theme.palette.input.icoOff.main
              : theme.palette.input.ico.main,
        },
      },
    }}
    {...rest}
  >
    <ArrowDownIcon />
  </Box>
)
