import * as Sentry from '@sentry/browser'
import { v4 as uuid } from 'uuid'
import { tenantCode } from 'setup/tenant'
import { cameliseDeep, snakeCaseDeep } from 'utils'
import { PANTHER_SERVICES_URL } from 'constants/api'
import { RESPONSE_ERROR, API_ERROR, ERROR_MESSAGE } from 'constants/error'

export type RequestBody = Record<string, any>
export type ApiResponse = unknown[] | Record<string, unknown>

const DEFAULT_HEADERS = Object.freeze({
  accept: 'application/json',
  'Content-Type': 'application/json',
  'Tenant-Code': tenantCode,
})

export const OFFLINE_ERROR_MESSAGE = 'offline'

const isPantherServices = (url: string) => url.includes(PANTHER_SERVICES_URL)

/*
  JSON body on success looks like e.g.:
    {
      maxAmount: {value: '1260000', currency: 'EUR'}
      minAmount: {value: '300000', currency: 'EUR'}
      term: 12
    }

  JSON body on error looks like:
    {
      errors: [
        code: "API_VALIDATION.MERCHANTUSER.EMAIL"
        message: "has already been taken"
      ]
      meta: {version: '1.9.0'}
    }

  panther-services: JSON body on error looks like:
    {
      businessCode: "INVALID_MERCHANT_INFO"
      description: "Merchant information should be present and valid."
    }
*/
const handleResponse = async <T extends ApiResponse>(
  response: Response,
  requestBody?: RequestBody,
) => {
  // Start by trying to parse response body
  let body: {
    businessCode?: string
    description?: string
    status?: number
    errors?: { code: string }[]
  } = {}

  try {
    body = await response.json()
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        message: 'API response without JSON body received',
        url: response.url,
        // @ts-expect-error Property 'traceId' does not exist on type 'Response'.
        traceId: response.traceId,
      },
    })
  }

  // First, check response body for errors
  if (isPantherServices(response.url)) {
    if (body.businessCode) {
      const message =
        API_ERROR[body.businessCode] || body.description || body.businessCode
      // @ts-expect-error Expected 0-1 arguments, but got 2
      throw new Error(message, { cause: body.businessCode })
    }
  } else if (body.errors) {
    const apiErrors = body.errors
    const message = apiErrors.reduce((result, err) => {
      if (err.code) {
        result = API_ERROR[err.code] || err.code
      }
      return result
    }, '')
    throw new Error(message)
  }

  // Third, check HTTP status for errors
  if (!response.ok) {
    const { status } = response
    const message = RESPONSE_ERROR[status] || ERROR_MESSAGE.GENERIC
    // @ts-expect-error Expected 0-1 arguments, but got 2
    const error = new Error(message, { cause: status })
    Sentry.captureException(error, {
      extra: {
        message: 'API call failed with HTTP error status',
        url: response.url,
        requestBody,
        responseBody: body,
        status,
        // @ts-expect-error Property 'traceId' does not exist on type 'Response'.
        traceId: response.traceId,
      },
    })
    throw error
  }

  // We need to camelise responses also from panther-services as they're
  // not always camel-cased, e.g. the merchant-integration.
  return cameliseDeep(body) as T
}

const request = async ({
  token,
  language,
  url,
  body,
  options,
}: {
  token?: string
  language?: string
  url: string
  body?: RequestBody
  options: Omit<RequestInit, 'body' | 'headers'>
}) => {
  if (!navigator.onLine) throw new Error(OFFLINE_ERROR_MESSAGE)

  const traceId = uuid()
  try {
    const authHeader = token ? { Authorization: `Bearer ${token}` } : {}
    const traceHeader = { 'trace-id': traceId }
    let init: RequestInit = {
      ...options,
      headers: {
        ...DEFAULT_HEADERS,
        ...authHeader,
        ...traceHeader,
        Language: language,
      },
    }
    if (body) {
      init = {
        ...init,
        body: JSON.stringify(
          isPantherServices(url) ? body : snakeCaseDeep(body),
        ),
      }
    }
    const response = await fetch(url, init)
    // @ts-expect-error Property 'traceId' does not exist on type 'Response'.
    response.traceId = traceId
    return response
  } catch (error: unknown) {
    Sentry.captureException(error, {
      extra: {
        message: 'API fetch failed, potential CORS issue?',
        url,
        traceId,
      },
    })
    throw error
  }
}

export const postRequest = async <T extends ApiResponse>({
  token,
  language,
  url,
  body,
}: {
  token?: string
  language?: string
  url: string
  body: RequestBody
}) => {
  const response = await request({
    token,
    language,
    url,
    body,
    options: {
      method: 'POST',
    },
  })
  return handleResponse<T>(response, body)
}

export const putRequest = async <T extends ApiResponse>({
  token,
  language,
  url,
  body,
}: {
  token?: string
  language?: string
  url: string
  body: RequestBody
}) => {
  const response = await request({
    token,
    language,
    url,
    body,
    options: {
      method: 'PUT',
    },
  })
  return handleResponse<T>(response, body)
}

export const patchRequest = async <T extends ApiResponse>({
  token,
  language,
  url,
  body,
}: {
  token?: string
  language?: string
  url: string
  body: RequestBody
}) => {
  const response = await request({
    token,
    language,
    url,
    body,
    options: {
      method: 'PATCH',
    },
  })
  return handleResponse<T>(response, body)
}

export const getRequest = async <T extends ApiResponse>({
  token,
  language,
  url,
}: {
  token?: string
  language?: string
  url: string
}) => {
  const response = await request({
    token,
    language,
    url,
    options: {
      method: 'GET',
    },
  })
  return handleResponse<T>(response)
}

export const deleteRequest = async <T extends ApiResponse>({
  token,
  language,
  url,
}: {
  token: string
  language: string
  url: string
}) => {
  const response = await request({
    token,
    language,
    url,
    options: {
      method: 'DELETE',
    },
  })
  return handleResponse<T>(response)
}
