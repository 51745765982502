import React from 'react'
import { Box, Typography } from '@mui/material'
import { Flex } from 'components/structural'
import { useLoans } from 'context/Loans'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as ClockImage } from 'images/clock.svg'
import { Layout } from '../components/Layout'
import { DashboardViewHeader } from '../components/DashboardViewHeader'
import { LoanHistory } from '../components/LoanHistory'
import { LoanDetailsBox } from '../components/LoanDetailsBox'

export const Dashboard = () => {
  const { selectedLoan, loans } = useLoans()

  return (
    <Layout viewHeader={<DashboardViewHeader />}>
      {!loans && (
        <Box sx={{ padding: '40px 20px', backgroundColor: 'global.bgB.main' }}>
          <FormattedMessage id="merchant.noLoansFound" />
        </Box>
      )}

      {selectedLoan?.disbursedAt && <DisbursedLoanDetails />}

      {selectedLoan?.approvedAt && !selectedLoan.disbursedAt && (
        <LoanAwaitingDisbursement />
      )}
    </Layout>
  )
}

const LoanAwaitingDisbursement = () => (
  <Box py={4} px={[3, 6, 4]}>
    <Box width="100%" maxWidth="368px" mt={[4, 5, 0]}>
      <ClockImage />
    </Box>
    <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
      <FormattedMessage id="loanApplicationApproved.content" />
      {'\n'}
      <Box component="span" sx={{ fontWeight: 500 }}>
        <FormattedMessage id="loanApplicationApproved.team" />
      </Box>
    </Typography>
  </Box>
)

const DisbursedLoanDetails = () => (
  <Flex flexDirection={['column', 'column', 'row']}>
    <Box sx={{ flex: 2 }}>
      <Box maxWidth="95%">
        <LoanHistory />
      </Box>
    </Box>
    <Box mt={[4, 2, 0]} sx={{ flex: 1, order: [-1, -1, 0] }}>
      <LoanDetailsBox />
    </Box>
  </Flex>
)
