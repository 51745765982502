import React, { useMemo, useRef, useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { getName } from 'i18n-iso-countries'
import { Flex, Box } from 'components/structural'
import { Grid } from 'components/Grid'
import { ButtonLink } from 'components/buttons'
import { ViewHeader } from 'components/headers'
import { Layout } from 'components/Layout'
import { Form } from 'components/Form'
import {
  isBanxy,
  isGetRaoul,
  isBanxware,
  isTestTenant,
  isLinkIntegration,
} from 'setup/tenant'
import { ROUTES } from 'constants/routes'
import { CUSTOMER_PROGRESS } from 'constants/loanApplication'
import { useLoanApplication } from 'context/LoanApplication'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useNavigation } from 'routing/hooks/useNavigation'
import type { BusinessDetails } from 'types/loanApplication'
import { Search } from './components/Search'

export const CompanySearch = () => {
  const { currentLoanApplication, isLoading, updateLoanApplicationById } =
    useLoanApplication()
  const { goToPrevPage, goToNextPage } = useFlowNavigation()
  const { navigateTo } = useNavigation()
  const validationRef = useRef<{ validateForm?: () => void }>({})
  const [formValues, setFormValues] = useState<BusinessDetails | null>(null)

  useEffect(() => {
    const { address2, country, ...restAddress } =
      currentLoanApplication?.businessDetails?.address || {}
    if (
      isLinkIntegration &&
      Object.values(restAddress).some(
        (element) => element === null || element === '',
      )
    ) {
      navigateTo(ROUTES.BUSINESS_ADDRESS)
    }
  }, [currentLoanApplication, navigateTo])

  useEffect(() => {
    if (formValues && validationRef.current.validateForm) {
      validationRef.current.validateForm()
    }
  }, [formValues])

  const getAddress = (values: BusinessDetails) => {
    const { address1, zipCode, city, country } = values?.address || {}
    const fullAddress = `${address1}\n${zipCode}\n${city}\n${
      getName(country, 'en') || ''
    }`
    return fullAddress
  }

  const handleSubmit = (values: BusinessDetails) => {
    if (values) {
      updateLoanApplicationById({
        loanApplicationId: currentLoanApplication?.id,
        loanApplication: {
          ...currentLoanApplication,
          businessDetails: values,
          customerProgress: CUSTOMER_PROGRESS.COMPANY_SEARCH,
        },
      }) as any
      goToNextPage()
    }
  }

  const goToBusinessAddress = (values?: BusinessDetails) => {
    if (values) {
      updateLoanApplicationById({
        loanApplicationId: currentLoanApplication?.id,
        loanApplication: {
          ...currentLoanApplication,
          businessDetails: values,
        },
      }) as any
    }
    navigateTo(ROUTES.BUSINESS_ADDRESS)
  }

  const hasAddress = useMemo(
    () =>
      [
        currentLoanApplication?.businessDetails.address.address1,
        !!currentLoanApplication?.businessDetails.address.zipCode,
        currentLoanApplication?.businessDetails.address.zipCode !== '00000',
        currentLoanApplication?.businessDetails.address.country,
        currentLoanApplication?.businessDetails.address.city,
      ].every(Boolean),
    [currentLoanApplication],
  )

  const hasFormValues = useMemo(
    () =>
      [
        formValues?.address?.address1,
        !!formValues?.address?.zipCode,
        formValues?.address?.zipCode !== '00000',
        formValues?.address?.city,
      ].every(Boolean),
    [formValues],
  )

  if (!currentLoanApplication) return null

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="companySearch.header"
          subId="expressApplicationCompanySearch.subheading"
        />
      }
      withProgress
    >
      <Grid.Column start={2} span={3}>
        <Flex flexDirection="column">
          <Form
            innerRef={(node: { values: BusinessDetails }) =>
              node ? setFormValues(node.values) : setFormValues(null)
            }
            onSubmit={handleSubmit}
            onBack={goToPrevPage}
            initialValues={{
              ...currentLoanApplication.businessDetails,
              name: hasAddress
                ? currentLoanApplication.businessDetails.name
                : null,
            }}
            loading={isLoading}
            withBack={!(isBanxy || isGetRaoul || isTestTenant || isBanxware)}
            validateOnMount
            validateOnChange
          >
            {(formikProps: {
              values: BusinessDetails
              validateForm: () => void
            }) => {
              const validate = (value: string) =>
                !value ||
                formikProps.values.address.zipCode === '00000' ||
                !formikProps.values.address.zipCode
                  ? 'validators.isRequired'
                  : undefined

              validationRef.current.validateForm = formikProps.validateForm

              return (
                <>
                  <Search
                    validate={validate}
                    editAddress={goToBusinessAddress}
                  />
                  {(hasAddress || hasFormValues) && (
                    <Grid columns={3}>
                      <Grid.Column
                        sx={{
                          gridColumnEnd: ['span 3', 'span 3', 'span 2'],
                        }}
                      >
                        <Flex
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Typography
                            data-testid="company-search-address"
                            style={{
                              whiteSpace: 'pre',
                              marginBottom: '34px',
                            }}
                          >
                            {getAddress(formikProps.values || formValues)}
                          </Typography>
                          <ButtonLink
                            onClick={() =>
                              goToBusinessAddress(
                                formikProps.values || formValues,
                              )
                            }
                          >
                            <FormattedMessage id="common.edit" />
                          </ButtonLink>
                        </Flex>
                      </Grid.Column>
                    </Grid>
                  )}
                </>
              )
            }}
          </Form>
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            pt="24px"
            width="100%"
          />
        </Flex>
      </Grid.Column>
    </Layout>
  )
}

export default CompanySearch
