import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { isDynamicRepayment, isGetRaoul, isBanxware } from 'setup/tenant'
import { createCurrencyFormatter } from 'utils/currency'
import { useLoanOffer } from 'context/LoanOffer'
import { useLanguage } from 'context/Language'
import { convertEuroCentsToEuro } from 'utils/offers'
import { isNil } from 'lodash'
import type { LoanApplication, BusinessDetails } from 'types/loanApplication'
import { SelectedOffer } from 'types/offers'
import { Caption } from './Caption'

type TenantSpecificLoanDetailsProps = {
  businessDetails: BusinessDetails
  selectedOffer: SelectedOffer
} & Pick<LoanApplication, 'approximateSales'>

export const TenantSpecificLoanDetails = ({
  approximateSales,
  businessDetails,
  selectedOffer,
}: TenantSpecificLoanDetailsProps) => {
  const { locale } = useLanguage()
  const formatter = createCurrencyFormatter(locale)
  const { isVoucherValid, voucherCode } = useLoanOffer()

  const getDynamicOrFixedRate = (rate?: string | number) => {
    if (isNil(rate)) {
      return '-'
    }

    return isDynamicRepayment
      ? `${(Number(rate) * 100).toFixed(2)}% `
      : formatter.formatInt(
          convertEuroCentsToEuro(approximateSales * Number(rate)),
        )
  }

  // Link integration tenants have selectedOffer?.term and non link integration tenants have selectedOffer?.duration
  const loanTermValue = selectedOffer?.duration || selectedOffer?.term
  // monthlyRepaymentValue could be percentage or amount based on type of repayment/ tenant
  let monthlyRepaymentValue
  if ((isGetRaoul || isBanxware) && selectedOffer?.payoutAmount) {
    monthlyRepaymentValue = formatter.formatInt(
      (Math.floor(Number(selectedOffer.payoutAmount.value) / 100) +
        Math.ceil(Number(selectedOffer.fee.value) / 100)) /
        loanTermValue,
    )
  } else if (!isGetRaoul && !isBanxware && selectedOffer?.rate) {
    monthlyRepaymentValue = getDynamicOrFixedRate(selectedOffer.rate)
  } else {
    monthlyRepaymentValue = '-'
  }
  const loanDuration = (
    <Box flex="0 1 170px" mr={6}>
      <Caption id="applicationSummary.loanDuration">
        {loanTermValue ? (
          <FormattedMessage values={{ count: loanTermValue }} id="misc.month" />
        ) : (
          '-'
        )}
      </Caption>
    </Box>
  )

  return (
    <>
      <Flex mt={4}>
        <Box flex="0 1 170px" mr={6}>
          <Caption id="applicationSummary.loanAmount">
            {selectedOffer?.payoutAmount
              ? `${formatter.formatInt(
                  Math.floor(Number(selectedOffer.payoutAmount.value) / 100),
                )}`
              : '-'}
          </Caption>
        </Box>
        <Box flex="0 2 450px">
          <Caption id="applicationSummary.businessPurpose">
            {businessDetails.loanPurpose}
          </Caption>
        </Box>
      </Flex>
      <Flex mt={4}>
        {loanDuration}
        <Flex flex="0 2 450px">
          {selectedOffer?.fee && (
            <Box flex="0 1 170px" mr={3}>
              <Caption id="applicationSummary.loanFee">
                {formatter.formatInt(
                  Math.ceil(Number(selectedOffer.fee.value) / 100),
                )}
              </Caption>
            </Box>
          )}
          {isVoucherValid && (
            <Box flex="0 1 170px" mr={3}>
              <Caption id="voucherCode">{voucherCode}</Caption>
            </Box>
          )}
          <Box flex="0 1 170px">
            <Caption id="applicationSummary.monthlyRepayment">
              {monthlyRepaymentValue}
              {selectedOffer && isDynamicRepayment && (
                <FormattedMessage id="applicationSummary.repaymentPercentage" />
              )}
            </Caption>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}
