import React, { useCallback, useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Button, BackButton } from 'components/buttons'
import { IndustryField } from 'components/Fields/IndustryField'
import {
  LegalRepresentative,
  OptinMerchantTerms,
  OptinMerchantAccount,
  DateOfBirth,
  FirstName,
  LegalForm,
  LastName,
  Salutation,
  Email,
} from 'components/Fields'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { ROUTES } from 'constants/routes'
import { useNavigation } from 'routing/hooks/useNavigation'
import { useLoanApplication } from 'context/LoanApplication'
import { useOwners } from 'context/Owners'
import { CUSTOMER_PROGRESS } from 'constants/loanApplication'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { isPayOne, isTeleCash } from 'setup/tenant'
import isPostalCode from 'validator/lib/isPostalCode'

const DEFAULT_ADDRESS = {
  zipCode: '00000',
  country: 'DEU',
}

export const AdditionalInformation = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { navigateTo } = useNavigation()
  const { goToPrevPage, goToNextPage, hasPrevRoute } = useFlowNavigation()
  const { createOwner, updateOwner } = useOwners()
  const {
    currentLoanApplication,
    fetchLoanApplicationById,
    updateCurrentMerchant,
    updateLoanApplicationById,
  } = useLoanApplication()

  const merchant = currentLoanApplication?.merchant
  const owner = merchant?.owners[0]

  const initialValues = {
    title: owner?.title,
    dateOfBirth: owner?.dateOfBirth,
    email: merchant?.email,
    firstName: owner?.firstName,
    industry: currentLoanApplication?.tenantSpecific.industry,
    lastName: owner?.lastName,
    legalForm: currentLoanApplication?.businessDetails.legalForm,
    legalRepresentative: !!owner?.legalRepresentative,
    optinAccount: !!merchant?.optinAccount,
    optinTerms: !!merchant?.optinTerms,
  }

  const addInitialInfo = useCallback(
    async (values) => {
      const {
        dateOfBirth,
        firstName,
        industry,
        lastName,
        legalForm,
        legalRepresentative,
        optinAccount,
        optinTerms,
        title,
        email,
      } = values

      let currentMerchant = { optinAccount, optinTerms }
      if (isPayOne) {
        currentMerchant = { ...currentMerchant, email }
      }

      // The order of the promises below matters since: application -> merchant -> owners
      await (owner?.id
        ? updateOwner({
            loanApplicationId: currentLoanApplication?.id,
            owner: {
              id: owner.id,
              applicantOwner: true,
              email,
              dateOfBirth,
              firstName,
              lastName,
              legalRepresentative,
              title,
            },
          })
        : createOwner({
            loanApplicationId: currentLoanApplication?.id,
            owner: {
              applicantOwner: true,
              email,
              dateOfBirth,
              firstName,
              lastName,
              legalRepresentative,
              title,
            },
          }))
      await updateCurrentMerchant(currentMerchant)
      await updateLoanApplicationById({
        loanApplicationId: currentLoanApplication?.id,
        loanApplication: {
          customerProgress: CUSTOMER_PROGRESS.ADDITIONAL_INFORMATION,
          tenantSpecific: {
            ...currentLoanApplication.tenantSpecific,
            industry,
          },
          businessDetails: {
            address: isPostalCode(
              String(currentLoanApplication.businessDetails.address.zipCode),
              'DE',
            )
              ? { ...currentLoanApplication.businessDetails.address }
              : { ...DEFAULT_ADDRESS },
            legalForm,
          },
        },
      })
    },
    [
      createOwner,
      currentLoanApplication,
      owner,
      updateCurrentMerchant,
      updateLoanApplicationById,
      updateOwner,
    ],
  )

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true)
      await addInitialInfo(values)
      await fetchLoanApplicationById(currentLoanApplication?.id)
      setIsLoading(false)
      goToNextPage()
    } catch (error) {
      setIsLoading(false)
      navigateTo(ROUTES.ERROR)
    }
  }

  return (
    <Layout
      viewHeader={<ViewHeader id="additionalInfo.header" />}
      withProgress={isTeleCash}
    >
      <Layout.MiddleAndRight>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ isValid, submitCount }) => (
            <FormikForm
              data-testid="pantherSignUpForm"
              style={{ width: '100%' }}
            >
              <Flex
                flexDirection="column"
                flex="1 0 auto"
                width="100%"
                pb="100px"
              >
                <Salutation />
                <FirstName />
                <LastName />
                <DateOfBirth />
                {isPayOne && (
                  <Email label={<FormattedMessage id="fields.email" />} />
                )}
                <LegalForm name="legalForm" data-testid="formFieldLegalForm" />
                {isPayOne && <IndustryField />}
                <Layout.MiddleAndRightMain>
                  <Box mb={5}>
                    <Typography variant="h5">
                      <FormattedMessage id="signUp.termsHeader" />
                    </Typography>
                  </Box>
                </Layout.MiddleAndRightMain>
                <OptinMerchantTerms />
                <OptinMerchantAccount />
                <LegalRepresentative
                  mb={5}
                  hint={null}
                  name="legalRepresentative"
                />
                <Layout.MiddleAndRightMain>
                  <Flex>
                    {hasPrevRoute && <BackButton onClick={goToPrevPage} />}
                    <Button
                      loading={isLoading}
                      type="submit"
                      disabled={(!isValid && submitCount > 0) || isLoading}
                      data-testid="submitButton"
                    >
                      <FormattedMessage id="form.submit" />
                    </Button>
                  </Flex>
                </Layout.MiddleAndRightMain>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </Layout.MiddleAndRight>
    </Layout>
  )
}
