export const BANK_LINKING_STEP = Object.freeze({
  ABORTED: 'aborted',
  AUTHORIZED: 'authorized',
  BANK: 'bank',
  CONNECTED: 'connected',
  ERROR: 'error',
  FINISH: 'finish',
  LOGIN: 'login',
  RENDERED: 'rendered',
  VALIDATION_FAILED: 'validation_failed',
})

export const BANK_LINKING_STATUS = Object.freeze({
  INITIAL: 'initial',
  PENDING: 'pending',
  SUCCESS: 'success',
})

export const XS2A_ERROR_MESSAGES = Object.freeze({
  trans_not_possible: 'bankLinkingFailed.incorrectBank',
  validation_failed: 'bankLinkingFailed.incorrectCredentials',
  GENERIC: 'bankLinkingFailed.generic',
})
