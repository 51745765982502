import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { acceptanceValidator } from 'utils/forms/validators'
import { Field as FormikField } from 'formik'
import { CheckboxComponent } from './CheckboxComponent'

export const OptinMerchantAccount = (props) => (
  <FormikField
    component={CheckboxComponent}
    data-testid="formFieldOptinAccount"
    name="optinAccount"
    label={
      <Typography variant="body2" style={{ lineHeight: '160%' }}>
        <FormattedMessage id="optinMerchant.account" />
      </Typography>
    }
    validate={acceptanceValidator}
    {...props}
  />
)
