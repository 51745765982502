import React from 'react'
import { FormattedMessage } from 'react-intl'

import { booleanRequiredValidator } from 'utils/forms/validators'

import { SwitchField } from './SwitchField'

export const LegalRepresentativeStakeholder = (props) => (
  <SwitchField
    labelId="fields.legalRepresentative.stakeholder"
    hint={<FormattedMessage id="fields.legalRepresentative.hint" />}
    validate={booleanRequiredValidator}
    {...props}
  />
)
