import React from 'react'

import { ButtonRaw } from 'components/buttons'

import { XIcon } from './icons'

export const CloseButton = ({ onClick }) => (
  <ButtonRaw {...{ onClick }}>
    <XIcon />
  </ButtonRaw>
)
