import marrSansWoff from './MarrSansCondensed-Semibold.woff'
import marrSansWoff2 from './MarrSansCondensed-Semibold.woff2'

export const typographyStyles = `
  @font-face {
    font-family: 'Marr Sans Cond';
    src: url(${marrSansWoff2}) format('woff2'),
      url(${marrSansWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
  }
`
