import React, {
  useCallback,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { ERROR_MESSAGE } from 'constants/error'
import { ErrorBoundary } from './ErrorBoundary'
import { useSetState } from '../utils/hooks'

type ErrorProviderProps = {
  children: React.ReactNode
}

type State = {
  error?: Error
  isApplicationError?: boolean
  isApiRequestError?: boolean
}

type ErrorContextState = State & {
  clearError: () => void
  handleApplicationError: (error: unknown) => void
  handleApiRequestError: (error: unknown) => void
}

export const ErrorContext = createContext<undefined | ErrorContextState>(
  undefined,
)

const initialState = Object.freeze({
  isApplicationError: false,
  isApiRequestError: false,
  error: null,
})

export const ErrorProvider = (props: ErrorProviderProps) => {
  const { children } = props

  const { pathname } = useLocation()
  const [state, setState] = useSetState<State>({ ...initialState })

  const handleApplicationError = useCallback(
    (error: Error) => {
      // eslint-disable-next-line no-console
      console.error('errror', { error })
      window.scrollTo(0, 0)
      setState({
        isApplicationError: true,
        error,
      })
    },
    [setState],
  )

  const handleApiRequestError = useCallback(
    (error: Error) => {
      // eslint-disable-next-line no-console
      console.error('errror', { error })
      setState({ isApiRequestError: true, error })
    },
    [setState],
  )

  const clearError = useCallback(() => {
    setState({ ...initialState })
  }, [setState])

  useEffect(() => {
    // Don't clear error if we just navigated to the dedicated error page,
    // which will display the last error set.
    if (pathname !== ROUTES.ERROR) {
      setState(({ error }) => {
        // Leave error about loan application not being editable on screen.
        if (
          error instanceof Error &&
          error.message !== ERROR_MESSAGE.NON_EDITABLE
        ) {
          clearError()
        }
      })
    }
  }, [pathname, clearError, setState])

  const { error, isApiRequestError, isApplicationError } = state

  const contextValue = useMemo(
    () => ({
      error,
      isApiRequestError,
      isApplicationError,
      clearError,
      handleApplicationError,
      handleApiRequestError,
    }),
    [
      error,
      isApiRequestError,
      isApplicationError,
      clearError,
      handleApplicationError,
      handleApiRequestError,
    ],
  )

  return (
    <ErrorContext.Provider value={contextValue}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </ErrorContext.Provider>
  )
}

export function useError() {
  const context = useContext(ErrorContext)
  if (context === undefined) {
    throw new Error('useError must be used within a ErrorProvider')
  }
  return context
}
