import styled from '@emotion/styled'
import activeTenant from 'setup/tenant'

export const HubspotFormStyles = styled.div<{ isLoading: boolean }>`
  display: ${(props) => (props.isLoading ? 'none' : 'block')};
  * {
    font-family: IBM Plex Sans, sans-serif !important;
  }

  p,
  li {
    font-size: 0.8rem;
    color: ${activeTenant.theme?.palette?.text?.normal};
    text-align: left;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  .hs-form-booleancheckbox label {
    display: flex;
    align-items: center;

    input {
      width: auto;
    }

    span {
      margin-left: 0.6rem;
    }
  }

  .hs-form-booleancheckbox-display {
    font-weight: bold;
  }

  .hs-error-msg {
    color: ${activeTenant.theme?.palette?.global.errorRed}!important;
  }

  .hs-fieldtype-text {
    text-align: left;

    label {
      display: block;
      font-weight: bold;
      color: ${activeTenant.theme?.palette?.text?.normal};
      margin-bottom: 0.5rem;
    }
  }

  input {
    width: 100%;
    padding: 0.6rem !important;
    border-radius: 0rem !important;
    border: 1px solid gray !important;
    font-size: 1.5rem !important;
  }

  input[type='submit'] {
    margin-top: 1rem;
    transition: 0.5s all;
    border: 1px solid ${activeTenant.theme?.palette?.global?.icoA}!important;
    padding: 1rem !important;
    border-radius: 0 !important;
    appearance: none;
    background-color: ${activeTenant.theme?.palette?.global?.icoA}!important;
    color: #fff !important;
    font-size: 1rem !important;

    &:hover {
      color: white;
    }

    &:disabled {
      opacity: 0.2;
    }
  }
`
