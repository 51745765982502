import React from 'react'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { ReactComponent as SslIcon } from 'images/ssl.svg'
import { ReactComponent as TuvIcon } from 'images/tuv.svg'
import { ReactComponent as Eu1Icon } from 'images/eu1.svg'
import { ReactComponent as IsoIcon } from 'images/iso.svg'
import { Divider } from 'components/Divider'

const Element = ({ id, icon }) => (
  <Flex mb={[3, 3, 0]}>
    <Box
      flex="0 0 64px"
      mr={3}
      sx={{ color: (theme) => theme.palette.global.icoA.main }}
    >
      {icon}
    </Box>
    <Box pt={1}>
      <Typography variant="caption">
        <FormattedMessage {...{ id }} />
      </Typography>
    </Box>
  </Flex>
)

const Column = ({ children, ...rest }) => (
  <Flex
    flexGrow="0"
    flexShrink="1"
    flexBasis="50%"
    flexDirection="column"
    {...rest}
  >
    {children}
  </Flex>
)

export const Logos = () => (
  <Flex
    mt={6}
    py={4}
    sx={{ borderTop: (theme) => theme.borders.primary }}
    flexDirection={['column', 'column', 'row']}
  >
    <Column mr={[0, 0, 4]}>
      <Element icon={<SslIcon />} id="bankLinking.certificates.ssl" />
      <Divider sx={{ display: ['none', 'none', 'block'] }} />
      <Element icon={<TuvIcon />} id="bankLinking.certificates.tuv" />
    </Column>
    <Column>
      <Element icon={<IsoIcon />} id="bankLinking.certificates.iso" />
      <Divider sx={{ display: ['none', 'none', 'block'] }} />
      <Element icon={<Eu1Icon />} id="bankLinking.certificates.eu" />
    </Column>
  </Flex>
)
