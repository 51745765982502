import React from 'react'
import { Box, Flex } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { ButtonRaw } from 'components/buttons'
import { EditIcon } from '../icons'

type EditableBoxProps = {
  id: string
  children: React.ReactNode
  onClick?: () => void
}

export const EditableBox = ({ id, onClick, children }: EditableBoxProps) => (
  <Box>
    <Flex alignItems="center" my={4}>
      <Typography variant="h5">
        <FormattedMessage id={id} />
      </Typography>
      {onClick && (
        <ButtonRaw
          onClick={onClick}
          style={{
            marginLeft: 'auto',
          }}
        >
          <Box width="24px" height="24px" data-cy={`editButton_${id}`}>
            <EditIcon />
          </Box>
        </ButtonRaw>
      )}
    </Flex>
    {children}
  </Box>
)
