import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { requiredValidator } from 'utils/forms/validators'
import activeTenant from 'setup/tenant'
import { SelectField } from './SelectField'

export const IndustryField = (props) => {
  const intl = useIntl()
  const options = useMemo(
    () =>
      activeTenant.tenantSpecific?.industries?.map(({ value, id }) => ({
        value,
        message: intl.formatMessage({ id }),
      })),
    [intl],
  )

  return (
    <SelectField
      name="industry"
      data-testid="industry"
      inputWrapperSx={{ pr: 3, height: ['100px', '78px', '78px'] }}
      label={<FormattedMessage id="fields.industryField" />}
      validate={requiredValidator}
      hint={<FormattedMessage id="fields.industryField.hint" />}
      options={options}
      {...props}
    />
  )
}
