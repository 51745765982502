import countries, { LocaleData } from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'
import de from 'i18n-iso-countries/langs/de.json'
import tr from 'i18n-iso-countries/langs/tr.json'
import activeTenant from 'setup/tenant'
import { ConfiguredLocales } from 'tenants'
import enTranslationMessages from 'translations/en.json'
import deTranslationMessages from 'translations/de.json'
import trTranslationMessages from 'translations/tr.json'
import { navigatorLanguage } from 'utils'

countries.registerLocale(en as LocaleData)
countries.registerLocale(de as LocaleData)
countries.registerLocale(tr as LocaleData)

// prettier-ignore
export const appLocales: ConfiguredLocales[] = [
  'en',
  'de',
  'tr',
]

export const DEFAULT_LOCALE = activeTenant.tenantLocales.includes(
  navigatorLanguage.substr(0, 2),
)
  ? (navigatorLanguage.substr(0, 2) as ConfiguredLocales)
  : activeTenant.defaultLocale

export const formatTranslationMessages = <T extends string>(
  locale: ConfiguredLocales,
  messages: Record<T, string>,
): Record<T, string> => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {}
  const flattenFormattedMessages = (
    formattedMessages: Record<string, string>,
    key: string,
  ) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }
  return Object.keys(messages).reduce(flattenFormattedMessages, {})
}

const baseTranslations = {
  en: formatTranslationMessages('en', enTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
  tr: formatTranslationMessages('tr', trTranslationMessages),
}

// extracts the tenant specific translation terms based on a `.TENANT_CODE` suffix and creates an object
// containing the base terms that are not tenant specific. e.g.
/**
 * {
 *   "term.grl": "hello there"
 * }
 *
 * becomes:
 *
 * {
 *   "term": "hello there"
 * }
 *
 * and can be used accordingly in the formatted message components.
 */
export const extractTenantSpecificTranslations = (
  translations: Record<string, string>,
) => {
  const tenantSpecificTranslations: Record<string, string> = {}
  const tenantOverrideSuffix = activeTenant?.tenantOverrides
    ?.translationOverrideSuffix
    ? `.${activeTenant.tenantOverrides?.translationOverrideSuffix}`
    : ''
  if (tenantOverrideSuffix) {
    Object.entries(translations).forEach(([term, translation]) => {
      if (term.includes(tenantOverrideSuffix)) {
        tenantSpecificTranslations[term.replace(tenantOverrideSuffix, '')] =
          translation
      }
    })
  }

  return tenantSpecificTranslations
}

export const translationMessages: Record<
  ConfiguredLocales,
  Record<string, string>
> = {
  en: {},
  de: {},
  tr: {},
}

// populate the active translation messages with only those specific to the selected tenant
activeTenant.tenantLocales.forEach((locale) => {
  translationMessages[locale] = {
    ...baseTranslations[locale],
    ...extractTenantSpecificTranslations(baseTranslations[locale]),
  }
})
