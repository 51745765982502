import React from 'react'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Accordion } from 'components/Accordion'
import { Button } from 'components/buttons'
import { Layout } from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { LEGAL_FORMS } from 'constants/loanApplication'
import { ReactComponent as WhatYouNeedIcon } from 'images/what-you-need.svg'
import { ReactComponent as DocumentsIcon } from 'images/documents.svg'
import { ReactComponent as TimeIcon } from 'images/time.svg'
import { ReactComponent as TransferIcon } from 'images/transfer.svg'
import { ReactComponent as DeviceIcon } from 'images/device.svg'
import {
  getBusinessDocs,
  getDocumentTypeTranslationProps,
} from 'utils/documents'
import { DesignerGrlImage } from 'components/icons'
import { isMca } from 'setup/tenant'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useLanguage } from 'context/Language'
import { useLoanApplication } from 'context/LoanApplication'

export const LoanProcessOverview = () => {
  const { currentLoanApplication } = useLoanApplication()
  const { goToNextPage } = useFlowNavigation()
  const { dateLocale } = useLanguage()

  const { businessDetails } = currentLoanApplication || {}
  const businessDocs = getBusinessDocs(businessDetails?.legalForm)

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="loanProcessOverview.header"
          icon={<WhatYouNeedIcon />}
        />
      }
      withProgress
    >
      <Layout.Middle>
        <Flex flexDirection="column">
          <Accordion
            summaryId="loanProcessOverview.minutes.header"
            detailsId="loanProcessOverview.minutes.content"
            icon={<TimeIcon />}
          />
          {!isMca && businessDocs.length > 0 && (
            <Accordion
              summaryId="loanProcessOverview.documents.header"
              detailsId="loanProcessOverview.documents.content"
              icon={<DocumentsIcon />}
            >
              {businessDocs.map((docType, index) => {
                const documentTypeTranslationProps =
                  getDocumentTypeTranslationProps({
                    documentType: docType,
                    index,
                    locale: dateLocale,
                  })
                return (
                  <Typography key={docType} component="span" variant="body2">
                    <FormattedMessage {...documentTypeTranslationProps} />
                  </Typography>
                )
              })}
            </Accordion>
          )}
          {isMca && businessDocs.length > 0 && (
            <Accordion
              summaryId="loanProcessOverview.documents.header"
              detailsId="loanProcessOverview.documents.content"
              additionalDetails={businessDocs.map((docType, index) => {
                const documentTypeTranslationProps =
                  getDocumentTypeTranslationProps({
                    documentType: docType,
                    index,
                    locale: dateLocale,
                  })

                return (
                  <Typography key={docType} variant="body2">
                    <FormattedMessage {...documentTypeTranslationProps} />
                  </Typography>
                )
              })}
              icon={<DocumentsIcon />}
            />
          )}
          {[
            LEGAL_FORMS.GBR,
            LEGAL_FORMS.GMBH,
            LEGAL_FORMS.OHG,
            LEGAL_FORMS.GMBH_COKG,
            LEGAL_FORMS.EK,
            LEGAL_FORMS.UG,
          ].includes(businessDetails.legalForm) && (
            <Accordion
              summaryId="loanProcessOverview.legalRep.header"
              detailsId="loanProcessOverview.legalRep.content"
              icon={<TransferIcon />}
            />
          )}
          <Accordion
            summaryId="loanProcessOverview.bankAccess.header"
            detailsId="loanProcessOverview.bankAccess.content"
            icon={<DeviceIcon />}
          />
          <Flex mt={5}>
            <Button data-cy="startApplication" onClick={goToNextPage} withArrow>
              <FormattedMessage id="loanProcessOverview.start" />
            </Button>
          </Flex>
        </Flex>
      </Layout.Middle>
      <Layout.Right>
        <Box sx={{ display: ['none', 'block', 'block'] }}>
          <Flex
            sx={{
              justifyContent: 'center',
              px: 2,
              borderBottom: '3px solid #29118B',
              mt: [0, 7, 0],
            }}
          >
            <DesignerGrlImage />
          </Flex>
        </Box>
      </Layout.Right>
    </Layout>
  )
}

export default LoanProcessOverview
