import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { CircularProgressbarWithChildren as Progress } from 'components/ProgressBar'
import { ButtonRaw } from 'components/buttons'
import { toBase64, formatBytes } from 'utils'
import { useDocuments } from 'context/Documents'
import { useConfiguration } from 'context/Configuration'
import { Error } from './Error'
import { TrashIcon, OkIcon, UploadIcon, AttachmentIcon } from './icons'

const UploadBox = ({
  title,
  documentType,
  loanApplicationId,
  acceptedFileTypes = ['application/pdf'],
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [acceptedFile, setAcceptedFile] = useState(null)
  const [error, setError] = useState(null)
  const { configuration } = useConfiguration()
  const { uploadDocument, documents, deleteDocument, isLoading, isError } =
    useDocuments()

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptedFiles[0]) {
        setError({ name: 'type' })
        return
      }
      if (acceptedFiles[0].size > configuration?.maxDocumentFileSize) {
        setError({ name: 'size' })
        return
      }
      setError(null)
      setIsUploading(true)
      setAcceptedFile(acceptedFiles[0])
      toBase64({
        file: acceptedFiles[0],
        callback: (data) => {
          uploadDocument({
            id: loanApplicationId,
            onUploadProgress: setProgress,
            document: {
              file: {
                data,
                filename: acceptedFiles[0].name,
                contentType: acceptedFiles[0].type,
              },
              documentType,
            },
          })
        },
      })
    },
    [
      configuration?.maxDocumentFileSize,
      documentType,
      loanApplicationId,
      uploadDocument,
    ],
  )
  const uploadedDocument = documents?.find(
    (doc) => doc.documentType === documentType,
  )
  useEffect(() => {
    if (isError) {
      setIsUploading(false)
      setProgress(0)
    }
  }, [isError])
  useEffect(() => {
    if (!isLoading && uploadedDocument?.documentType === documentType) {
      setIsUploading(false)
      setAcceptedFile(null)
    }
  }, [isLoading, uploadedDocument, documentType])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  })
  return (
    <Flex flexDirection="row" alignItems="center" mb="16px">
      <Flex
        {...getRootProps()}
        sx={{
          border: (theme) => theme.borders.primary,
          borderRadius: '12px',
          flexDirection: 'column',
          flex: '1 1 auto',
          cursor: 'pointer',
        }}
      >
        <input data-testid={`${documentType}-fileInput`} {...getInputProps()} />
        <Flex pl={4} py={3}>
          <Box width="24px" height="24px" mr={3}>
            <AttachmentIcon />
          </Box>
          <Flex flexDirection="column" flexGrow="1">
            <Typography variant="body2">{title}</Typography>
            <Box mt={1} mb={uploadDocument ? 3 : 0}>
              <Typography variant="caption">
                <FormattedMessage id="documentsUpload.dragDropFootnote" />
              </Typography>
            </Box>
            {uploadedDocument && (
              <Flex
                justifyContent="space-between"
                py={4}
                pr={3}
                sx={{ borderTop: (theme) => theme.borders.primary }}
              >
                <Flex flexDirection="column">
                  <Typography variant="body2">
                    {uploadedDocument.filename}
                  </Typography>
                  <Typography variant="caption" color="text.subtle.main">
                    {formatBytes(uploadedDocument.size)}
                  </Typography>
                </Flex>
                <Flex alignItems="center">
                  <Box mr={3}>
                    <ButtonRaw
                      onClick={(event) => {
                        event.stopPropagation()
                        deleteDocument({
                          id: loanApplicationId,
                          documentId: uploadedDocument.id,
                        })
                        setProgress(0)
                      }}
                    >
                      <Box width="24px" height="24px">
                        <TrashIcon />
                      </Box>
                    </ButtonRaw>
                  </Box>
                  <Box width="24px" height="24px">
                    <OkIcon />
                  </Box>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>

        {error && <Error name={error.name} />}
        {isUploading && acceptedFile && (
          <Flex flexDirection="column" backgroundColor="global.bgA.main" pl={7}>
            <Flex justifyContent="space-between" py={4} pr={3}>
              <Flex flexDirection="column">
                <Typography variant="body2">{acceptedFile.name}</Typography>
                <Typography variant="caption">
                  {formatBytes(acceptedFile.size)}
                </Typography>
              </Flex>
              <Flex alignItems="center">
                <Box mr={3}>
                  <Typography variant="caption">{progress} %</Typography>
                </Box>
                <Box width="24px" height="24px">
                  <Progress
                    strokeWidth={8}
                    paths={[
                      {
                        value: Math.floor(progress),
                        styles: { pathColor: '#29118B', strokeLinecap: 'butt' },
                      },
                    ]}
                  />
                </Box>
              </Flex>
            </Flex>
            {/* <Line
              strokeWidth="1"
              strokeLinecap="butt"
              strokeColor="#1e4693"
              trailWidth="1"
              trailColor="#f4f5f4"
              percent={progress}
              style={{ width: '100%', height: '100%' }}
            /> */}
          </Flex>
        )}
        <Flex
          sx={{
            borderTop: (theme) =>
              `1px dashed ${theme.palette.global.line.main}`,
          }}
          p={4}
          justifyContent="center"
        >
          <Typography variant="body2">
            <FormattedMessage id="documentsUpload.dragDrop" />
          </Typography>
          <Box width="24px" height="24px" mx={4}>
            <UploadIcon />
          </Box>
          <Typography variant="body2">
            <FormattedMessage id="documentsUpload.orSelect" />
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default UploadBox
