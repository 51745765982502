import React from 'react'
import { Box } from 'components/structural'
import { BoxProps } from '@mui/material/Box'

type InputAreaProps = BoxProps & {
  boxed?: boolean
}

const InputArea = ({
  children,
  sx,
  boxed = false,
  ...rest
}: InputAreaProps) => (
  <Box
    sx={{
      gridColumnStart: '1',
      gridColumnEnd: ['span 3', 'span 3', boxed ? 'span 3' : 'span 2'],
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

type HintAreaProps = BoxProps & {
  boxed?: boolean
}

const HintArea = ({ children, sx, boxed = false, ...rest }: HintAreaProps) => (
  <Box
    sx={{
      gridColumnStart: ['1', '1', boxed ? '1' : '3'],
      gridColumnEnd: ['span 3', 'span 3', boxed ? 'span 2' : 'span 1'],
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

type InputGridrops = BoxProps & {
  boxed?: boolean
}

export const InputGrid = ({ children, sx, ...rest }: InputGridrops) => (
  <Box
    sx={{
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '48px',
      rowGap: 0,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

InputGrid.Input = InputArea
InputGrid.Hint = HintArea
