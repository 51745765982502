import React from 'react'
import { Flex } from 'components/structural'
import { CircularProgress } from '@mui/material'

export const LoanApplicationSpinner = () => (
  <Flex
    flexDirection="column"
    width="100%"
    height="100%"
    alignItems="center"
    justifyContent="center"
    flex="1 0 auto"
    maxWidth={[null, null, null, 1024]}
  >
    <CircularProgress data-testid="spinner" />
  </Flex>
)
