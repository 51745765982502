import React from 'react'
import { Box } from 'components/structural'
import { ReactComponent as X } from 'images/icons/x-blue.svg'
import { ReactComponent as Globe } from 'images/icons/globe.svg'

export const XIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <X />
  </Box>
)

export const GlobeIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          fill: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Globe />
  </Box>
)
