import React from 'react'
import { Typography } from '@mui/material'
import { ApplicationGirlImage } from 'components/icons'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { ReactComponent as CompleteIcon } from 'images/complete.svg'
import { ReactComponent as ValidGreenIcon } from 'images/icons/valid-green.svg'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'

export const ApplicationFinalSubmitted = () => (
  <Layout
    viewHeader={
      <ViewHeader
        id="expressApplicationSubmitted.header"
        subId="expressApplicationExpressEligibleSubmitted.subheading"
        icon={<CompleteIcon />}
      />
    }
    withProgress
  >
    <Layout.Middle>
      <Box
        backgroundColor="global.okGreen.light"
        px="28px"
        py="28px"
        data-testid="processComplete"
      >
        <Flex alignItems="center" sx={{ color: 'global.okGreen.main' }}>
          <Box width="24px" height="24px" mr={4}>
            <ValidGreenIcon />
          </Box>
          <Typography variant="h5">
            <FormattedMessage id="expressApplicationSubmitted.processComplete" />
          </Typography>
        </Flex>
      </Box>
      <Box mt={[4, 4, 6]} mb={[6, 6, 0]}>
        <Typography variant="body2">
          <FormattedMessage id="expressApplicationSubmitted.description" />
        </Typography>
      </Box>
    </Layout.Middle>
    <Layout.Right>
      <ApplicationGirlImage />
    </Layout.Right>
  </Layout>
)
