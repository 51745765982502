import getRaoulApple from 'images/favicons/get-raoul/apple-touch-icon.png'
import getRaoul32 from 'images/favicons/get-raoul/favicon-32x32.png'
import getRaoul16 from 'images/favicons/get-raoul/favicon-16x16.png'
import getRaoulPin from 'images/favicons/get-raoul/safari-pinned-tab.svg'
import getRaoulManifest from 'images/favicons/get-raoul/site.webmanifest'

import '!file-loader?name=get-raoul-[name].[ext]!../images/favicons/get-raoul/android-chrome-192x192.png'
import '!file-loader?name=get-raoul-[name].[ext]!../images/favicons/get-raoul/android-chrome-512x512.png'

import { ReactComponent as GetRaoulLogo } from 'images/logos/raoul.svg'
import { ROUTES } from 'constants/routes'

import { TenantConfig, TenantPalette, TenantShape } from '.'

const palette: TenantPalette = {
  global: {
    bgA: '#FFFFFF',
    bgB: '#EEF4FB',
    line: '#E0E0E0',
    icoA: '#EF8633',
    icoB: '#666666',
    icoOff: '#E0E0E0',
    errorRed: '#DF3A5A',
    okGreen: '#68AC57',
    onDark: '#FFFFFF',
  },
  header: {
    bg: '#FFFFFE',
    title: '#0D0D0D',
    text: '#0D0D0D',
    link: '#EF8633',
  },
  input: {
    bgA: '#FFFFFF',
    bgB: '#FFFFFF',
    stroke: '#E0E0E0',
    placeholder: '#E0E0E0',
    label: '#EF8633',
    value: '#2D2D2D',
    ico: '#EF8633',
    icoOff: '#E0E0E0',
  },
  text: {
    normal: '#0D0D0D',
    heading: '#666666',
    link: '#EF8633',
    subtle: '#666666',
    off: '#E0E0E0',
  },
  button: {
    bgA: '#EF8633',
    bgB: '#F5F5F5',
    bgC: '#EEF4FB',
    labelA: '#FFFFFF',
    labelB: '#666666',
    labelC: '#666666',
  },
}

const shape: TenantShape = {
  borderRadius: 0,
  fieldBorderRadius: '0',
}

const getRaoulTenantConfig: TenantConfig = {
  tenantCode: 'GRL',
  name: 'Get Raoul',
  title: 'Get Raoul',
  logo: {
    component: GetRaoulLogo,
    width: 124,
  },
  iconSet: {
    appleTouch: getRaoulApple,
    favicon16: getRaoul16,
    favicon32: getRaoul32,
    manifest: getRaoulManifest,
    pin: getRaoulPin,
    tileColor: '#603cba',
  },
  contactEmail: 'support@getraoul.de',
  imprintLink: 'https://www.getraoul.de/impressum',
  faqLink: 'http://help.banxware.com/support/solutions/80000290462',
  tenantOverrides: {
    translationOverrideSuffix: 'grl',
  },
  theme: {
    palette,
    shape,
  },
  gtmId: 'GTM-TCWTNDQ',
  loanApplicationFlow: [ROUTES.MERCHANT_PORTAL],
  customizeLoanOfferRoute: ROUTES.CUSTOMIZE_LOAN_OFFER_GRL,
  isOnlyMerchantPortal: true,
}

export default getRaoulTenantConfig
