import React, { useState } from 'react'
import _ from 'lodash'
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { styled } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { SPACING } from 'constants/styles'
import { ArrowDownIcon } from 'components/icons'

const List = styled('ul')`
  padding-left: 1em;
`

const ListElement = styled('li')`
  margin: 0;
`

const ICON_MOBILE_WIDTH = 48
const ICON_TABLET_DESKTOP_WIDTH = 64

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(6),
  },
  expandIcon: {
    position: 'absolute',
    padding: 0,
    fontSize: 0,
    right: theme.spacing(3),
  },
}))

export const Accordion = ({
  summaryId, // text that appears as title
  detailsId, // text that appears after expanding
  additionalDetails, // bullets after expanded text
  icon,
  children,
  ...restProps
}) => {
  const [expanded, setExpanded] = useState(false)
  const handleChange = (__, newExpanded) => {
    setExpanded(newExpanded)
  }
  const classes = useStyles()
  return (
    <Box mb={3}>
      <MuiAccordion
        onChange={handleChange}
        {...restProps}
        {...{ expanded }}
        square
      >
        <Box
          sx={{
            border: (theme) => theme.borders.primary,
          }}
        >
          <AccordionSummary
            expandIcon={
              <Box width="24px" height="24px">
                <ArrowDownIcon />
              </Box>
            }
            {...{ classes }}
          >
            <Flex
              py={[5, 4, 4]}
              px={4}
              pr={0}
              alignItems="center"
              flex="1 1 auto"
            >
              <Flex
                height="100%"
                flexDirection="column"
                width={[
                  `${ICON_MOBILE_WIDTH}px`,
                  `${ICON_TABLET_DESKTOP_WIDTH}px`,
                  `${ICON_TABLET_DESKTOP_WIDTH}px`,
                ]}
                minWidth={[
                  `${ICON_MOBILE_WIDTH}px`,
                  `${ICON_TABLET_DESKTOP_WIDTH}px`,
                  `${ICON_TABLET_DESKTOP_WIDTH}px`,
                ]}
              >
                {icon}
              </Flex>
              <Flex flexDirection="column" flex="1 1 auto" mx={4}>
                <Typography variant="h5" style={{ textAlign: 'left' }}>
                  <FormattedMessage id={summaryId} />
                </Typography>
                {children && (
                  <List>
                    {_.isArray(children) ? (
                      children.filter(Boolean).map((element, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <ListElement key={index}>{element}</ListElement>
                      ))
                    ) : (
                      <ListElement>{children}</ListElement>
                    )}
                  </List>
                )}
              </Flex>
            </Flex>
          </AccordionSummary>
        </Box>
        <Box
          sx={{
            border: (theme) => theme.borders.primary,
            borderTop: 'none',
          }}
        >
          <AccordionDetails>
            <Flex
              flex="1 1 auto"
              flexDirection="column"
              px={4}
              py={4}
              pl={[
                `${ICON_MOBILE_WIDTH + SPACING[6]}px`,
                `${ICON_TABLET_DESKTOP_WIDTH + SPACING[6]}px`,
                `${ICON_TABLET_DESKTOP_WIDTH + SPACING[6]}px`,
              ]}
              backgroundColor="global.bgA.main"
            >
              <Typography variant="body1" component="span">
                <FormattedMessage id={detailsId} />
                {additionalDetails && (
                  <List>
                    {_.isArray(additionalDetails) ? (
                      additionalDetails
                        .filter(Boolean)
                        .map((element, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <ListElement key={index}>{element}</ListElement>
                        ))
                    ) : (
                      <ListElement>{additionalDetails}</ListElement>
                    )}
                  </List>
                )}
              </Typography>
              {children && (
                <List>
                  {_.isArray(children) ? (
                    children.filter(Boolean).map((element, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <ListElement key={index}>{element}</ListElement>
                    ))
                  ) : (
                    <ListElement>{children}</ListElement>
                  )}
                </List>
              )}
            </Flex>
          </AccordionDetails>
        </Box>
      </MuiAccordion>
    </Box>
  )
}

export default Accordion
