import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { Grid } from 'components/Grid'
import { BackButton, Button } from 'components/buttons'
import { ViewHeader } from 'components/headers'
import { Layout } from 'components/Layout'
import { ShareholdersIcon } from 'components/icons'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useOwners } from 'context/Owners'
import { useLoanApplication } from 'context/LoanApplication'
import { useError } from 'context/Error'
import { CUSTOMER_PROGRESS, LEGAL_FORMS } from 'constants/loanApplication'
import { getStakeholderCountByLegalForm, setValidationMessage } from './utils'
import { Stakeholder } from './components/Stakeholder'
import { AddNew } from './components/Stakeholder/AddNew'

export const Stakeholders = () => {
  const { goToNextPage, goToPrevPage } = useFlowNavigation()
  const {
    currentLoanApplication,
    fetchLoanApplicationById,
    isLoading: isLoanApplicationLoading,
    updateLoanApplicationById,
  } = useLoanApplication()
  const {
    createOwner,
    deleteOwner,
    updateOwner,
    isLoading: isOwnersLoading,
  } = useOwners()
  const { isApplicationError, clearError } = useError()
  const { owners = [] } = currentLoanApplication?.merchant || {}

  const totalShares = useMemo(
    () =>
      (owners || []).reduce((result, owner) => {
        result += owner?.sharesPercent
        return result
      }, 0),
    [owners],
  )

  const ownersHaveAllInformation = useMemo(
    () => owners.every((owner) => !!owner.nationality && !!owner.taxNumber),
    [owners],
  )

  const uboOwners = useMemo(
    () => (owners || []).filter((owner) => owner.ultimateBeneficial),
    [owners],
  )
  const applicantOwner = useMemo(
    () => (owners || []).filter((owner) => owner.applicantOwner),
    [owners],
  )
  const nonApplicantOwners = useMemo(
    () => (owners || []).filter((owner) => !owner.applicantOwner),
    [owners],
  )
  const sortedNonApplicantOwners = nonApplicantOwners.sort(
    (a, b) => b.sharesPercent - a.sharesPercent,
  )

  const missingStakeholderCount =
    getStakeholderCountByLegalForm(
      currentLoanApplication?.businessDetails.legalForm,
    ) - uboOwners.length

  const handleCreateOwner = async (values) => {
    await createOwner({
      loanApplicationId: currentLoanApplication.id,
      owner: {
        ...values,
        addressAttributes: values?.address,
      },
    })
    await fetchLoanApplicationById(currentLoanApplication.id)
    if (isApplicationError) clearError()
  }

  const handleUpdateOwner = async (values) => {
    const shares = values.ultimateBeneficial ? values?.sharesPercent : 0
    await updateOwner({
      loanApplicationId: currentLoanApplication.id,
      owner: {
        ...values,
        sharesPercent: shares,
        addressAttributes: values?.address,
      },
    })
    await fetchLoanApplicationById(currentLoanApplication.id)
    if (isApplicationError) clearError()
  }

  const handleDeleteOwner = async (ownerId) => {
    await deleteOwner({
      loanApplicationId: currentLoanApplication.id,
      ownerId,
    })
    await fetchLoanApplicationById(currentLoanApplication.id)
    if (isApplicationError) clearError()
  }

  const handleGoToNextPage = () => {
    updateLoanApplicationById({
      loanApplicationId: currentLoanApplication.id,
      loanApplication: {
        customerProgress: CUSTOMER_PROGRESS.STAKEHOLDERS,
      },
    })
    goToNextPage()
  }

  const validationMessage = setValidationMessage(
    currentLoanApplication.businessDetails.legalForm,
    missingStakeholderCount,
    !LEGAL_FORMS.GMBH_COKG && totalShares,
    !ownersHaveAllInformation,
  )

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="stakeholders.header"
          subId="expressApplicationStakeHolder.subheading"
          icon={
            <ShareholdersIcon style={{ position: 'relative', top: '-10px' }} />
          }
        />
      }
      withProgress
    >
      <Grid.Column start={2} span={3}>
        <Grid columns={3}>
          <Grid.Column sx={{ gridColumnEnd: ['span 3', 'span 3', 'span 2'] }}>
            <Box mb={5}>
              <Typography variant="h5">
                <FormattedMessage id="stakeholders.heading" />
              </Typography>
            </Box>
          </Grid.Column>
        </Grid>
        {applicantOwner.map((owner) => (
          <Stakeholder
            key={owner.id}
            owner={owner}
            handleUpdateOwner={handleUpdateOwner}
            handleDeleteOwner={handleDeleteOwner}
          />
        ))}
        {sortedNonApplicantOwners.map((owner) => (
          <Stakeholder
            key={owner.id}
            owner={owner}
            handleUpdateOwner={handleUpdateOwner}
            handleDeleteOwner={handleDeleteOwner}
          />
        ))}
        <Grid columns={3}>
          <Grid.Column sx={{ gridColumnEnd: ['span 3', 'span 3', 'span 2'] }}>
            {validationMessage !== '' && (
              <Box
                width="100%"
                backgroundColor="global.bgA.main"
                px={1}
                py={0}
                mb={5}
              >
                <Typography variant="body1">
                  <FormattedMessage id={validationMessage} />
                </Typography>
              </Box>
            )}
          </Grid.Column>
        </Grid>
        <AddNew
          handleCreateOwner={handleCreateOwner}
          totalShares={totalShares}
        />
        <Grid columns={3}>
          <Grid.Column sx={{ gridColumnEnd: ['span 3', 'span 3', 'span 2'] }}>
            <Flex mt={5}>
              <BackButton onClick={goToPrevPage} />
              <Button
                withArrow
                type="submit"
                data-testid="submitButton"
                disabled={
                  missingStakeholderCount > 0 ||
                  (!LEGAL_FORMS.GMBH_COKG && totalShares > 100) ||
                  isApplicationError ||
                  isLoanApplicationLoading ||
                  isOwnersLoading ||
                  !ownersHaveAllInformation
                }
                loading={isLoanApplicationLoading || isOwnersLoading}
                onClick={handleGoToNextPage}
              >
                <FormattedMessage id="stakeholders.submit" />
              </Button>
            </Flex>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Layout>
  )
}
