import React, { useState, useEffect } from 'react'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Typography, CircularProgress } from '@mui/material'
import { ReactComponent as TrashRedIcon } from 'images/icons/trash-red.svg'
import { BackButton, ButtonRaw, Button } from 'components/buttons'
import {
  isGetRaoul,
  isBanxware,
  isPayOne,
  isLinkIntegration,
} from 'setup/tenant'
import { ReactComponent as ErrorIcon } from 'images/bullets/error.svg'
import { ROUTES } from 'constants/routes'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { CUSTOMER_PROGRESS } from 'constants/loanApplication'
import { useLoanApplication } from 'context/LoanApplication'
import { useNavigation } from 'routing/hooks/useNavigation'
import { BANK_LINKING_STEP, BANK_LINKING_STATUS } from '../constants'
import { useBankLinking } from '../hooks/useBankLinking'
import { StyledWidget } from './styles'
import { BankInfoBox } from './BankInfoBox'
import { HourglassIcon, LinkCIcon, TrashIcon } from './icons'

export const LinkAccountWidget = () => {
  const {
    initializeBankLinking,
    isLoading,
    linkingStep,
    resetBankLinking,
    sessionKey,
    xs2aFormRef,
  } = useBankLinking()
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const { goToNextPage, goToPrevPage } = useFlowNavigation()
  const { navigateTo } = useNavigation()
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const submitLoanApplication = async () => {
    if (
      currentLoanApplication.linkedBankAccount?.status ===
      BANK_LINKING_STATUS.SUCCESS
    ) {
      await updateLoanApplicationById({
        loanApplicationId: currentLoanApplication.id,
        loanApplication: {
          ...currentLoanApplication,
          expressFlow: true,
          submitted: true,
          customerProgress: CUSTOMER_PROGRESS.BANK_LINKING,
        },
      })
      goToNextPage()
    }
  }

  useEffect(() => {
    if (
      currentLoanApplication.linkedBankAccount?.status ===
      BANK_LINKING_STATUS.SUCCESS
    ) {
      return
    }
    initializeBankLinking()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteAccount = async () => {
    if (!deleting) {
      setDeleting(true)
      return
    }
    setLoading(true)
    setDeleting(false)
    await initializeBankLinking()
    setLoading(false)
  }

  const handleCancelDelete = () => {
    setDeleting(false)
  }

  const handleRetryConnection = async () => {
    setLoading(true)
    await initializeBankLinking()
    setLoading(false)
  }

  const handleGoBack = async () => {
    if (
      currentLoanApplication.linkedBankAccount?.status !==
      BANK_LINKING_STATUS.SUCCESS
    ) {
      await resetBankLinking()
    }
    if (
      currentLoanApplication?.businessDetails?.crefoId &&
      (isGetRaoul || isPayOne || isLinkIntegration || isBanxware)
    ) {
      navigateTo(ROUTES.COMPANY_SEARCH)
    } else {
      goToPrevPage()
    }
  }

  const formRendered = linkingStep === BANK_LINKING_STEP.BANK && !isLoading
  const trashIcon = deleting ? <TrashRedIcon /> : <TrashIcon />

  if (linkingStep === BANK_LINKING_STEP.FINISH) {
    return (
      <>
        <BankInfoBox
          headerId="bankLinking.pending.header"
          contentId="bankLinking.pending.content"
          icon={<HourglassIcon />}
        />
        <Flex mt={4}>
          <BackButton onClick={goToPrevPage} />
          <Button
            withArrow
            disabled
            loading
            data-testid="loadingButton"
            px={[0, 3, 3]}
          >
            <FormattedMessage id="form.reviewSubmit" />
          </Button>
        </Flex>
      </>
    )
  }

  if (linkingStep === BANK_LINKING_STEP.ERROR) {
    return (
      <>
        <BankInfoBox
          headerId="bankLinkingFailed.header"
          contentId="bankLinkingFailed.content"
          icon={<ErrorIcon />}
        />
        <Flex mt={4}>
          <BackButton onClick={handleGoBack} />
          <Button onClick={handleRetryConnection} data-testid="retryButton">
            <FormattedMessage id="bankLinkingFailed.retry" />
          </Button>
        </Flex>
      </>
    )
  }

  if (
    linkingStep === BANK_LINKING_STEP.CONNECTED ||
    currentLoanApplication.linkedBankAccount?.status ===
      BANK_LINKING_STATUS.SUCCESS
  ) {
    return (
      <>
        <BankInfoBox headerId="bankLinking.linked.header" icon={<LinkCIcon />}>
          <Box alignSelf="center">
            <Typography variant="body2">
              {currentLoanApplication.linkedBankAccount?.bankName}
            </Typography>
          </Box>
          <Flex
            ml="auto"
            mr="56px"
            sx={{
              transition: 'opacity 0.4s ease-in-out',
              opacity: deleting ? 0 : 1,
            }}
          >
            <Flex flexDirection="column" mr={3}>
              <Box mb={1}>
                <Typography variant="caption">
                  <FormattedMessage id="bankLinking.linked.owner" />
                </Typography>
              </Box>
              <Typography variant="caption">
                <FormattedMessage id="bankLinking.linked.iban" />
              </Typography>
            </Flex>
            <Flex flexDirection="column">
              <Box mb={1}>
                <Typography variant="caption" data-testid="bank-linked-holder">
                  {currentLoanApplication.linkedBankAccount?.holderName}
                </Typography>
              </Box>
              <Typography variant="caption" data-testid="bank-linked-account">
                {currentLoanApplication.linkedBankAccount?.iban}
              </Typography>
            </Flex>
          </Flex>
          <Flex
            backgroundColor="#fff"
            sx={{
              position: 'absolute',
              left: '100%',
              top: '20px',
              bottom: '20px',
              transition: 'transform 0.4s cubic-bezier(.58,.01,.2,1.47)',
              transform: `translate(${deleting ? '-100%' : '-56px'})`,
            }}
          >
            <Flex
              pl={3}
              sx={{
                borderLeft: (theme) => theme.borders.primary,
              }}
              alignItems="center"
            >
              <ButtonRaw
                data-cy="deleteBankAccount"
                onClick={handleDeleteAccount}
              >
                <Box width="24px" mr={3} sx={{ color: 'global.errorRed.main' }}>
                  {loading ? (
                    <CircularProgress color="inherit" size="24px" />
                  ) : (
                    trashIcon
                  )}
                </Box>
                <Typography
                  variant="body1"
                  color="global.errorRed.main"
                  style={{ whiteSpace: 'pre' }}
                >
                  <FormattedMessage id="bankLinking.confirmRemoving" />
                </Typography>
              </ButtonRaw>
            </Flex>
            <Flex
              px={3}
              ml={3}
              alignItems="center"
              sx={{ borderLeft: (theme) => theme.borders.primary }}
            >
              <ButtonRaw onClick={handleCancelDelete}>
                <Typography
                  variant="body1"
                  color="text.link.main"
                  style={{ whiteSpace: 'pre' }}
                >
                  <FormattedMessage id="bankLinking.cancelRemoving" />
                </Typography>
              </ButtonRaw>
            </Flex>
          </Flex>
        </BankInfoBox>
        <Flex mt={4}>
          <BackButton onClick={handleGoBack} />
          <Button
            withArrow
            onClick={submitLoanApplication}
            data-testid="submitButton"
            px={[0, 3, 3]}
          >
            <FormattedMessage id="form.reviewSubmit" />
          </Button>
        </Flex>
      </>
    )
  }

  return (
    <Box key={sessionKey} sx={{ position: 'relative' }}>
      <StyledWidget
        data-testid="xs2aWidget"
        ref={xs2aFormRef}
        id="XS2A-Form"
        data-xs2a={sessionKey || 'xs2aWidget'}
      />
      {formRendered && (
        <BackButton
          style={{ position: 'absolute', bottom: '114px' }}
          onClick={handleGoBack}
        />
      )}
    </Box>
  )
}
