import React, { createContext, useEffect, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { API_URL, API_PATH } from 'constants/api'
import { noop } from 'lodash'
import { ERROR_MESSAGE } from 'constants/error'
import localforage from 'localforage'
import { postRequest } from '../utils/api'
import { useError } from '../Error'
import { PERSIST_KEY } from '../constants'
import { usePersistedState } from '../utils/persist'

type AuthProviderProps = {
  children: React.ReactNode
}

type DecodedToken = {
  token?: string
  tokenExpiry?: string
}

type State = DecodedToken & {
  isLoading?: boolean
  isError?: boolean
  merchantId?: string
}

export type AuthContextState = State & {
  logIn: (credentials: { email: string; password: string }) => Promise<void>
  logOut: () => Promise<void>
  setToken: (token: string, tokenExpiry: string) => void
  setMerchantId: (merchantId: string) => void
}

export const AuthContext = createContext<undefined | AuthContextState>(
  undefined,
)

const initialState = Object.freeze({
  isLoading: false,
  isError: false,
  merchantId: null,
  token: null,
  tokenExpiry: null,
})

export function AuthProvider(props: AuthProviderProps) {
  const { children } = props

  const { handleApplicationError, isApiRequestError, error } = useError()
  const history = useHistory()

  const [state, setState] = usePersistedState<State>(
    PERSIST_KEY.AUTH,
    initialState,
  )

  const logIn = useCallback(
    async ({ email, password }) => {
      try {
        setState({ isLoading: true })
        const { auth } = await postRequest<{
          auth: Record<'token' | 'exp', string>
        }>({
          url: `${API_URL}${API_PATH.AUTH}`,
          body: {
            email,
            password,
            merchantId: state.merchantId,
          },
        })
        setState({ token: auth.token, tokenExpiry: auth.exp })
      } catch (e) {
        handleApplicationError(e)
      } finally {
        setState({ isLoading: false })
      }
    },
    [handleApplicationError, setState, state.merchantId],
  )

  const logOut = useCallback(async () => {
    await localforage.clear()
    setState({ ...initialState })
    history.push(ROUTES.LOGIN)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setState])

  useEffect(() => {
    if (isApiRequestError && error?.message === ERROR_MESSAGE.UNAUTHORIZED) {
      logOut().catch(noop)
    }
  }, [isApiRequestError, logOut, error])

  const setToken = useCallback(
    (token, tokenExpiry) => {
      setState({ token, tokenExpiry })
    },
    [setState],
  )

  const setMerchantId = useCallback(
    (merchantId) => {
      setState({ merchantId })
    },
    [setState],
  )

  const { isLoading, isError, merchantId, token, tokenExpiry } = state

  const contextValue = React.useMemo(
    () => ({
      isLoading,
      isError,
      merchantId,
      token,
      tokenExpiry,
      logIn,
      logOut,
      setToken,
      setMerchantId,
    }),
    [
      isLoading,
      isError,
      merchantId,
      token,
      tokenExpiry,
      logIn,
      logOut,
      setToken,
      setMerchantId,
    ],
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
