import * as Sentry from '@sentry/browser'
import { API_URL } from 'constants/api'
import { getRequest } from 'context/utils/api'

export type CompanyNameSuggestion = {
  crefonummer?: string
  identnummer?: string
  name: string
  handelsName?: string
  land: string
  bundesland?: string
  plz: string
  ort: string
  strasseHausnummer: string
}

export const getCompanyMatches = async (
  searchTerm: string,
  token: string,
): Promise<CompanyNameSuggestion[]> => {
  const url = `${API_URL}/firmenwissen/${searchTerm}`
  try {
    const response = await getRequest<{
      companyNameSuggestions: CompanyNameSuggestion[]
    }>({
      url,
      token,
    })
    return response.companyNameSuggestions || []
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        message: 'Firmenwissen company lookup failed',
        url,
        searchTerm,
      },
    })

    throw error
  }
}
