import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import industries from 'data/industries.json'
import { requiredValidator } from 'utils/forms/validators'
import { Field as FormikField } from 'formik'
import { AutocompleteComponent } from 'components/Fields/AutocompleteComponent'
import { useLanguage } from '../../context/Language'

export const IndustryParentCode = ({ name = 'industryParentCode' }) => {
  const { locale } = useLanguage()
  const industryGroups = useMemo(
    () =>
      _.map(_.get(industries, locale), (value, key) => ({
        value: key,
        message: value.label,
      })),
    [locale],
  )
  return (
    <FormikField
      component={AutocompleteComponent}
      name={name}
      id={name}
      data-testid="formFieldParentCode"
      label={<FormattedMessage id="fields.industryGroup" />}
      validate={requiredValidator}
      options={industryGroups}
    />
  )
}
