import * as Sentry from '@sentry/browser'
import { generatePath } from 'react-router'
import { useCallback, useEffect, useState } from 'react'
import { ROUTES } from 'constants/routes'
import useQueryParams from 'hooks/useQueryParams'
import localforage from 'localforage'
import { noop } from 'lodash'
import { isLinkIntegration } from 'setup/tenant'
import { getPersisted, setPersisted } from 'context/utils/persist'
import { PANTHER_SERVICES_URL } from 'constants/api'
import { putRequest } from 'context/utils/api'
import { useAuth } from 'context/Auth'
import { useLoanOffer } from 'context/LoanOffer'
import { useLoanApplication } from 'context/LoanApplication'
import type { LoanApplication } from 'types/loanApplication'
import { useLoans } from 'context/Loans'
import { useNavigation } from './useNavigation'

const decodeMerchantInfo = async (merchantInfo) => {
  try {
    const url = `${PANTHER_SERVICES_URL}/merchant-integration`
    const response = await putRequest<{
      auth: Record<'token' | 'exp', string>
      loanApplication: LoanApplication
    }>({
      url,
      body: {
        merchantInfo,
      },
    })
    return response
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        message: 'Decoding of merchantInfo failed',
        merchantInfo,
      },
    })

    throw error
  }
}

export const useMerchantIntegration = () => {
  const {
    setLoanOffer,
    setRevenue,
    fetchSelectedLoanOffer,
    setIsLoading: setIsloadingLoanOffers,
    isLoading: isLoadingLoanOffers,
  } = useLoanOffer()
  const { loans, currentLoan } = useLoans()
  const { setToken, token } = useAuth()
  const { setLoanApplication } = useLoanApplication()
  const { navigateTo } = useNavigation()
  const {
    query: { merchantInfo },
  } = useQueryParams({ decode: false })
  const [isInitializing, setIsInitializing] = useState(false)

  const initFromMerchantInfo = useCallback(
    async (merchantInfoBlob: string | string[]) => {
      try {
        const { auth, loanApplication } = await decodeMerchantInfo(
          merchantInfoBlob,
        )

        setToken(auth.token, auth.exp)

        if (auth.token) {
          setRevenue({
            value: String(loanApplication.approximateSales),
            currency: 'EUR',
          })

          if (loanApplication) {
            setLoanApplication(loanApplication)

            if (
              loanApplication.status === 'initial' &&
              loanApplication.offers
            ) {
              setLoanOffer(loanApplication.offers)
            } else {
              await fetchSelectedLoanOffer(loanApplication.id)
            }
          }
        }
      } catch (error: unknown) {
        // @ts-expect-error Property 'cause' does not exist on type 'unknown'
        switch (error.cause) {
          case 'MERCHANT_INFO_TIMEOUT':
            navigateTo(ROUTES.LINK_TIMED_OUT)
            break
          case 'INVALID_MERCHANT_INFO':
            navigateTo(ROUTES.MERCHANT_INTEGRATION_ERROR)
            Sentry.captureException(error, {
              extra: {
                // @ts-expect-error Property 'cause' does not exist on type 'unknown'
                message: error.cause,
              },
            })
            break
          case 'INVALID_LEGAL_FORM':
            navigateTo(ROUTES.LOAN_REJECTION_LEGALFORM)
            break
          case 'LE_REGION':
            navigateTo(ROUTES.LOAN_REJECTION_REGION)
            break
          case 'REJECTED_COOLOFF_PERIOD':
            navigateTo(ROUTES.LOAN_REJECTION_LEGALFORM)
            break
          case 'LE_REVENUE':
            navigateTo(ROUTES.LOAN_REJECTION_REVENUE)
            break
          case 'LE_RUN_TIME':
            navigateTo(ROUTES.LOAN_REJECTION_TIME_OF_RUN_BUSINESS)
            break
          default:
            navigateTo(ROUTES.ERROR)
        }
      } finally {
        setIsloadingLoanOffers(false)
        setIsInitializing(false)
        await setPersisted('merchantIntegrationPersisted', true)
      }
    },
    [
      fetchSelectedLoanOffer,
      navigateTo,
      setIsloadingLoanOffers,
      setLoanApplication,
      setLoanOffer,
      setRevenue,
      setToken,
    ],
  )

  const initMerchantIntegration = useCallback(async () => {
    if (merchantInfo) {
      // allow a new blob to clear a previously generated one by clearing the localforage state
      await localforage.clear()
      await initFromMerchantInfo(merchantInfo)
    }
  }, [initFromMerchantInfo, merchantInfo])

  useEffect(() => {
    if (isLinkIntegration) {
      const checkForMerchantInfo = async () => {
        const isPersisted = await getPersisted('merchantIntegrationPersisted')
        if (
          !merchantInfo &&
          !isInitializing &&
          !isLoadingLoanOffers &&
          !isPersisted &&
          !loans
        ) {
          navigateTo(ROUTES.LINK_WITHOUT_BLOB)
        }

        if (
          !isInitializing &&
          token &&
          currentLoan &&
          currentLoan?.status !== 'repaid'
        ) {
          navigateTo(
            generatePath(ROUTES.MERCHANT_PORTAL, {
              id: currentLoan?.id,
            }) as any,
          )
        }
      }

      checkForMerchantInfo().catch(noop)
    }
  }, [
    merchantInfo,
    isInitializing,
    isLoadingLoanOffers,
    navigateTo,
    currentLoan,
    loans,
    token,
  ])

  useEffect(() => {
    if (isLinkIntegration) {
      if (!isInitializing) {
        if (merchantInfo) {
          setIsInitializing(true)
          setIsloadingLoanOffers(true)
          initMerchantIntegration().then().catch(noop)
        }
      }
    }
  }, [
    initMerchantIntegration,
    isInitializing,
    navigateTo,
    setIsloadingLoanOffers,
    merchantInfo,
  ])

  return { isInitializing }
}
