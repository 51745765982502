import React, { ReactElement, useRef, useState } from 'react'
import {
  TextField,
  Popper,
  Typography,
  Autocomplete,
  InputAdornment,
  Box,
  Paper,
  CircularProgress,
  AutocompleteRenderInputParams,
  AutocompleteProps,
  PopperProps,
  SxProps,
  AutocompleteRenderOptionState,
} from '@mui/material'
import { FieldProps } from 'formik'
import { styled } from '@mui/system'
import { useHint, useFieldError } from 'hooks'
import { InputGrid, InputWrapper } from 'components/Input'
import { cleanFormikProps } from 'components/Form'
import { SelectedIcon, SearchIcon, PopupIcon } from './icons'

type Option = {
  message: string
  value: string
}

type AutocompleteComponentProps<T extends Option> = DeepPartial<FieldProps> &
  Pick<
    AutocompleteProps<T, false, true, false>,
    'options' | 'placeholder' | 'disabled' | 'onChange'
  > & {
    id?: string
    mb?: number
    name?: string
    inputContainerSx?: SxProps
    inputWrapperSx?: SxProps
    hintContainerSx?: SxProps
    boxed?: boolean
    hint: ReactElement
    label?: React.ReactNode
    loading?: boolean
  }

// Custom list background with color border
const OurPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  borderWidth: '1px',
  borderStyle: 'solid',
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  borderColor: theme.palette.global.icoA.main,
  borderTop: 'none',
  margin: '-1px',
}))

const searchIconAdornment = (
  <InputAdornment position="start">
    <Box width="16px" height="16px">
      <SearchIcon />
    </Box>
  </InputAdornment>
)

const spinnerAdornment = (
  <Box sx={{ position: 'absolute', right: 64, top: 28 }}>
    <CircularProgress size={16} />
  </Box>
)

export function AutocompleteComponent<T extends Option>({
  form: { touched, errors, submitCount, setFieldValue, setTouched },
  field: { name, value, onBlur: fieldOnBlur = () => undefined },
  options = [],
  label,
  placeholder,
  hint,
  mb = 3,
  boxed,
  inputContainerSx = {},
  inputWrapperSx = {
    pr: 3,
  },
  hintContainerSx = {},
  disabled,
  loading,
  ...restProps
}: AutocompleteComponentProps<T>) {
  const [focused, setFocused] = useState(false)
  const anchorRef = useRef(null)
  const hintOffset = hint ? '30px' : '0px'

  const fieldValue = options.find((option) => option.value === value) || null

  const autocompleteProps = cleanFormikProps(restProps)

  const [hasFieldError, fieldError] = useFieldError({
    touched,
    name,
    submitCount,
    errors,
  })

  const { hintComponent, TriggerComponent } = useHint({
    hint,
    boxed,
    error: hasFieldError,
    errorId: fieldError,
  })

  const hintButtonAdornment = (
    <TriggerComponent
      sx={{ position: 'absolute', right: 0, top: 'calc(50% - 14px)' }}
    />
  )

  const popupIcon = (
    <Box width="24px" height="24px">
      <PopupIcon disabled={disabled} data-cy={`${restProps.id}-popup`} />
    </Box>
  )

  // Custom TextInput with search icon left on focus and hint button right
  const InputComponent = (
    inputComponentProps: AutocompleteRenderInputParams,
  ) => (
    <TextField
      {...inputComponentProps}
      variant="standard"
      label={label}
      name={name}
      placeholder={placeholder}
      error={hasFieldError}
      InputProps={{
        ...inputComponentProps.InputProps,
        disableUnderline: true,
        startAdornment: focused && searchIconAdornment,
        endAdornment: (
          <>
            {/* loading spinner */}
            {loading ? spinnerAdornment : null}
            {/* x and v icons coming from Autocomplete */}
            {inputComponentProps.InputProps.endAdornment}
            {/* ? button */}
            {hintButtonAdornment}
          </>
        ),
        sx: {
          paddingTop: '16px',
          paddingLeft: '16px',
          '&& .MuiAutocomplete-input': {
            paddingTop: '10px',
          },
        },
      }}
      InputLabelProps={{
        sx: { whiteSpace: 'normal' },
      }}
      onFocus={() => setFocused(true)}
      onBlur={(event) => {
        fieldOnBlur(event)
        setFocused(false)
      }}
    />
  )

  // Adapt popper width and alignment to our InputWrapper
  const PopperComponent = (popperProps: PopperProps) => (
    <Popper
      {...popperProps}
      placement="bottom-start"
      modifiers={[
        {
          // Prevent popper from changing location on scroll
          name: 'flip',
          enabled: false,
        },
      ]}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      style={anchorRef.current ? { width: anchorRef.current?.clientWidth } : {}}
    />
  )

  // Custom list item with checkbox icon
  const OptionComponent = (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState,
  ) => (
    <Box
      {...optionProps}
      component="li"
      sx={{
        '&[aria-selected="true"]:not(:hover)': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <Box sx={{ width: '16px', height: '16px', pr: 2 }}>
        {state.selected && <SelectedIcon />}
      </Box>
      <Typography variant="body1">{option.message}</Typography>
    </Box>
  )

  return (
    <InputGrid mb={mb}>
      <InputGrid.Input sx={inputContainerSx}>
        <InputWrapper
          error={hasFieldError}
          sx={inputWrapperSx}
          boxed={boxed}
          focus={focused}
          anchorRef={anchorRef}
        >
          <Autocomplete
            multiple={false}
            disableClearable={false}
            disablePortal
            openOnFocus
            options={options}
            disabled={disabled}
            value={fieldValue}
            popupIcon={popupIcon}
            renderInput={InputComponent}
            renderOption={OptionComponent}
            getOptionLabel={(option) => option.message}
            PopperComponent={PopperComponent}
            PaperComponent={OurPaper}
            onChange={(_, option) => {
              setFieldValue?.(name, option?.value)
            }}
            onBlur={() => {
              setTouched?.({ ...touched, [name]: true })
            }}
            sx={{
              // Offset the clear and popup icons to make space for the ? button
              '& .MuiAutocomplete-endAdornment': {
                right: hintOffset,
              },
              // Reduce the size of the popup indicator's hover circle
              '& .MuiIconButton-root': {
                padding: '2px',
              },
            }}
            data-testid="autocomplete"
            {...(autocompleteProps as AutocompleteProps<T, false, true, false>)}
          />
        </InputWrapper>
      </InputGrid.Input>
      <InputGrid.Hint sx={hintContainerSx} boxed={boxed}>
        {hintComponent}
      </InputGrid.Hint>
    </InputGrid>
  )
}
