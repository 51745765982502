import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { useHistory, generatePath } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useMenu } from 'hooks/useMenu'
import { ROUTES } from 'constants/routes'
import { useLoans } from 'context/Loans'

const formatApprovedAtDate = (date: string) => date?.split('T')[0]

export const LoanSelector = () => {
  const { loans, selectedLoan, setSelectedLoan } = useLoans()
  const { TriggerComponent, Menu, MenuItem, anchorElement, handleClose } =
    useMenu()
  const history = useHistory()

  if (!selectedLoan) return null

  return (
    <>
      {loans && (
        <>
          <Box>
            <Flex ref={anchorElement} my={[3, 3, 0]} p={[0, 0, 3]}>
              <TriggerComponent>
                <Flex
                  flexDirection="column"
                  alignItems={['flex-start', 'flex-start', 'flex-end']}
                  mr={1}
                >
                  <Typography variant="body1" fontWeight="bold">
                    <FormattedMessage id="loanSelector.title" />
                  </Typography>
                  <Typography variant="caption" color="text.subtle.main">
                    {formatApprovedAtDate(selectedLoan.approvedAt)}, ID :{' '}
                    {selectedLoan.humanReadableId}
                  </Typography>
                </Flex>
              </TriggerComponent>
            </Flex>
          </Box>
          <Menu>
            {loans.map((loan) => {
              const { id, humanReadableId, approvedAt } = loan
              return (
                <MenuItem
                  key={id}
                  value={id}
                  onClick={() => {
                    setSelectedLoan(loan)
                    history.push(
                      generatePath(ROUTES.MERCHANT_PORTAL, {
                        id,
                      }),
                    )
                    handleClose()
                  }}
                >
                  {formatApprovedAtDate(approvedAt)}, ID : {humanReadableId}
                </MenuItem>
              )
            })}
          </Menu>
        </>
      )}
    </>
  )
}
