import React from 'react'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { CircularProgressbarWithChildren as Progress } from 'components/ProgressBar'
import { format } from 'date-fns'
import { getName } from 'i18n-iso-countries'
import { ReactComponent as PersonIdIcon } from 'images/icons/person-id.svg'
import { parseAddress, parseTitle, hasAnyPartOfAddress } from 'utils'
import activeTenant from 'setup/tenant'
import { DATE_FORMAT } from 'constants/format'
import { useLanguage } from 'context/Language'

const ICON_WIDTH = 32
const ICON_MARGIN_RIGHT = 16

export const OwnerBox = ({
  applicantOwner,
  title,
  firstName,
  lastName,
  legalRepresentative,
  ultimateBeneficial,
  email,
  sharesPercent,
  dateOfBirth,
  nationality,
  address = {},
}) => {
  const { locale } = useLanguage()

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        p: 3,
        flexGrow: 1,
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'row', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            flex: '1 1 50%',
            pb: [3, 0, 0],
            borderRight: (theme) => [
              'none',
              email || hasAnyPartOfAddress(address)
                ? theme.borders.primary
                : 'none',
              email || hasAnyPartOfAddress(address)
                ? theme.borders.primary
                : 'none',
            ],
            borderBottom: (theme) => [theme.borders.primary, 'none', 'none'],
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              mr: `${ICON_MARGIN_RIGHT}px`,
              minWidth: '0px',
            }}
          >
            <Box
              sx={{
                width: `${ICON_WIDTH}px`,
                height: `${ICON_WIDTH}px`,
                minWidth: `${ICON_WIDTH}px`,
                color: (theme) => theme.palette.global.icoA.main,
              }}
            >
              <PersonIdIcon />
            </Box>
            <Box
              sx={{
                width: `${ICON_WIDTH}px`,
                height: `${ICON_WIDTH}px`,
                minWidth: `${ICON_WIDTH}px`,
              }}
            >
              {ultimateBeneficial && sharesPercent && (
                <Progress
                  strokeWidth={6}
                  paths={[
                    {
                      value: Math.floor(sharesPercent),
                      styles: {
                        pathColor: activeTenant.theme.palette.global.icoA.main,
                        strokeLinecap: 'butt',
                      },
                    },
                  ]}
                >
                  <Box
                    sx={{
                      fontWeight: 500,
                      fontSize: sharesPercent === 100 ? '9px' : '10px',
                      color: 'text.heading.main',
                      fontFamily: 'IBM Plex Sans, sans-serif',
                    }}
                  >
                    {sharesPercent}%
                  </Box>
                </Progress>
              )}
            </Box>
          </Flex>
          <Flex sx={{ flexDirection: 'column' }}>
            <Flex sx={{ flexWrap: 'wrap', mx: -2, ml: 1 }}>
              {applicantOwner && (
                <Box sx={{ color: 'text.off.main', mr: 1 }}>
                  <Typography variant="h5">
                    <FormattedMessage id="stakeholders.you" />
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  color:
                    !title && !firstName && !lastName
                      ? 'text.off.main'
                      : 'text.heading.main',
                }}
              >
                <Typography variant="h5">
                  {!title && !firstName && !lastName ? (
                    <FormattedMessage id="stakeholders.anotherPersonDetails" />
                  ) : (
                    <>
                      <FormattedMessage id={parseTitle(title)} /> {firstName}{' '}
                      {lastName}
                    </>
                  )}
                </Typography>
              </Box>
            </Flex>
            <Flex sx={{ flexDirection: 'column', mt: 'auto', ml: 1 }}>
              {legalRepresentative && (
                <Box sx={{ mt: [0, 5, 5] }}>
                  <Typography variant="caption">
                    <FormattedMessage id="stakeholders.legalRep" />
                  </Typography>
                </Box>
              )}
              {ultimateBeneficial && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="caption">
                    <FormattedMessage id="stakeholders.ubo" />
                  </Typography>
                </Box>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          sx={{
            pl: [`${ICON_WIDTH + ICON_MARGIN_RIGHT}px`, 3, 3],
            flex: '1 1 50%',
            pt: [3, 0, 0],
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Flex
            sx={{ flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <Box sx={{ color: 'text.normal.main' }}>
              <Typography variant="caption">{email}</Typography>
            </Box>
            <Box
              sx={{
                mt: 3,
                whiteSpace: 'pre-line',
                color: 'text.normal.main',
              }}
            >
              <Typography variant="caption">
                {hasAnyPartOfAddress(address)
                  ? parseAddress(address, locale)
                  : ''}
              </Typography>
            </Box>
            <Flex sx={{ mt: 3 }}>
              <Box sx={{ color: 'text.subtle.main' }}>
                <Typography variant="caption">
                  <FormattedMessage id="ownerBox.dateOfBirth" />
                </Typography>
              </Box>
              <Box sx={{ color: 'text.normal.main', ml: 1 }}>
                <Typography variant="caption">
                  {format(new Date(dateOfBirth), DATE_FORMAT)}
                </Typography>
              </Box>
            </Flex>
            <Flex>
              <Box sx={{ color: 'text.subtle.main' }}>
                <Typography variant="caption">
                  <FormattedMessage id="ownerBox.nationality" />
                </Typography>
              </Box>
              <Box sx={{ color: 'text.normal.main', ml: 1 }}>
                <Typography variant="caption">
                  {getName(nationality, locale)}
                </Typography>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
