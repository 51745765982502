import querystring from 'query-string'
import { useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const useQueryParams = (options?: querystring.ParseOptions) => {
  const { pathname, search } = useLocation()
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { push } = useHistory()

  const clear = useCallback(() => {
    push(pathname)
  }, [push, pathname])

  const parsedSearch = useMemo(() => {
    const mergedOptions = {
      parseNumbers: true,
      parseBooleans: true,
      ...options,
    }

    return querystring.parse(search, mergedOptions)
  }, [search, options])

  return { query: parsedSearch, clear }
}

export default useQueryParams
