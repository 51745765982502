import React from 'react'
import { Box, Flex } from 'components/structural'
import { Typography } from '@mui/material'

type PropertyProps = {
  title: React.ReactNode
  value: React.ReactNode
}

export const Property = (props: PropertyProps) => {
  const { title, value } = props

  return (
    <Flex>
      <Box mb={[2, 2, '10px']} mr="20px">
        <Typography variant="body1">{value}</Typography>
      </Box>
      <Typography variant="body1"> {title}</Typography>
    </Flex>
  )
}
