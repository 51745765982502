import React from 'react'
import { Box } from '@mui/material'

export const Divider = ({ sx, ...rest }) => (
  <Box
    component="hr"
    sx={{
      border: 'none',
      backgroundColor: 'global.line.main',
      height: '1px',
      width: '100%',
      my: 4,
      ...sx,
    }}
    {...rest}
  />
)
