import React, {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import { IntlProvider } from 'react-intl'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-GB'
import trLocale from 'date-fns/locale/tr'
import { DEFAULT_LOCALE, translationMessages } from 'i18n'
import { getPersisted, setPersisted } from 'context/utils/persist'
import { useSetState } from 'context/utils/hooks'
import { PERSIST_KEY } from 'context/constants'
import { ConfiguredLocales } from 'tenants'
import { noop } from 'lodash'

declare global {
  interface Window {
    xs2a: { lang: (lang: string) => void }
  }
}

type LanguageProviderProps<T extends string> = {
  children: React.ReactNode
  messages?: Record<T, string>
}

type KeyOfLanguage = keyof typeof LANGUAGE_LOCALE

type State = {
  dateLocale: KeyOfLanguage
  locale: ConfiguredLocales
  language: typeof LANGUAGE_CODE[KeyOfLanguage]
}

type LanguageContextState = State & {
  handleSwitchLanguage: (locale: ConfiguredLocales) => void
}

export const LanguageContext = createContext<undefined | LanguageContextState>(
  undefined,
)

const LANGUAGE_LOCALE = {
  EN: enLocale,
  DE: deLocale,
  TR: trLocale,
}

const LANGUAGE_CODE = {
  EN: 'en-US',
  DE: 'de-DE',
  TR: 'tr-TR',
} as const

const initialState = Object.freeze({
  dateLocale: LANGUAGE_LOCALE[DEFAULT_LOCALE],
  locale: DEFAULT_LOCALE,
  language: LANGUAGE_CODE[DEFAULT_LOCALE.toUpperCase()],
})

export const LanguageProvider = <T extends string>(
  props: LanguageProviderProps<T>,
) => {
  const { children, messages } = props

  const [state, setState] = useSetState<State>({ ...initialState })

  useEffect(() => {
    const hydrateState = async () => {
      const persistedState = await getPersisted<State>(PERSIST_KEY.LANGUAGE)
      setState({
        ...initialState,
        ...persistedState,
        dateLocale: persistedState?.locale
          ? LANGUAGE_LOCALE[persistedState.locale]
          : LANGUAGE_LOCALE[initialState.locale],
      })
    }
    hydrateState().catch(noop)
  }, [setState])

  useEffect(() => {
    setPersisted(PERSIST_KEY.LANGUAGE, state).catch(noop)
  }, [state])

  const handleSwitchLanguage = useCallback(
    (locale: ConfiguredLocales) => {
      setState({
        locale,
        dateLocale: LANGUAGE_LOCALE[locale],
        language: LANGUAGE_CODE[locale.toUpperCase()],
      })
    },
    [setState],
  )

  const { dateLocale, locale, language } = state

  const contextValue = useMemo(
    () => ({ dateLocale, locale, language, handleSwitchLanguage }),
    [handleSwitchLanguage, dateLocale, locale, language],
  )

  useEffect(() => {
    // update FTS widget's language everytime locale changes
    window.xs2a?.lang(locale)
  }, [locale])

  return (
    <IntlProvider
      locale={state?.locale}
      messages={messages ?? translationMessages[state.locale]}
      onError={(err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          // eslint-disable-next-line no-console
          console.warn('Missing translation', err.message)
        }
      }}
    >
      <LanguageContext.Provider value={contextValue}>
        {React.Children.only(children)}
      </LanguageContext.Provider>
    </IntlProvider>
  )
}

export function useLanguage() {
  const context = useContext(LanguageContext)
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}
