import React from 'react'
import { Box } from 'components/structural'

import { ReactComponent as Pdf } from 'images/icons/pdf.svg'
import { ReactComponent as Edit } from 'images/icons/edit.svg'

export const PdfIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          // @ts-expect-error Property 'borders' does not exist on type 'Theme'
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Pdf />
  </Box>
)

export const EditIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          // @ts-expect-error Property 'borders' does not exist on type 'Theme'
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Edit />
  </Box>
)
