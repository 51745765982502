import React from 'react'
import { Box } from 'components/structural'

import { ReactComponent as XWhite } from 'images/icons/x-white.svg'

export const XIcon = () => (
  <Box width="24px">
    <XWhite />
  </Box>
)
