import React from 'react'
import { Layout } from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { ReactComponent as LoginIcon } from 'images/login.svg'
import { useAuth } from '../../context/Auth'
import { LoginForm } from './components/LoginForm'

export const Login = () => {
  const { logIn } = useAuth()
  return (
    <Layout viewHeader={<ViewHeader icon={<LoginIcon />} id="login.header" />}>
      <Layout.MiddleAndRight>
        <LoginForm onSubmit={logIn} />
      </Layout.MiddleAndRight>
    </Layout>
  )
}
