import { get } from 'lodash'
import { FormikErrors, FormikTouched } from 'formik'

type UseFieldErrorProps = {
  touched: FormikTouched<Record<string, boolean>>
  name: string
  errors: FormikErrors<Record<string, string>>
  submitCount: number
}

export const useFieldError = ({
  touched,
  name,
  errors,
  submitCount,
}: UseFieldErrorProps): [boolean, string | undefined] => {
  const fieldTouched = get(touched, name)
  const fieldError = get(errors, name)
  const wasSubmitted = submitCount > 0
  const hasFieldError = (fieldTouched || wasSubmitted) && !!fieldError

  return [hasFieldError, fieldError]
}
