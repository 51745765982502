import localforage from 'localforage'
import { noop } from 'lodash'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useSetState } from './hooks'

export const setPersisted = async <T>(
  persistKey: string,
  persistedState: T,
) => {
  await localforage.setItem(persistKey, persistedState)
}

export const getPersisted = async <T>(persistKey: string) => {
  const persistedState = await localforage.getItem<T>(persistKey)
  return persistedState
}

export const removePersisted = async (persistKey: string) => {
  await localforage.removeItem(persistKey)
}

export const usePersistedState: <T>(
  persistKey: string,
  initial: T,
) => [T & { isHydrating: boolean }, Dispatch<SetStateAction<T>>] = <T>(
  persistKey: string,
  initial: T,
) => {
  const [state, setState] = useSetState(initial)
  const [isHydrating, setisHydrating] = useState(true)

  useEffect(() => {
    const hydrateState = async () => {
      const persistedState = await getPersisted<T>(persistKey)
      if (persistedState) {
        setState({
          ...initial,
          ...persistedState,
        })
      }
      setisHydrating(false)
    }

    hydrateState().catch(noop)
  }, [setState, persistKey, initial])

  useEffect(() => {
    if (!isHydrating) {
      setPersisted(persistKey, state).catch(noop)
    }
  }, [state, isHydrating, persistKey])

  return [{ ...state, isHydrating }, setState]
}
