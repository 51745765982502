import React from 'react'
import Layout from 'components/Layout'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FormattedMessage } from 'react-intl'

export default function Imprint() {
  return (
    <Layout
      topHeaderProps={{
        withLogin: false,
      }}
    >
      <Layout.Middle style={{ whiteSpace: 'pre-line' }}>
        <Typography variant="h5">
          <FormattedMessage id="imprint.title" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="imprint.subtitle" />
        </Typography>
        <Box pt={3}>
          <Typography variant="h5">
            <FormattedMessage id="imprint.banxware.title" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="imprint.banxware.address" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="imprint.banxware.phoneNumbers" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="imprint.banxware.email" />
          </Typography>
        </Box>
        <Box pt={3}>
          <Typography variant="body1">
            <FormattedMessage id="imprint.banxware.content" />
          </Typography>
        </Box>
        <Box pt={3}>
          <Typography variant="h5">
            <FormattedMessage id="imprint.banxwareSales.title" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="imprint.banxwareSales.address" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="imprint.banxwareSales.email" />
          </Typography>
        </Box>
        <Box pt={3}>
          <Typography variant="body1">
            <FormattedMessage id="imprint.banxwareSales.content" />
          </Typography>
        </Box>
      </Layout.Middle>
    </Layout>
  )
}
