import React, { useRef, useState } from 'react'
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Flex } from 'components/structural'
import { ArrowDownIcon } from 'components/icons'

import { ButtonRaw } from 'components/buttons'

import { SelectedIcon } from './icons'

const useMenuStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    boxShadow: `0px 16px 32px rgba(53, 89, 131, 0.2), inset 0px 2px 0px ${theme.palette.global.icoA.main}`,
    paddingTop: '2px',
  },
  list: {
    minWidth: '320px',
    padding: 0,
  },
}))

const useMenuItemStyles = makeStyles({
  root: {
    padding: 0,
  },
})

export const useMenu = ({
  iconOpen = <ArrowDownIcon />,
  iconClose = <ArrowDownIcon />,
  iconSize = '16px',
  animateIcon = true,
  anchorVerticalTop = 60,
} = {}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const anchorElement = useRef(null)
  const handleClick = () => {
    setAnchorEl(anchorElement.current)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const Menu = ({ children, ...rest }) => (
    <MuiMenu
      classes={useMenuStyles()}
      elevation={0}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: anchorVerticalTop,
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...rest}
    >
      {children}
    </MuiMenu>
  )
  const MenuItem = React.forwardRef(
    ({ children, selected, icon, sx, ...rest }, ref) => (
      <MuiMenuItem classes={useMenuItemStyles()} {...rest} {...{ ref }}>
        <Flex width="100%" p={4} {...{ sx }}>
          <Box width="24px" height="24px" p="2px" mr={3}>
            {selected && <SelectedIcon />}
            {icon && !selected && icon}
          </Box>
          <Typography variant="body1" color="text.heading.main">
            {children}
          </Typography>
        </Flex>
      </MuiMenuItem>
    ),
  )

  const TriggerComponent = ({ children, ...rest }) => (
    <ButtonRaw onClick={handleClick} {...rest}>
      {children}
      <Box
        sx={{
          position: 'relative',
          top: '2px',
          transform: anchorEl && animateIcon ? 'rotate(180deg)' : 'none',
          cursor: 'pointer',
        }}
        height={iconSize}
        width={iconSize}
        data-testid="triggerComponent"
      >
        {anchorEl ? iconClose : iconOpen}
      </Box>
    </ButtonRaw>
  )

  return {
    Menu,
    MenuItem,
    TriggerComponent,
    handleClose,
    anchorElement,
  }
}
