import React, { useState, useEffect } from 'react'
import { Box } from 'components/structural'
import { useIntl } from 'react-intl'
import { isMobile } from 'react-device-detect'

import { copyToClipboard } from 'utils'

import { Wrapper } from './Wrapper'

export const Copy = ({ text }) => {
  const [copied, setCopied] = useState(false)
  const [animate, setAnimate] = useState(false)
  const intl = useIntl()
  const tooltipMessage = intl.formatMessage({
    id: 'loanApplicationApproved.copyReferenceNumber',
  })
  const tooltipMessageMobile = intl.formatMessage({
    id: 'copy.copied',
  })
  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        setCopied(true)
        setAnimate(false)
      }, 150)
    }
  }, [animate])
  return (
    <Box sx={{ position: 'relative' }}>
      <Wrapper
        aria-label={copied || isMobile ? tooltipMessageMobile : tooltipMessage}
        onMouseLeave={() => {
          setTimeout(() => setCopied(false), 150)
        }}
        onClick={() => {
          setAnimate(true)
          copyToClipboard(text)
        }}
        tabIndex={0}
        sx={{
          '&::before': {
            backgroundColor: 'global.icoA.main',
            px: 3,
            py: 2,
            ...(animate
              ? {
                  opacity: '0 !important',
                }
              : {}),
          },
          '&::after': {
            borderBottomColor: 'global.icoA.main',
            ...(animate
              ? {
                  opacity: '0 !important',
                }
              : {}),
          },
        }}
      >
        <Box width="24px" height="24px" />
      </Wrapper>
    </Box>
  )
}
