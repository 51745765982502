import React from 'react'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import { Button, BackButton } from 'components/buttons'
import { TextFieldComponent } from 'components/Fields/TextFieldComponent'
import { ROUTES } from 'constants/routes'
import {
  requiredValidator,
  composeValidators,
  passwordStrengthValidator,
} from 'utils/forms/validators'
import {
  Email,
  LegalRepresentative,
  OptinMerchantTerms,
  OptinMerchantAccount,
  LegalForm,
  FirstName,
  LastName,
  Salutation,
  DateOfBirth,
} from 'components/Fields'
import { Flex, Box } from 'components/structural'
import { useNavigation } from 'routing/hooks/useNavigation'
import Layout from 'components/Layout'
import { ValidationList } from 'components/ValidationList'

export const SignUpForm = React.memo(
  ({ handleSignUp, initialValues, isLoading }) => {
    const { navigateTo } = useNavigation()

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSignUp}
      >
        {({
          isValid,
          submitCount,
          setFieldValue,
          values: { password, passwordConfirmation, email },
        }) => (
          <FormikForm data-testid="pantherSignUpForm" style={{ width: '100%' }}>
            <Flex
              flexDirection="column"
              flex="1 0 auto"
              width="100%"
              pb="100px"
            >
              <>
                <Salutation name="title" />
                <FirstName name="firstName" />
                <LastName name="lastName" />
              </>

              <Email
                label={<FormattedMessage id="fields.email" />}
                name="email"
                onChange={(event) => {
                  setFieldValue('email', event.target?.value?.replace(/ /g, ''))
                }}
              />
              <FormikField
                onChange={(event) => {
                  setFieldValue(
                    'emailConfirmation',
                    event.target?.value?.replace(/ /g, ''),
                  )
                }}
                component={TextFieldComponent}
                data-testid="formFieldEmailConfirmation"
                name="emailConfirmation"
                type="email"
                label={<FormattedMessage id="fields.emailRepeat" />}
                validate={composeValidators(requiredValidator, (value) =>
                  value !== email ? 'validators.emailsDoNotMatch' : undefined,
                )}
              />
              <FormikField
                component={TextFieldComponent}
                data-testid="formFieldPassword"
                name="password"
                type="password"
                label={<FormattedMessage id="fields.passwordNew" />}
                autoComplete="new-password"
                validate={composeValidators(
                  requiredValidator,
                  passwordStrengthValidator,
                )}
              />
              <FormikField
                component={TextFieldComponent}
                data-testid="formFieldPasswordConfirmation"
                autoComplete="new-password"
                name="passwordConfirmation"
                type="password"
                label={<FormattedMessage id="fields.passwordNewRepeat" />}
                validate={composeValidators(requiredValidator, (value) =>
                  value !== password
                    ? 'validators.passwordsDoNotMatch'
                    : undefined,
                )}
              />
              <ValidationList
                password={password}
                passwordConfirmation={passwordConfirmation}
              />
              <DateOfBirth name="dateOfBirth" />
              <LegalForm
                name="businessDetails.legalForm"
                data-testid="formFieldLegalForm"
              />
              <Layout.MiddleAndRightMain>
                <Box mb={5}>
                  <Typography variant="h5">
                    <FormattedMessage id="signUp.termsHeader" />
                  </Typography>
                </Box>
              </Layout.MiddleAndRightMain>
              <OptinMerchantTerms name="optinTerms" />
              <OptinMerchantAccount />
              <LegalRepresentative
                name="legalRepresentative"
                mb={5}
                hint={null}
              />
              <Layout.MiddleAndRightMain>
                <Flex>
                  <BackButton onClick={() => navigateTo(ROUTES.LOAN_OFFERS)} />
                  <Button
                    loading={isLoading}
                    type="submit"
                    disabled={(!isValid && submitCount > 0) || isLoading}
                    data-testid="submitButton"
                  >
                    <FormattedMessage id="panther2Go.signup.buttonText" />
                  </Button>
                </Flex>
              </Layout.MiddleAndRightMain>
            </Flex>
          </FormikForm>
        )}
      </Formik>
    )
  },
)
