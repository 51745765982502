import React from 'react'
import { Box, Flex } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

export const BankInfoBox = ({ headerId, contentId, icon, children }) => (
  <Flex
    flexDirection="column"
    sx={{ border: (theme) => theme.borders.primary }}
    data-testid="bank-linked-header"
  >
    <Box flex="1 1 auto" py="28px" pl={3} backgroundColor="global.bgB.main">
      <Typography variant="body1" style={{ fontWeight: 500 }}>
        <FormattedMessage id={headerId} />
      </Typography>
    </Box>
    <Flex
      flex="1 1 auto"
      p={3}
      sx={{
        borderTop: (theme) => theme.borders.primary,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box width="24px" mr={3} alignSelf="center">
        {icon}
      </Box>
      {contentId && (
        <Typography variant="body2">
          <FormattedMessage id={contentId} />
        </Typography>
      )}
      {children}
    </Flex>
  </Flex>
)
