import React from 'react'
import { Box } from 'components/structural'

//
// Generic component for handling grid
// Works by default for 4 columns
// If you want to change number of columns like <Grid columns={3}>
// be sure to adjust Grid.Column as well, e.g.
//
// <Grid.Column
//   sx={{
//     gridColumnEnd: ['span 3', 'span 3', 'span 2']
//   }}
// >
//

export const Grid = ({ children, columns = 4, sx, ...rest }) => (
  <Box
    sx={{
      display: 'grid',
      width: '100%',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridGap: '48px',
      rowGap: 0,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

const Column = ({ children, span = 4, start = 1, sx, ...rest }) => (
  <Box
    sx={{
      gridColumnStart: [1, 1, start],
      gridColumnEnd: ['span 4', 'span 4', `span ${span}`],
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

Grid.Column = Column
