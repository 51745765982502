import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Grid } from 'components/Grid'
import { Flex } from 'components/structural'
import { NavigationButton } from 'components/buttons'
import { TELECASH_LINK } from 'tenants/telecash'
import { isTeleCash } from 'setup/tenant'
import { PAYONE_LINK } from 'tenants/payone'

export const RedirectToMerchantOnlinePortal = () => (
  <>
    <Flex flexDirection="column" width="100%" alignItems="center">
      <Grid columns={3}>
        <Grid.Column
          sx={{
            mt: 4,
            mb: 4,
          }}
        >
          <Typography variant="h5">
            <FormattedMessage id="linkTimedOut.submitText" />
          </Typography>
        </Grid.Column>
      </Grid>
      <Grid columns={3}>
        <Grid.Column>
          <NavigationButton link={isTeleCash ? TELECASH_LINK : PAYONE_LINK}>
            <FormattedMessage id="linkTimedOut.submit" />
          </NavigationButton>
        </Grid.Column>
      </Grid>
    </Flex>
  </>
)

export default RedirectToMerchantOnlinePortal
