import React from 'react'
import { styled } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import Layout from '../../../components/Layout'
import { ViewHeader } from '../../../components/headers'
import { ReactComponent as InvalidIcon } from '../../../images/invalid.svg'
import { RejectionGirlImage } from '../../../components/icons'
import activeTenant, {
  isMcaAdjustedPage1GroupEligibilityRejection,
  tenantCode,
} from '../../../setup/tenant'
import { EmailSubscriptionForm } from './components/EmailSubscriptionForm'

const NavigationLink = styled('a')`
  text-decoration: none;
`

const LoanRejectionEligibility = ({ content }) => (
  <Layout
    viewHeader={
      <ViewHeader
        id="eligibilityRejection.header"
        subId={tenantCode === 'PNT' ? 'eligibilityRejection.content' : content}
        subValues={{
          faqs: (
            <NavigationLink
              href={activeTenant.faqLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="misc.faqs" />
            </NavigationLink>
          ),
        }}
        icon={<InvalidIcon style={{ position: 'relative', top: '-10px' }} />}
      />
    }
  >
    <Layout.MiddleAndRight>
      {isMcaAdjustedPage1GroupEligibilityRejection && (
        <EmailSubscriptionForm rejectionReason={content} />
      )}
    </Layout.MiddleAndRight>
    <Layout.Middle>
      <RejectionGirlImage />
    </Layout.Middle>
  </Layout>
)
export default LoanRejectionEligibility
