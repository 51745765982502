import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { BackButton, Button } from 'components/buttons'

/** Strips the extra formik props when an input is used in order to prevent invalid props being passed down to the DOM and causing a console error   */
export const cleanFormikProps = (props) => {
  const {
    validateOnMount: formikValidateOnMount,
    validateOnBlur: formikValidateOnBlur,
    validateField: formikValidateField,
    validateForm: formikValidateForm,
    submitForm: formikSubmitForm,
    setSubmitting: formikSetSubmitting,
    setStatus: formikSetStatus,
    setValues: formikSetValues,
    setFieldValue: formikSetFieldValue,
    setFieldError: formikSetFieldError,
    setFormikState: formikSetFormikState,
    setTouched: formikSetTouched,
    setErrors: formikSetErrors,
    handleSubmit: formikHandleSubmit,
    handleChange: formikHandleChange,
    handleReset: formikHandleReset,
    handleBlur: formikHandleBlur,
    resetForm: formikResetForm,
    setFieldTouched: formikSetFieldTouched,
    validateOnChange: formikValidateOnChange,
    getFieldHelpers: formikGetFieldHelpers,
    getFieldMeta: formikGetFieldMeta,
    getFieldProps: formikGetFieldProps,
    registerField: formikRegisterField,
    unregisterField: formikUnregisterField,
    initialStatus: formikInitialStatus,
    initialTouched: formikInitialTouched,
    initialErrors: formikInitialErrors,
    initialValues: formikInitialValues,
    isValidating: formikIsValidating,
    isSubmitting: formikIsSubmitting,
    isValid: formikIsValid,
    dirty: formikDirty,
    submitCount: formikSubmitCount,
    meta: formikMeta,
    ...restProps
  } = props

  return restProps
}

export const Form = ({ children, loading, withBack, onBack, ...restProps }) => (
  <Formik enableReinitialize {...restProps}>
    {({ isValid, submitCount, values, ...rest }) => (
      <FormikForm
        onSubmit={rest.handleSubmit}
        data-testid="formikForm"
        style={{ width: '100%' }}
      >
        <Flex flexDirection="column">
          {typeof children === 'function'
            ? children({ isValid, submitCount, values, ...rest })
            : // Deep clone the children of the form, ensuring we can properly access the formik props in subsequent uses
            React.Children.map(
              children,
              (child) =>
                React.isValidElement(child) &&
                React.cloneElement(child, {
                  isValid,
                  submitCount,
                  values,
                  ...rest,
                }),
            )}
        </Flex>
        <Box
          sx={{
            display: 'grid',
            width: '100%',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: '48px',
          }}
          mb={3}
        >
          <Box
            sx={{
              gridColumnStart: '1',
              gridColumnEnd: ['span 3', 'span 3', 'span 2'],
            }}
          >
            <Flex>
              {withBack && <BackButton onClick={onBack} />}
              <Button
                type="submit"
                disabled={!isValid}
                sx={{ flex: '1 0 auto' }}
                data-testid="submitButton"
                withArrow
                loading={loading}
              >
                <FormattedMessage id="form.submit" />
              </Button>
            </Flex>
          </Box>
        </Box>
      </FormikForm>
    )}
  </Formik>
)

export default Form
