import React, { useState, useRef } from 'react'
import debounce from 'lodash/debounce'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ButtonRaw } from 'components/buttons'
import { ReactComponent as PersonIdIcon } from 'images/icons/person-id.svg'
import { ReactComponent as TrashIcon } from 'images/icons/trash.svg'
import { parseAddress, parseTitle, hasAnyPartOfAddress } from 'utils'
import { Switch } from 'components/Switch'
import { Formik, Form as FormikForm, Field } from 'formik'
import { useLanguage } from 'context/Language'
import { stakeholderValidator } from '../../utils'
import { UltimateBeneficialOwner } from './UltimateBeneficialOwner'

const ICON_WIDTH = 32
const ICON_MARGIN_RIGHT = 16

const DeleteConfirmation = ({ onCancel, onConfirm }) => (
  <Box
    sx={{
      border: (theme) => theme.borders.primary,
      mt: 3,
      backgroundColor: '#fff',
    }}
  >
    <Switch
      labelId="stakeholders.deleteConfirmation"
      onYes={onConfirm}
      onNo={onCancel}
    />
  </Box>
)

export const Summary = (props) => {
  const [deleting, setDeleting] = useState(false)
  const { locale } = useLanguage()
  const submitRef = useRef(null)
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        p: 3,
        flexGrow: 1,
      }}
      data-testid={props.owner.applicantOwner ? 'applicantOwner' : ''}
    >
      <Flex
        flexDirection={['column', 'row', 'row']}
        justifyContent="space-between"
      >
        <Flex
          sx={{
            borderRight: (theme) => [
              'none',
              props.owner.email || hasAnyPartOfAddress(props.owner.address)
                ? theme.borders.primary
                : 'none',
              props.owner.email || hasAnyPartOfAddress(props.owner.address)
                ? theme.borders.primary
                : 'none',
            ],
            borderBottom: (theme) => [theme.borders.primary, 'none', 'none'],
          }}
          flex="1 1 50%"
          pb={[3, 0, 0]}
        >
          <Box
            mr={`${ICON_MARGIN_RIGHT}px`}
            sx={{
              width: `${ICON_WIDTH}px`,
              height: `${ICON_WIDTH}px`,
              minWidth: `${ICON_WIDTH}px`,
              color: (theme) => theme.palette.global.icoA.main,
            }}
          >
            <PersonIdIcon />
          </Box>
          <Flex flexDirection="column" sx={{ width: '80%' }}>
            <Flex
              flexDirection="row"
              sx={{
                color:
                  !props.owner.title &&
                  !props.owner.firstName &&
                  !props.owner.lastName
                    ? 'text.off.main'
                    : 'input.value.main',
              }}
            >
              {props.owner.applicantOwner && (
                <Typography variant="h5" mr={1}>
                  <FormattedMessage id="stakeholders.you" />
                </Typography>
              )}
              {!props.owner.title &&
              !props.owner.firstName &&
              !props.owner.lastName ? (
                <Typography variant="h5" ml={2}>
                  <FormattedMessage id="stakeholders.anotherPersonDetails" />
                </Typography>
              ) : (
                <>
                  <Typography variant="h5" mr={1}>
                    <FormattedMessage id={parseTitle(props.owner.title)} />
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {` ${props.owner.firstName} ${props.owner.lastName}`}
                  </Typography>
                </>
              )}
            </Flex>
            <Flex flexDirection="column" justifyContent="space-between">
              {props.owner.legalRepresentative && (
                <Box mt={[0, 5, 5]}>
                  <Typography variant="caption">
                    <FormattedMessage id="stakeholders.legalRep" />
                  </Typography>
                </Box>
              )}
              {props.owner.ultimateBeneficial && (
                <Box mt={props.owner.legalRepresentative ? 1 : [0, 5, 5]}>
                  <Typography variant="caption">
                    <FormattedMessage id="stakeholders.ubo" />
                  </Typography>
                </Box>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          pl={[`${ICON_WIDTH + ICON_MARGIN_RIGHT}px`, 3, 3]}
          flex="1 1 50%"
          pt={[3, 0, 0]}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Flex flexDirection="column" justifyContent="space-between">
            <Box
              sx={{
                color: props.owner.email ? 'input.value.main' : 'text.off.main',
              }}
            >
              <Typography variant="caption">{props.owner.email}</Typography>
            </Box>
            <Box
              sx={{
                whiteSpace: 'pre-line',
                color: props.owner.email ? 'input.value.main' : 'text.off.main',
                mt: 3,
              }}
            >
              <Typography variant="caption">
                {hasAnyPartOfAddress(props.owner.address)
                  ? parseAddress(props.owner.address, locale)
                  : ''}
              </Typography>
            </Box>
          </Flex>
          {!props.owner.applicantOwner && (
            <ButtonRaw
              onClick={() => {
                setDeleting(true)
              }}
            >
              <Box
                width="24px"
                height="24px"
                sx={{ color: (theme) => theme.palette.global.icoA.main }}
              >
                <TrashIcon />
              </Box>
            </ButtonRaw>
          )}
        </Flex>
      </Flex>
      <Formik
        initialValues={{
          id: props.owner.id,
          sharesPercent: props.owner.sharesPercent,
          ultimateBeneficial: props.owner.ultimateBeneficial,
          legalRepresentative: props.owner.legalRepresentative,
        }}
        validate={stakeholderValidator}
        onSubmit={props.handleUpdateOwner}
        enableReinitialize
        validateOnBlur
      >
        {({ handleSubmit }) => {
          submitRef.current = () => debounce(handleSubmit, 500)

          return (
            <FormikForm
              onChange={submitRef.current()}
              style={{ width: '100%' }}
            >
              <Field
                component={UltimateBeneficialOwner}
                mb={0}
                name="ultimateBeneficial"
                inputContainerSx={{
                  mb: 3,
                  mt: 3,
                  backgroundColor: 'global.bgB.main',
                  gridColumnEnd: '4',
                }}
                hintContainerSx={{
                  borderTop: 'none',
                  gridColumnStart: '1',
                  gridColumnEnd: '4',
                }}
                hint={
                  <FormattedMessage id="fields.ultimateBeneficialOwner.hint" />
                }
                dataCy="ultimateBeneficialOwner"
              />
            </FormikForm>
          )
        }}
      </Formik>
      {deleting && (
        <DeleteConfirmation
          onCancel={() => {
            setDeleting(false)
          }}
          onConfirm={props.handleDeleteOwner}
        />
      )}
    </Flex>
  )
}
