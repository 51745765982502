import React, { createContext, useContext, useEffect, useCallback } from 'react'
import { useSetState } from 'context/utils/hooks'
import { useError } from 'context/Error'
import { useApiRequest } from 'context/utils/useApiRequest'
import { API_URL, API_PATH } from 'constants/api'
import { omit } from 'lodash'
import type {
  IdentityVerification,
  LoanApplication,
} from 'types/loanApplication'
import { useLoanOffer } from 'context/LoanOffer'
import { useLoans } from 'context/Loans'
import { useAuth } from '../Auth'

type LoanApplicationProviderProps = { children: React.ReactNode }

type State = {
  currentLoanApplication?: LoanApplication
  identityVerification?: IdentityVerification
  isError?: boolean
  isLoading?: boolean
  loanApplications?: LoanApplication[]
}

type Merchant = {
  optinAccount: boolean
  optinTerms: boolean
  optinTax?: boolean
  email?: string
}

const chronologically = (a: LoanApplication, b: LoanApplication) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()

export type LoanApplicationContextState = State & {
  // handlers
  createLoanApplication: (
    loanApplication: DeepPartial<LoanApplication>,
  ) => Promise<null | LoanApplication>
  fetchIdentityVerificationsById: (loanApplicationId: string) => void
  fetchLoanApplicationById: (loanApplicationId: string) => Promise<void>
  fetchLoanApplications: () => Promise<null | LoanApplication[]>
  updateCurrentMerchant: (props: Merchant) => Promise<void>
  updateLoanApplicationById: (props: {
    loanApplicationId: string
    loanApplication: Partial<LoanApplication>
  }) => Promise<void>
  setLoanApplication: (loanApplication: LoanApplication | null) => void
  startNewApplicationForExistingMerchant: () => Promise<void>
  shouldAllowNewApplication: boolean
  isEligibleForRefill: boolean
}

export const LoanApplicationContext = createContext<
  undefined | LoanApplicationContextState
>(undefined)

const initialState: State = Object.freeze({
  currentLoanApplication: null,
  identityVerification: null,
  isError: false,
  isLoading: false,
  loanApplications: null,
})

export function LoanApplicationProvider(props: LoanApplicationProviderProps) {
  const { children } = props

  const [state, setState] = useSetState<State>({ ...initialState })
  const { token } = useAuth()
  const { handleApiRequestError, handleApplicationError } = useError()
  const { getRequest, patchRequest, postRequest } = useApiRequest()
  const { setApplicationForm } = useLoanOffer()

  useEffect(() => {
    if (!token) {
      setState({ ...initialState })
    }
  }, [setState, token])

  const fetchLoanApplications = useCallback(async () => {
    try {
      setState({ isLoading: true })
      const { loanApplications } = await getRequest<{
        loanApplications: LoanApplication[]
      }>({
        url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}`,
      })
      const sorted = [...loanApplications].sort(chronologically)
      const currentLoanApplication = sorted?.[0]
      setState({ loanApplications, currentLoanApplication })
      return loanApplications
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      handleApiRequestError(error)
      setState({ isError: true })
      return null
    } finally {
      setState({ isLoading: false })
    }
  }, [getRequest, handleApiRequestError, setState])

  const fetchLoanApplicationById = useCallback(
    async (loanApplicationId) => {
      try {
        setState({ isLoading: true })
        const { loanApplication } = await getRequest<{
          loanApplication: LoanApplication
        }>({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}`,
        })
        setState({ currentLoanApplication: loanApplication })
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        handleApiRequestError(error)
        setState({ isError: true })
      } finally {
        setState({ isLoading: false })
      }
    },
    [getRequest, handleApiRequestError, setState],
  )

  const updateLoanApplicationById = useCallback(
    async ({
      loanApplicationId,
      loanApplication,
    }: {
      loanApplicationId: string
      loanApplication: Partial<LoanApplication>
    }) => {
      try {
        setState({ isLoading: true })
        const { loanApplication: updatedLoanApplication } = await patchRequest<{
          loanApplication: LoanApplication
        }>({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}`,
          body: {
            loanApplication: omit(loanApplication, [
              'address',
              'createdAt',
              'dataRetrievalStatus',
              'humanReadableId',
              'id',
              'linkedBankAccount',
              'merchant',
              'offerConfiguration',
              'offers',
              'optinTermsAndConditions',
              'owners',
              'rejectionCode',
              'status',
            ]),
          },
        })
        setState({ currentLoanApplication: updatedLoanApplication })
      } catch (error: unknown) {
        handleApplicationError(error)
        setState({ isError: true })
      } finally {
        setState({ isLoading: false })
      }
    },
    [handleApplicationError, patchRequest, setState],
  )

  const createLoanApplication = useCallback(
    async (loanApplication: DeepPartial<LoanApplication>) => {
      try {
        setState({ isLoading: true })
        const response = await postRequest<{
          loanApplication: LoanApplication
        }>({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}`,
          body: { loanApplication: omit(loanApplication, ['voucher']) },
        })
        setState({ currentLoanApplication: response.loanApplication })
        return response.loanApplication
      } catch (error: unknown) {
        handleApplicationError(error)
        setState({ isError: true })
        return null
      } finally {
        setState({ isLoading: false })
      }
    },
    [handleApplicationError, postRequest, setState],
  )

  const fetchIdentityVerificationsById = useCallback(
    async (loanApplicationId: string) => {
      try {
        setState({ isLoading: true })
        const { identityVerification } = await getRequest<{
          identityVerification: IdentityVerification
        }>({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}/identity_verifications`,
        })
        setState({ identityVerification })
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        handleApiRequestError(error)
      } finally {
        setState({ isLoading: false })
      }
    },
    [getRequest, handleApiRequestError, setState],
  )

  const updateCurrentMerchant = useCallback(
    async (merchantProps: Merchant) => {
      const { optinAccount, optinTax, optinTerms, email } = merchantProps
      try {
        setState({ isLoading: true })
        await patchRequest({
          url: `${API_URL}${API_PATH.MERCHANTS}/current`,
          body: {
            optinAccount,
            optinTerms,
            optinTax,
            email,
          },
        })
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        handleApiRequestError(error)
        setState({ isError: true })
      } finally {
        setState({ isLoading: false })
      }
    },
    [handleApiRequestError, patchRequest, setState],
  )

  const setLoanApplication = useCallback(
    (loanApplication: LoanApplication | null) => {
      setState({ currentLoanApplication: loanApplication })
    },
    [setState],
  )

  const {
    currentLoanApplication,
    identityVerification,
    isError,
    isLoading,
    loanApplications,
  } = state

  // Initiates a new loan application for existing merchants (previously signed up) that are eligible for new LAP - either renewal or previously rejected/ closed, populates the applicationForm with their previous data
  const startNewApplicationForExistingMerchant = useCallback(async () => {
    const {
      approximateSales,
      businessDetails,
      tenantSpecific,
      timeOfRunBusiness,
    } = currentLoanApplication

    const repeatLoanApplication = {
      approximateSales,
      businessDetails,
      tenantSpecific,
      timeOfRunBusiness,
    }

    setApplicationForm(currentLoanApplication)
    await createLoanApplication(repeatLoanApplication)
  }, [createLoanApplication, currentLoanApplication, setApplicationForm])

  const { loans } = useLoans()

  const isEligibleForRefill =
    state?.currentLoanApplication?.merchant?.eligible ||
    loans?.[loans?.length - 1]?.status === 'repaid'

  const shouldAllowNewApplication =
    isEligibleForRefill ||
    state?.currentLoanApplication?.status === 'closed' ||
    state?.currentLoanApplication?.status === 'rejected'

  const contextValue = React.useMemo(
    () => ({
      currentLoanApplication,
      identityVerification,
      isError,
      isLoading,
      loanApplications,
      createLoanApplication,
      fetchIdentityVerificationsById,
      fetchLoanApplicationById,
      fetchLoanApplications,
      updateCurrentMerchant,
      updateLoanApplicationById,
      setLoanApplication,
      startNewApplicationForExistingMerchant,
      shouldAllowNewApplication,
      isEligibleForRefill,
    }),
    [
      currentLoanApplication,
      identityVerification,
      isError,
      isLoading,
      loanApplications,
      createLoanApplication,
      fetchIdentityVerificationsById,
      fetchLoanApplicationById,
      fetchLoanApplications,
      updateCurrentMerchant,
      updateLoanApplicationById,
      setLoanApplication,
      startNewApplicationForExistingMerchant,
      shouldAllowNewApplication,
      isEligibleForRefill,
    ],
  )

  return (
    <LoanApplicationContext.Provider value={contextValue}>
      {children}
    </LoanApplicationContext.Provider>
  )
}

export function useLoanApplication() {
  const context = useContext(LoanApplicationContext)
  if (context === undefined) {
    throw new Error(
      'useLoanApplication must be used within a LoanApplicationProvider',
    )
  }
  return context
}
