import React from 'react'

import { ButtonRaw } from 'components/buttons'
import { BurgerIcon } from 'components/icons'

export const BurgerButton = ({ onClick }) => (
  <ButtonRaw {...{ onClick }}>
    <BurgerIcon />
  </ButtonRaw>
)
