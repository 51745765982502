import React from 'react'
import { Box } from 'components/structural'

import { ReactComponent as Secured } from 'images/icons/secured.svg'

export const SecuredIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Secured />
  </Box>
)
