import { styled } from '@mui/system'

export const StyledMenu = styled('nav')(({ theme, open }) => ({
  position: 'fixed',
  background: '#fff',
  height: '100vh',
  top: 0,
  right: 0,
  transition: 'transform 0.3s ease-in-out',
  transform: open ? 'translateX(0)' : 'translateX(100%)',
  zIndex: '2',
  width: '100%',
  overflow: 'scroll',

  [theme.breakpoints.up('tablet')]: {
    width: '360px',
    borderLeft: `1px solid ${theme.palette.global.line.main}`,
    boxShadow: open ? '0px 16px 32px rgba(53, 89, 131, 0.2)' : 'none',
  },
}))
