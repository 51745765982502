import _mapValues from 'lodash/mapValues'
import _camelCase from 'lodash/camelCase'
import _lowerCase from 'lodash/lowerCase'
import _isPlainObject from 'lodash/isPlainObject'
import _isArray from 'lodash/isArray'
import _mapKeys from 'lodash/mapKeys'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _snakeCase from 'lodash/snakeCase'
import _includes from 'lodash/includes'
import _keys from 'lodash/keys'
import _clone from 'lodash/clone'
import _curryRight from 'lodash/curryRight'
import fp, { __ } from 'lodash/fp'
import { getName } from 'i18n-iso-countries'

export const mapKeysDeep = (cb) => (obj) => {
  if (!_isPlainObject(obj)) return obj

  return _mapValues(_mapKeys(obj, cb), (val) => {
    if (_isPlainObject(val)) return mapKeysDeep(cb)(val)
    if (_isArray(val)) return _map(val, (v) => mapKeysDeep(cb)(v))
    return val
  })
}

export const cameliseDeep = (value) => {
  const cameliseFn = (_, key) => _camelCase(key)
  return _isArray(value)
    ? value.map(mapKeysDeep(cameliseFn))
    : mapKeysDeep(cameliseFn)(value)
}

export const lowerCaseDeep = (value) => {
  const lowerFn = (_, key) => _lowerCase(key).replace(/\s/g, '')
  return _isArray(value)
    ? value.map(mapKeysDeep(lowerFn))
    : mapKeysDeep(lowerFn)(value)
}

export const snakeCaseDeep = (value) => {
  const snakeCaseFn = (_, key) => _snakeCase(key)
  return _isArray(value)
    ? value.map(mapKeysDeep(snakeCaseFn))
    : mapKeysDeep(snakeCaseFn)(value)
}

export const getResponseError = (response) => {
  const bodyErrors = _get(response, 'body.errors')

  if (bodyErrors) {
    return bodyErrors.map(({ message } = {}) => message)
  }

  return response.statusText || 'Connection error'
}

export const navigatorLanguage =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      navigator.systemLanguage ||
      'en'

export const toBase64 = ({ file, callback }) =>
  new Promise((resolve, reject) => {
    if (typeof file === 'string') {
      if (callback) {
        callback(file)
      }
      resolve(file)
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (callback) {
        callback(reader.result)
      }
      resolve(reader.result)
    }
    reader.onerror = (error) => reject(error)
  })

export function bytesToSize(bytes) {
  if (!bytes) return ''
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`
}

export const loadScript = (src) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load', () => resolve())
    script.addEventListener('error', (error) => reject(error))
    document.body.appendChild(script)
  })

export const getMatchFromSubstrings = (substring, collection) =>
  fp.find(fp.includes(__, substring))(collection)

export const copyToClipboard = (text) => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const rename = _curryRight((obj, key, newKey) => {
  if (_includes(_keys(obj), key)) {
    obj[newKey] = _clone(obj[key], true)
    delete obj[key]
  }
  return obj
})

export const hasAnyPartOfAddress = ({
  address1,
  address2,
  zipCode,
  city,
  country,
}) => [address1, address2, zipCode, city, country].some(Boolean)

export const parseAddress = (
  { address1, address2, zipCode, city, country } = {},
  locale,
  countrySeparator = '\n',
) =>
  [
    ...(address1 ? [address1, '\n'] : []),
    ...(address2 ? [address2, '\n'] : []),
    ...(zipCode ? [zipCode, ' '] : []),
    ...(city ? [city, countrySeparator] : []),
    country ? getName(country, locale) : '',
  ].reduce((acc, value) => `${acc}${value}`, '')

export const parseTitle = (title) => {
  switch (title) {
    case 'mr':
      return 'salutations.mr'
    case 'mrs':
      return 'salutations.mrs'
    case 'other':
    default:
      return 'salutations.other'
  }
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}
