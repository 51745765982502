import React, { useState, useRef } from 'react'

import { useOutsideClick, useScrollLock } from 'hooks'

import { Menu } from './Menu'
import { BurgerButton } from './BurgerButton'

export const useMenu = () => {
  const [open, setOpen] = useState(false)
  const anchorEl = useRef()

  useOutsideClick(anchorEl, () => setOpen(false))

  useScrollLock(open)

  return {
    burgerButton: <BurgerButton onClick={() => setOpen((prev) => !prev)} />,
    menu: <Menu {...{ open, setOpen, anchorEl }} />,
  }
}
