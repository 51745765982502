export const LOAN_APPLICATION_STATUS = {
  APPROVAL: 'approval',
  APPROVED: 'approved',
  ELIGIBLE: 'eligible',
  EXPRESS_ELIGIBLE: 'express_eligible',
  EXPRESS_REVIEW: 'express_review',
  EXPRESS_SUBMITTED: 'express_submitted',
  INITIAL: 'initial',
  INTERNAL_REVIEW: 'internal_review',
  KYC: 'kyc',
  REJECTED: 'rejected',
  SUBMITTED: 'submitted',
  CLOSED: 'closed',
} as const

export const LOAN_APPLICATION_REJECTION_CODE = {
  ELIGIBILITY: 'LE_ELIGIBILITY',
} as const

export const REJECTION_CODE = {
  REGION: 'LE_REGION',
  ELIGIBILITY: 'LE_ELIGIBILITY',
} as const

export const REJECTION_CODE_V2 = {
  NON_ELIGIBLE_TIME_OF_RUN_BUSINESS: 'NON_ELIGIBLE_TIME_OF_RUN_BUSINESS',
  NON_ELIGIBLE_REVENUE: 'NON_ELIGIBLE_REVENUE',
  NON_ELIGIBLE_LOAN_AMOUNT: 'NON_ELIGIBLE_LOAN_AMOUNT',
  NON_ELIGIBLE_TOO_HIGH_DESIRED_AMOUNT: 'NON_ELIGIBLE_TOO_HIGH_DESIRED_AMOUNT',
  NON_ELIGIBLE_TOO_LOW_DESIRED_AMOUNT: 'NON_ELIGIBLE_TOO_LOW_DESIRED_AMOUNT',
  NON_ELIGIBLE_COUNTRY: 'NON_ELIGIBLE_COUNTRY',
} as const

export type TimeOfRunBusiness = {
  range: string
  value: number
  translationId: string
}

export const TIME_OF_RUN_BUSINESS: TimeOfRunBusiness[] = [
  {
    range: '0-6',
    value: 5,
    translationId: 'fields.timeOfRunBusiness.under6Months',
  },
  {
    range: '6-12',
    value: 11,
    translationId: 'fields.timeOfRunBusiness.6to12months',
  },
  {
    range: '12-24',
    value: 23,
    translationId: 'fields.timeOfRunBusiness.12to24months',
  },
  {
    range: '24-',
    value: 25,
    translationId: 'fields.timeOfRunBusiness.moreThan24months',
  },
]

export const TIME_OF_RUN_BUSINESS_FOR_BANXWARE: TimeOfRunBusiness[] = [
  {
    range: '0-6',
    value: 5,
    translationId: 'fields.timeOfRunBusiness.under3Months.banxware',
  },
  {
    range: '6-12',
    value: 11,
    translationId: 'fields.timeOfRunBusiness.3to12months.banxware',
  },
  {
    range: '12-24',
    value: 23,
    translationId: 'fields.timeOfRunBusiness.12to24months',
  },
  {
    range: '24-',
    value: 25,
    translationId: 'fields.timeOfRunBusiness.moreThan24months',
  },
]

export const LEGAL_FORMS = {
  EK: 'legalForms.ek',
  FREELANCER: 'legalForms.freelancer',
  GBR: 'legalForms.gbr',
  GMBH: 'legalForms.gmbh',
  GMBH_COKG: 'legalForms.gmbh&cokg',
  SOLEPROPRIETOR: 'legalForms.soleproprietor',
  UG: 'legalForms.ug',
  OHG: 'legalForms.ohg',
  // AG: 'legalForms.ag',
  // EG: 'legalForms.eg',
  // KG: 'legalForms.kg',
  // PARTG: 'legalForms.partg',
} as const

export const CUSTOMER_PROGRESS = {
  APPLICATION_REVIEW: 'APPLICATION_REVIEW',
  APPLICATION_SUMMARY: 'APPLICATION_SUMMARY',
  ADDITIONAL_INFORMATION: 'ADDITIONAL_INFORMATION',
  BANK_LINKING: 'BANK_LINKING',
  BUSINESS_DETAILS: 'BUSINESS_DETAILS',
  COMPANY_SEARCH: 'COMPANY_SEARCH',
  CONNECT_SHOPPING_PLATFORM: 'CONNECT_SHOPPING_PLATFORM',
  DOCUMENTS_UPLOAD: 'DOCUMENTS_UPLOAD',
  EXPRESS_APPLICATION_REVIEW: 'EXPRESS_APPLICATION_REVIEW',
  EXPRESS_APPLICATION_SUBMITTED: 'EXPRESS_APPLICATION_SUBMITTED',
  IDENTITY_VERIFICATION: 'IDENTITY_VERIFICATION',
  LOAN_OFFERS: 'LOAN_OFFERS',
  LOAN_PROCESS_OVERVIEW: 'LOAN_PROCESS_OVERVIEW',
  MERCHANT_PORTAL: 'MERCHANT_PORTAL',
  OWNER_DETAILS: 'OWNER_DETAILS',
  ROOT: 'ROOT',
  STAKEHOLDERS: 'STAKEHOLDERS',
} as const
