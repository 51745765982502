import React from 'react'
import { Typography } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'

export const AccordionDetail = ({ fieldKey, fieldValue }) => (
  <Flex justifyContent="space-between">
    <Box>
      <Typography variant="body2">
        <FormattedMessage id={fieldKey} />
      </Typography>
    </Box>
    <Box>
      <Typography variant="body2" style={{ fontWeight: 500 }}>
        {fieldValue}
      </Typography>
    </Box>
  </Flex>
)

export default AccordionDetail
