import React from 'react'
import { Box } from 'components/structural'

import { ReactComponent as Arrow } from 'images/icons/arrow-right.svg'

export const ArrowIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: () => '#ffffff',
        },
      },
      width: '24px',
      height: '24px',
      ml: 3,
      pt: '2px',
      position: 'relative',
    }}
  >
    <Arrow />
  </Box>
)
