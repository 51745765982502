import React from 'react'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const ICON_DESKTOP_WIDTH = 96
const ICON_DESKTOP_MARGIN = 48

export function ViewHeader({
  id,
  idValues,
  subId,
  subValues,
  backgroundColor = 'global.bgB.main',
  icon,
  children,
  ...rest
}) {
  return (
    <Flex
      sx={{
        width: '100%',
        backgroundColor,
      }}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      data-testid="viewHeader"
    >
      <Box
        sx={{
          px: [3, 6, 4],
          maxWidth: '1344px',
          display: 'grid',
          width: '100%',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridGap: '48px',
        }}
      >
        <Box
          sx={{
            gridColumnStart: ['1', '1', '2'],
            gridColumnEnd: ['span 4', 'span 4', 'span 2'],
          }}
        >
          <Flex
            width="100%"
            flexDirection="row"
            alignItems={['flex-start', 'center', 'center']}
            justifyContent={['flex-start', 'space-between', 'flex-start']}
            my={[5, 5, 7]}
            sx={{ position: 'relative' }}
            {...rest}
          >
            {icon ? (
              <Box
                sx={{
                  position: ['static', 'static', 'absolute'],
                  order: [1, 2, 1],
                  top: '-15px',
                  left: `-${ICON_DESKTOP_WIDTH + ICON_DESKTOP_MARGIN}px`,
                  mt: [2, 0, 0],
                  mb: [2, 0, 0],
                  mr: [3, 0, 0],
                  width: ['48px', '64px', `${ICON_DESKTOP_WIDTH}px`],
                  minWidth: ['48px', '64px', `${ICON_DESKTOP_WIDTH}px`],
                }}
              >
                {icon}
              </Box>
            ) : null}
            <Flex
              flexDirection="column"
              sx={{
                order: [2, 1, 2],
              }}
              mt={[2, 0, 0]}
            >
              <Typography
                variant="h1"
                sx={{
                  color: 'header.title.main',
                  whiteSpace: 'pre-wrap',
                  textAlign: 'left',
                }}
              >
                <FormattedMessage id={id} values={idValues} />
              </Typography>

              {subId && (
                <Box mt={5} sx={{ whiteSpace: 'pre-line' }}>
                  <Typography
                    sx={{ fontWeight: 500, color: 'header.text.main' }}
                    variant="body1"
                  >
                    <FormattedMessage id={subId} values={subValues} />
                  </Typography>
                </Box>
              )}
              {children}
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}
