import React from 'react'
import { Typography, Box } from '@mui/material'
import { Button } from 'components/buttons'
import { Flex } from 'components/structural'
import { useLoans } from 'context/Loans'
import { FormattedMessage } from 'react-intl'
import activeTenant, { isLinkIntegration } from 'setup/tenant'
import { useLoanApplication } from 'context/LoanApplication'
import { noop } from 'lodash'
import { isPostApplicationDecision } from 'types/loanApplication'

type LoanStatuses =
  | 'active_in_arrears'
  | 'disbursement_error'
  | 'disbursement_pending'
  | 'repaid'
  | 'eligible_active'

type LoanInfoBannerStatuses = {
  [key in LoanStatuses]: { backgroundColor: string; translationId: string }
}

const LINK_TO_ELS_WITH_BXW_PARTNER_ID =
  'https://portal.banxware.com/api/partner/BXW01GW53E7J8PD0PEBV69BS6YV54'

const loanInfoBannerStatuses: LoanInfoBannerStatuses = {
  active_in_arrears: {
    backgroundColor: 'global.errorRed.light',
    translationId: 'activeInArrears.header',
  },
  disbursement_error: {
    backgroundColor: 'global.errorRed.light',
    translationId: 'loanApplicationApproved.disbursementError',
  },
  disbursement_pending: {
    backgroundColor: '#f1f7f8',
    translationId: 'loanApplicationApproved.approved',
  },
  eligible_active: {
    backgroundColor: 'global.okGreen.light',
    translationId: 'isRenewalEligibleMessage',
  },
  repaid: {
    backgroundColor: 'global.okGreen.light',
    translationId: 'successfullyRepaidMessage',
  },
}

const eligibleActiveLoanStatuses = [
  'active',
  'collection',
  'default',
  'delayed_on_payment',
]

export const LoanInfoBanner = () => {
  const { selectedLoan } = useLoans()
  const { currentLoanApplication, isEligibleForRefill } = useLoanApplication()
  const shouldAllowNewApplicationAndPostApplication =
    isEligibleForRefill &&
    // The merchant's most recent application must be complete in order to let them start a new one
    isPostApplicationDecision(currentLoanApplication)
  const { startNewApplicationForExistingMerchant } = useLoanApplication()
  const loanBanner =
    shouldAllowNewApplicationAndPostApplication &&
    eligibleActiveLoanStatuses.includes(selectedLoan?.status)
      ? loanInfoBannerStatuses.eligible_active
      : loanInfoBannerStatuses[selectedLoan?.status as LoanStatuses]

  if (loanBanner) {
    return (
      <Box
        sx={{
          backgroundColor: loanBanner.backgroundColor,
          width: '100%',
          margin: '30px 0',
        }}
      >
        <Box p="25px">
          <Flex alignItems="center" justifyContent="space-between">
            <Box maxWidth="50%">
              <Typography fontWeight={400} variant="h5">
                <FormattedMessage
                  id={loanBanner.translationId}
                  values={{
                    id: selectedLoan?.humanReadableId,
                    contactEmail: activeTenant.contactEmail,
                  }}
                />
              </Typography>
            </Box>
            {shouldAllowNewApplicationAndPostApplication && !isLinkIntegration && (
              <Button
                style={{
                  flexGrow: 0,
                  color: 'button.labelA.main',
                  height: '45px',
                }}
                onClick={() => {
                  window.location.href = LINK_TO_ELS_WITH_BXW_PARTNER_ID
                }}
                data-testid="reapply.button"
              >
                <FormattedMessage id="reapplyButton" />
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
    )
  }

  return <></>
}
