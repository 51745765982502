import React from 'react'
import LoanRejectionEligibility from '../index'

const RevenueEligibilty = () => {
  return (
    <LoanRejectionEligibility content="eligibilityRejection.revenue.content"
    />
  )
}
export default RevenueEligibilty
