import React, { useMemo } from 'react'
import { Flex, Box } from 'components/structural'
import activeTenant from 'setup/tenant'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ButtonRaw } from 'components/buttons'
import { copyToClipboard } from 'utils'
import { PendingIcon, ShareIcon, OkIcon } from './icons'
import { useLoanApplication } from '../../context/LoanApplication'

const IdentityVerifications = () => {
  const { identityVerification } = useLoanApplication()
  const { stakeholders = [] } = identityVerification || {}
  const allDone = useMemo(
    () => stakeholders.every((stakeholder) => stakeholder.status === 'READY'),
    [stakeholders],
  )

  return (
    <Flex
      sx={{
        border: (theme) => theme.borders.primary,
        borderRadius: activeTenant.theme.shape.fieldBorderRadius,
      }}
      width="100%"
      flexDirection="column"
    >
      <Flex
        width="100%"
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor="global.bgA.main"
        p={4}
        borderTop="theme.borders.primary"
        borderRadius={activeTenant.theme.shape.fieldBorderRadius}
      >
        <Flex flex="1 0 50%">
          <Typography variant="caption">
            <FormattedMessage id="applicationReview.identity.name" />
          </Typography>
        </Flex>
        <Flex justifyContent="space-between" flex="1 0 50%">
          <Typography variant="caption">
            <FormattedMessage id="applicationReview.identity.status" />
          </Typography>
          {!allDone && (
            <Typography variant="caption">
              <FormattedMessage id="applicationReview.identity.copyLink" />
            </Typography>
          )}
        </Flex>
      </Flex>
      <Box p={3}>
        {stakeholders.map((stakeholder, index) => (
          <Box width="100%" key={stakeholder.id}>
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                pt: index > 0 ? 3 : 0,
                pb:
                  stakeholders.length > 1 &&
                  index < identityVerification?.stakeholders.length - 1
                    ? 3
                    : 0,
                borderBottom: (theme) =>
                  stakeholders.length > 1 && index < stakeholders.length - 1
                    ? theme.borders.primary
                    : 'none',
              }}
            >
              <Flex flexDirection="column" flex="1 0 50%">
                <Typography variant="body1">
                  {stakeholder.firstName} {stakeholder.lastName}
                </Typography>
                <Typography variant="caption" color="text.subtle.main">
                  {stakeholder.email}
                </Typography>
              </Flex>
              <Flex flex="1 0 50%">
                <Flex
                  flexDirection="row"
                  flex="1 0 auto"
                  justifyContent="space-between"
                >
                  {stakeholder.status !== 'READY' ? (
                    <Flex alignItems="center">
                      <Box width="24px" height="24px" mr={1}>
                        <PendingIcon />
                      </Box>
                      <Typography variant="caption" color="text.subtle.main">
                        <FormattedMessage id="status.pending" />
                      </Typography>
                    </Flex>
                  ) : (
                    <Flex alignItems="center">
                      <Box width="24px" height="24px" mr={1}>
                        <OkIcon />
                      </Box>
                      <Typography variant="caption" color="text.subtle.main">
                        <FormattedMessage id="status.completed" />
                      </Typography>
                    </Flex>
                  )}
                  {stakeholder.status !== 'READY' && (
                    <ButtonRaw
                      onClick={() => {
                        copyToClipboard(stakeholder.redirectUrl)
                      }}
                    >
                      <Box width="24px" height="24px" mr={1}>
                        <ShareIcon />
                      </Box>
                    </ButtonRaw>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Box>
        ))}
      </Box>
    </Flex>
  )
}

export default IdentityVerifications
