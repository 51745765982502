import React, { useEffect, useMemo } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Box, Flex } from 'components/structural'
import { ButtonBase } from 'components/buttons'
import { ViewHeader } from 'components/headers'
import Layout from 'components/Layout'
import { InfoBox } from 'components/InfoBox'
import IdentityVerifications from 'components/IdentityVerificationsTable'
import { ReactComponent as ExternalIcon } from 'images/external.svg'
import { ReactComponent as WebidLogo } from 'images/webid-logo.svg'
import { ClockIcon, IdIcon, VerificationIcon } from './icons'
import { useLoanApplication } from '../../../context/LoanApplication'

const IdentityVerification = () => {
  const {
    currentLoanApplication,
    fetchIdentityVerificationsById,
    identityVerification,
    isLoading,
  } = useLoanApplication()
  const { stakeholders = [] } = identityVerification || {}

  const applicantOwner = useMemo(
    () =>
      stakeholders.find((stakeholder) => Boolean(stakeholder.applicantOwner)),
    [stakeholders],
  )

  const allDone = useMemo(
    () => stakeholders.every((stakeholder) => stakeholder.status === 'READY'),
    [stakeholders],
  )

  useEffect(() => {
    fetchIdentityVerificationsById(currentLoanApplication.id)
  }, [currentLoanApplication.id, fetchIdentityVerificationsById])

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="identityVerification.header"
          icon={<VerificationIcon />}
        />
      }
      withProgress
      data-testid="identityVerificationPage"
    >
      <Layout.Middle>
        <Flex flexDirection="column">
          <Box mb={4}>
            <Typography variant="h5">
              <FormattedMessage id="identityVerification.almostDone" />
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
              <FormattedMessage id="identityVerification.description" />
            </Typography>
          </Box>

          <Flex mb={6}>
            <Box width="24px" height="24px" mr={3}>
              <IdIcon />
            </Box>
            <Typography variant="h5">
              <FormattedMessage id="identityVerification.idReminder" />
            </Typography>
          </Flex>

          {isLoading ? (
            <Flex justifyContent="center" mb="30px">
              <CircularProgress />
            </Flex>
          ) : (
            <IdentityVerifications />
          )}
          {allDone && (
            <Flex justifyContent="center">
              <InfoBox icon={<ClockIcon />} mt={6} flex="1 1 auto">
                <Typography
                  variant="body2"
                  style={{ whiteSpace: 'pre-line' }}
                  data-testid="identityVerification.allDone"
                >
                  <FormattedMessage id="identityVerification.allDone" />
                </Typography>
              </InfoBox>
            </Flex>
          )}
          {applicantOwner?.redirectUrl && applicantOwner?.status === 'PENDING' && (
            <>
              <InfoBox mt={6}>
                <Flex>
                  <Typography variant="body2">
                    <FormattedMessage id="identityVerification.whatNow" />
                  </Typography>
                  <Flex
                    flexShrink="0"
                    sx={{ borderLeft: (theme) => theme.borders.primary }}
                    alignItems="center"
                    pl={5}
                    ml={3}
                  >
                    <Box width="86px">
                      <WebidLogo />
                    </Box>
                  </Flex>
                </Flex>
              </InfoBox>
              <Flex mt={6}>
                <ButtonBase
                  style={{ width: '100%' }}
                  onClick={() => {
                    window.location.href = applicantOwner.redirectUrl
                  }}
                  data-testid="identityVerification.startVerification"
                  icon={<ExternalIcon />}
                >
                  <Typography variant="button">
                    <FormattedMessage id="identityVerification.startVerification" />
                  </Typography>
                </ButtonBase>
              </Flex>
            </>
          )}
        </Flex>
      </Layout.Middle>
    </Layout>
  )
}

export default IdentityVerification
