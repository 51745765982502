import { useMemo } from 'react'
import { useAuth } from 'context/Auth'
import { isLinkIntegration, isPayOne, isTeleCash } from 'setup/tenant'
import { ROUTES } from 'constants/routes'
import { useLoanApplication } from 'context/LoanApplication'
import { useLocation } from 'react-router-dom'
import { PROTECTED_FROM_REROUTE_PATHS } from 'routing/routes'
import { useCustomerProgress } from './useCustomerProgress'

/** A hook that determines whether and where the user should be redirected upon loading the site  */
export const useLoanApplicationRedirect = () => {
  const { shouldAllowNewApplication } = useLoanApplication()
  const { token } = useAuth()
  const customerProgress = useCustomerProgress()
  const { pathname } = useLocation()

  const isProtectedPath = useMemo(
    () => PROTECTED_FROM_REROUTE_PATHS.includes(pathname),
    [pathname],
  )
  const loanApplicationRedirect = useMemo(() => {
    const defaultInitialRoute = isPayOne
      ? ROUTES.ADDITIONAL_INFORMATION
      : isTeleCash
      ? ROUTES.LOAN_OFFERS
      : ROUTES.COMPANY_SEARCH

    const allowNewApplicationInitialRoute = isLinkIntegration
      ? defaultInitialRoute
      : ROUTES.ROOT

    // The default initial route post authentication
    const initialRoute = shouldAllowNewApplication
      ? allowNewApplicationInitialRoute
      : defaultInitialRoute

    if (!token) {
      return null
    }

    if (customerProgress?.stage) {
      return customerProgress?.route
    }
    return initialRoute
  }, [token, customerProgress, shouldAllowNewApplication])

  if (isProtectedPath) return null

  return loanApplicationRedirect
}
