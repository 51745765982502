/* eslint-disable @typescript-eslint/no-unsafe-call */
import activeTenant from 'setup/tenant'
import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import tenantConfigs, { TenantCode } from 'tenants'
import { baseMuiTheme } from './baseMuiTheme'
import { muify } from './utils'

export const createMuiTheme = (tenantCode?: TenantCode) => {
  const palette = tenantCode
    ? tenantConfigs[tenantCode].theme.palette
    : activeTenant.theme.palette

  const shape = tenantCode
    ? tenantConfigs[tenantCode].theme.shape
    : activeTenant.theme.shape

  const theme = createTheme({
    ...baseMuiTheme,
    palette: {
      ...baseMuiTheme.palette,
      ...muify(palette, baseMuiTheme.palette.tonalOffset),
    },
    shape: {
      borderRadius: shape.borderRadius,
    },
    borders: {
      ...baseMuiTheme.borders,
      primary: `1px solid ${palette.global.line}`,
      globalError: `1px solid ${palette.global.errorRed}`,
    },
    components: deepmerge(baseMuiTheme.components, {
      // The input label appears nested in other MUI components, so we must
      // use a global override to style them all.
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: palette.input.value,
          },
          shrink: {
            color: palette.input.label,
          },
        },
      },
      // The unwrapped Typography component from MUI is used everywhere, so
      // this is the only place we have to apply tenant-specific styling.
      MuiTypography: {
        styleOverrides: {
          h1: {
            color: palette.text.heading,
          },
          h2: {
            color: palette.text.heading,
          },
          h3: {
            color: palette.text.heading,
          },
          h4: {
            color: palette.text.heading,
          },
          h5: {
            color: palette.text.heading,
          },
          body1: {
            color: palette.text.normal,
          },
          body2: {
            color: palette.text.normal,
          },
          caption: {
            color: palette.text.normal,
          },
        },
      },
    }),
  })

  return theme
}
