import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  requiredValidator,
  zipCodeValidator,
  zipCodeValidatorDE,
  composeValidators,
} from 'utils/forms/validators'

import { TextField } from './TextField'

const defaultValidators = composeValidators(requiredValidator, zipCodeValidator)

export const deOnlyValidators = composeValidators(
  requiredValidator,
  zipCodeValidatorDE,
)

export const Postcode = (props) => (
  <TextField
    name="postcode"
    label={<FormattedMessage id="fields.postcode" />}
    validate={defaultValidators}
    {...props}
  />
)
