import React from 'react'
import { styled } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { ReactComponent as InvalidIcon } from 'images/invalid.svg'
import { RejectionGirlImage } from 'components/icons'
import activeTenant from 'setup/tenant'

const NavigationLink = styled('a')`
  text-decoration: none;
`

const LoanRejectionRegion = () => (
  <Layout
    viewHeader={
      <ViewHeader
        id="regionRejection.header"
        subId="regionRejection.content"
        subValues={{
          faqs: (
            <NavigationLink
              href={activeTenant.faqLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="misc.faqs" />
            </NavigationLink>
          ),
        }}
        icon={<InvalidIcon style={{ position: 'relative', top: '-10px' }} />}
      />
    }
  >
    <Layout.Middle>
      <RejectionGirlImage />
    </Layout.Middle>
  </Layout>
)

export default LoanRejectionRegion
