import React from 'react'
import { Flex, Box } from 'components/structural'
import { MAX_WIDTH, MOBILE_MENU_BREAKPOINT } from 'constants/styles'
import { LoginButton, LogoutButton } from 'components/buttons'
import { useMenu as useMobileMenu } from 'components/Menu'
import { Logo } from 'components/Logo'
import { useWindowSizes } from 'hooks'
import { isLinkIntegration, isPayOne } from 'setup/tenant'
import { useAuth } from 'context/Auth'
import { useLoanApplication } from 'context/LoanApplication'
import { useLanguageMenu } from './useLanguageMenu'
import { NameAndEmail } from './NameAndEmail'

type TopHeaderProps = {
  maxWidth?: typeof MAX_WIDTH
  px?: number | number[]
  mx?: number | number[]
  withLogin: boolean
}

export const TopHeader = ({
  maxWidth = MAX_WIDTH,
  px = [3, 6, 4],
  mx,
  withLogin = true,
  ...rest
}: TopHeaderProps) => (
  <Flex
    justifyContent="center"
    alignSelf="stretch"
    minHeight="80px"
    bgcolor="global.bgA.main"
    sx={{ boxShadow: (theme: any) => theme.boxShadows.topHeader }}
    {...rest}
  >
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      {...{ maxWidth, px, mx }}
    >
      <Logo />
      <Flex alignItems="center">
        <NavUserInfoBox withLogin={withLogin} />
      </Flex>
    </Flex>
  </Flex>
)

export const NavUserInfoBox = ({ withLogin }: Partial<TopHeaderProps>) => {
  const { logOut, token } = useAuth()
  const { windowWidth } = useWindowSizes()
  const { menu: mobileMenu, burgerButton } = useMobileMenu()
  const { langMenu, langMenuRef, globeButton } = useLanguageMenu()
  const isMobileMenuWidth = windowWidth < MOBILE_MENU_BREAKPOINT
  const { currentLoanApplication } = useLoanApplication()
  const { merchant } = currentLoanApplication || {}

  return (
    <>
      {isMobileMenuWidth ? (
        <>
          {burgerButton}
          {mobileMenu}
        </>
      ) : (
        <>
          <Flex>
            {merchant?.name && token && (
              <NameAndEmail
                name={merchant.name}
                email={!isPayOne && merchant.email}
              />
            )}
            {!isLinkIntegration && withLogin && !token && <LoginButton />}
            {!isLinkIntegration && token && <LogoutButton logout={logOut} />}
          </Flex>
          <Box ref={langMenuRef} flexShrink="0" ml={4} mb={1}>
            {globeButton}
          </Box>
          {langMenu}
        </>
      )}
    </>
  )
}
