import React from 'react'
import fp from 'lodash/fp'
import { FormattedNumber } from 'react-intl'

export const getPercentage = (backendPercentFormat: number) =>
  fp.divide(backendPercentFormat, 100)

export const getPercentageMultiplicand = (backendPercentFormat: number) =>
  fp.divide(backendPercentFormat, 10000)

export const padEndWithPercentSign = (percentValue: number) =>
  `${percentValue} %`

export const formatPercentage = fp.flow(getPercentage, padEndWithPercentSign)

export const calculatePercentage = fp.pipe(fp.divide, fp.multiply(100), (a) =>
  a.toFixed(2),
)

/** Takes a backend supplied percentage value and displays it in an intl format. e.g. 1700.3 -> 17.03%  */
export function formattedPercentage(value: number) {
  // FormattedNumber accepts a value between 0 and 1
  const pct = fp.divide(getPercentage(value), 100)
  return (
    <FormattedNumber
      value={pct}
      // eslint-disable-next-line react/style-prop-object
      style="percent"
      minimumFractionDigits={0}
      maximumFractionDigits={2}
    />
  )
}
