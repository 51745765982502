import banxwareApple from 'images/favicons/banxware/apple-touch-icon.png'
import banxware32 from 'images/favicons/banxware/favicon-32x32.png'
import banxware16 from 'images/favicons/banxware/favicon-16x16.png'
import banxwarePin from 'images/favicons/banxware/safari-pinned-tab.svg'
import banxwareManifest from 'images/favicons/banxware/site.webmanifest'
import { ReactComponent as BanxwareLogo } from 'images/logos/banxware.svg'
import { EXPRESS_FLOW, ROUTES } from 'constants/routes'
import '!file-loader?name=banxware-[name].[ext]!../images/favicons/banxware/android-chrome-192x192.png'
import '!file-loader?name=banxware-[name].[ext]!../images/favicons/banxware/android-chrome-512x512.png'
import type { TenantConfig } from '.'

const testTenantConfig: TenantConfig = {
  tenantCode: 'PNT',
  title: 'Banxware',
  defaultLocale: 'en',
  tenantLocales: ['en', 'de', 'tr'],
  contactEmail: 'info@banxware.com',
  faqLink: 'http://buylando.webflow.io/#faq',
  imprintLink: 'https://banxware.com/imprint',
  termsOfService: 'https://docs.banxware.com/AGB%20Banxware%20MCA.pdf',
  privacyPolicy:
    'https://docs.banxware.com/Datenschutzinfo%20Banxware%20MCA.pdf',
  supportContactNumber: '00 49 30 311 937 03',
  supportWorkingHours: '09:00 - 18:00',
  iconSet: {
    appleTouch: banxwareApple,
    favicon16: banxware16,
    favicon32: banxware32,
    manifest: banxwareManifest,
    pin: banxwarePin,
    tileColor: '#da532c',
  },
  logo: {
    component: BanxwareLogo,
    width: 154,
  },
  loanApplicationFlow: EXPRESS_FLOW,
  customizeLoanOfferRoute: ROUTES.CUSTOMIZE_LOAN_OFFER_TEST,
}

export default testTenantConfig
