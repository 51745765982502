import React from 'react'
import { Box } from 'components/structural'
import { Footer } from 'components/Footer'
import { MAX_WIDTH } from 'constants/styles'
import { TopHeader } from 'components/headers/TopHeader/TopHeader'
import { Divider } from 'components/Divider'
import { ApplicationSupportInformation } from './SupportInformationDisplay'

type LayoutProps = {
  children: React.ReactNode
  viewHeader: React.ReactNode
}

export const Layout = ({ children, viewHeader }: LayoutProps) => (
  <Box style={{ minHeight: '100vh', width: '100%' }}>
    <TopHeader withLogin />
    <Box maxWidth={MAX_WIDTH} width="100%" margin="auto">
      <Box>{viewHeader}</Box>
      <Box px={[3, 6, 4]} pb={9} width="100%">
        <Box>{children}</Box>
      </Box>
      <Box px={[3, 6, 4]} mb={5}>
        <Divider />
        <ApplicationSupportInformation />
      </Box>
    </Box>
    <Footer />
  </Box>
)

export default Layout
