import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from 'components/structural'
import { Tab, Tabs, AppBar } from '@mui/material'
import { Term, PossibleTerms } from '@banxware/offer-generation/configuration'

type SwitchTermsProps = {
  availableTerms: PossibleTerms
  possibleTermsForDesiredAmount: PossibleTerms
  onChange: (term: Term) => void
  term: Term
}

const SwitchTerms = (props: SwitchTermsProps) => {
  const { availableTerms, possibleTermsForDesiredAmount, onChange, term } =
    props

  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newTerm: Term,
  ) => {
    onChange(newTerm)
  }

  useEffect(() => {
    if (!possibleTermsForDesiredAmount.includes(term)) {
      // if a term is unavailable when the slider moves, bump the selected value to the first available value in the new list of possible terms
      onChange(possibleTermsForDesiredAmount[0])
    }
  }, [possibleTermsForDesiredAmount, availableTerms, term, onChange])

  return (
    <Flex flex="1 1 auto" flexDirection={['column', 'row', 'row']}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flex="1 1 auto"
        sx={{
          border: (theme) => [
            'none',
            // @ts-expect-error Property 'borders' does not exist on type 'Theme'.
            theme.borders.primary,
            // @ts-expect-error Property 'borders' does not exist on type 'Theme'.
            theme.borders.primary,
          ],
          // @ts-expect-error Property 'borders' does not exist on type 'Theme'.
          borderLeft: (theme) => [theme.borders.primary, 'none', 'none'],
        }}
      >
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            TabIndicatorProps={{
              style: {
                height: '4px',
              },
            }}
            value={term}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="action tabs example"
          >
            {availableTerms.map((availableTerm) => (
              <Tab
                id={`action-tab-${availableTerm}`}
                aria-controls={`action-tabpanel-${availableTerm}`}
                key={availableTerm}
                value={availableTerm}
                label={
                  <FormattedMessage
                    id="loanOffer.availableTerms"
                    values={{ term: availableTerm }}
                  />
                }
                disabled={
                  !possibleTermsForDesiredAmount.includes(availableTerm)
                }
                sx={{
                  color: 'text.normal.main',
                  backgroundColor: 'global.bgA.main',
                  borderLeft: (theme) =>
                    // @ts-expect-error Property 'global' does not exist on type 'Palette'.
                    `1px solid ${theme.palette.global.line.main}`,
                  minHeight: '80px',
                  shadow: 'none',
                  '&.Mui-selected': {
                    backgroundColor: 'button.bgB.main',
                  },
                  '&.Mui-disabled': {},
                }}
              />
            ))}
          </Tabs>
        </AppBar>
      </Flex>
    </Flex>
  )
}

export default SwitchTerms
