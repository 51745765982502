import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ScrollToTop from 'hooks/ScrollToTop'
import { GlobalStyles } from './GlobalStyles'
import { DocumentHead } from './DocumentHead'
import { Routes } from './routing'
import { Providers } from './Providers'

export function App() {
  return (
    <>
      <DocumentHead />
      <Providers>
        <>
          <ScrollToTop />
          <Routes />
        </>
      </Providers>
      <GlobalStyles />
    </>
  )
}
