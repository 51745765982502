import React from 'react'
import { Form as FormikForm, Formik, Field as FormikField } from 'formik'
import { Flex, Box } from 'components/structural'
import { ReactComponent as SubmitIcon } from 'images/icons/submit.svg'
import { BackButton, Button } from 'components/buttons'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { acceptanceValidator } from 'utils/forms/validators'
import { CheckboxComponent } from 'components/Fields/CheckboxComponent'
import { LoanApplication } from '../../../../types/loanApplication'

export type LoanOptinFormValues = {
  optinOwnInitiative?: boolean
} & Pick<
  LoanApplication,
  'optinPep' | 'optinSchufa' | 'optinTerms' | 'optinFacta'
>

type LoanOptinProps = {
  goToPrevPage: () => void
  initialValues: LoanOptinFormValues
  submitLoanApplication: (values: LoanOptinFormValues) => void
}

export const LoanOptin = ({
  goToPrevPage,
  initialValues,
  submitLoanApplication,
}: LoanOptinProps) => (
  <Formik
    onSubmit={submitLoanApplication}
    alignItems="center"
    initialValues={initialValues}
    validateOnMount
    enableReinitialize
    withBack
  >
    {({ isValid }) => (
      <FormikForm data-testid="formikForm" style={{ width: '100%' }}>
        <Flex flexDirection="column">
          <FormikField
            component={CheckboxComponent}
            name="optinTerms"
            label={
              <Typography variant="body2" style={{ lineHeight: '160%' }}>
                <FormattedMessage id="optinLoan.terms" />
              </Typography>
            }
            validate={acceptanceValidator}
          />
          <FormikField
            component={CheckboxComponent}
            name="optinSchufa"
            label={
              <Typography variant="body2" style={{ lineHeight: '160%' }}>
                <FormattedMessage id="optinLoan.schufa" />
              </Typography>
            }
            validate={acceptanceValidator}
          />
          <FormikField
            component={CheckboxComponent}
            name="optinPep"
            label={
              <Typography variant="body2" style={{ lineHeight: '160%' }}>
                <FormattedMessage id="optinLoan.pep" />
              </Typography>
            }
            validate={acceptanceValidator}
          />
          {initialValues.optinOwnInitiative !== undefined && (
            <FormikField
              component={CheckboxComponent}
              name="optinOwnInitiative"
              label={
                <Typography variant="body2" style={{ lineHeight: '160%' }}>
                  <FormattedMessage id="optinLoan.initiative" />
                </Typography>
              }
              validate={acceptanceValidator}
            />
          )}
          <FormikField
            component={CheckboxComponent}
            name="optinFacta"
            label={
              <Typography variant="body2" style={{ lineHeight: '160%' }}>
                <FormattedMessage id="optinLoan.facta" />
              </Typography>
            }
            validate={acceptanceValidator}
            mb={5}
          />
        </Flex>
        <Box
          sx={{
            display: 'grid',
            width: '100%',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: '48px',
          }}
          mb={3}
        >
          <Box
            sx={{
              gridColumnStart: '1',
              gridColumnEnd: ['span 3', 'span 3', 'span 2'],
            }}
          >
            <Box mb="30px" mt="30px">
              <Typography variant="body2">
                <FormattedMessage id="applicationSummary.submitNote" />
              </Typography>
            </Box>
            <Flex>
              <BackButton onClick={goToPrevPage} />
              <Button
                type="submit"
                disabled={!isValid}
                sx={{ flex: '1 0 auto', paddingLeft: 0 }}
                data-testid="submitButton"
                icon={<SubmitIcon />}
              >
                <FormattedMessage id="applicationSummary.submitApplication" />
              </Button>
            </Flex>
          </Box>
        </Box>
      </FormikForm>
    )}
  </Formik>
)
