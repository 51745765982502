import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import industries from 'data/industries.json'
import { requiredValidator } from 'utils/forms/validators'
import { Field as FormikField } from 'formik'
import { AutocompleteComponent } from 'components/Fields/AutocompleteComponent'
import { useLanguage } from '../../context/Language'

export const IndustryChildCode = ({ industryParentCode }) => {
  const { locale } = useLanguage()
  const industryValues = useMemo(
    () =>
      industryParentCode
        ? _.map(
            _.get(industries, [locale, industryParentCode, 'values']),
            (value, key) => ({
              value: key,
              message: value,
            }),
          )
        : [],
    [locale, industryParentCode],
  )
  return (
    <FormikField
      component={AutocompleteComponent}
      name="industryChildCode"
      id="industryChildCode"
      data-testid="formFieldChildCode"
      label={<FormattedMessage id="fields.industry" />}
      validate={requiredValidator}
      options={industryValues}
      disabled={industryValues.length === 0}
    />
  )
}
