import React, { useState } from 'react'
import activeTenant from 'setup/tenant'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessage } from 'react-intl'
import { Box, Flex } from 'components/structural'
import { GlobeIcon, SelectedIcon } from 'components/icons'
import { MenuButton as Button } from './MenuButton'
import { useLanguage } from '../../context/Language'

const useStyles = makeStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
})

const makeSummaryStyles = (expanded) =>
  makeStyles((theme) => ({
    root: {
      borderBottom: expanded ? '1px solid rgba(0, 0, 0, 0.1)' : 'none',
    },
    expandIcon: {
      position: 'absolute',
      padding: 0,
      fontSize: 0,
      right: theme.spacing(3),
      [theme.breakpoints.up('tablet')]: {
        right: theme.spacing(6),
      },
    },
  }))

export const LanguageAccordion = ({ onOptionClick }) => {
  const [expanded, setExpanded] = useState(true)
  const onChange = (__, newExpanded) => setExpanded(newExpanded)
  const { locale } = useLanguage()
  const useSummaryStyles = makeSummaryStyles(expanded)
  const classes = useStyles()
  const summaryClasses = useSummaryStyles()
  return (
    <Accordion {...{ expanded, onChange, classes }} square>
      <AccordionSummary classes={summaryClasses}>
        <Button data-cy="langMenuOpen">
          <Box width="24px" height="24px" mr={3}>
            <GlobeIcon />
          </Box>
          <Typography variant="body1" color="text.heading.main">
            <FormattedMessage id="topHeader.changeLanguage" />
          </Typography>
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <Flex flexDirection="column" flex="1 1 auto">
          {activeTenant.tenantLocales.map((loc) => (
            <Button
              key={loc}
              flex="1 1 auto"
              data-cy={`langMenuLocale-${loc}`}
              onClick={() => onOptionClick(loc)}
            >
              <Flex>
                <Box width="24px" height="24px" p="2px" mr={3}>
                  {loc === locale && <SelectedIcon />}
                </Box>
                <Typography variant="body1" color="text.heading.main">
                  <FormattedMessage id={`lang.${loc}`} />
                </Typography>
              </Flex>
            </Button>
          ))}
        </Flex>
      </AccordionDetails>
    </Accordion>
  )
}
