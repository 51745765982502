import React, { useMemo } from 'react'
import { Flex, Box } from 'components/structural'
import { Typography } from '@mui/material'
import { matchPath, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ButtonRaw } from 'components/buttons'
import { ReactComponent as DoneInactiveIcon } from 'images/bullets/done-inactive.svg'
import { ReactComponent as OffIcon } from 'images/bullets/off.svg'
import { LOAN_APPLICATION_STATUS } from 'constants/loanApplication'
import { useNavigation } from 'routing/hooks/useNavigation'
import { generateSteps } from './steps'
import { OnIcon, DoneIcon } from './icons'
import { useLoanApplication } from '../../context/LoanApplication'
import { useConfiguration } from '../../context/Configuration'

export const ApplicationProgress = () => {
  const { identityVerification, currentLoanApplication } = useLoanApplication()
  const { pathname } = useLocation()
  const { navigateTo } = useNavigation()
  const { configuration } = useConfiguration()
  const { submitted, status, businessDetails } = currentLoanApplication || {}

  const isExpressSubmitted =
    status === LOAN_APPLICATION_STATUS.EXPRESS_SUBMITTED
  const isPhase1 =
    !status ||
    status === LOAN_APPLICATION_STATUS.INITIAL ||
    status === LOAN_APPLICATION_STATUS.ELIGIBLE ||
    status === LOAN_APPLICATION_STATUS.EXPRESS_SUBMITTED

  // @ts-ignore
  const { firmenwissenEnabled, tenant: { codatEnabled } = {} } =
    configuration || {}
  const steps = generateSteps({
    firmenwissenEnabled,
    codatEnabled,
    legalForm: businessDetails?.legalForm,
    isPhase1,
  })

  const isLoanApplicationSubmitted = submitted || isExpressSubmitted

  const allDone = useMemo(
    () =>
      Boolean(identityVerification?.stakeholders) &&
      identityVerification.stakeholders.every(
        (stakeholder) => stakeholder?.status === 'READY',
      ),
    [identityVerification],
  )

  const currentStepIndex = steps.findIndex((step) =>
    step.routes.some((route) =>
      matchPath(pathname, { path: route, exact: true }),
    ),
  )

  return (
    <Flex flexDirection="column">
      {steps.map((step, index) => {
        const lastStepIndex = steps.length - 1
        const verificationStepIndex = steps.length
        const stepCompleted =
          index < currentStepIndex ||
          allDone ||
          (lastStepIndex !== index && isLoanApplicationSubmitted)
        const currentStep = index === currentStepIndex
        const beforeCompletedLineRule = stepCompleted
          ? 'global.icoA.main'
          : 'global.icoOff.main'
        const inaccessiblePhase1Steps =
          !isPhase1 &&
          (step.id === 'applicationProgress.general' ||
            step.id === 'applicationProgress.offers')
        const beforeCompletedTextRule =
          stepCompleted || currentStep ? 'text.normal.main' : 'text.subtle.main'
        const getIcon = () => {
          if (stepCompleted) {
            if (
              currentStepIndex === lastStepIndex ||
              currentStepIndex === verificationStepIndex ||
              inaccessiblePhase1Steps
            ) {
              return <DoneInactiveIcon />
            }
            return <DoneIcon />
          }
          if (currentStep) {
            return <OnIcon />
          }
          return <OffIcon />
        }
        return (
          <Flex key={step.id}>
            <Flex flexDirection="column" alignItems="center" flex="0 0 16px">
              <Flex
                height="16px"
                width="16px"
                flex="0 0 16px"
                my={1}
                justifyContent="center"
                alignItems="center"
              >
                {getIcon()}
              </Flex>
              {index !== lastStepIndex && (
                <Box height="100%" width="16px">
                  <Box
                    width="1px"
                    ml="8px"
                    height="100%"
                    sx={{
                      borderRightStyle:
                        index === lastStepIndex - 1 ? 'dashed' : 'solid',
                      borderRightWidth: '1px',
                      borderRightColor: !isLoanApplicationSubmitted
                        ? beforeCompletedLineRule
                        : 'global.icoOff.main',
                    }}
                  />
                </Box>
              )}
            </Flex>
            <Box pl={3} pb={4}>
              <ButtonRaw
                style={{
                  cursor:
                    !isLoanApplicationSubmitted && step.linksTo && stepCompleted
                      ? 'pointer'
                      : 'unset',
                  textAlign: 'left',
                }}
                onClick={() => {
                  if (
                    !isLoanApplicationSubmitted &&
                    step.linksTo &&
                    stepCompleted
                  )
                    navigateTo(step.linksTo)
                }}
                data-testid={`${step.id}.button`}
              >
                <Typography
                  variant="body2"
                  color={
                    isLoanApplicationSubmitted || inaccessiblePhase1Steps
                      ? 'text.subtle.main'
                      : beforeCompletedTextRule
                  }
                >
                  <FormattedMessage id={step.id} />
                </Typography>
              </ButtonRaw>
            </Box>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default ApplicationProgress
