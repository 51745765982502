import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { requiredValidator } from 'utils/forms/validators'
import { Field as FormikField } from 'formik'
import { AutocompleteComponent } from './AutocompleteComponent'

export const Salutation = (props) => {
  const intl = useIntl()
  const options = useMemo(
    () => [
      { message: intl.formatMessage({ id: 'salutations.mr' }), value: 'mr' },
      { message: intl.formatMessage({ id: 'salutations.mrs' }), value: 'mrs' },
      {
        message: intl.formatMessage({ id: 'salutations.other' }),
        value: 'other',
      },
    ],
    [intl],
  )

  return (
    <FormikField
      component={AutocompleteComponent}
      data-testid="formFieldSalutation"
      name="title"
      id="title"
      label={<FormattedMessage id="fields.salutation" />}
      validate={requiredValidator}
      options={options}
      {...props}
    />
  )
}
