import { useCallback, useState } from 'react'
import { useLoanApplication } from 'context/LoanApplication'
import { useError } from 'context/Error'
import { useLoans } from 'context/Loans'
import { useAuth } from 'context/Auth'

export function useInitializeLoanApplication() {
  const { token } = useAuth()
  const { fetchLoanApplications } = useLoanApplication()
  const { fetchLoans } = useLoans()
  const { handleApiRequestError } = useError()
  const [isLoanApplicationInitialized, setIsLoanApplicationInitialized] =
    useState<boolean>(false)

  const initializeLoanApplication = useCallback(async () => {
    try {
      if (!token) {
        throw new Error('User is not authenticated')
      }
      const [loansAndApplications] = await Promise.all([
        fetchLoanApplications(),
        fetchLoans(),
      ])

      if (!loansAndApplications?.length) {
        throw new Error('No loan applications in response')
      }
    } catch (error) {
      handleApiRequestError(error)
    } finally {
      setIsLoanApplicationInitialized(true)
    }
  }, [token, fetchLoanApplications, fetchLoans, handleApiRequestError])

  return {
    initializeLoanApplication,
    isLoanApplicationInitialized,
  }
}
