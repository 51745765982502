import React from 'react'
import { Flex } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Grid } from 'components/Grid'
import { Button } from 'components/buttons'

type StatusProps = {
  handleNavigateToRoot: () => void
  status: string
}

export function Status(props: StatusProps) {
  return (
    <Flex flexDirection="column" flex="1 0 auto" width="100%">
      <Grid columns={3}>
        <Grid.Column
          sx={{
            gridColumnEnd: ['span 3', 'span 3', 'span 2'],
          }}
        >
          <Typography variant="body2">
            <FormattedMessage
              id={`panther2Go.resetPassword.${
                props.status || 'sent'
              }.subheading`}
            />
          </Typography>
          <Button
            type="submit"
            style={{
              marginTop: '24px',
              alignSelf: 'center',
              borderRadius: 0,
              width: '100%',
            }}
            onClick={props.handleNavigateToRoot}
          >
            <FormattedMessage
              id={`panther2Go.resetPassword.${
                props.status || 'sent'
              }.buttonText`}
            />
          </Button>
        </Grid.Column>
      </Grid>
    </Flex>
  )
}
