import banxwareApple from 'images/favicons/banxware/apple-touch-icon.png'
import banxware32 from 'images/favicons/banxware/favicon-32x32.png'
import banxware16 from 'images/favicons/banxware/favicon-16x16.png'
import banxwarePin from 'images/favicons/banxware/safari-pinned-tab.svg'
import banxwareManifest from 'images/favicons/banxware/site.webmanifest'
import { ReactComponent as BanxwareLogo } from 'images/logos/banxware.svg'
import { DEFAULT_FLOW, ROUTES } from 'constants/routes'
import type { LoanApplicationFlow, LoanApplicationRoute } from 'types/routes'
import banxyTenantConfig, {
  palette as banxyPalette,
  shape as banxyShape,
} from './banxy'
import '!file-loader?name=banxware-[name].[ext]!../images/favicons/banxware/android-chrome-192x192.png'
import '!file-loader?name=banxware-[name].[ext]!../images/favicons/banxware/android-chrome-512x512.png'
import getRaoulTenantConfig from './getRaoul'
import banxwareTenantConfig from './banxware'
import telecashTenantConfig from './telecash'
import payoneTenantConfig from './payone'
import testTenantConfig from './testTenant'

export const tenants = {
  BANXY: {
    tenantCode: 'BXY',
    tenantDomain: 'banxy',
  },
  GET_RAOUL: {
    tenantCode: 'GRL',
    tenantDomain: 'getraoul',
  },
  BANXWARE: {
    tenantCode: 'BXW',
    tenantDomain: 'banxware',
  },
  PANTHER_TO_GO: {
    tenantCode: 'PNT',
    tenantDomain: 'panther',
  },
  TELECASH: {
    tenantCode: 'TLC',
    tenantDomain: 'telecash',
  },
  TEST_TENANT: {
    tenantCode: 'TEST',
    tenantDomain: 'testtenant',
  },
  PAYONE: {
    tenantCode: 'PAY',
    tenantDomain: ['haendlerfinanzierung', 'payone'],
  },
} as const

type TenantCodeKeys = keyof typeof tenants
type TenantDefinition = typeof tenants[TenantCodeKeys]
export type TenantCode = typeof tenants[TenantCodeKeys]['tenantCode']

export type ConfiguredLocales = 'en' | 'de' | 'tr'

type LinkingPlatform = {
  value: string
  message: string
  status: 'Linked' | 'Pending' | null
  redirectUrl: string
}

export type TenantOverrides = {
  translationOverrideSuffix: string
  customLinkingPlatforms?: Array<LinkingPlatform>
}

export type IconSet = {
  appleTouch: any
  favicon16: any
  favicon32: any
  manifest: any
  pin: any
  tileColor: string
}

export type TenantPalette = {
  global: {
    bgA: string
    bgB: string
    line: string
    icoA: string
    icoB: string
    icoOff: string
    errorRed: string
    okGreen: string
    onDark: string
  }
  header: {
    bg: string
    title: string
    text: string
    link: string
  }
  input: {
    bgA: string
    bgB: string
    stroke: string
    placeholder: string
    label: string
    value: string
    ico: string
    icoOff: string
  }
  text: {
    normal: string
    heading: string
    link: string
    subtle: string
    off: string
  }
  button: {
    bgA: string
    bgB: string
    bgC: string
    labelA: string
    labelB: string
    labelC: string
  }
}

export type TenantShape = {
  borderRadius: number
  fieldBorderRadius: string
}

/** Values for configuring a tenant - most of these will need to be passed when configuring a tenant but defaults are set as fallbacks */
export type TenantConfig = {
  tenantCode: TenantCode
  loanApplicationFlow: LoanApplicationFlow
} & Partial<{
  // The default banxware wide min repayment percentage for dynamic repayments - this is currently set as an arbitrary fixed number across everything
  minRepaymentPercentage: number
  hubspotPortalId: string
  name: string
  title: string
  iconSet: IconSet
  contactEmail: string
  customizeLoanOfferRoute: LoanApplicationRoute
  faqLink: string
  imprintLink: string
  termsOfService: string
  privacyPolicy: string
  supportContactNumber: string
  supportWorkingHours: string
  tenantOverrides: TenantOverrides
  theme: {
    palette: TenantPalette
    shape: TenantShape
  }
  tenantLocales: Array<ConfiguredLocales>
  tenantSpecific: {
    industries: Array<{ value: string; id: string }>
  }
  defaultLocale: ConfiguredLocales
  gtmId: string
  logo: {
    component: any
    width?: number
  }
  isOnlyMerchantPortal: boolean
}>

const defaultTenantConfigValues: TenantConfig = {
  tenantCode: 'PNT',
  minRepaymentPercentage: 0.75,
  hubspotPortalId: '8646589',
  title: 'Banxware',
  defaultLocale: 'en',
  tenantLocales: ['en', 'de', 'tr'],
  contactEmail: 'info@banxware.com',
  faqLink: 'http://buylando.webflow.io/#faq',
  imprintLink: 'https://banxware.com/imprint',
  termsOfService: 'https://docs.banxware.com/AGB%20Banxware%20MCA.pdf',
  privacyPolicy:
    'https://docs.banxware.com/Datenschutzinfo%20Banxware%20MCA.pdf',
  supportContactNumber: '00 49 30 311 937 03',
  supportWorkingHours: '09:00 - 18:00',
  iconSet: {
    appleTouch: banxwareApple,
    favicon16: banxware16,
    favicon32: banxware32,
    manifest: banxwareManifest,
    pin: banxwarePin,
    tileColor: '#da532c',
  },
  logo: {
    component: BanxwareLogo,
    width: 154,
  },
  loanApplicationFlow: DEFAULT_FLOW,
  customizeLoanOfferRoute: ROUTES.CUSTOMIZE_LOAN_OFFER,
  theme: {
    palette: banxyPalette,
    shape: banxyShape,
  },
}

const tenantConfigs: { [tenantCode in TenantCode]: TenantConfig } = {
  BXY: { ...defaultTenantConfigValues, ...banxyTenantConfig },
  GRL: { ...defaultTenantConfigValues, ...getRaoulTenantConfig },
  BXW: { ...defaultTenantConfigValues, ...banxwareTenantConfig },
  TLC: { ...defaultTenantConfigValues, ...telecashTenantConfig },
  PAY: { ...defaultTenantConfigValues, ...payoneTenantConfig },
  TEST: {
    ...defaultTenantConfigValues,
    ...testTenantConfig,
    tenantCode: 'TEST',
  },
  PNT: defaultTenantConfigValues,
}

export const getSelectedTenantCode: () => TenantCode = () =>
  Object.keys(tenants).reduce((result, tenantKey) => {
    const t = tenants[tenantKey] as TenantDefinition
    if (
      Array.isArray(t.tenantDomain) &&
      t.tenantDomain.some((domain) =>
        window.location.href.includes(domain as string),
      )
    ) {
      result = t.tenantCode
      return result
    }
    if (window.location.href.includes(t.tenantDomain as string)) {
      result = t.tenantCode
    }
    return result
  }, 'PNT') as TenantCode

export default tenantConfigs
