import React from 'react'
import { Layout } from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { isTeleCash, isLinkIntegration } from 'setup/tenant'
import { BusinessIcon } from 'components/icons'
import { ReactComponent as BusinessTeleCashIcon } from 'images/business-telecash.svg'
import { ROUTES } from 'constants/routes'
import { CUSTOMER_PROGRESS } from 'constants/loanApplication'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useError } from 'context/Error'
import { useLoanApplication } from 'context/LoanApplication'
import { useNavigation } from 'routing/hooks/useNavigation'
import { useLanguage } from 'context/Language'
import { DefaultForm } from './components/DefaultForm'

export const BusinessDetails = () => {
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const { error } = useError()
  const { goToPrevPage, goToNextPage } = useFlowNavigation()
  const { navigateTo } = useNavigation()
  const { locale } = useLanguage()

  const initialValues = {
    industryParentCode:
      currentLoanApplication.businessDetails.industryCode?.substring(0, 1) ||
      '',
    industryChildCode:
      currentLoanApplication.businessDetails.industryCode?.substring(1) || '',
    businessDetails: {
      ...currentLoanApplication.businessDetails,
      numberOfEmployees: currentLoanApplication.businessDetails
        .numberOfEmployees
        ? new Intl.NumberFormat(locale).format(
            currentLoanApplication.businessDetails.numberOfEmployees,
          )
        : '',
    },
    loanPurpose: currentLoanApplication.loanPurpose,
    tenantSpecific: currentLoanApplication.tenantSpecific,
  }

  const handleSubmit = ({
    industryChildCode,
    industryParentCode,
    businessDetails,
    loanPurpose,
    tenantSpecific,
  }) => {
    const { numberOfEmployees, ...restBusinessDetails } = businessDetails
    const numberOfEmployeesInUsFormat = String(
      parseFloat(numberOfEmployees.replace(',', '.')),
    )

    updateLoanApplicationById({
      loanApplicationId: currentLoanApplication.id,
      loanApplication: {
        ...currentLoanApplication,
        customerProgress: CUSTOMER_PROGRESS.BUSINESS_DETAILS,
        loanPurpose,
        businessDetails: {
          ...restBusinessDetails,
          loanPurpose,
          address: {
            ...currentLoanApplication.businessDetails.address,
            ...restBusinessDetails.address,
          },
          industryCode: `${industryParentCode}${industryChildCode}`,
          numberOfEmployees: numberOfEmployeesInUsFormat,
        },
        tenantSpecific: {
          ...currentLoanApplication.tenantSpecific,
          ...(tenantSpecific || {}),
        },
      },
    })
    goToNextPage()
  }

  const handleGoBack = () => {
    if (currentLoanApplication?.businessDetails?.crefoId) {
      navigateTo(ROUTES.COMPANY_SEARCH)
    } else {
      goToPrevPage()
    }
  }

  // check done because of hydration issues, please remove once issue resolved:

  if (
    (!isLinkIntegration &&
      currentLoanApplication.businessDetails?.address?.address1 ===
        'Teststrasse') ||
    !currentLoanApplication
  ) {
    return null
  }

  return (
    <Layout
      viewHeader={
        <ViewHeader
          icon={
            isTeleCash ? (
              <BusinessTeleCashIcon />
            ) : (
              <BusinessIcon style={{ position: 'relative', top: '-10px' }} />
            )
          }
          id="businessDetails.header.expressApplication"
          subId="expressApplicationBusinessDetails.subheading"
        />
      }
      withProgress
    >
      <Layout.MiddleAndRight>
        <DefaultForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          handleGoBack={handleGoBack}
          legalForm={currentLoanApplication.businessDetails?.legalForm}
          error={error}
        />
      </Layout.MiddleAndRight>
    </Layout>
  )
}

export default BusinessDetails
