import React from 'react'
import fp from 'lodash/fp'
import { Flex, Box } from 'components/structural'
import makeStyles from '@mui/styles/makeStyles'
import { FormControlLabel, Checkbox } from '@mui/material'

import { InputGrid } from 'components/Input'
import { useHint } from 'hooks'

import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from './icons'

const useCheckboxStyles = makeStyles({
  root: {
    position: 'relative',
    alignSelf: 'flex-start',
    marginLeft: '16px',
    marginRight: '16px',
  },
})

const useRippleStyles = makeStyles((theme) => ({
  child: {
    backgroundColor: '#7d759a',
  },
  rippleVisible: {
    opacity: 0.5,
    animation: `$enter 550ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes enter': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0.1,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0.5,
    },
  },
}))

export const CheckboxComponent = ({
  field,
  form: { errors, touched, submitCount },
  label,
  labelProps = {},
  mb = 3,
  inputContainerSx = {},
  hintContainerSx = {},
  hint,
  boxed,
  ...props
}) => {
  const classes = useCheckboxStyles()
  const rippleClasses = useRippleStyles()
  const submitted = submitCount > 0
  const fieldTouched = fp.get(field.name)(touched)
  const fieldError = fp.get(field.name)(errors)
  const showError =
    (fieldTouched && !!fieldError) || (submitted && !!fieldError)
  const { hintComponent, TriggerComponent } = useHint({
    hint,
    boxed,
    error: showError,
    errorId: fieldError,
  })
  return (
    <InputGrid {...{ mb }}>
      <InputGrid.Input sx={inputContainerSx}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          sx={{
            color: showError ? 'global.errorRed.main' : 'text.heading.main',
          }}
        >
          <FormControlLabel
            margin="dense"
            label={
              <span
                sx={{
                  color: 'inherit',
                }}
              >
                {label}
              </span>
            }
            variant="caption"
            control={
              <Checkbox
                checked={Boolean(field.value) || false}
                checkedIcon={<CheckboxCheckedIcon />}
                icon={<CheckboxUncheckedIcon />}
                TouchRippleProps={{ classes: rippleClasses }}
                {...field}
                {...props}
                {...{ classes }}
              />
            }
            {...labelProps}
          />
          <TriggerComponent ml={2} mr={3} flex="1 0 auto" />
        </Flex>
      </InputGrid.Input>
      <InputGrid.Hint sx={hintContainerSx} {...{ boxed }}>
        <Box>{hintComponent}</Box>
      </InputGrid.Hint>
    </InputGrid>
  )
}
