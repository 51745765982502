import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { Button, BackButton } from 'components/buttons'
import { Grid } from 'components/Grid'
import activeTenant, { isDynamicRepayment } from 'setup/tenant'
import { useNavigation } from 'routing/hooks/useNavigation'
import { Divider } from 'components/Divider'
import ButtonLink from 'components/buttons/ButtonLink/ButtonLink'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useLanguage } from 'context/Language'
import { createCurrencyFormatter } from 'utils/currency'

type LoanOfferBoxProps = {
  amount?: number
  duration?: number
  fee?: number
  rate?: number
  onClick: () => Promise<void>
}

export const LoanOfferBox = (props: LoanOfferBoxProps) => {
  const { amount, duration, fee, rate, onClick } = props

  const { goToPrevPage } = useFlowNavigation()
  const { navigateTo } = useNavigation()
  const { locale } = useLanguage()
  const formatter = createCurrencyFormatter(locale)

  const handleNavigateToCustomizeLoanOffer = () => {
    navigateTo(activeTenant.customizeLoanOfferRoute)
  }

  const totalOwed = amount && fee && amount + fee
  // @FIXME this is a temp work-around for fixed rates
  const tempRate = isDynamicRepayment
    ? rate
    : totalOwed && duration && totalOwed / duration

  return (
    <Grid.Column start={2} span={2}>
      <Flex flexDirection="column">
        {amount && (
          <>
            <Flex justifyContent="center">
              <Typography sx={{ color: 'text.heading.main' }}>
                <FormattedMessage id="loanOffer.payoutAmount" />
              </Typography>
            </Flex>

            <Flex
              justifyContent="center"
              sx={{
                mt: '5px',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: 'text.normal.main',
                  fontSize: ['60px', '60px', '60px'],
                  lineHeight: ['80px', '80px', '80px'],
                }}
                data-testid="loanOfferAmount"
              >
                {formatter.formatInt(amount)}
              </Typography>
            </Flex>

            <ButtonLink
              data-testid="customizeLoanOfferButton"
              Icon={null}
              onClick={handleNavigateToCustomizeLoanOffer}
              sx={{
                mb: 6,
              }}
            >
              <FormattedMessage id="loanOffers.customizeOffer.actionText" />
            </ButtonLink>
          </>
        )}

        <Box mb="60px">
          {duration && (
            <Flex>
              <Box mb={[2, 2, '10px']} mr="20px">
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  <FormattedMessage
                    values={{
                      count: duration,
                    }}
                    id="misc.month"
                  />
                </Typography>
              </Box>
              <Typography>
                <FormattedMessage id="misc.estimatedRepaymentTime" />
              </Typography>
            </Flex>
          )}

          {fee && (
            <Flex>
              <Box mb={[2, 2, '10px']} mr="20px">
                <Typography variant="body1">{` + ${formatter.formatInt(
                  fee,
                )}`}</Typography>
              </Box>
              <Typography>
                <FormattedMessage id="loanOffer.repaymentFee" />
              </Typography>
            </Flex>
          )}
          {totalOwed && (
            <Flex>
              <Box mb={[2, 2, '10px']} mr="20px">
                <Typography variant="body1">
                  {`= ${formatter.formatInt(totalOwed)}`}
                </Typography>
              </Box>
              <Typography variant="body1">
                <FormattedMessage id="loanOffer.totalOwed" />
              </Typography>
            </Flex>
          )}

          {tempRate && (
            <Flex>
              <Box mb={[2, 2, '10px']} mr="20px">
                <Typography variant="body1">
                  {isDynamicRepayment
                    ? `${tempRate}%`
                    : formatter.formatInt(tempRate)}
                </Typography>
              </Box>
              <Typography variant="body1">
                <FormattedMessage
                  id={
                    isDynamicRepayment
                      ? 'loanOffer.repaymentPercentage'
                      : 'loanOffer.monthlyRepayment'
                  }
                />
              </Typography>
            </Flex>
          )}
        </Box>
        <Divider sx={{ display: ['none', 'none', 'block'] }} />
        <Flex mb="15px" mt="15px">
          <BackButton onClick={goToPrevPage} />
          <Button data-testid="loanOfferButton" withArrow onClick={onClick}>
            <FormattedMessage id="loanOffer.requestLoan" />
          </Button>
        </Flex>
      </Flex>
    </Grid.Column>
  )
}
