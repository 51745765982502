import _ from 'lodash'

export const convertKiloEuroToEuro = (kiloEuro: number) =>
  _.multiply(kiloEuro, 1000)
export const convertEuroToKiloEuro = (euro: number) => _.divide(euro, 1000)
export const convertEuroCentsToEuro = (cents: number) => _.divide(cents, 100)
export const convertEuroToEuroCents = (euro: number) => _.multiply(euro, 100)

export const convertKiloEuroToEuroCents = _.flow(
  convertKiloEuroToEuro,
  convertEuroToEuroCents,
)

export const convertEuroCentsToKiloEuro = _.flow(
  convertEuroCentsToEuro,
  convertEuroToKiloEuro,
)
