import { ConfiguredLocales } from 'tenants'

type CustomNumberFormat = Intl.NumberFormat & {
  formatInt: (value: number | bigint) => string
  revert: (value: string) => number
}

export const createNumberLocaleFormatter = (
  locale: ConfiguredLocales,
  options: Intl.NumberFormatOptions,
): CustomNumberFormat => {
  const formatter = new Intl.NumberFormat(locale, options) as CustomNumberFormat
  formatter.formatInt = (value: number | bigint) =>
    formatter.format(value).replace(/\D00(?=\D*$)/, '')
  formatter.revert = (number: string) => {
    const thousandSeparator = (11111).toLocaleString(locale).replace(/1/g, '')
    const decimalSeparator = (1.1).toLocaleString(locale).replace(/1/g, '')
    return Number(
      number
        .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
        .replace(new RegExp(`\\${decimalSeparator}`), '.')
        .replace(/[ €]/g, ''),
    )
  }

  return formatter
}
