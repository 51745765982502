import React from 'react'
import { ErrorProvider } from './Error'
import { LanguageProvider } from './Language'
import { DocumentsProvider } from './Documents'
import { AccountProvider } from './Account'
import { LoansProvider } from './Loans'
import { LoanOfferProvider } from './LoanOffer'
import { LoanApplicationProvider } from './LoanApplication'
import { OwnersProvider } from './Owners'
import { AuthProvider } from './Auth'
import { ConfigurationProvider } from './Configuration'

type GlobalContextProvidersProps = {
  children: React.ReactNode
}

export const GlobalContextProviders = (props: GlobalContextProvidersProps) => {
  const { children } = props

  return (
    <LanguageProvider>
      <ErrorProvider>
        <AuthProvider>
          <ConfigurationProvider>
            {React.Children.only(children)}
          </ConfigurationProvider>
        </AuthProvider>
      </ErrorProvider>
    </LanguageProvider>
  )
}

type LoanApplicationContextProvidersProps = {
  children: React.ReactNode
}

export const LoanApplicationContextProviders = (
  props: LoanApplicationContextProvidersProps,
) => {
  const { children } = props

  return (
    <AccountProvider>
      <LoansProvider>
        <LoanOfferProvider>
          <LoanApplicationProvider>
            <OwnersProvider>
              <DocumentsProvider>
                {React.Children.only(children)}
              </DocumentsProvider>
            </OwnersProvider>
          </LoanApplicationProvider>
        </LoanOfferProvider>
      </LoansProvider>
    </AccountProvider>
  )
}
