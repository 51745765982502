import { BREAKPOINTS, SPACING } from 'constants/styles'

export const baseMuiTheme = {
  spacing: SPACING,
  typography: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    h1: {
      fontFamily: 'Marr Sans Cond',
      fontSize: '32px',
      lineHeight: '40px',
      [`@media (min-width:${BREAKPOINTS.desktop})`]: {
        lineHeight: '54px',
        fontSize: '48px',
      },
    },
    h3: {
      fontFamily: 'Marr Sans Cond',
      fontSize: '32px',
      lineHeight: '40px',
      [`@media (min-width:${BREAKPOINTS.desktop})`]: {
        lineHeight: '110%',
        fontSize: '36px',
      },
    },
    h4: {
      fontFamily: 'Marr Sans Cond',
      fontSize: '24px',
      lineHeight: '120%',
      [`@media (min-width:${BREAKPOINTS.desktop})`]: {
        lineHeight: '110%',
        fontSize: '28px',
      },
    },
    h5: {
      fontSize: '18px',
      lineHeight: '160%',
      fontWeight: 500,
    },
    // Text/Normal
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    // Text/Small
    body2: {
      fontSize: '14px',
      lineHeight: '160%',
    },
    // Text/Tiny
    caption: {
      fontSize: '12px',
      lineHeight: '18px',
    },
    button: {
      fontSize: '18px',
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      ...Object.keys(BREAKPOINTS).reduce(
        (accumulator, label) => ({
          ...accumulator,
          [label]: parseInt(BREAKPOINTS[label], 10),
        }),
        { mobile: 0 },
      ),
    },
  },
  palette: {
    tonalOffset: {
      light: 0.92,
      dark: 0.2,
    },
  },
  borders: {},
  // Can't use 'shadows', MUI expects it to be an array
  boxShadows: {
    topHeader: 'inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1)',
    offer: '0px 3px 0px #eef4fb',
    offerRight: 'inset -1px 0px 0px #cad2dc',
    accordion: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1)',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          // necessary for e.g. the accordion to not get a slight box shadow
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      // prevent accordion from growing slightly when expanding
      styleOverrides: {
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordion: {
      // prevent accordion from growing slightly when expanding
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControl: {
      // uniform height of all form controls
      styleOverrides: {
        root: {
          height: '78px',
          justifyContent: 'center',
        },
      },
    },
    MuiCheckbox: {
      // customize spacing around check boxes
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingLeft: 0,
          paddingBottom: 0,
          paddingRight: 0,
          marginRight: '16px',
        },
      },
    },
    MuiInput: {
      // center things in inputs
      styleOverrides: {
        root: {
          height: '100%',
          width: '100%',
        },
        formControl: {
          'label + &': {
            marginTop: 0,
          },
        },
        input: {
          boxSizing: 'border-box',
          padding: 0,
          height: '100%',
          width: '100%',
          paddingTop: '22px',
          paddingLeft: '17px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '18px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 1,
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '16px',
          paddingRight: '80px',
          pointerEvents: 'none',
          // 2 * line height
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          overflow: 'visible',
        },
        formControl: {
          left: '17px',
          transform: 'translate(0, 23px) scale(1)',
        },
        shrink: {
          transform: 'translate(0, 10px) scale(0.85)',
        },
      },
    },
    MuiButton: {
      // needed for button styling
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          lineHeight: 1,
        },
        contained: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:disabled': {
            color: '#CAD2DC',
            border: '1px solid #CAD2DC',
            backgroundColor: '#EEF4FB',
          },
        },
        containedSecondary: {
          textTransform: 'none',
          color: '#29118B',
          border: '1px solid #CAD2DC',
          '&:hover': {
            border: '1px solid #29118B',
          },
        },
        outlined: {
          textTransform: 'none',
          border: '1px solid #CAD2DC',
        },
      },
    },
  },
}
