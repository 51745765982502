import React from 'react'
import { InputGrid, InputWrapper } from 'components/Input'
import fp from 'lodash/fp'

import { useHint } from 'hooks'
import { Switch } from 'components/Switch'

export const SwitchComponent = ({
  field: { name, value },
  form: { touched, errors, submitCount, setFieldValue },
  hint,
  mb = 3,
  boxed,
  inputContainerStyles = {},
  inputWrapperSx = {},
  hintContainerSx = {},
  labelId,
}) => {
  const submitted = submitCount > 0
  const fieldTouched = fp.get(name)(touched)
  const fieldError = fp.get(name)(errors)
  const showError =
    (fieldTouched && !!fieldError) || (submitted && !!fieldError)
  const { hintComponent, TriggerComponent } = useHint({
    hint,
    boxed,
    error: showError,
    errorId: fieldError,
  })

  return (
    <InputGrid mb={mb}>
      <InputGrid.Input sx={inputContainerStyles} boxed={boxed}>
        <InputWrapper error={showError} sx={inputWrapperSx} boxed={boxed}>
          <Switch
            {...{ labelId }}
            data-testid={labelId}
            triggerHintComponent={<TriggerComponent />}
            isYes={value}
            onYes={() => setFieldValue(name, true)}
            onNo={() => setFieldValue(name, false)}
          />
        </InputWrapper>
      </InputGrid.Input>
      <InputGrid.Hint sx={hintContainerSx} boxed={boxed}>
        {hintComponent}
      </InputGrid.Hint>
    </InputGrid>
  )
}
