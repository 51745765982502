import { domainEnvironment } from 'setup/environment'

const API_BASE_URL_BY_ENVIRONMENT = Object.freeze({
  DEV: 'https://api-dev.pc-in.net',
  STG: 'https://api-stg.pc-in.net',
  PROD: 'https://api.banxware.com',
})

const API_URL_BY_ENVIRONMENT = Object.freeze({
  DEV: 'https://api-dev.pc-in.net/api/v1',
  STG: 'https://api-stg.pc-in.net/api/v1',
  PROD: 'https://api.banxware.com/api/v1',
})

const PANTHER_SERVICES_URL_BY_ENVIRONMENT = Object.freeze({
  DEV: 'https://panther-services-api-dev.pc-in.net',
  STG: 'https://panther-services-api-stg.pc-in.net',
  PROD: 'https://panther-services-api.banxware.com',
})

export const API_BASE_URL =
  API_BASE_URL_BY_ENVIRONMENT[domainEnvironment] ||
  API_BASE_URL_BY_ENVIRONMENT.DEV

export const API_URL =
  API_URL_BY_ENVIRONMENT[domainEnvironment] || API_URL_BY_ENVIRONMENT.DEV

export const PANTHER_SERVICES_URL =
  PANTHER_SERVICES_URL_BY_ENVIRONMENT[domainEnvironment] ||
  PANTHER_SERVICES_URL_BY_ENVIRONMENT.DEV

export const API_PATH = Object.freeze({
  AUTH: '/auth',
  CODAT_CONNECTIONS: '/merchants/current/codat_connections',
  CODAT_LINKING: '/merchants/current/codat_linking',
  CONFIGURATION: '/configuration',
  EMAIL_SUBSCRIPTIONS: '/email_subscriptions',
  LOAN_APPLICATIONS: '/loan_applications',
  LOAN_OFFERS: '/loan_offers',
  LOANS: '/loans',
  MERCHANTS: '/merchants',
  NEW_PASSWORD: '/new_password',
  OFFERS: '/offers',
  RESET_PASSWORD: '/reset_password',
  SCORING_PROCESS: '/fts/scoring_processes',
  SIGN_UP: '/sign_up',
  VOUCHER: '/voucher',
})
