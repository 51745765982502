import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { acceptanceValidator } from 'utils/forms/validators'
import activeTenant from 'setup/tenant'
import { Field as FormikField } from 'formik'
import { CheckboxComponent } from './CheckboxComponent'

const NavigationLink = styled('a')`
  text-decoration: none;
`

export const OptinMerchantTerms = (props) => (
  <FormikField
    component={CheckboxComponent}
    data-testid="formFieldOptinTerms"
    name="optinTerms"
    label={
      <Typography variant="body2" style={{ lineHeight: '160%' }}>
        <FormattedMessage
          id="optinMerchant.terms"
          values={{
            termsOfService: (
              <NavigationLink
                href={activeTenant.termsOfService}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="misc.termsOfService" />
              </NavigationLink>
            ),
            privacyPolicy: (
              <NavigationLink
                href={activeTenant.privacyPolicy}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="misc.privacyPolicy" />
              </NavigationLink>
            ),
          }}
        />
      </Typography>
    }
    validate={acceptanceValidator}
    {...props}
  />
)
