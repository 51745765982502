import React from 'react'

/**
 * Wrapper around `React.useState` that allows `setState`'s callback to return `void`.
 * The callback's returned value is spreaded into previous state - therefore it can be `void`
 *
 * Prefer `React.useState`
 */
export const useSetState = <T>(initialState: T) => {
  const [state, setState] = React.useState(initialState)

  const setStateCallback = React.useCallback<
    React.Dispatch<T | ((prevState: T) => void | T)>
  >(
    (patch) => {
      setState((prevState) => ({
        ...prevState,
        ...(patch instanceof Function ? patch(prevState) : patch),
      }))
    },
    [setState],
  )

  return [state, setStateCallback] as const
}
