import React from 'react'
import { useError } from 'context/Error'
import { ErrorComponent } from './ErrorComponent'

// This wrapper is used only when we route directly to the ROUTES.ERROR route.
// The ErrorComponent should be used for embedding the error page, e.g. from the
// ErrorBoundary, to avoid gnarly import paths with potential circular dependencies.
export const ErrorView = () => {
  const { error } = useError()
  return <ErrorComponent error={error} isFromErrorBoundary={false} />
}
