import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { requiredValidator } from 'utils/forms/validators'
import { LEGAL_FORMS } from 'constants/loanApplication'
import { Field as FormikField } from 'formik'
import { AutocompleteComponent } from './AutocompleteComponent'

export const LegalForm = (props) => {
  const intl = useIntl()
  const options = useMemo(
    () =>
      Object.values(LEGAL_FORMS).map((legalForm) => ({
        message: intl.formatMessage({ id: legalForm }),
        value: legalForm,
      })),
    [intl],
  )

  return (
    <FormikField
      id="businessDetails.legalForm"
      component={AutocompleteComponent}
      inputWrapperSx={{ pr: 3, height: ['100px', '78px', '78px'] }}
      label={<FormattedMessage id="fields.legalForm" />}
      validate={requiredValidator}
      options={options}
      hint={<FormattedMessage id="fields.legalForm.hint" />}
      {...props}
    />
  )
}
