import React from 'react'
import { Box } from 'components/structural'
import { ReactComponent as Selected } from 'images/icons/list-ok.svg'

export const SelectedIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) => theme.palette.input.ico.main,
        },
      },
    }}
  >
    <Selected />
  </Box>
)
