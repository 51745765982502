import { ReactComponent as TeleCashLogo } from 'images/logos/telecash.svg'
import { EXPRESS_FLOW, ROUTES } from 'constants/routes'
import teleCashApple from 'images/favicons/telecash/apple-touch-icon.png'
import teleCash16 from 'images/favicons/telecash/favicon-16x16.png'
import teleCash32 from 'images/favicons/telecash/favicon-32x32.png'
import teleCashManifest from 'images/favicons/telecash/site.webmanifest'
import teleCashPin from 'images/favicons/telecash/safari-pinned-tab.svg'
import { TenantConfig, TenantPalette } from '.'

const palette: TenantPalette = {
  global: {
    bgA: '#FFFFFF',
    bgB: '#F5F5F5',
    line: '#E0E0E0',
    icoA: '#FF6600',
    icoB: '#0B78BF',
    icoOff: '#E0E0E0',
    errorRed: '#DD3435',
    okGreen: '#54B34A',
    onDark: '#FFFFFF',
  },
  header: {
    bg: '#FFFFFF',
    title: '#000000',
    text: '#000000',
    link: '#0B78BF',
  },
  input: {
    bgA: '#FFFFFF',
    bgB: '#FFFFFF',
    stroke: '#E0E0E0',
    placeholder: '#666666',
    label: '#FF6600',
    value: '#2D2D2D',
    ico: '#FF6600',
    icoOff: '#E0E0E0',
  },
  text: {
    normal: '#000000',
    heading: '#666666',
    link: '#0B78BF',
    subtle: '#666666',
    off: '#E0E0E0',
  },
  button: {
    bgA: '#FF6600',
    bgB: '#F5F5F5',
    bgC: '#6699CC',
    labelA: '#FFFFFF',
    labelB: '#FF6600',
    labelC: '#FFFFFF',
  },
}

const shape: TenantShape = {
  borderRadius: 0,
  fieldBorderRadius: '0',
}

export const TELECASH_INDUSTRIES = [
  {
    value: 'BEAUTY',
    id: 'fields.industries.beauty',
  },
  {
    value: 'BROKER',
    id: 'fields.industries.broker',
  },
  {
    value: 'IT',
    id: 'fields.industries.it',
  },
  {
    value: 'RESTAURANT',
    id: 'fields.industries.restaurant',
  },
  {
    value: 'TRANSPORTATION',
    id: 'fields.industries.transportation',
  },
  {
    value: 'ECOMMERCE_FASHION',
    id: 'fields.industries.eCommerceFashion',
  },
  {
    value: 'ECOMMERCE_OTHER',
    id: 'fields.industries.eCommerceOther',
  },
  {
    value: 'JEWELRY',
    id: 'fields.industries.jewellery',
  },
  {
    value: 'RETAIL',
    id: 'fields.industries.retail',
  },
  {
    value: 'SHOES',
    id: 'fields.industries.shoes',
  },
  {
    value: 'FASHION',
    id: 'fields.industries.fashion',
  },
  {
    value: 'BICYCLES',
    id: 'fields.industries.bicycles',
  },
]

const telecashTenantConfig: TenantConfig = {
  tenantCode: 'TLC',
  name: 'Telecash',
  title: 'Händlerliquidität',
  logo: {
    component: TeleCashLogo,
  },
  iconSet: {
    appleTouch: teleCashApple,
    favicon16: teleCash16,
    favicon32: teleCash32,
    manifest: teleCashManifest,
    pin: teleCashPin,
    tileColor: '#da532c',
  },
  supportContactNumber: '00 49 30 311 970 64',
  contactEmail: 'telecash@banxware.com',
  faqLink: 'https://help.banxware.com/de/support/solutions/80000458439',
  tenantOverrides: {
    translationOverrideSuffix: 'tlc',
  },
  theme: {
    palette,
    shape,
  },
  gtmId: 'GTM-NWHGKKM',
  tenantSpecific: {
    industries: TELECASH_INDUSTRIES,
  },
  loanApplicationFlow: EXPRESS_FLOW,
  customizeLoanOfferRoute: ROUTES.CUSTOMIZE_LOAN_OFFER_TLC,
}

export const TELECASH_LINK =
  'https://mop.telecash.de/mop/faces/login/tc/login.xhtml'

export default telecashTenantConfig
