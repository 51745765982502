import React, { useRef } from 'react'
import { Box, Flex } from 'components/structural'
import { matchPath, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import { Logo } from 'components/Logo'
import { ROUTES } from 'constants/routes'
import { useLanguage } from 'context/Language'
import { useLoanApplication } from 'context/LoanApplication'
import { useAuth } from 'context/Auth'
import { useNavigation } from 'routing/hooks/useNavigation'
import { isLinkIntegration } from 'setup/tenant'
import { LanguageAccordion } from './LanguageAccordion'
import { LoginButton } from './LoginButton'
import { LogoutButton } from './LogoutButton'
import { CloseButton } from './CloseButton'
import { StyledMenu } from './StyledMenu'

export const Menu = ({ open, setOpen, anchorEl }) => {
  const scheduled = useRef()
  const { handleSwitchLanguage } = useLanguage()
  const { currentLoanApplication } = useLoanApplication()
  const { token, logOut } = useAuth()
  const { navigateTo } = useNavigation()
  const { pathname } = useLocation()

  const isOnIdentityVerification = matchPath(pathname, {
    path: ROUTES.IDENTITY_VERIFICATION,
    exact: true,
  })

  const handleNavigateToLogin = () => {
    navigateTo(ROUTES.LOGIN)
  }

  const loggedInInfo = (
    <Flex flexDirection="column" sx={{ color: '#fff' }}>
      <Typography variant="body1">
        {currentLoanApplication?.merchant?.name}
      </Typography>
      <Typography variant="caption">
        {currentLoanApplication?.merchant?.email}
      </Typography>
    </Flex>
  )

  const handleLanguageAccordionChange = (loc) => {
    scheduled.current = () => handleSwitchLanguage(loc)
    setOpen(false)
  }

  return (
    <StyledMenu
      ref={anchorEl}
      open={open}
      onTransitionEnd={(event) => {
        if (event.propertyName === 'transform') {
          if (!open && typeof scheduled.current === 'function') {
            scheduled.current()
            scheduled.current = null
          }
        }
      }}
    >
      <Flex flexDirection="column">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          minHeight="80px"
          px={[3, 6, 4]}
          backgroundColor="button.bgA.main"
          sx={{
            borderBottom: '1px solid rgba(255, 255, 255, .4)',
            svg: {
              'path:not(.raoul_svg__keepColor)': {
                fill: '#fff',
              },
            },
          }}
        >
          <Logo />
          <CloseButton onClick={() => setOpen(false)} />
        </Flex>
      </Flex>
      {token && (
        <Box
          py={3}
          px={[3, 6, 4]}
          backgroundColor="button.bgA.main"
          color="#fff"
        >
          {loggedInInfo}
        </Box>
      )}

      <LanguageAccordion onOptionClick={handleLanguageAccordionChange} />
      {!isLinkIntegration && (
        <>
          {token ? (
            <LogoutButton
              onClick={() => {
                scheduled.current = () => {
                  logOut()
                  // Custom login foor identity verification screen
                  // because we let logged out user see identity verification screen
                  // we need to force navigation here
                  // otherwise user will not get redirected
                  // when log out on own action
                  if (isOnIdentityVerification) navigateTo(ROUTES.ROOT)
                }
                setOpen(false)
              }}
            />
          ) : (
            <LoginButton
              onClick={() => {
                scheduled.current = handleNavigateToLogin
                setOpen(false)
              }}
            />
          )}
        </>
      )}
    </StyledMenu>
  )
}
