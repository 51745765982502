import React from 'react'
import { Box, Flex } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ButtonRaw } from 'components/buttons'
import { EditIcon } from '../icons'

type EditableBoxFilesProps = {
  id: string
  children: React.ReactElement
  onClick?: () => void
}

export const EditableBoxFiles = ({
  id,
  children,
  onClick,
}: EditableBoxFilesProps) => (
  <Flex alignItems="flexStart" my={4}>
    <Box flex="0 1 176px" mr={6}>
      <Typography variant="h5">
        <FormattedMessage id={id} />
      </Typography>
    </Box>
    {children}
    {onClick && (
      <ButtonRaw
        onClick={onClick}
        style={{
          marginLeft: 'auto',
          alignSelf: 'flex-start',
        }}
      >
        <Box width="24px" height="24px" data-cy={`editButton_${id}`}>
          <EditIcon />
        </Box>
      </ButtonRaw>
    )}
  </Flex>
)
