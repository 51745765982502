import { useCallback } from 'react'
import { useAuth } from '../Auth'
import { useLanguage } from '../Language'
import {
  postRequest,
  putRequest,
  patchRequest,
  getRequest,
  deleteRequest,
  RequestBody,
  ApiResponse,
} from './api'

export const useApiRequest = () => {
  const { token } = useAuth()
  const { language } = useLanguage()

  const handlePostRequest = useCallback(
    async <T extends ApiResponse>({
      url,
      body,
    }: {
      url: string
      body?: RequestBody
    }) => postRequest<T>({ token, language, url, body }),
    [token, language],
  )
  const handlePutRequest = useCallback(
    async <T extends ApiResponse>({
      url,
      body,
    }: {
      url: string
      body: RequestBody
    }) => putRequest<T>({ token, language, url, body }),
    [token, language],
  )
  const handlePatchRequest = useCallback(
    async <T extends ApiResponse>({
      url,
      body,
    }: {
      url: string
      body: RequestBody
    }) => patchRequest<T>({ token, language, url, body }),
    [token, language],
  )
  const handleGetRequest = useCallback(
    async <T extends ApiResponse>({ url }: { url: string }) =>
      getRequest<T>({ token, language, url }),
    [token, language],
  )
  const handleDeleteRequest = useCallback(
    async <T extends ApiResponse>({ url }: { url: string }) =>
      deleteRequest<T>({ token, language, url }),
    [token, language],
  )

  return {
    postRequest: handlePostRequest,
    putRequest: handlePutRequest,
    patchRequest: handlePatchRequest,
    getRequest: handleGetRequest,
    deleteRequest: handleDeleteRequest,
  }
}
