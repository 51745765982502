import React, { useEffect } from 'react'
import { Layout } from 'components/Layout'
import { useLocation } from 'react-router-dom'
import { ReactComponent as LoginIcon } from 'images/login.svg'
import { ViewHeader } from 'components/headers'
import { ROUTES } from 'constants/routes'
import { useQueryParams } from 'hooks'
import { useNavigation } from 'routing/hooks/useNavigation'
import { RequestReset } from './components/RequestReset'
import { Status } from './components/Status'
import { ChangePassword } from './components/ChangePassword'
import { useResetPassword } from './hooks/useResetPassword'

export function ResetPassword() {
  const {
    query: { token: resetToken },
  } = useQueryParams()
  const { navigateTo } = useNavigation()
  const location = useLocation()
  const {
    changePassword,
    isError,
    isLoading,
    isPasswordChanged,
    isRequestSent,
    isTokenValid,
    requestPasswordReset,
    validateResetToken,
  } = useResetPassword()
  const isResetPassword = location.pathname === ROUTES.RESET_PASSWORD_NEW

  const handleChangePassword = (values) => {
    changePassword({
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      resetToken,
    })
  }

  const handleNavigateToRoot = () => {
    navigateTo(ROUTES.ROOT)
  }
  const handleNavigateToRequestReset = () => {
    navigateTo(ROUTES.RESET_PASSWORD)
  }

  useEffect(() => {
    if (isResetPassword && resetToken) {
      validateResetToken(resetToken)
    }
  }, [isResetPassword, resetToken, validateResetToken])

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id={
            !isResetPassword
              ? 'panther2Go.resetPassword.heading'
              : 'panther2Go.setPassword.heading'
          }
          icon={<LoginIcon />}
        />
      }
    >
      <Layout.MiddleAndRight>
        {!isResetPassword && !isRequestSent && (
          <RequestReset
            isLoading={isLoading}
            handleRequestReset={requestPasswordReset}
          />
        )}
        {isResetPassword && !isPasswordChanged && (
          <ChangePassword
            isExpired={isError || !isTokenValid}
            isLoading={isLoading}
            handleChangePassword={handleChangePassword}
            handleNavigateToRequestReset={handleNavigateToRequestReset}
          />
        )}
        {(isRequestSent || isPasswordChanged) && (
          <Status
            status={isRequestSent ? 'sent' : 'success'}
            handleNavigateToRoot={handleNavigateToRoot}
          />
        )}
      </Layout.MiddleAndRight>
    </Layout>
  )
}
