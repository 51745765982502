import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import * as Sentry from '@sentry/browser'
import { useLoanApplication } from 'context/LoanApplication'
import { noop } from 'lodash'
import { useAuth } from '../Auth'
import { useSetState } from '../utils/hooks'
import { useApiRequest } from '../utils/useApiRequest'
import { postRequestDocuments, UploadDocument } from './apiRequests'
import { API_URL, API_PATH } from '../../constants/api'

type DocumentsProviderProps = {
  children: React.ReactNode
}

type Document = {
  id: string
  documentType: string
  filename: string
  contentType: string
  size: number
}

type State = {
  documents?: Document[]
  isLoading?: boolean
  isError?: boolean
}

type DocumentsContextState = State & {
  fetchDocuments: (id: string) => Promise<void>
  uploadDocument: (props: {
    id: string
    onUploadProgress: (percentCompleted: number) => void
    document: UploadDocument
  }) => Promise<void>
  deleteDocument: (props: { id: string; documentId: string }) => Promise<void>
}

export const DocumentsContext = createContext<
  undefined | DocumentsContextState
>(undefined)

const initialState = Object.freeze({
  documents: [],
  isLoading: false,
  isError: false,
})

export function DocumentsProvider(props: DocumentsProviderProps) {
  const { children } = props
  const { currentLoanApplication } = useLoanApplication()
  const { token } = useAuth()
  const [state, setState] = useSetState<State>({ ...initialState })
  const { getRequest, deleteRequest } = useApiRequest()

  useEffect(() => {
    if (!token) {
      setState({ ...initialState })
    }
  }, [setState, token])

  const fetchDocuments = useCallback(
    async (id: string) => {
      try {
        setState({ isLoading: true })
        const { documents } = await getRequest<{ documents: Document[] }>({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${id}/documents`,
        })
        setState({ documents, isLoading: false })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({ error })
        setState({ isError: true, isLoading: false })
      }
    },
    [getRequest, setState],
  )

  const uploadDocument = useCallback(
    async ({
      id,
      onUploadProgress,
      document,
    }: {
      id: string
      onUploadProgress: (percentCompleted: number) => void
      document: UploadDocument
    }) => {
      try {
        setState({ isLoading: true })
        await postRequestDocuments(token, id, onUploadProgress, {
          document,
        })
        await fetchDocuments(id)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({ error })
        setState({ isError: true, isLoading: false })
        Sentry.captureException(error, {
          extra: {
            message: 'File upload error',
            documentType: document?.documentType,
            contentType: document?.file?.contentType,
            filename: document?.file?.filename,
          },
        })
      }
    },
    [fetchDocuments, setState, token],
  )

  const deleteDocument = useCallback(
    async ({ id, documentId }: { id: string; documentId: string }) => {
      try {
        setState({ isLoading: true })
        await deleteRequest({
          url: `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${id}/documents/${documentId}`,
        })
        await fetchDocuments(id)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({ error })
        setState({ isError: true, isLoading: false })
      }
    },
    [deleteRequest, fetchDocuments, setState],
  )

  const { isError, isLoading, documents } = state

  useEffect(() => {
    if (currentLoanApplication?.id && !documents) {
      fetchDocuments(currentLoanApplication.id).catch(noop)
    }
  }, [currentLoanApplication, documents, fetchDocuments])

  const contextValue = useMemo(
    () => ({
      isError,
      isLoading,
      documents,
      fetchDocuments,
      uploadDocument,
      deleteDocument,
    }),
    [
      isError,
      isLoading,
      documents,
      fetchDocuments,
      uploadDocument,
      deleteDocument,
    ],
  )
  return (
    <DocumentsContext.Provider value={contextValue}>
      {children}
    </DocumentsContext.Provider>
  )
}

export function useDocuments() {
  const context = useContext(DocumentsContext)
  if (context === undefined) {
    throw new Error('useDocuments must be used within a DocumentsProvider')
  }
  return context
}
