import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { isBanxware } from 'setup/tenant'
import {
  TIME_OF_RUN_BUSINESS,
  TIME_OF_RUN_BUSINESS_FOR_BANXWARE,
} from 'constants/loanApplication'
import { requiredValidator } from 'utils/forms/validators'
import { SelectField } from './SelectField'

export const TimeOfRunBusiness = (props) => {
  const intl = useIntl()

  const options = useMemo(
    () =>
      TIME_OF_RUN_BUSINESS.map(({ range, translationId }) => ({
        value: range,
        message: intl.formatMessage({ id: translationId }),
      })),
    [intl],
  )
  const banxwareOptions = useMemo(
    () =>
      TIME_OF_RUN_BUSINESS_FOR_BANXWARE.map(({ range, translationId }) => ({
        value: range,
        message: intl.formatMessage({ id: translationId }),
      })),
    [intl],
  )

  return (
    <SelectField
      name="timeOfRunBusiness"
      inputWrapperSx={{ pr: 3, height: ['100px', '78px', '78px'] }}
      label={<FormattedMessage id="fields.timeOfRunBusiness" />}
      validate={requiredValidator}
      hint={<FormattedMessage id="fields.timeOfRunBusiness.hint" />}
      options={isBanxware ? banxwareOptions : options}
      data-testid="timeOfRunBusiness"
      {...props}
    />
  )
}
