import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { noop } from 'lodash'
import { useSetState } from '../utils/hooks'
import { useError } from '../Error'
import { useApiRequest } from '../utils/useApiRequest'
import { API_URL, API_PATH } from '../../constants/api'
import { useAuth } from '../Auth'

export type LoansContext = {
  fetchLoans: () => Promise<void>
  fetchLoanHistoryById: (id: string) => Promise<void>
  initMerchant: () => void
} & LoansContextState

export type Loan = {
  amount: number
  amountDue: number
  approvedAt: string
  disbursedAt: string
  duePercentage: number
  duration: number
  expectedMonthlyRepayment: number
  fee: number
  humanReadableId: string
  id: string
  loanApplicationId: string
  onTime: boolean
  progressPercentage: string
  rate: number
  status: string
  totalLeft: number
  totalOwed: number
  totalPaid: number
  totalRepaymentsAmount: number
}

export type LoanHistory = {
  amount: number
  creditorAccount: string
  currency: string
  date: string
  id: string
  loanId: string
  merchantTransactionId: string
  status: 'initial' | 'pending' | 'success' | 'error'
  title: string
  type: 'repayment' | 'disbursement' | 'loanFee'
}

export type LoansContextState = {
  currentLoan?: Loan | null
  defaultLoanId?: string | null
  isLoading?: boolean
  loanHistory?: LoanHistory[] | null
  loans?: Loan[] | null
  selectedLoan?: Loan | null
  setSelectedLoan?: (loan: Loan) => void
  setCurrentLoan?: (loan: Loan) => void
}

const initialState: LoansContextState = {
  currentLoan: null,
  defaultLoanId: null,
  isLoading: false,
  loanHistory: null,
  loans: null,
  selectedLoan: null,
  setSelectedLoan: () => null,
  setCurrentLoan: () => null,
} as const

type LoansProviderProps = {
  children: React.ReactNode
}

export const LoansContext = createContext<null | LoansContext>(null)

export function LoansProvider(props: LoansProviderProps) {
  const { token } = useAuth()
  const [state, setState] = useSetState<LoansContextState>(initialState)
  const { handleApiRequestError } = useError()
  const history = useHistory()
  const { getRequest } = useApiRequest()

  useEffect(() => {
    if (!token) {
      setState({ ...initialState })
    }
  }, [setState, token])

  const setSelectedLoan = useCallback(
    (selectedLoan: Loan) => {
      setState({ selectedLoan })
    },
    [setState],
  )

  const setCurrentLoan = useCallback(
    (loan: Loan | null) => {
      setState({ currentLoan: loan })
    },
    [setState],
  )

  const fetchLoans = useCallback(async () => {
    try {
      setState({ isLoading: true })
      const { loans } = await getRequest<{ loans: Loan[] }>({
        url: `${API_URL}${API_PATH.LOANS}`,
      })
      // Current open (not repaid) loan is the default loan
      const defaultLoanId =
        loans && loans.length > 0 ? loans[loans.length - 1].id : null
      const loanId = history.location.pathname.split('/')[2]
      setState({
        loans,
        selectedLoan:
          loans?.find((loan) => loan.id === loanId) || loans[loans.length - 1],
        currentLoan: loans?.[loans.length - 1],
        defaultLoanId,
      })
    } catch (error) {
      handleApiRequestError(error)
    } finally {
      setState({
        isLoading: false,
      })
    }
  }, [getRequest, handleApiRequestError, setState, history.location.pathname])

  const fetchLoanHistoryById = useCallback(
    async (loanId) => {
      try {
        setState({ isLoading: true })
        const { history: loanHistory } = await getRequest<{
          history: LoanHistory[]
        }>({
          url: `${API_URL}${API_PATH.LOANS}/${loanId}/history`,
        })
        setState({ loanHistory })
      } catch (error) {
        handleApiRequestError(error)
      } finally {
        setState({ isLoading: false })
      }
    },
    [getRequest, handleApiRequestError, setState],
  )

  // Fetch associated loan history when the selectedLoan is determined
  useEffect(() => {
    if (state?.selectedLoan && token) {
      fetchLoanHistoryById(state?.selectedLoan?.id).catch(noop)
    }
  }, [state.selectedLoan, fetchLoanHistoryById, token])

  const initMerchant = useCallback(() => {
    history.push(
      generatePath(ROUTES.MERCHANT_PORTAL, {
        id: state.defaultLoanId || 'not-found',
      }),
    )
  }, [history, state.defaultLoanId])

  const {
    currentLoan,
    defaultLoanId,
    isLoading,
    loanHistory,
    loans,
    selectedLoan,
  } = state

  const contextValue = useMemo(
    () => ({
      currentLoan,
      defaultLoanId,
      fetchLoanHistoryById,
      fetchLoans,
      initMerchant,
      isLoading,
      loanHistory,
      loans,
      selectedLoan,
      setSelectedLoan,
      setCurrentLoan,
    }),
    [
      currentLoan,
      defaultLoanId,
      fetchLoanHistoryById,
      fetchLoans,
      initMerchant,
      isLoading,
      loanHistory,
      loans,
      selectedLoan,
      setSelectedLoan,
      setCurrentLoan,
    ],
  )

  return (
    <LoansContext.Provider value={contextValue}>
      {props.children}
    </LoansContext.Provider>
  )
}

export function useLoans() {
  const context = useContext<LoansContext>(LoansContext)
  if (context === undefined) {
    throw new Error('useLoans must be used within a LoansContextProvider')
  }
  return context
}
