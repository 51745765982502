export const BREAKPOINTS = {
  tablet: '480px',
  desktop: '960px',
}
export const MOBILE_MENU_BREAKPOINT = 768
export const MAX_WIDTH = '1344px'
export const BUTTON_HEIGHT = 80
export const MERCHANT_GUTTER = '80px'
//                      0, 1, 2, 3,  4,  5,  6,  7,  8,  9
export const SPACING = [0, 4, 8, 16, 24, 32, 48, 64, 80, 96]
