import React from 'react'
import { Box } from 'components/structural'
import { Typography } from '@mui/material'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { ReactComponent as ManImage } from 'images/man.svg'
import { ErrorManImage } from 'components/icons'
import { isMcaAdjustedPage1Group } from 'setup/tenant'
import { ReactComponent as ErrorIcon } from 'images/error.svg'
import { domainEnvironment } from 'setup/environment'
import { DOMAIN_ENVIRONMENTS } from 'constants/environments'
import { ROUTES } from 'constants/routes'
import { useNavigation } from 'routing/hooks/useNavigation'
import { ReloadButton } from './ReloadButton'

const ErrorHeader = ({ error, isFromErrorBoundary, clearError }) => {
  const { navigateTo } = useNavigation()

  let id = 'applicationError.header' // 'Application does not respond'
  let subId = 'errorMessage.generic' // 'We're having problems at the moment'
  if (isFromErrorBoundary) {
    subId = 'applicationError.content' // 'Our tech team has been informed'
  } else if (error) {
    // For errors read from the redux store, the error is already the response property
    if ('response' in error) {
      error = error.response
    }
    if (error?.status === '401') {
      id = 'errorMessage.unauthorized' // 'Login data incorrect'
      subId = undefined // No suitable string available
    } else if (error?.status === '404') {
      id = 'guestScreen.linkBroken.header' // 'Your link seems broken'
      subId = 'guestScreen.linkBroken.content' // 'Try copying again'
    }
  }

  const reloadButton = isFromErrorBoundary && (
    <ReloadButton
      onClick={() => {
        if (clearError) clearError()
        navigateTo(ROUTES.ROOT)
      }}
    />
  )

  return (
    <ViewHeader id={id} subId={subId} icon={<ErrorIcon />}>
      {domainEnvironment === DOMAIN_ENVIRONMENTS.DEV && (
        <Box mt={3}>
          <Typography variant="caption" color="global.errorRed.main">
            {error?.toString()}
          </Typography>
        </Box>
      )}
      {reloadButton}
    </ViewHeader>
  )
}

export const ErrorComponent = (props) => {
  const { error, isFromErrorBoundary, clearError } = props

  return (
    <Layout
      topHeaderProps={{
        withLogin: false,
      }}
      viewHeader={
        <ErrorHeader
          error={error}
          isFromErrorBoundary={isFromErrorBoundary}
          clearError={clearError}
        />
      }
      withoutGenericError
    >
      <Layout.Middle sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            top: ['-80px', '-120px', '-140px'],
            width: '100%',
            maxWidth: '420px',
          }}
        >
          {isMcaAdjustedPage1Group ? <ErrorManImage /> : <ManImage />}
        </Box>
      </Layout.Middle>
    </Layout>
  )
}
