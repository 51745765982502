import { useEffect } from 'react'

export const useScrollLock = (open) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'visible'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [open])
}
