import React from 'react'
import { Box, Flex } from 'components/structural'

import { SecuredIcon } from './icons'

export const InfoBox = ({ children, sx, icon, ...rest }) => (
  <Flex
    sx={{
      flexDirection: 'row',
      boxShadow: '0px 3px 0px rgba(77, 133, 198, 0.1)',
      backgroundColor: 'global.bgB.main',
      p: 3,
      ...sx,
    }}
    {...rest}
  >
    <Box flex="0 0 24px" mr={3}>
      {icon}
    </Box>
    {children}
  </Flex>
)

InfoBox.defaultProps = {
  icon: <SecuredIcon />,
}
