import React, { ReactElement } from 'react'
import { format, isValid as isValidDate } from 'date-fns'
import { InputGrid, InputWrapper } from 'components/Input'
import { DatePicker } from 'components/DatePicker'
import { useHint, useFieldError } from 'hooks'
import { SxProps } from '@mui/material'
import { FieldProps } from 'formik'
import { DesktopDatePickerProps } from '@mui/lab/DesktopDatePicker'

type DatePickerComponentProps = DeepPartial<FieldProps> &
  Partial<DesktopDatePickerProps<Date>> & {
    mb?: number
    inputContainerSx?: SxProps
    inputWrapperSx?: SxProps
    hintContainerSx?: SxProps
    boxed?: boolean
    hint?: ReactElement
    label: React.ReactNode
  }

export const DatePickerComponent = (props: DatePickerComponentProps) => {
  const {
    field: { value: fieldValue, name },
    form: { setFieldValue, errors, touched, submitCount, setTouched },
    label,
    hint,
    mb = 3,
    boxed,
    inputContainerSx = {},
    inputWrapperSx = {
      pr: 3,
    },
    hintContainerSx = {},
    ...restProps
  } = props
  const [hasFieldError, fieldError] = useFieldError({
    touched,
    name,
    submitCount,
    errors,
  })

  const { hintComponent, TriggerComponent } = useHint({
    hint,
    boxed,
    error: hasFieldError,
    errorId: fieldError,
  })

  return (
    <InputGrid mb={mb}>
      <InputGrid.Input sx={inputContainerSx}>
        <InputWrapper error={hasFieldError} sx={inputWrapperSx} boxed={boxed}>
          <DatePicker
            setTouched={setTouched}
            touched={touched}
            name={name}
            label={label}
            onChange={(date) =>
              setFieldValue(
                name,
                isValidDate(date) ? format(date, 'yyyy-MM-dd') : date,
              )
            }
            value={fieldValue || null}
            InputProps={{
              disableUnderline: true,
              endAdornment: <TriggerComponent sx={{ ml: 2 }} />,
            }}
            {...restProps}
          />
        </InputWrapper>
      </InputGrid.Input>
      <InputGrid.Hint sx={hintContainerSx} boxed={boxed}>
        {hintComponent}
      </InputGrid.Hint>
    </InputGrid>
  )
}
