import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { requiredValidator } from 'utils/forms/validators'
import getCountries from 'utils/countries'
import { SelectField } from './SelectField'
import { useLanguage } from '../../context/Language'

export const Nationality = (props) => {
  const { locale } = useLanguage()
  const countriesList = useMemo(
    () =>
      getCountries(locale).map(({ value, message }) => ({
        value,
        message,
      })),
    [locale],
  )
  return (
    <SelectField
      name="nationality"
      data-testid="nationality"
      label={<FormattedMessage id="fields.nationality" />}
      validate={requiredValidator}
      options={countriesList}
      {...props}
    />
  )
}
