import { Typography } from '@mui/material'
import { ReactComponent as SupportImage } from 'images/support.svg'
import { ReactComponent as ArrowRightIcon } from 'images/icons/arrow-right.svg'
import { ReactComponent as FaqImage } from 'images/faq.svg'
import { ReactComponent as PhoneIcon } from 'images/icons/phone.svg'
import { ButtonBase as Button } from 'components/buttons'
import { Copy } from 'components/Copy'
import { Flex, Box } from 'components/structural'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import activeTenant from 'setup/tenant'
import { useLoans } from 'context/Loans'
import { Divider } from 'components/Divider'

type SupportBoxProps = {
  icon: React.ReactNode
  header: React.ReactNode
  buttonIcon: React.ReactNode
  buttonText: React.ReactNode
  onButtonClick: (event: React.MouseEvent) => void
  content: React.ReactNode
  footer: React.ReactNode
  mr?: number[]
  mb?: number[]
}

const SupportBox = ({
  icon,
  header,
  buttonIcon,
  buttonText,
  onButtonClick,
  content,
  footer,
  mr,
  mb,
}: SupportBoxProps) => (
  <Flex flexDirection="column" flex="1 1 50%" mr={mr} mb={mb}>
    <Flex alignItems="center">
      <Box flex="0 0 64px" mr={3}>
        {icon}
      </Box>
      <Typography
        variant="h5"
        style={{
          lineHeight: '1.5em',
          height: '4.5em',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {header}
      </Typography>
    </Flex>
    <Divider />
    <Button icon={buttonIcon} onClick={onButtonClick}>
      {buttonText}
    </Button>
    <Box mt={4}>
      <Typography variant="body1">{content}</Typography>
    </Box>
    <Divider />
    {footer}
  </Flex>
)

export const ApplicationSupportInformation = () => {
  const { selectedLoan } = useLoans()
  const loanId = selectedLoan?.humanReadableId

  return (
    <Flex flexDirection={['column', 'column', 'row']} pt={8}>
      <SupportBox
        mr={[0, 0, 8]}
        mb={[8, 8, 0]}
        icon={<SupportImage />}
        header={<FormattedMessage id="loanApplicationApproved.questions" />}
        buttonIcon={<PhoneIcon />}
        buttonText={
          <FormattedMessage id="loanApplicationApproved.callSupport" />
        }
        onButtonClick={() => {
          window.location.href = `tel:${activeTenant.supportContactNumber}`
        }}
        content={
          <Box component="span" sx={{ whiteSpace: 'pre-wrap', lineHeight: 2 }}>
            <FormattedMessage id="loanApplicationApproved.supportAvailableFrom" />
            {'\n'}
            <Box
              component="span"
              sx={{ fontWeight: 500, color: 'global.icoA.main' }}
              mr={3}
            >
              {activeTenant.supportWorkingHours}
            </Box>
            <FormattedMessage id="loanApplicationApproved.supportDays" />
          </Box>
        }
        footer={
          <Typography variant="body1">
            <Box
              component="span"
              sx={{ whiteSpace: 'pre-wrap', lineHeight: 2 }}
            >
              <FormattedMessage id="loanApplicationApproved.callUsAt" />
              {'\n'}
              <Box
                component="a"
                href={`tel:${activeTenant.supportContactNumber}`}
                sx={{
                  fontWeight: 500,
                  color: 'global.icoA.main',
                  textDecoration: 'none',
                }}
              >
                {activeTenant.supportContactNumber}
              </Box>
            </Box>
          </Typography>
        }
      />

      <SupportBox
        icon={<FaqImage />}
        header={<FormattedMessage id="loanApplicationApproved.needHelp" />}
        buttonIcon={<ArrowRightIcon />}
        buttonText={<FormattedMessage id="loanApplicationApproved.goToFaqs" />}
        onButtonClick={() => {
          window.location.href = activeTenant.faqLink
        }}
        content={
          <Box component="span" sx={{ whiteSpace: 'pre-wrap' }}>
            <FormattedMessage
              id="loanApplicationApproved.faqContent"
              values={{
                faqLink: (
                  <Box
                    component="a"
                    href={activeTenant.faqLink}
                    sx={{
                      color: 'global.icoA.main',
                      textDecoration: 'none',
                      fontWeight: 500,
                    }}
                  >
                    <FormattedMessage id="loanApplicationApproved.inOurFaq" />
                  </Box>
                ),
              }}
            />
          </Box>
        }
        footer={
          <Flex justifyContent="space-between">
            <Typography variant="body1">
              <Box
                component="span"
                sx={{ whiteSpace: 'pre-wrap', lineHeight: 2 }}
              >
                <FormattedMessage id="loanApplicationApproved.loanReference" />
                {'\n'}
                <Box
                  component="span"
                  sx={{ fontWeight: 500, color: 'global.icoA.main' }}
                >
                  {loanId}
                </Box>
              </Box>
            </Typography>
            <Box mb={2} alignSelf="flex-end">
              <Copy text={loanId} />
            </Box>
          </Flex>
        }
      />
    </Flex>
  )
}
