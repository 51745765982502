import React from 'react'
import { Box } from 'components/structural'
import { ReactComponent as Hourglass } from 'images/icons/hourglass.svg'
import { ReactComponent as LinkC } from 'images/icons/link-c.svg'
import { ReactComponent as Trash } from 'images/icons/trash.svg'

export const HourglassIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          '&:first-of-type': {
            stroke: (theme) => theme.palette.global.icoA.main,
          },
        },
        g: {
          fill: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Hourglass />
  </Box>
)

export const LinkCIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <LinkC />
  </Box>
)

export const TrashIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Trash />
  </Box>
)
