export const CONNECTED_PLATFORM_STATUS = {
  LINKED: 'Linked',
  UPLOADED: 'Uploaded',
  PENDING_AUTH: 'PendingAuth',
} as const

export const PLATFORM_NAME = {
  amazonsellercentral: 'Amazon Seller Central',
  prestashop: 'PrestaShop',
  shopify: 'Shopify',
  stripe: 'Stripe',
  sumup: 'SumUp',
  woocommerce: 'WooCommerce',
  zettle: 'Zettle',
  manualupload: 'Manual Upload',
  hood: 'Hood',
  manualreportupload: 'Manual Report Upload',
  afterbuy: 'Afterbuy',
  ebay: 'eBay',
  gambio: 'Gambio',
  girocard: 'Girocard',
} as const
