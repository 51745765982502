import React from 'react'
import { Slider, SliderProps, Box as MuiBox, Typography } from '@mui/material'
import { Flex } from 'components/structural'

type BaseProps = {
  label?: React.ReactNode
  value?: React.ReactNode
}

type RangeLabelProps = BaseProps & {
  alignment?: React.CSSProperties['alignItems']
}

const RangeLabel = ({ label, value, alignment }: RangeLabelProps) => (
  <MuiBox
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: alignment,
    }}
  >
    <Typography variant="caption" color="text.subtle.main">
      {label}
    </Typography>
    <Typography variant="subtitle2" color="text.subtle.main">
      {value}
    </Typography>
  </MuiBox>
)

type RangeLabelsProps = {
  min: RangeLabelProps
  max: RangeLabelProps
  disabled?: boolean
}

export const RangeLabels = ({ min, max, disabled }: RangeLabelsProps) => (
  <MuiBox
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: disabled ? 'text.off.main' : 'text.normal.main',
    }}
  >
    <RangeLabel {...min} alignment="flex-start" />
    <RangeLabel {...max} alignment="flex-end" />
  </MuiBox>
)

type CurrentValueProps = BaseProps

export const CurrentValue = ({ label, value }: CurrentValueProps) => (
  <Flex
    justifyContent="center"
    sx={{
      fontSize: ['60px', '60px', '60px'],
      lineHeight: ['80px', '80px', '80px'],
    }}
    mt="5px"
  >
    <MuiBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: 'text.heading.main',
          mt: 2,
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          color: 'text.normal.main',
          mt: 2,
          fontSize: 'inherit',
          lineHeight: 'inherit',
        }}
      >
        {value}
      </Typography>
    </MuiBox>
  </Flex>
)

type CustomizeSliderProps = SliderProps & {
  testId: string
}

export const CustomizeSlider = ({ testId, ...rest }: CustomizeSliderProps) => (
  <Slider
    size="small"
    data-testid={testId}
    valueLabelDisplay="off"
    sx={{
      '.MuiSlider-mark': {
        display: 'none',
      },
      '.MuiSlider-rail': {
        backgroundColor: 'input.stroke.main',
      },
      '.MuiSlider-track': {
        height: '3px',
      },
      '.MuiSlider-thumb': {
        width: '20px',
        height: '20px',
      },
    }}
    {...rest}
  />
)
