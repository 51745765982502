import React from 'react'
import { Box, Flex } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { ReactComponent as FileErrorIcon } from 'images/icons/file-error.svg'

export const Error = ({ name }) => {
  const getId = () => {
    switch (name) {
      case 'size':
        return 'documentsUpload.sizeError.content'
      case 'type':
        return 'documentsUpload.formatError.content'
      default:
        return null
    }
  }
  const id = getId()
  return (
    <Flex
      justifyContent="center"
      backgroundColor="global.errorRed.light"
      mx={3}
      mb={3}
      py="12px"
      sx={{ border: (theme) => theme.borders.globalError }}
      data-testid="error"
    >
      <Box width="24px" mr={2}>
        <FileErrorIcon />
      </Box>
      <Typography variant="body2" color="global.errorRed.main">
        {id && <FormattedMessage {...{ id }} />}
      </Typography>
    </Flex>
  )
}
