import React from 'react'
import { ConfiguredLocales } from 'tenants'
import { FormattedNumber } from 'react-intl'
import { navigatorLanguage } from '.'
import { createNumberLocaleFormatter } from './numberLocaleFormatter'

export function formatCurrency(amount: number) {
  return (
    <FormattedNumber
      value={amount / 100}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency="EUR"
      minimumFractionDigits={0}
      maximumFractionDigits={2}
    />
  )
}

export const createCurrencyFormatter = (locale: ConfiguredLocales) =>
  createNumberLocaleFormatter(locale, {
    style: 'currency',
    currency: 'EUR',
  })

export const numberFormatterNoFraction = new Intl.NumberFormat(
  navigatorLanguage,
  {
    maximumFractionDigits: 0,
  },
)
