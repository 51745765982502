import React from 'react'
import { useLoanApplication } from 'context/LoanApplication'
import { Grid } from 'components/Grid'
import { ViewHeader } from 'components/headers'
import { Layout } from 'components/Layout'
import { ReactComponent as BusinessIcon } from 'images/business.svg'
import { ReactComponent as BusinessTeleCashIcon } from 'images/business-telecash.svg'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { isTeleCash } from 'setup/tenant'
import { useNavigation } from 'routing/hooks/useNavigation'
import { ROUTES } from 'constants/routes'
import { CUSTOMER_PROGRESS } from 'constants/loanApplication'
import { BusinessAddressForm } from './components/BusinessAddressForm'

export const BusinessAddress = () => {
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const { goToPageAfterRoute } = useFlowNavigation()
  const { navigateTo } = useNavigation()

  const { address, name, crefoId } =
    currentLoanApplication?.businessDetails || {}
  const hasAddress = address?.zipCode !== '00000'
  const initialValues = {
    name: hasAddress ? name : '',
    crefoId: hasAddress ? crefoId : null,
    address: {
      country: address?.country,
      ...(hasAddress ? address : {}),
    },
  }

  const handleSubmit = (values) => {
    updateLoanApplicationById({
      loanApplicationId: currentLoanApplication.id,
      loanApplication: {
        ...currentLoanApplication,
        businessDetails: values,
        customerProgress: CUSTOMER_PROGRESS.COMPANY_SEARCH,
      },
    })
    goToPageAfterRoute(ROUTES.COMPANY_SEARCH)
  }

  const handleGoBack = () => {
    navigateTo(ROUTES.COMPANY_SEARCH)
  }

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="businessAddress.header"
          icon={isTeleCash ? <BusinessTeleCashIcon /> : <BusinessIcon />}
        />
      }
      withProgress
    >
      <Grid.Column start={2} span={3}>
        <BusinessAddressForm
          handleSubmit={handleSubmit}
          handleGoBack={handleGoBack}
          initialValues={initialValues}
        />
      </Grid.Column>
    </Layout>
  )
}
