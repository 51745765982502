import React from 'react'
import LoanRejectionEligibility from '../index'

const TimeOfRunBusinessEligibilty = () => {
  return (
    <LoanRejectionEligibility content="eligibilityRejection.time.content"
      />
  )
}
export default TimeOfRunBusinessEligibilty
