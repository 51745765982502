import React from 'react'
import { Field as FormikField, FieldValidator } from 'formik'
import { useIntl } from 'react-intl'
import { BusinessDetails } from 'types/loanApplication'
import { Firmenwissen } from './Firmenwissen'

type SearchProps = {
  validate: FieldValidator
  editAddress: (props: BusinessDetails) => void
}

export const Search = ({ validate, editAddress }: SearchProps) => {
  const intl = useIntl()
  const placeholder = intl.formatMessage({ id: 'fields.name.placeholder' })

  return (
    <FormikField
      placeholder={placeholder}
      name="name"
      component={Firmenwissen}
      validate={validate}
      editAddress={editAddress}
    />
  )
}
