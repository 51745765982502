import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Form } from 'components/Form'
import { Layout } from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { LegalForm, Country, LegalRepresentative } from 'components/Fields'
import { ReactComponent as QuestionsIcon } from 'images/questions.svg'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useConfiguration } from '../../../context/Configuration'
import { useLoanApplication } from '../../../context/LoanApplication'

export const BusinessLegal = () => {
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const { configuration } = useConfiguration()
  const { goToNextPage } = useFlowNavigation()

  const handleSubmit = (values) => {
    updateLoanApplicationById({
      loanApplicationId: currentLoanApplication.id,
      loanApplication: {
        ...currentLoanApplication,
        ...values,
      },
    })
    goToNextPage()
  }

  return (
    <Layout
      viewHeader={
        <ViewHeader id="businessLegal.header" icon={<QuestionsIcon />} />
      }
      withProgress
    >
      <Layout.MiddleAndRight>
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            businessDetails: currentLoanApplication.businessDetails,
            legalRepresentative: currentLoanApplication.legalRepresentative,
          }}
        >
          <Country
            name="businessDetails.address.country"
            label={<FormattedMessage id="fields.registrationCountry" />}
          />
          <LegalForm
            mb={5}
            name="businessDetails.legalForm"
            disabled={
              !configuration?.tenant?.demoMode &&
              Boolean(currentLoanApplication?.businessDetails?.legalForm)
            }
          />
          <LegalRepresentative
            mb={5}
            name="businessDetails.legalRepresentative"
          />
        </Form>
      </Layout.MiddleAndRight>
    </Layout>
  )
}

export default BusinessLegal
