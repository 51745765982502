import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { API_URL, API_PATH } from 'constants/api'
import { useError } from 'context/Error'
import { PLATFORM_NAME } from 'views/LoanApplication/ConnectShoppingPlatform/constants'
import { useSetState } from '../utils/hooks'
import { useApiRequest } from '../utils/useApiRequest'

type ConfigurationProviderProps = {
  children: React.ReactNode
}

type Configuration = {
  firmenwissenEnabled?: boolean
  maxDocumentFileSize?: number
  tenant?: {
    name: string
    code: string
    accountOpening: boolean
    codatEnabled: boolean
    demoMode: boolean
    codatIntegrationsList: (keyof typeof PLATFORM_NAME)[]
    externalLoans: boolean
    kycProvider: string
    offerGenerationConfiguration: {
      loanDuration: number
      minLoanAmount: number
      maxLoanAmount: number
      loanAmountStep: number
      additionalParameters: {
        maxLen: number
        minLen: number
        lenToFee: Record<string, number>
        loanFactor: number
      }
    }
  }
  registers?: string[]
  acquirers?: { name: string; id: string }[]
}

type State = {
  configuration?: Configuration
  isError?: boolean
  isLoading?: boolean
}

export type ConfigurationContextState = State & {
  fetchConfiguration: () => Promise<void>
}

export const ConfigurationContext = createContext<
  undefined | ConfigurationContextState
>(undefined)

const initialState = Object.freeze({
  configuration: null,
  isError: false,
  isLoading: false,
})

export function ConfigurationProvider(props: ConfigurationProviderProps) {
  const { children } = props

  const { handleApiRequestError } = useError()
  const [state, setState] = useSetState<State>({ ...initialState })
  const { getRequest } = useApiRequest()

  const fetchConfiguration = useCallback(async () => {
    try {
      setState({ isLoading: true })
      const { configuration } = await getRequest<{
        configuration: Configuration
      }>({
        url: `${API_URL}${API_PATH.CONFIGURATION}`,
      })
      setState({ configuration })
    } catch (error) {
      handleApiRequestError(error)
      setState({ isError: true })
    } finally {
      setState({ isLoading: false })
    }
  }, [getRequest, handleApiRequestError, setState])

  const { configuration, isError, isLoading } = state

  const contextValue = useMemo(
    () => ({
      configuration,
      isError,
      isLoading,
      fetchConfiguration,
    }),
    [configuration, isError, isLoading, fetchConfiguration],
  )

  return (
    <ConfigurationContext.Provider value={contextValue}>
      {children}
    </ConfigurationContext.Provider>
  )
}

export function useConfiguration() {
  const context = useContext(ConfigurationContext)
  if (context === undefined) {
    throw new Error(
      'useConfiguration must be used within a ConfigurationProvider',
    )
  }
  return context
}
