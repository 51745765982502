import React from 'react'
import activeTenant from 'setup/tenant'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { CircularProgress, Typography } from '@mui/material'
import CsvDownloader from 'react-csv-downloader'
import { styled } from '@mui/system'
import { DownloadIcon } from 'components/icons'
import { useLoans } from 'context/Loans'
import { PANTHER_SERVICES_URL } from 'constants/api'
import { LoanHistoryAccordion } from './LoanHistoryAccordion'
import { useApiRequest } from '../../../context/utils/useApiRequest'

const Styles = styled('div')`
  div[role='button'] {
    outline: 0;
  }
`

const columns = [
  {
    id: 'id',
    displayName: 'ID',
  },
  {
    id: 'loanId',
    displayName: 'Loan ID',
  },
  {
    id: 'merchantTransactionId',
    displayName: 'Merchant Transaction ID',
  },
  {
    id: 'date',
    displayName: 'Date',
  },
  {
    id: 'amount',
    displayName: 'Amount',
  },
]

export const LoanHistory = () => {
  const { loanHistory, selectedLoan } = useLoans()

  const { getRequest } = useApiRequest()

  if (!loanHistory) {
    return <CircularProgress style={{ margin: '0 auto', marginTop: '50px' }} />
  }

  const redirectToContract = async (loanApplicationId) => {
    const url = `${PANTHER_SERVICES_URL}/contract/${loanApplicationId}`
    const { contractSignedUrl } = await getRequest({ url })
    window.open(contractSignedUrl, '_blank')
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        zIndex: 1,
        // @ts-expect-error Property 'borders' does not exist on type 'Theme'
        border: (theme) => theme.borders.primary,
        borderRadius: activeTenant.theme.shape.fieldBorderRadius,
      }}
      px={[3, 3, 0]}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p={3}
        data-testid="loanHistoryRecords"
        sx={{ borderBottom: '1px solid', borderColor: 'global.line.main' }}
      >
        <Typography variant="h5">
          <FormattedMessage id="dashboard.repaymentRecords.header" />
        </Typography>
        <Flex>
          <Typography variant="body2">
            <FormattedMessage id="repayments.download" />
          </Typography>
          <Box ml={3} sx={{ cursor: 'pointer' }}>
            <Styles>
              <CsvDownloader
                filename="history"
                separator=";"
                columns={columns}
                datas={loanHistory as { [key: string]: any }[]}
              >
                <Box width="24px" height="24px">
                  <DownloadIcon />
                </Box>
              </CsvDownloader>
            </Styles>
          </Box>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p={3}
        data-testid="downloadContract"
        sx={{ borderBottom: '1px solid', borderColor: 'global.line.main' }}
      >
        <Typography variant="h5">
          <FormattedMessage id="dashboard.contract.header" />
        </Typography>
        <Flex>
          <Typography variant="body2">
            <FormattedMessage id="contract.download" />
          </Typography>
          <Box ml={3} sx={{ cursor: 'pointer' }}>
            <Styles>
              <Box
                width="24px"
                height="24px"
                onClick={() =>
                  redirectToContract(selectedLoan.loanApplicationId)
                }
              >
                <DownloadIcon />
              </Box>
            </Styles>
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection="column">
        {loanHistory.length !== 0 ? (
          loanHistory.map((historyRecord) => (
            <LoanHistoryAccordion key={historyRecord.id} {...historyRecord} />
          ))
        ) : (
          <Box p={3}>
            <Typography
              fontWeight="200"
              variant="h5"
              style={{ textAlign: 'start' }}
            >
              <FormattedMessage id="dashboard.loanHistoryRecords.noRecords" />
            </Typography>
          </Box>
        )}
      </Flex>
    </Box>
  )
}
