import React from 'react'
import { Button, Typography } from '@mui/material'
import { Box } from 'components/structural'

import { ArrowIcon } from './icons'

export const ButtonLink = ({
  children,
  Icon = ArrowIcon,
  error,
  sx,
  variant = 'body2',
  ...restProps
}) => (
  <Button
    sx={{
      textTransform: 'none',
      padding: 0,
      minWidth: 'auto',
      height: 'auto',
      '&:hover': {
        backgroundColor: 'inherit',
        '& p': {
          color: error ? 'global.errorRed.dark' : 'text.link.dark',
        },
        '& svg g': {
          stroke: (theme) =>
            error
              ? theme.palette.global.errorRed.dark
              : theme.palette.text.link.dark,
        },
      },
      ...sx,
    }}
    {...restProps}
    disableRipple
  >
    <Typography
      variant={variant}
      sx={{
        color: error ? 'global.errorRed.main' : 'text.link.main',
      }}
    >
      {children}
    </Typography>
    {Icon && (
      <Box width="24px" height="24px" ml={2}>
        <Icon {...{ error }} />
      </Box>
    )}
  </Button>
)

export default ButtonLink
