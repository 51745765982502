import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { MAX_WIDTH } from 'constants/styles'
import { FooterNavigation } from './FooterNavigation'

export const Footer = ({
  maxWidth = MAX_WIDTH,
  px = [3, 6, 4],
  mx,
  ...rest
}) => (
  <Flex
    alignSelf="stretch"
    justifyContent="center"
    backgroundColor="global.bgA.main"
    sx={{ zIndex: 1 }}
    {...rest}
  >
    <Flex
      flexDirection={['column', 'column', 'row']}
      justifyContent={['center', 'center', 'space-between']}
      alignItems={['flex-start', 'flex-start', 'center']}
      width="100%"
      {...{ maxWidth, px, mx }}
    >
      <Box my={4}>
        <Typography variant="caption" color="text.subtle.main">
          © {new Date().getFullYear()}&nbsp;
          <FormattedMessage id="footer.allRightsReserved" />
        </Typography>
      </Box>
      <Box mb={[4, 4, 0]}>
        <FooterNavigation />
      </Box>
    </Flex>
  </Flex>
)
