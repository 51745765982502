import React from 'react'
import { Box } from 'components/structural'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'

import { SignOutIcon } from 'components/icons'

import { MenuButton as Button } from './MenuButton'

export const LogoutButton = ({ onClick }) => (
  <Button
    data-cy="logout-button"
    {...{ onClick }}
    style={{
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    }}
  >
    <Box width="24px">
      <SignOutIcon />
    </Box>
    <Box ml={3} sx={{ color: 'text.heading.main' }}>
      <Typography variant="body2">
        <FormattedMessage id="header.menuSignOut" />
      </Typography>
    </Box>
  </Button>
)
