import { darken, lighten } from '@mui/system'

// Convert colors specified as string values to MUI color objects.
// The light, dark values don't get generated by createTheme for custom colors,
// so we do it manually here using the same method as MUI does it internally.
function convert(colors, tonalOffset) {
  const array = Object.keys(colors).map((key) =>
    typeof colors[key] === 'object'
      ? { [key]: convert(colors[key], tonalOffset) }
      : {
          [key]: {
            main: colors[key],
            light: lighten(colors[key], tonalOffset.light),
            dark: darken(colors[key], tonalOffset.dark),
          },
        },
  )
  const object = array.length > 0 ? Object.assign(...array) : {}
  return object
}

// Takes a BXW design system color template and turns it into a MUI theme palette.
export function muify(colors, tonalOffset = { light: 0.5, dark: 0.5 }) {
  const palette = convert(colors, tonalOffset)

  // Set MUI's expected defaults to appropriate design system swatches
  palette.primary = palette.global.icoA
  palette.secondary = palette.global.icoB
  palette.error = palette.global.errorRed
  palette.success = palette.global.okGreen

  return palette
}
