import React from 'react'
import { FormattedMessage } from 'react-intl'
import { createCurrencyFormatter } from 'utils/currency'
import { isDynamicRepayment } from 'setup/tenant'
import { useLoanOffer } from 'context/LoanOffer'
import { Typography } from '@mui/material'
import { Flex, Box } from 'components/structural'
import { ReactComponent as ValidGreenIcon } from 'images/icons/valid-green.svg'
import { useLanguage } from '../../../../context/Language'
import { Property } from '../Property'

type VoucherValidationProps = {
  discountedFee: number
  originalFee: number
  rate?: number
  payoutAmount: number
}

const VoucherValidation = (props: VoucherValidationProps) => {
  const { discountedFee, rate, payoutAmount, originalFee } = props
  const fee = discountedFee || originalFee
  const { isVoucherValid, voucherCode, discountValue, discountReason } =
    useLoanOffer()
  const { locale } = useLanguage()
  const formatter = createCurrencyFormatter(locale)
  const totalOwed = payoutAmount && fee && payoutAmount + fee

  return isVoucherValid ? (
    <>
      {discountValue <= 0 && (
        <Property
          title={<FormattedMessage id="loanOffer.repaymentFee" />}
          value={<>{formatter.formatInt(discountedFee)}</>}
        />
      )}
      {discountValue > 0 && (
        <>
          <Property
            title={<FormattedMessage id="loanOffer.undiscountedRepaymentFee" />}
            value={
              <>
                <del> + {formatter.formatInt(originalFee)} </del>
              </>
            }
          />
          <Property
            title={<FormattedMessage id="loanOffer.discountedRepaymentFee" />}
            value={<>+ {formatter.formatInt(discountedFee)}</>}
          />
        </>
      )}
      <Property
        title={<FormattedMessage id="loanOffer.totalOwed" />}
        value={`= ${formatter.formatInt(totalOwed)}`}
      />
      {rate && (
        <Property
          title={
            <FormattedMessage
              id={
                isDynamicRepayment
                  ? 'loanOffer.repaymentPercentage'
                  : 'loanOffer.monthlyRepayment'
              }
            />
          }
          value={isDynamicRepayment ? `${rate}%` : formatter.formatInt(rate)}
        />
      )}
      <Box
        sx={{
          backgroundColor: discountValue > 0 ? 'global.okGreen.light' : null,
          px: '28px',
          py: '8px',
          width: '100%',
        }}
      >
        <Flex alignItems="center" sx={{ color: 'global.okGreen.main' }}>
          <Box width="24px" height="24px" mr={5}>
            <ValidGreenIcon />
          </Box>
          <Typography variant="h5">
            {discountReason ? (
              <FormattedMessage
                id="voucher.success.reason"
                values={{
                  voucherCode,
                  discountReason,
                }}
              />
            ) : (
              <FormattedMessage
                id="voucher.success"
                values={{
                  voucherCode,
                }}
              />
            )}
          </Typography>
        </Flex>
      </Box>
    </>
  ) : (
    <>
      <Property
        title={<FormattedMessage id="loanOffer.repaymentFee" />}
        value={`+ ${formatter.formatInt(discountedFee)} ${' '}`}
      />

      <Property
        title={<FormattedMessage id="loanOffer.totalOwed" />}
        value={`= ${formatter.formatInt(totalOwed)}`}
      />
      {rate && (
        <Property
          title={
            <FormattedMessage
              id={
                isDynamicRepayment
                  ? 'loanOffer.repaymentPercentage'
                  : 'loanOffer.monthlyRepayment'
              }
            />
          }
          value={isDynamicRepayment ? `${rate}%` : formatter.formatInt(rate)}
        />
      )}
    </>
  )
}

export default VoucherValidation
