import React from 'react'
import { styled } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { ReactComponent as InvalidIcon } from 'images/invalid.svg'
import { RejectionGirlImage } from 'components/icons'
import { Button } from 'components/buttons'
import activeTenant, { isLinkIntegration } from 'setup/tenant'

const LINK_TO_ELS_WITH_BXW_PARTNER_ID =
  'https://portal.banxware.com/api/partner/BXW01GW53E7J8PD0PEBV69BS6YV54'

const NavigationLink = styled('a')`
  text-decoration: none;
`

const ApplyAgain = styled('div')`
  margin: 28px 0;
`

const displayApplyAgain = !isLinkIntegration

const LoanClosed = () => {
  const handleNavigationToELSPortal = () => {
    window.location.href = LINK_TO_ELS_WITH_BXW_PARTNER_ID
  }

  return (
    <Layout
      viewHeader={
        <ViewHeader
          id="eligibilityRejection.header"
          subId="loanClosed.content"
          subValues={{
            faqs: (
              <NavigationLink
                href={activeTenant.faqLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="misc.faqs" />
              </NavigationLink>
            ),
          }}
          icon={<InvalidIcon style={{ position: 'relative', top: '-10px' }} />}
        />
      }
    >
      <Layout.Middle>
        {displayApplyAgain && (
          <ApplyAgain>
            <Button
              type="submit"
              style={{
                width: '100%',
              }}
              onClick={handleNavigationToELSPortal}
              data-testid="applyAgainButton"
            >
              <Typography variant="h5" color="inherit">
                <FormattedMessage id="eligibilityRejection.creditScore.button" />
              </Typography>
            </Button>
          </ApplyAgain>
        )}
        <RejectionGirlImage />
      </Layout.Middle>
    </Layout>
  )
}

export default LoanClosed
