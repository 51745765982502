import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Flex, Box } from 'components/structural'

import { passwordValidators } from 'utils/forms/validators'

const Check = ({ isOk }) => (
  <Box
    width="8px"
    height="8px"
    sx={{
      mr: 2,
      border: (theme) =>
        isOk
          ? `2px solid ${theme.palette.global.okGreen.main}`
          : `2px solid ${theme.palette.global.errorRed.main}`,
      borderRadius: '50%',
      backgroundColor: isOk ? 'global.okGreen.main' : '#fff',
    }}
  />
)

export const ValidationList = ({ password, passwordConfirmation }) => (
  <>
    <Flex flexWrap="wrap" mb={3}>
      <Flex alignItems="center" mr={2}>
        <Check isOk={passwordValidators.hasEnoughCharacters.test(password)} />
        <Typography variant="caption" color="text.subtle.main">
          <FormattedMessage id="passwordValidationList.minChars" />
        </Typography>
      </Flex>
      <Flex alignItems="center" mr={2}>
        <Check isOk={passwordValidators.hasNumber.test(password)} />
        <Typography variant="caption" color="text.subtle.main">
          <FormattedMessage id="passwordValidationList.number" />
        </Typography>
      </Flex>
      <Flex alignItems="center" mr={2}>
        <Check isOk={passwordValidators.hasUppercase.test(password)} />
        <Typography variant="caption" color="text.subtle.main">
          <FormattedMessage id="passwordValidationList.uppercase" />
        </Typography>
      </Flex>
      <Flex alignItems="center" mr={2}>
        <Check isOk={passwordValidators.hasLowercase.test(password)} />
        <Typography variant="caption" color="text.subtle.main">
          <FormattedMessage id="passwordValidationList.lowercase" />
        </Typography>
      </Flex>
      <Flex alignItems="center" mr={2}>
        <Check
          isOk={password.length > 0 && password === passwordConfirmation}
        />
        <Typography variant="caption" color="text.subtle.main">
          <FormattedMessage id="passwordValidationList.match" />
        </Typography>
      </Flex>
    </Flex>
    <Flex flexWrap="wrap" mb={3}>
      <Flex alignItems="center" mr={2}>
        <Typography variant="caption" color="text.subtle.main">
          <FormattedMessage id="passwordExample" />
        </Typography>
      </Flex>
    </Flex>
  </>
)

export default ValidationList
