import React, { useMemo } from 'react'
import { Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLanguage } from 'context/Language'
import { Box } from 'components/structural'
import { Form } from 'components/Form'
import { Field as FormikField } from 'formik'
import getCountries from 'utils/countries'
import { TextFieldComponent } from 'components/Fields/TextFieldComponent'
import {
  requiredValidator,
  zipCodeValidator,
  zipCodeValidatorDE,
  composeValidators,
} from 'utils/forms/validators'
import { AutocompleteComponent } from 'components/Fields/AutocompleteComponent'

const defaultValidators = composeValidators(requiredValidator, zipCodeValidator)
export const deOnlyValidators = composeValidators(
  requiredValidator,
  zipCodeValidatorDE,
)

export const BusinessAddressForm = (props) => {
  const { locale } = useLanguage()
  const intl = useIntl()
  const countriesList = useMemo(
    () =>
      getCountries(locale).map(({ value, message }) => ({
        value,
        message,
      })),
    [locale],
  )

  return (
    <Form
      enableReinitialize
      initialValues={props.initialValues}
      onBack={props.handleGoBack}
      onSubmit={props.handleSubmit}
      withBack
    >
      <Box mb={5}>
        <Typography variant="h5">
          <FormattedMessage id="businessAddress.info.header" />
        </Typography>
      </Box>
      <FormikField
        component={TextFieldComponent}
        data-testid="formFieldName"
        hint={<FormattedMessage id="fields.name.hint" />}
        label={<FormattedMessage id="fields.name" />}
        name="name"
        placeholder={intl.formatMessage({ id: 'fields.name.placeholder' })}
        validate={requiredValidator}
      />
      <Box mb={5}>
        <Typography variant="h5">
          <FormattedMessage id="businessAddress.address.header" />
        </Typography>
      </Box>
      <FormikField
        component={TextFieldComponent}
        data-testid="formFieldAddress1"
        label={<FormattedMessage id="fields.address1" />}
        name="address.address1"
        validate={requiredValidator}
      />
      <FormikField
        component={TextFieldComponent}
        data-testid="formFieldAddress2"
        label={<FormattedMessage id="fields.address2" />}
        name="address.address2"
      />
      <FormikField
        component={TextFieldComponent}
        data-testid="formFieldZipCode"
        label={<FormattedMessage id="fields.postcode" />}
        name="address.zipCode"
        validate={defaultValidators}
      />
      <FormikField
        component={TextFieldComponent}
        data-testid="formFieldCity"
        label={<FormattedMessage id="fields.city" />}
        name="address.city"
        validate={requiredValidator}
      />
      <FormikField
        component={AutocompleteComponent}
        data-testid="formFieldCountry"
        disabled={!!props.initialValues.address.country}
        hint={null}
        inputWrapperSx={{ pr: 3, height: ['100px', '78px', '78px'] }}
        label={<FormattedMessage id="fields.country" />}
        name="address.country"
        options={countriesList}
        validate={requiredValidator}
      />
    </Form>
  )
}
