import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from '@mui/material'
import { ReactComponent as PersonIdIcon } from 'images/icons/person-id.svg'
import { ArrowDownIcon } from 'components/icons'
import { Grid } from 'components/Grid'
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg'
import { ButtonRaw } from 'components/buttons'
import { stakeholderValidator } from '../../utils'
import { Form } from './Form'

const ICON_WIDTH = 32
const ICON_MARGIN_RIGHT = 16

export const AddNew = (props) => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  const handleOpenForm = () => {
    setIsFormOpen(!isFormOpen)
  }

  const handleSubmit = (values) => {
    props.handleCreateOwner(values)
    handleOpenForm()
  }

  return (
    <>
      <Flex alignItems="center" mb={5}>
        <ButtonRaw onClick={handleOpenForm} data-cy="addNewStakeholder">
          <Flex justifyContent="center" width="80px">
            <Box
              width="16px"
              height="16px"
              sx={{ color: (theme) => theme.palette.global.icoA.main }}
            >
              <PlusIcon />
            </Box>
          </Flex>
          <Box sx={{ color: 'text.off.main' }}>
            <Typography variant="h5" color="inherit">
              <FormattedMessage id="stakeholders.addNew" />
            </Typography>
          </Box>
        </ButtonRaw>
      </Flex>
      {isFormOpen && (
        <Box mb={5}>
          <Accordion
            onChange={handleOpenForm}
            expanded={isFormOpen}
            square
            sx={{
              width: 'inherit',
              '& .MuiAccordionSummary-root': {
                padding: 0,
              },
            }}
          >
            <Grid columns={3}>
              <Grid.Column
                sx={{
                  gridColumnEnd: ['span 3', 'span 3', 'span 2'],
                  border: (theme) => theme.borders.primary,
                  backgroundColor: 'global.bgB.main',
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <IconButton onClick={handleOpenForm}>
                      <Box sx={{ width: '24px', height: '24px' }}>
                        <ArrowDownIcon />
                      </Box>
                    </IconButton>
                  }
                  sx={{
                    cursor: 'default !important',
                    width: 'inherit',
                    '& .MuiAccordionSummary-expandIconWrapper': {
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    },
                    '& .MuiAccordionSummary-root': {
                      padding: '0',
                    },
                    '& .MuiAccordionSummary-content': {
                      width: '100%',
                    },
                    '& .MuiIconButton-root': {
                      width: '24px',
                      height: '24px',
                      padding: '2px',
                      margin: 2,
                    },
                  }}
                >
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      p: 3,
                      flexGrow: 1,
                    }}
                  >
                    <Flex
                      flexDirection={['column', 'row', 'row']}
                      justifyContent="space-between"
                    >
                      <Flex
                        sx={{
                          borderRight: ['none', 'none', 'none'],
                          borderBottom: (theme) => [
                            theme.borders.primary,
                            'none',
                            'none',
                          ],
                        }}
                        flex="1 1 50%"
                        pb={[3, 0, 0]}
                      >
                        <Box
                          mr={`${ICON_MARGIN_RIGHT}px`}
                          sx={{
                            width: `${ICON_WIDTH}px`,
                            height: `${ICON_WIDTH}px`,
                            minWidth: `${ICON_WIDTH}px`,
                            color: (theme) => theme.palette.global.icoA.main,
                          }}
                        >
                          <PersonIdIcon />
                        </Box>
                        <Flex flexDirection="column">
                          <Flex>
                            <Box
                              sx={{
                                color: 'text.off.main',
                              }}
                            >
                              <Typography variant="h5">
                                <FormattedMessage id="stakeholders.anotherPersonDetails" />
                              </Typography>
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </AccordionSummary>
              </Grid.Column>
            </Grid>
            <AccordionDetails
              sx={{
                padding: 0,
              }}
            >
              <Form
                isAddingNew
                initialValues={{}}
                onCancel={handleOpenForm}
                onSubmit={handleSubmit}
                validate={stakeholderValidator}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  )
}
