import React from 'react'
import { Box } from 'components/structural'
import { darken } from 'polished'
import { ReactComponent as Globe } from 'images/icons/globe.svg'
import { ReactComponent as Burger } from 'images/icons/burger.svg'
import { ReactComponent as X } from 'images/icons/x.svg'
import { ReactComponent as SignOut } from 'images/icons/sign-out.svg'
import { ReactComponent as Selected } from 'images/icons/list-ok.svg'
import { ReactComponent as Login } from 'images/icons/login.svg'
import { ReactComponent as ArrowDown } from 'images/icons/arrow-down.svg'
import { ReactComponent as ArrowRight } from 'images/icons/arrow-right.svg'
import { ReactComponent as Download } from 'images/icons/download.svg'
import { ReactComponent as Offers } from 'images/offers.svg'
import { ReactComponent as Designer } from 'images/designer.svg'
import { ReactComponent as Business } from 'images/business.svg'
import { ReactComponent as Personal } from 'images/personal.svg'
import { ReactComponent as LogIn } from 'images/login.svg'
import { ReactComponent as Shareholders } from 'images/shareholders.svg'
import {
  ReactComponent as Application,
  ReactComponent as ApplicationGirl,
} from 'images/application.svg'
import { ReactComponent as Happy } from 'images/happy.svg'
import { ReactComponent as RejectionGirl } from 'images/no-message.svg'
import { ReactComponent as ManImage } from 'images/man.svg'

export const ArrowDownIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <ArrowDown />
  </Box>
)

export const ArrowLeftIcon = () => (
  <Box
    sx={{
      transform: 'rotate(180deg)',
      svg: {
        path: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <ArrowRight />
  </Box>
)

export const DownloadIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Download />
  </Box>
)

export const LoginIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Login />
  </Box>
)

export const SelectedIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Selected />
  </Box>
)

export const SignOutIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <SignOut />
  </Box>
)

export const BurgerIcon = () => (
  <Box
    width="18px"
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Burger />
  </Box>
)

export const XIcon = () => (
  <Box
    width="24px"
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <X />
  </Box>
)

export const GlobeIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          fill: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Globe />
  </Box>
)

export const OffersIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          rect: {
            '&:nth-of-type(2)': {
              fill: (theme) => theme.palette.global.icoA.main,
            },
          },

          circle: {
            fill: (theme) => theme.palette.global.icoA.main,
          },
        },
      },
    }}
  >
    <Offers />
  </Box>
)

export const DesignerImage = () => (
  <Box
    sx={{
      svg: {
        '& > path:nth-of-type(6)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Designer style={{ marginBottom: '-3px', width: '100%' }} />
  </Box>
)

export const BusinessIcon = () => (
  <Box
    sx={{
      svg: {
        '& > rect:last-of-type': {
          fill: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Business />
  </Box>
)

export const PersonalIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          '&:nth-of-type(2)': {
            fill: (theme) => theme.palette.global.icoA.main,
          },
          '&:nth-of-type(4)': {
            fill: (theme) => theme.palette.global.icoA.main,
          },
        },
      },
    }}
  >
    <Personal />
  </Box>
)

export const LogInIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <LogIn />
  </Box>
)

export const ShareholdersIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          path: {
            '&:first-of-type': {
              fill: (theme) => theme.palette.global.icoA.main,
            },
          },
        },

        rect: {
          '&:nth-of-type(4)': {
            fill: (theme) => darken(0.1, theme.palette.global.icoA.main),
          },
          '&:last-of-type': {
            fill: (theme) => theme.palette.global.icoA.main,
          },
        },
      },
    }}
  >
    <Shareholders />
  </Box>
)

export const ApplicationImage = () => (
  <Box
    sx={{
      'svg > g > path:first-of-type': {
        fill: (theme) => theme.palette.global.icoA.main,
      },
    }}
  >
    <Application />
  </Box>
)

export const HappyImage = () => (
  <Box
    sx={{
      svg: {
        g: {
          'path:nth-of-type(3)': {
            fill: (theme) => theme.palette.global.icoA.main,
          },

          'path:nth-of-type(24)': {
            fill: (theme) => theme.palette.global.icoA.main,
          },

          'path:nth-of-type(31)': {
            fill: (theme) => theme.palette.global.icoB.main,
          },

          'path:nth-of-type(50)': {
            fill: (theme) => theme.palette.global.icoA.main,
          },

          'path:nth-of-type(66)': {
            fill: (theme) => theme.palette.global.icoB.main,
          },

          'path:nth-of-type(105)': {
            fill: (theme) => theme.palette.global.icoB.main,
          },

          'path:nth-of-type(35)': {
            fill: (theme) => theme.palette.global.icoB.main,
          },
        },
      },
    }}
  >
    <Happy />
  </Box>
)
export const RejectionGirlImage = () => (
  <Box
    sx={{
      svg: {
        '& > path:nth-of-type(2)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },

        'path:nth-of-type(64)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },

        'path:nth-of-type(48)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },

        'path:nth-of-type(23)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },

        'path:nth-of-type(9)': {
          fill: (theme) => theme.palette.global.icoB.main,
        },

        'path:nth-of-type(21)': {
          fill: (theme) => theme.palette.global.icoB.main,
        },
      },
    }}
  >
    <RejectionGirl />
  </Box>
)
export const ApplicationGirlImage = () => (
  <Box
    sx={{
      svg: {
        '& > g': {
          '& > path:nth-of-type(1)': {
            fill: (theme) => theme.palette.global.icoA.main,
          },

          '& > path:nth-of-type(7)': {
            fill: (theme) => theme.palette.global.icoB.main,
          },

          '& > path:nth-of-type(11), & > path:nth-of-type(13)': {
            fill: (theme) => theme.palette.global.icoB.main,
          },
        },
      },
    }}
  >
    <ApplicationGirl />
  </Box>
)

export const ErrorManImage = () => (
  <Box
    sx={{
      svg: {
        '& > path:nth-of-type(2)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },

        'path:nth-of-type(11)': {
          fill: (theme) => theme.palette.global.icoB.main,
        },

        'path:nth-of-type(7)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },

        'path:nth-of-type(48)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },

        'path:nth-of-type(23)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },

        'path:nth-of-type(3)': {
          fill: (theme) => theme.palette.global.icoB.main,
        },
      },
    }}
  >
    <ManImage />
  </Box>
)

export const DesignerGrlImage = () => (
  <Box
    sx={{
      svg: {
        '.designer_svg__hair': {
          fill: (theme) => theme.palette.global.icoB.main,
        },

        'path:nth-of-type(15), path:nth-of-type(13), path:nth-of-type(9)': {
          fill: (theme) => theme.palette.global.icoA.main,
        },

        'path:nth-of-type(68), path:nth-of-type(78), path:nth-of-type(70)': {
          fill: (theme) => theme.palette.global.icoB.main,
        },
      },
    }}
  >
    <Designer style={{ marginBottom: '-3px', width: '100%' }} />
  </Box>
)
