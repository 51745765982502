import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { requiredValidator } from 'utils/forms/validators'
import { Field as FormikField } from 'formik'
import { useConfiguration } from '../../context/Configuration'
import { AutocompleteComponent } from './AutocompleteComponent'

export const Register = ({ name = 'register' }) => {
  const { configuration } = useConfiguration()
  const registersList = useMemo(
    () =>
      (configuration?.registers || []).map((register) => ({
        value: register,
        message: register,
      })),
    [configuration],
  )
  return (
    <FormikField
      component={AutocompleteComponent}
      data-testid="formFieldRegister"
      name={name}
      id={name}
      label={<FormattedMessage id="fields.register" />}
      validate={requiredValidator}
      options={registersList}
    />
  )
}
