import { useLoanOffer } from 'context/LoanOffer'
import localforage from 'localforage'
import { noop } from 'lodash'
import { useEffect } from 'react'
import useQueryParams from './useQueryParams'

/** Fetches a standalone referral partner parameter from a query string, for use in the root page of the application */
export const useReferralPartnerParam = () => {
  const { query, clear } = useQueryParams()
  const { setApplicationForm } = useLoanOffer()

  useEffect(() => {
    const getReferralPartnerString = async () => {
      const hasPartnerParam = !!query?.partner

      if (hasPartnerParam) {
        await localforage.clear()

        if (hasPartnerParam) {
          setApplicationForm({
            tenantSpecific: { partner: query?.partner as string },
          })
          clear()
        }
      }
    }

    if (query) {
      getReferralPartnerString().catch(noop)
    }
  }, [query, setApplicationForm, clear])
}
