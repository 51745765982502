import payoneApple from 'images/favicons/payone/apple-touch-icon.png'
import payone32 from 'images/favicons/payone/favicon-32x32.png'
import payone16 from 'images/favicons/payone/favicon-16x16.png'
import payonePin from 'images/favicons/payone/safari-pinned-tab.svg'
import payoneManifest from 'images/favicons/payone/site.webmanifest'
import { ReactComponent as PayOneLogo } from 'images/logos/payone.svg'
import { ROUTES } from 'constants/routes'
import { TenantConfig, TenantPalette, TenantShape } from '.'
import '!file-loader?name=payone-[name].[ext]!../images/favicons/payone/android-chrome-192x192.png'
import '!file-loader?name=payone-[name].[ext]!../images/favicons/payone/android-chrome-512x512.png'

const palette: TenantPalette = {
  global: {
    bgA: '#FFFFFF',
    bgB: '#F5F5F5',
    line: '#E0E0E0',
    icoA: '#4792D2',
    icoB: '#0D0D0D',
    icoOff: '#E0E0E0',
    errorRed: '#DF3A5A',
    okGreen: '#68AC57',
    onDark: '#FFFFFF',
  },
  header: {
    bg: '#FFFFFE',
    title: '#0D0D0D',
    text: '#0D0D0D',
    link: '#4792D2',
  },
  input: {
    bgA: '#FFFFFF',
    bgB: '#FFFFFF',
    stroke: '#E0E0E0',
    placeholder: '#E0E0E0',
    label: '#3875A8',
    value: '#2D2D2D',
    ico: '#3875A8',
    icoOff: '#E0E0E0',
  },
  text: {
    normal: '#0D0D0D',
    heading: '#666666',
    link: '#0B78BF',
    subtle: '#666666',
    off: '#E0E0E0',
  },
  button: {
    bgA: '#4792D2',
    bgB: '#F5F5F5',
    bgC: '#99CAEA',
    labelA: '#FFFFFF',
    labelB: '#4792D2',
    labelC: '#666666',
  },
}

const shape: TenantShape = {
  borderRadius: 0,
  fieldBorderRadius: '0',
}

export const PAYONE_INDUSTRIES = [
  {
    value: 'GASTRONOMY',
    id: 'fields.industries.gastronomy',
  },
  {
    value: 'HAIR_AND_BEAUTY_SALON',
    id: 'fields.industries.hairAndBeauty',
  },
  {
    value: 'HOTEL_AND_LODGING',
    id: 'fields.industries.hotelsAndLodging',
  },
  {
    value: 'RETAIL_AND_COMMERCE',
    id: 'fields.industries.retailAndCommerce',
  },
  {
    value: 'OTHER',
    id: 'fields.industries.other',
  },
]

export const PAYONE_FLOW = [
  ROUTES.ADDITIONAL_INFORMATION,
  ROUTES.LOAN_OFFERS,
  ROUTES.COMPANY_SEARCH,
  ROUTES.CONNECT_SHOPPING_PLATFORM,
  ROUTES.BANK_LINKING,
  ROUTES.EXPRESS_APPLICATION_REVIEW,
  ROUTES.BUSINESS_DETAILS,
  ROUTES.OWNER_DETAILS,
  ROUTES.STAKEHOLDERS,
  ROUTES.APPLICATION_SUMMARY,
  ROUTES.EXPRESS_APPLICATION_SUBMITTED,
  ROUTES.IDENTITY_VERIFICATION,
  ROUTES.MERCHANT_PORTAL,
]

const payoneTenantConfig: TenantConfig = {
  tenantCode: 'PAY',
  name: 'PayOne',
  title: 'PayOne',
  logo: {
    component: PayOneLogo,
  },
  iconSet: {
    appleTouch: payoneApple,
    favicon16: payone16,
    favicon32: payone32,
    manifest: payoneManifest,
    pin: payonePin,
    tileColor: '#603cba',
  },
  supportContactNumber: '00 49 30 311 968 86',
  contactEmail: 'payone@banxware.com',
  faqLink: 'https://help.banxware.com/de/support/solutions/80000457845',
  tenantOverrides: {
    translationOverrideSuffix: 'pay',
  },
  theme: {
    palette,
    shape,
  },
  gtmId: 'GTM-5XJFWCT',
  tenantSpecific: {
    industries: PAYONE_INDUSTRIES,
  },
  loanApplicationFlow: PAYONE_FLOW,
  customizeLoanOfferRoute: ROUTES.CUSTOMIZE_LOAN_OFFER_PAY,
}

// @TODO this is not the final link - Janes will provide another one soon
export const PAYONE_LINK = 'https://portal.bs-card-service.com'

export default payoneTenantConfig
