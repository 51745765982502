import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { CircularProgressbarWithChildren as Progress } from 'components/ProgressBar'
import { Typography } from '@mui/material'
import { Box, Flex } from 'components/structural'
import { formatCurrency } from 'utils/currency'
import { BUTTON_HEIGHT } from 'constants/styles'
import activeTenant, {
  isRateOfRepaymentGroup,
  isMonthlyRepaymentGroup,
  isDynamicRepayment,
} from 'setup/tenant'
import { useLoans } from 'context/Loans'
import { formattedPercentage } from 'utils/percentage'

export const LoanDetailsBox = () => {
  const { selectedLoan } = useLoans()
  const {
    progressPercentage,
    totalPaid,
    totalLeft,
    amount,
    fee,
    rate,
    duration,
    totalRepaymentsAmount,
    expectedMonthlyRepayment,
  } = selectedLoan

  const minRepayment = useMemo(
    () =>
      isDynamicRepayment &&
      formatCurrency(
        ((amount + fee) / duration) * activeTenant.minRepaymentPercentage,
      ),
    [amount, duration, fee],
  )

  return (
    <Box
      data-testid="loanDetailsBox"
      bgcolor="global.bgA.main"
      pb={[`${BUTTON_HEIGHT}px`, `${BUTTON_HEIGHT}px`, 0]}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          flexGrow: '1',
          alignItems: 'center',
          py: 4,
          px: 3,
          backgroundColor: 'button.bgA.main',
          borderColor: 'button.bgA.main',
          borderRadius: activeTenant.theme.shape.fieldBorderRadius,
        }}
      >
        <Typography variant="h5" style={{ color: '#fff' }}>
          <FormattedMessage id="dashboard.loan.remainingBalance" />
        </Typography>
        <Typography variant="h4" style={{ color: '#fff' }}>
          {formatCurrency(totalLeft)}
        </Typography>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        sx={{
          // @ts-expect-error Property 'borders' does not exist on type 'Theme'
          border: (theme) => theme.borders.primary,
          borderRadius: activeTenant.theme.shape.fieldBorderRadius,
          color: 'text.normal.main',
          marginTop: activeTenant.theme.shape.fieldBorderRadius,
          backgroundColor: 'global.bgB.main',
        }}
        width="100%"
      >
        <Box width="128px" mt={4}>
          <Progress strokeWidth={18} value={progressPercentage}>
            <Flex flexDirection="column" alignItems="center">
              <Typography variant="caption" color="text.subtle.main">
                <FormattedMessage id="dashboard.loan.complete" />
              </Typography>
              <Typography variant="h5" color="global.icoA.main">
                {Math.floor(Number(progressPercentage))}%
              </Typography>
            </Flex>
          </Progress>
        </Box>
        <Box
          mt={4}
          pb={4}
          px={3}
          sx={{
            alignSelf: 'stretch',
            // @ts-expect-error Property 'borders' does not exist on type 'Theme'
            borderBottom: (theme) => theme.borders.primary,
          }}
        >
          <Flex justifyContent="space-between" mb={2}>
            <Typography variant="body2" color="text.subtle.main">
              <FormattedMessage id="dashboard.loan.totalPaid" />
            </Typography>
            <Typography
              variant="body2"
              style={{ color: 'inherit', fontWeight: 500 }}
            >
              {formatCurrency(totalPaid)}
            </Typography>
          </Flex>
          <Flex justifyContent="space-between" mb={minRepayment ? 2 : 0}>
            <Typography variant="body2" color="text.subtle.main">
              <FormattedMessage id="dashboard.loan.remainingBalance" />
            </Typography>
            <Typography
              variant="body2"
              style={{ color: 'inherit', fontWeight: 500 }}
            >
              {formatCurrency(totalLeft)}
            </Typography>
          </Flex>
          {minRepayment && (
            <Flex justifyContent="space-between">
              <Typography variant="body2" color="text.subtle.main">
                <FormattedMessage id="dashboard.loan.minPayment" />
              </Typography>
              <Typography
                variant="body2"
                style={{ color: 'inherit', fontWeight: 500 }}
              >
                {minRepayment}
              </Typography>
            </Flex>
          )}
        </Box>
        <Box mt={4} pb={4} px={3} sx={{ alignSelf: 'stretch' }}>
          <Flex justifyContent="space-between" mb={2}>
            <Typography variant="body2" color="text.subtle.main">
              <FormattedMessage id="dashboard.loan.amount" />
            </Typography>
            <Typography
              variant="body2"
              style={{ color: 'inherit', fontWeight: 500 }}
            >
              {formatCurrency(amount)}
            </Typography>
          </Flex>
          <Flex justifyContent="space-between" mb={2}>
            <Typography variant="body2" color="text.subtle.main">
              <FormattedMessage id="dashboard.loan.fee" />
            </Typography>

            <Typography
              variant="body2"
              style={{ color: 'inherit', fontWeight: 500 }}
            >
              {formatCurrency(fee)}
            </Typography>
          </Flex>
          <Flex justifyContent="space-between">
            <Typography variant="body2" color="text.subtle.main">
              {isMonthlyRepaymentGroup ? (
                <FormattedMessage id="dashboard.loan.monthlyRepayment" />
              ) : (
                <FormattedMessage id="dashboard.loan.minRepayment" />
              )}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: 'inherit', fontWeight: 500 }}
            >
              {isMonthlyRepaymentGroup &&
                ((expectedMonthlyRepayment > 0 &&
                  formatCurrency(expectedMonthlyRepayment)) ||
                  0)}
              {isRateOfRepaymentGroup && formattedPercentage(rate)}
            </Typography>
          </Flex>
        </Box>{' '}
      </Flex>
    </Box>
  )
}
