import TagManager from 'react-gtm-module'
import { DOMAIN_ENVIRONMENTS } from 'constants/environments'
import { domainEnvironment } from 'setup/environment'
import activeTenant from 'setup/tenant'

export const initGtm = () => {
  const gtmId = activeTenant.gtmId
  if (domainEnvironment === DOMAIN_ENVIRONMENTS.PROD && gtmId) {
    TagManager.initialize({
      gtmId,
    })
  }
}
