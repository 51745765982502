import React from 'react'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import {
  Address1,
  Address2,
  City,
  Postcode,
  Salutation,
  FirstName,
  LastName,
  DateOfBirth,
  TaxId,
  Nationality,
  LegalRepresentative,
  Country,
  Email,
  LegalRepresentativeStakeholder,
} from 'components/Fields'
import { Button } from 'components/buttons'
import { UltimateBeneficialOwner } from './UltimateBeneficialOwner'

const inputContainerStyles = {
  px: 3,
  pb: 3,
  borderRight: (theme) => theme.borders.primary,
  borderLeft: (theme) => theme.borders.primary,
  backgroundColor: 'global.bgB.main',
}

export const Form = ({
  initialValues,
  isAddingNew,
  isApplicantOwner,
  onCancel,
  onSubmit,
  validate,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    enableReinitialize
    style={{ width: '100%' }}
    validate={isAddingNew && validate}
  >
    {({ isValid }) => (
      <FormikForm style={{ width: '100%' }}>
        <Flex flexDirection="column">
          <Salutation
            name="title"
            mb={0}
            inputContainerStyles={{
              ...inputContainerStyles,
              pt: 3,
            }}
            boxed
          />
          <FirstName
            name="firstName"
            mb={0}
            {...{ inputContainerStyles }}
            boxed
          />
          <LastName
            name="lastName"
            mb={0}
            {...{ inputContainerStyles }}
            boxed
          />
          <Email
            mb={0}
            {...{ inputContainerStyles }}
            name="email"
            boxed
            label={<FormattedMessage id="fields.email" />}
          />
          {isApplicantOwner ? (
            <LegalRepresentative
              validate={undefined}
              name="legalRepresentative"
              hint={<FormattedMessage id="fields.legalRepresentative.hint" />}
              mb={0}
              inputContainerStyles={{
                ...inputContainerStyles,
                gridColumnEnd: ['span 3', 'span 3', 'span 2'],
              }}
              boxed
            />
          ) : (
            <LegalRepresentativeStakeholder
              name="legalRepresentative"
              inputContainerSx={{
                px: 3,
                borderLeft: (theme) => theme.borders.primary,
                borderRight: (theme) => theme.borders.primary,
                backgroundColor: 'global.bgB.main',
                gridColumnEnd: ['span 3', 'span 3', 'span 2'],
              }}
              hintContainerSx={{
                borderTop: 'none',
                gridColumnStart: '1',
                gridColumnEnd: ['span 3', 'span 3', 'span 2'],
                px: 3,
                pb: 3,
                borderLeft: (theme) => theme.borders.primary,
                borderRight: (theme) => theme.borders.primary,
                backgroundColor: 'global.bgB.main',
              }}
              mb={0}
            />
          )}
          {isAddingNew && (
            <FormikField
              component={UltimateBeneficialOwner}
              mb={0}
              validate={undefined}
              inputContainerStyles={{
                ...inputContainerStyles,
                gridColumnEnd: ['span 3', 'span 3', 'span 2'],
              }}
              name="ultimateBeneficial"
              hint={
                <FormattedMessage id="fields.ultimateBeneficialOwner.hint" />
              }
              dataCy="ultimateBeneficialOwner"
            />
          )}
          <Address1
            mb={0}
            {...{ inputContainerStyles }}
            name="address.address1"
            boxed
          />
          <Address2
            mb={0}
            {...{ inputContainerStyles }}
            name="address.address2"
            boxed
          />
          <Postcode
            mb={0}
            {...{ inputContainerStyles }}
            name="address.zipCode"
            boxed
          />
          <City
            mb={0}
            {...{ inputContainerStyles }}
            name="address.city"
            boxed
          />
          <Country
            mb={0}
            {...{ inputContainerStyles }}
            name="address.country"
            hint={undefined}
            boxed
          />
          <DateOfBirth
            mb={0}
            {...{ inputContainerStyles }}
            name="dateOfBirth"
            boxed
          />
          <Nationality
            mb={0}
            {...{ inputContainerStyles }}
            name="nationality"
            hint={undefined}
            boxed
          />
          <TaxId
            mb={0}
            {...{ inputContainerStyles }}
            name="taxNumber"
            hint={<FormattedMessage id="fields.taxId.hint" />}
            boxed
          />
          <Box
            sx={{
              display: 'grid',
              width: '100%',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridGap: '48px',
              rowGap: 0,
            }}
          >
            <Box
              sx={{
                gridColumnStart: '1',
                gridColumnEnd: ['span 3', 'span 3', 'span 2'],
                pb: 3,
                px: 3,
                borderRight: (theme) => theme.borders.primary,
                borderLeft: (theme) => theme.borders.primary,
                borderBottom: (theme) => theme.borders.primary,
                backgroundColor: 'global.bgB.main',
              }}
            >
              <Flex flexDirection="row" justifyContent="center">
                <Button
                  onClick={onCancel}
                  style={{ marginRight: '8px', flex: '1 0 auto' }}
                >
                  <FormattedMessage id="common.cancel" />
                </Button>
                <Button
                  style={{ marginLeft: '8px', flex: '1 0 auto' }}
                  type="submit"
                  data-cy="saveNewStakeholder"
                  disabled={!isValid}
                >
                  <FormattedMessage id="common.save" />
                </Button>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </FormikForm>
    )}
  </Formik>
)
