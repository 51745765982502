import { LoanPurpose } from '@banxware/offer-generation'
import type { SelectedOffer } from 'types/offers'

export type Address = {
  address1: string
  address2: string | null
  city: string
  country: string
  zipCode: string
  countryCode?: string
  id?: string | null
  lat?: string | null
  lng?: string | null
  state?: string | null
  streetNumber?: string | null
}

export type BusinessDetails = {
  address: Address
  crefoId: string
  foundationDate: string
  industryCode: string
  legalForm: string
  legalRepresentative: string | null
  loanPurpose: LoanPurpose
  name: string
  numberOfEmployees: string
  register: string | null
  registrationNumber: string | null
  responsiblePublicAuthority: string | null
  subjectToVat: boolean
  taxNumber: string
  vatId: string | null
  website: string | null
}

export type LinkedBankAccount = {
  status: string
  holderName: string | null
  bankName: string | null
  iban: string | null
  expired: boolean
}

export type Merchant = {
  acquirerId: string | null
  address: Address
  email: string
  externalId: string
  id: string
  name: string
  optinAccount: boolean
  optinTax: boolean
  optinTerms: boolean
  owners: Owner[]
  eligible: boolean
}

export type Owner = {
  address: Address
  applicantOwner: boolean
  dateOfBirth: string
  email: string
  firstName: string
  id: string
  lastName: string
  legalRepresentative: boolean
  merchantId: string
  nationality: string
  professionalTitle: null | string
  sharesPercent: number
  taxNumber: string
  title: string
  ultimateBeneficial: boolean
}

export type TenantSpecific = Partial<{
  useCase: string
  practiceDuration: string
  merchantId: string
  phoneNumber: string
  efn: string
  selectedLoanPurpose: LoanPurpose
  // @TODO - consolidate these
  industryType: string
  industry: string
  // @TODO - update number handling logic in HomePage
  expectedSettlementAmount: string
  specialization: string
  typeOfPractice: string
  voucher: string
  partner: string
  mcc: string
  discountType: string
  discountValue: number
  discountReason: string
}>

const LOAN_APPLICATION_STATUS = {
  INITIAL: 'initial',
  ELIGIBLE: 'eligible',
  EXPRESS_SUBMITTED: 'express_submitted',
  SUBMITTED: 'submitted',
  INTERNAL_REVIEW: 'internal_review',
  KYC: 'kyc',
  SCREENING: 'screening',
  APPROVAL: 'approval',
  APPROVED: 'approved',
  WAITING_FOR_CUSTOMER: 'waiting_for_customer',
  REJECTED: 'rejected',
  CLOSED: 'closed',
} as const

type LoanApplicationStatus =
  typeof LOAN_APPLICATION_STATUS[keyof typeof LOAN_APPLICATION_STATUS]

const POST_SUBMISSION_STATUSES = [
  LOAN_APPLICATION_STATUS.EXPRESS_SUBMITTED,
  LOAN_APPLICATION_STATUS.SUBMITTED,
  LOAN_APPLICATION_STATUS.APPROVAL,
  LOAN_APPLICATION_STATUS.APPROVED,
  LOAN_APPLICATION_STATUS.KYC,
  LOAN_APPLICATION_STATUS.SCREENING,
  LOAN_APPLICATION_STATUS.WAITING_FOR_CUSTOMER,
  LOAN_APPLICATION_STATUS.REJECTED,
  LOAN_APPLICATION_STATUS.CLOSED,
]

const POST_DECISION_STATUSES = [
  LOAN_APPLICATION_STATUS.APPROVED,
  LOAN_APPLICATION_STATUS.REJECTED,
  LOAN_APPLICATION_STATUS.CLOSED,
]

export const isPostApplicationSubmission = (application: LoanApplication) =>
  [...(POST_SUBMISSION_STATUSES as [LoanApplicationStatus])].includes(
    application?.status,
  )

export const isPostApplicationDecision = (application: LoanApplication) =>
  [...(POST_DECISION_STATUSES as [LoanApplicationStatus])].includes(
    application?.status,
  )

export type LoanApplication = {
  approximateSales: number
  businessDetails: BusinessDetails
  createdAt: string
  customerProgress: string
  dataRetrievalStatus: string
  humanReadableId: string
  id: string
  linkedBankAccount: LinkedBankAccount
  loanPurpose?: LoanPurpose
  merchant: Partial<Merchant>
  owners: Owner[]
  offerConfiguration: unknown
  offers: SelectedOffer[]
  optinFacta: boolean
  optinOwnInitiative: boolean
  optinPep: boolean
  optinSchufa: boolean
  optinTerms: boolean
  percentOfCreditCardsSales?: string
  rejectionCode: null | string
  // @TODO type this
  status: LoanApplicationStatus
  submitted: boolean
  tenantSpecific: TenantSpecific
  timeOfRunBusiness: string
  voucher: string
  address?: Address
  // @TODO - move to tenantSpecific and reassign field name in industryfield component
  industry?: string
}

export type IdentityVerification = {
  // @TODO type status
  stakeholders: { status: string }[]
}
