import React from 'react'
import { Box } from 'components/structural'

import { ReactComponent as Verification } from 'images/verification.svg'
import { ReactComponent as Clock } from 'images/icons/clock.svg'
import { ReactComponent as Id } from 'images/icons/id.svg'

export const VerificationIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Verification />
  </Box>
)

export const ClockIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Clock />
  </Box>
)

export const IdIcon = () => (
  <Box
    sx={{
      svg: {
        rect: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Id />
  </Box>
)
