import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TextFieldComponent } from 'components/Fields/TextFieldComponent'
import { requiredValidator } from 'utils/forms/validators'
import { Field as FormikField } from 'formik'
// import { TextField } from './TextField'

export const ResponsiblePublicAuthority = ({
  name = 'responsiblePublicAuthority',
}) => (
  <FormikField
    component={TextFieldComponent}
    data-testid="formFieldResponsiblePublicAuthority"
    label={<FormattedMessage id="fields.responsiblePublicAuthority" />}
    name={name}
    validate={requiredValidator}
  />
)
