import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export type BoxProps = MuiBoxProps

export const Box = styled(MuiBox)({
  boxSizing: 'border-box',
  minWidth: 0,
}) as typeof MuiBox

export type FlexProps = BoxProps

export const Flex = styled(Box)({ display: 'flex' }) as typeof Box
