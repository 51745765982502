import React from 'react'
import { Box } from 'components/structural'
import { Layout } from 'components/Layout'
import { Grid } from 'components/Grid'
import { ViewHeader } from 'components/headers'
import { ReactComponent as InvalidIcon } from 'images/invalid.svg'
import { ReactComponent as ManImage } from 'images/man.svg'
import { ErrorManImage } from 'components/icons'
import { isMcaAdjustedPage1Group } from 'setup/tenant'

export const NotFoundPage = () => (
  <Layout
    viewHeader={
      <ViewHeader
        id="notFound.header"
        subId="notFound.content"
        icon={<InvalidIcon />}
      />
    }
  >
    <Grid.Column start={2} span={2} sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          top: ['-65px', '-75px', '-115px'],
          width: '100%',
          maxWidth: '420px',
        }}
      >
        {isMcaAdjustedPage1Group ? <ErrorManImage /> : <ManImage />}
      </Box>
    </Grid.Column>
  </Layout>
)

export default NotFoundPage
