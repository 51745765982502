import React from 'react'
import { Flex } from 'components/structural'
import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { LoanSelector } from './LoanSelector'
import { LoanInfoBanner } from './LoanInfoBanner'

type DashboardViewHeaderProps = {
  children?: React.ReactNode
}

export const DashboardViewHeader = ({ children }: DashboardViewHeaderProps) => (
  <Box py={4} px={[3, 6, 4]}>
    <Flex
      flexDirection={['column', 'column', 'row']}
      my={[4, 5, 6]}
      justifyContent="space-between"
      alignItems={['flex-start', 'flex-start', 'center']}
    >
      <Typography
        variant="h1"
        style={{
          whiteSpace: 'pre-wrap',
          textAlign: 'left',
        }}
      >
        <FormattedMessage id="merchantDashboard.header" />
      </Typography>
      <LoanSelector />
    </Flex>
    <LoanInfoBanner />
    {children}
  </Box>
)
