import React from 'react'
import { Flex } from 'components/structural'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ROUTES } from 'constants/routes'
import { useNavigation } from 'routing/hooks/useNavigation'
import { useAuth } from '../../../context/Auth'
import { ButtonRaw as Button } from '../ButtonRaw/ButtonRaw'

export const LoginButton = () => {
  const { token } = useAuth()
  const { navigateTo } = useNavigation()

  const handleNavigateToLogin = () => {
    navigateTo(ROUTES.LOGIN)
  }

  if (token) return null
  return (
    <Flex ml={4} alignItems="center">
      <Button
        onClick={handleNavigateToLogin}
        data-testid="loginButton"
        sx={{
          color: 'text.link.main',
        }}
      >
        <Typography variant="body2" sx={{ color: 'text.link.main' }}>
          <FormattedMessage id="header.login" />
        </Typography>
      </Button>
    </Flex>
  )
}
