import React, { useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import debounce from 'lodash/debounce'
import { Typography, Box, Stack } from '@mui/material'
import { useAuth } from 'context/Auth'
import { AutocompleteFirmenwissenComponent as Autocomplete } from 'components/Fields/AutocompleteComponent/AutocompleteFirmenwissenComponent'
import { SelectedIcon } from 'components/Fields/AutocompleteComponent/icons'
import { alpha2ToAlpha3 } from 'i18n-iso-countries'
import { BusinessDetails } from 'types/loanApplication'
import { FieldInputProps, FormikProps } from 'formik'
import { noop } from 'lodash'
import { CompanyNameSuggestion, getCompanyMatches } from '../../api/apiRequests'

type FirmenwissenProps = {
  form: FormikProps<BusinessDetails>
  placeholder: string
  boxed: boolean
  field: FieldInputProps<string>
  editAddress: (props?: BusinessDetails) => void
}

export const Firmenwissen = ({
  form,
  placeholder,
  field,
  boxed,
  editAddress,
}: FirmenwissenProps) => {
  const { values } = form
  const initialSelection: CompanyNameSuggestion =
    values?.crefoId || values?.address?.zipCode !== '00000'
      ? {
          name: values.name,
          strasseHausnummer: values.address?.address1,
          ort: values.address?.city,
          plz: values.address?.zipCode,
          land: values.address?.country,
        }
      : null

  const [selection, setSelection] =
    useState<CompanyNameSuggestion>(initialSelection)
  const [inputValue, setInputValue] = useState<string>('')
  const [options, setOptions] = useState(
    initialSelection ? [initialSelection] : [],
  )
  const [loading, setLoading] = useState(false)

  const { token } = useAuth()

  const search = useRef(
    debounce(async (searchTerm: string) => {
      if (searchTerm === '') {
        setOptions([])
      } else if (searchTerm.length > 2) {
        try {
          setLoading(true)
          const matches = await getCompanyMatches(searchTerm, token)
          const filteredOptions = matches.reduce<CompanyNameSuggestion[]>(
            (result, match) => {
              if (
                !result.some((item) => item.name === match.name) &&
                match.land === 'DE'
              ) {
                result.push(match)
              }
              return result
            },
            [],
          )
          setOptions(filteredOptions)
        } catch (error) {
          setOptions([])
        } finally {
          setLoading(false)
        }
      }
    }, 500),
  )

  const select = (selectedCompany: CompanyNameSuggestion) => {
    setOptions([selectedCompany])
    setSelection(selectedCompany)
    updateFields(selectedCompany)
  }

  const updateFields = (selectedCompany: CompanyNameSuggestion) => {
    form.setFieldValue('name', selectedCompany?.name)
    form.setFieldValue('crefoId', selectedCompany?.crefonummer)
    form.setFieldValue('address.city', selectedCompany?.ort)
    form.setFieldValue('address.address1', selectedCompany?.strasseHausnummer)
    form.setFieldValue('address.zipCode', selectedCompany?.plz)
    // firmenwissen returns a two letter country code but the backend expects a 3 letter one, this converts it accordingly
    form.setFieldValue(
      'address.country',
      alpha2ToAlpha3(selectedCompany?.land) || 'DEU',
    )
    form.setFieldValue(field.name, selectedCompany?.name || null)
  }

  const noOptions = (
    <div
      data-cy="firmenwissenNoOptions"
      onMouseDown={() => {
        editAddress()
      }}
    >
      <FormattedMessage id="fields.firmewissenName.noOptions" />
      <span
        style={{
          fontWeight: 'bold',
          textDecoration: 'underline',
          cursor: 'pointer',
          display: 'inline-block',
        }}
      >
        <FormattedMessage id="fields.firmewissenName.clickHere" />
      </span>
    </div>
  )

  // Custom list item with checkbox icon
  const OurOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: CompanyNameSuggestion,
    { selected }: { selected: boolean },
  ) => {
    if (!option.name) return null
    return (
      <Box
        {...props}
        component="li"
        sx={{
          '&[aria-selected="true"]:not(:hover)': {
            backgroundColor: 'transparent !important',
          },
        }}
      >
        <Box sx={{ width: '16px', height: '16px', pr: 2 }}>
          {selected && <SelectedIcon />}
        </Box>
        <Stack direction="column">
          <Typography variant="body1">{option.name}</Typography>
          <Typography variant="body2" color="text.subtle.main">
            {option.strasseHausnummer}, {option.plz} {option.ort}
          </Typography>
        </Stack>
      </Box>
    )
  }
  return (
    <Autocomplete
      data-cy="firmenwissenComponent"
      form={form}
      field={field}
      disableClearable
      label={<FormattedMessage id="fields.name" />}
      placeholder={placeholder}
      inputValue={inputValue}
      value={selection}
      options={options}
      hint={<FormattedMessage id="fields.name.hint" />}
      noOptionsText={noOptions}
      renderOption={OurOption}
      getOptionLabel={(opt: CompanyNameSuggestion) => opt.name ?? ''}
      isOptionEqualToValue={(
        opt: CompanyNameSuggestion,
        val: CompanyNameSuggestion,
      ) => opt.name === val.name}
      loading={loading}
      boxed={boxed}
      onInputChange={(_, newInputValue: string) => {
        setInputValue(newInputValue)
        if (!options.some((opt) => opt.name === newInputValue)) {
          search.current(newInputValue)?.catch(noop)
        }
      }}
      onChange={(_, { name: newName }) => {
        const currentOption: CompanyNameSuggestion | null = options.find(
          ({ name: optionName }) => optionName === newName || null,
        )
        if (currentOption) select(currentOption)
      }}
    />
  )
}
