import { LEGAL_FORMS } from 'constants/loanApplication'
import {
  VALIDATOR_MESSAGE_ID,
  sharesAmountValidator,
  requiredValidator,
  composeValidators,
} from 'utils/forms/validators'

export const setValidationMessage = (
  legalForm,
  missingStakeholderCount,
  totalShares,
) => {
  if (totalShares > 100) {
    return 'stakeholders.totalExceedsLimit'
  }

  if (missingStakeholderCount > 0) {
    switch (legalForm) {
      case LEGAL_FORMS.GBR:
        if (missingStakeholderCount === 1) {
          return 'uboValidation.gbrAddOne'
        }
        return 'uboValidation.gbrAddTwo'
      case LEGAL_FORMS.GMBH:
        return 'uboValidation.gmbhAddOne'
      case LEGAL_FORMS.OHG:
        return 'uboValidation.gmbhAddOne'
      case LEGAL_FORMS.GMBH_COKG:
        return 'uboValidation.gmbhCoKgAddOne'
      case LEGAL_FORMS.UG:
        return 'uboValidation.ugAddOne'
      default:
        return ''
    }
  }
  return ''
}

export function getStakeholderCountByLegalForm(legalForm) {
  switch (legalForm) {
    case LEGAL_FORMS.GBR:
      return 2
    case LEGAL_FORMS.GMBH:
      return 1
    case LEGAL_FORMS.OHG:
      return 1
    case LEGAL_FORMS.GMBH_COKG:
      return 1
    case LEGAL_FORMS.UG:
      return 1
    default:
      return null
  }
}

const sharesValidator = composeValidators(
  requiredValidator,
  sharesAmountValidator,
)

export const stakeholderValidator = (values) => {
  if (!values?.ultimateBeneficial && !values?.legalRepresentative)
    return {
      ultimateBeneficial: VALIDATOR_MESSAGE_ID.LEGAL_REP_OR_UBO_REQUIRED,
    }
  if (values?.ultimateBeneficial) {
    const sharesError = sharesValidator(values?.sharesPercent)
    if (sharesError) return { sharedPercent: sharesError }
  }
  return {}
}
