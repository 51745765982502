import React from 'react'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Flex, Box } from 'components/structural'
import { ReactComponent as ValidGreenIcon } from 'images/icons/valid-green.svg'

export const SubmitSuccessful = () => (
  <Box backgroundColor="global.okGreen.light" px="28px" py="28px" width="100%">
    <Flex alignItems="center" sx={{ color: 'global.okGreen.main' }}>
      <Box width="24px" height="24px" mr={5}>
        <ValidGreenIcon />
      </Box>
      <Typography variant="h5">
        <FormattedMessage id="rejection.emailSubmitted" />
      </Typography>
    </Flex>
  </Box>
)
export default SubmitSuccessful
