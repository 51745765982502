import axios from 'axios'
import { snakeCaseDeep } from 'utils'
import { tenantCode } from 'setup/tenant'
import { API_URL, API_PATH } from 'constants/api'

export type UploadDocument = {
  file: {
    data: string
    filename: string
    contentType: string
  }
  documentType: string
}

export const postRequestDocuments = async (
  token: string,
  loanApplicationId: string,
  onUploadProgress: (percentCompleted: number) => void,
  document: { document: UploadDocument },
) => {
  const url = `${API_URL}${API_PATH.LOAN_APPLICATIONS}/${loanApplicationId}/documents`

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
      'Tenant-Code': tenantCode,
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )
      if (onUploadProgress) {
        onUploadProgress(percentCompleted)
      }
    },
  }

  await axios.post(url, JSON.stringify(snakeCaseDeep(document)), config)
}
