import React, { useEffect, useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { Typography } from '@mui/material'
import { Box } from 'components/structural'
import { Form } from 'components/Form'
import {
  composeValidators,
  requiredValidator,
  zipCodeDynamicValidator,
} from 'utils/forms/validators'
import { LEGAL_FORMS, CUSTOMER_PROGRESS } from 'constants/loanApplication'
import Layout from 'components/Layout'
import {
  Address1,
  Address2,
  City,
  Country,
  FirstName,
  LastName,
  Nationality,
  Postcode,
  Salutation,
  TaxId,
} from 'components/Fields'
import { ViewHeader } from 'components/headers'
import { PersonalIcon } from 'components/icons'
import { isTeleCash } from 'setup/tenant'
import { useOwners } from 'context/Owners'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useLoanApplication } from 'context/LoanApplication'
import { ReactComponent as PersonalTeleCashIcon } from 'images/personal-telecash.svg'
import { PrivateAddressToggle } from './PrivateAddressToggle'

export const OwnerDetails = () => {
  const { createOwner, updateOwner } = useOwners()
  const { goToPrevPage, goToNextPage } = useFlowNavigation()
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const [isSameAddress, setIsSameAddress] = useState<boolean>(
    currentLoanApplication.businessDetails.legalForm ===
      LEGAL_FORMS.SOLEPROPRIETOR ||
      currentLoanApplication.businessDetails.legalForm ===
        LEGAL_FORMS.FREELANCER,
  )
  const [formValues, setFormValues] = useState<unknown | null>(null)
  const validationRef = useRef<{ validateForm?: () => void }>({})

  const owner = currentLoanApplication.merchant?.owners?.[0]

  useEffect(() => {
    if (!currentLoanApplication || !owner) return
    if (Object.keys(owner.address || {}).length) {
      setIsSameAddress(false)
    }
    const areAddressesEqual = isEqual(
      pick(owner.address, [
        'address1',
        'address2',
        'city',
        'zipCode',
        'country',
      ]),
      currentLoanApplication.businessDetails.address,
    )
    if (
      currentLoanApplication.businessDetails.legalForm ===
        LEGAL_FORMS.SOLEPROPRIETOR ||
      currentLoanApplication.businessDetails.legalForm ===
        LEGAL_FORMS.FREELANCER ||
      areAddressesEqual
    ) {
      setIsSameAddress(true)
    }
  }, [currentLoanApplication, owner])

  const handleSubmit = async (values: Record<string, string>) => {
    const { address1, address2, city, zipCode, country } = (
      isSameAddress
        ? currentLoanApplication.businessDetails.address
        : values.address
    ) as Record<string, string>

    const ownerParams = {
      ...owner,
      applicantOwner: true,
      legalRepresentative: true,
      addressAttributes: {
        address1,
        address2,
        city,
        zipCode,
        country,
      },
      email: currentLoanApplication.merchant.email,
      dateOfBirth: owner.dateOfBirth,
      firstName: values.firstName,
      lastName: values.lastName,
      nationality: values.nationality,
      taxNumber: values.taxNumber,
      title: values.title,
      professionalTitle: values.professionalTitle,
    }

    if (values.isPrivateAddress) {
      ownerParams.addressAttributes = {
        ...currentLoanApplication.businessDetails.address,
      }
    }

    if (!owner) {
      await createOwner({
        loanApplicationId: currentLoanApplication.id,
        owner: ownerParams,
      })
    } else {
      await updateOwner({
        loanApplicationId: currentLoanApplication.id,
        owner: { ...ownerParams, id: owner.id },
      })
    }
    await updateLoanApplicationById({
      loanApplicationId: currentLoanApplication.id,
      loanApplication: {
        ...currentLoanApplication,
        customerProgress: CUSTOMER_PROGRESS.OWNER_DETAILS,
      },
    })
    goToNextPage()
  }

  useEffect(() => {
    if (formValues && validationRef.current.validateForm) {
      validationRef.current.validateForm()
    }
  }, [formValues])

  if (!currentLoanApplication) return null

  const initialValues = {
    ...owner,
    address: { ...owner?.address, country: 'DEU' },
  }

  return (
    <Layout
      viewHeader={
        <ViewHeader
          icon={
            isTeleCash ? (
              <PersonalTeleCashIcon
                style={{ position: 'relative', top: '-10px' }}
              />
            ) : (
              <PersonalIcon style={{ position: 'relative', top: '-10px' }} />
            )
          }
          id="loanApplication.steps.businessOwner"
          subId="expressApplicationPersonalDetails.subheading"
        />
      }
      withProgress
    >
      <Layout.MiddleAndRight>
        <Form
          innerRef={(node: { values: unknown }) =>
            node ? setFormValues(node.values) : setFormValues(null)
          }
          onSubmit={handleSubmit}
          onBack={goToPrevPage}
          initialValues={initialValues}
          withBack
        >
          {(formProps: {
            values: Record<string, Record<string, string>>
            validateForm: () => void
          }) => {
            validationRef.current.validateForm = formProps.validateForm
            return (
              <>
                <Box mb={5}>
                  <Typography variant="h5">
                    <FormattedMessage id="businessOwner.personalInformation" />
                  </Typography>
                </Box>
                <Salutation name="title" />
                <FirstName name="firstName" />
                <LastName name="lastName" />
                <Nationality />
                <TaxId
                  mb={6}
                  name="taxNumber"
                  hint={<FormattedMessage id="fields.taxId.hint" />}
                />
                <Box mb={5}>
                  <Typography variant="h5">
                    <FormattedMessage id="businessOwner.privateAddress" />
                  </Typography>
                </Box>
                <PrivateAddressToggle
                  mb={6}
                  isSameAddress={isSameAddress}
                  setIsSameAddress={setIsSameAddress}
                />
                {!isSameAddress && (
                  <>
                    <Address1 name="address.address1" />
                    <Address2 name="address.address2" />
                    <Postcode
                      name="address.zipCode"
                      validate={composeValidators(
                        requiredValidator,
                        zipCodeDynamicValidator(
                          formProps.values.address?.country,
                        ),
                      )}
                    />
                    <City name="address.city" />
                    <Country mb={6} name="address.country" hint={undefined} />
                  </>
                )}
              </>
            )
          }}
        </Form>
      </Layout.MiddleAndRight>
    </Layout>
  )
}
