import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { stringify } from 'query-string'
import { Typography } from '@mui/material'
import { BackButton, Button } from 'components/buttons'
import { AutocompleteComponent } from 'components/Fields/AutocompleteComponent'
import { ViewHeader } from 'components/headers'
import { Layout } from 'components/Layout'
import { LoanApplicationSpinner } from 'components/spinners/LoanApplicationSpinner'
import { Box, Flex } from 'components/structural'
import { DOCUMENT_TYPES } from 'constants/documentTypes'
import { CUSTOMER_PROGRESS } from 'constants/loanApplication'
import { ROUTES } from 'constants/routes'
import { useConfiguration } from 'context/Configuration'
import { useDocuments } from 'context/Documents'
import { useLoanApplication } from 'context/LoanApplication'
import { ReactComponent as TransferIcon } from 'images/transfer.svg'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useNavigation } from 'routing/hooks/useNavigation'
import {
  getRevenueDocs,
  getAfterbuyRevenueDoc,
  getEbayRevenueDoc,
  getGambioRevenueDoc,
  getGirocardRevenueDoc,
  getManualReportRevenueDoc,
  getHoodRevenueDoc,
} from 'utils/documents'
import { noop } from 'lodash'
import activeTenant from 'setup/tenant'
import { usePlatformConnect } from './usePlatformConnect'
import { LinkedPlatformBox } from './components/LinkedPlatformBox'
import { CONNECTED_PLATFORM_STATUS, PLATFORM_NAME } from './constants'

const AFTERBUY = 'afterbuy'
const EBAY = 'ebay'
const GAMBIO = 'gambio'
const GIROCARD = 'girocard'
const MANUAL_UPLOAD = 'manualupload'
const UPLOAD_STATUS = 'Uploaded'
const MANUAL_REPORT_UPLOAD = 'manualreportupload'
const HOOD = 'hood'
const LINKED_STATUS = 'Linked'

type Platform = {
  message: string
  redirectUrl: string
  status: string
  value: string
}

export const ConnectShoppingPlatform = () => {
  const { pathname } = useLocation()
  const { goToNextPage, goToPrevPage } = useFlowNavigation()
  const { navigateTo } = useNavigation()
  const { configuration } = useConfiguration()
  const { codatIntegrationsList } = configuration?.tenant || {}
  const {
    connectedPlatforms,
    fetchConnectedPlatforms,
    isLoading,
    performPlatformLinking,
    redirectUrl,
  } = usePlatformConnect()
  const { currentLoanApplication, updateLoanApplicationById } =
    useLoanApplication()
  const { documents } = useDocuments()
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>(null)
  const [platforms, setPlatforms] = useState<Platform[]>([])

  useEffect(() => {
    void fetchConnectedPlatforms()
  }, [fetchConnectedPlatforms])

  useEffect(() => {
    let hasAfterbuyDocuments = false
    let hasEbayDocuments = false
    let hasGambioDocuments = false
    let hasGirocardDocuments = false
    let hasHoodUploadedDocuments = false
    let hasMultipleUploadedDocuments = false
    let hasManualReportUploadedDocuments = false

    const uploadedAfterbuyDocuments = documents.filter((doc) =>
      doc.documentType.includes('AfterbuyRevenueReport'),
    )
    const uploadedEbayDocuments = documents.filter((doc) =>
      doc.documentType.includes('EbayRevenueReport'),
    )
    const uploadedGambioDocuments = documents.filter((doc) =>
      doc.documentType.includes('GambioRevenueReport'),
    )
    const uploadedGirocardDocuments = documents.filter((doc) =>
      doc.documentType.includes('GirocardRevenueReport'),
    )
    const multipleUploadDocs = documents.filter((doc) =>
      doc.documentType.includes('2022'),
    )
    const uploadedManualReportDocuments = documents.filter((doc) =>
      doc.documentType.includes('ManualReportRevenueReport'),
    )
    const uploadedHoodDocuments = documents.filter((doc) =>
      doc.documentType.includes('HoodRevenueReport'),
    )
    let requiredDocuments: string[]

    if (multipleUploadDocs.length && !hasMultipleUploadedDocuments) {
      requiredDocuments = getRevenueDocs(DOCUMENT_TYPES.CODAT_REVENUE_REPORT)
      hasMultipleUploadedDocuments = requiredDocuments.every(
        (requiredDocument) =>
          multipleUploadDocs.some(
            (doc) => requiredDocument === doc.documentType,
          ),
      )
    }
    if (
      uploadedManualReportDocuments.length &&
      !hasManualReportUploadedDocuments
    ) {
      requiredDocuments = getManualReportRevenueDoc()
      hasManualReportUploadedDocuments = requiredDocuments.every(
        (requiredDocument) =>
          uploadedManualReportDocuments.some(
            (doc) => requiredDocument === doc.documentType,
          ),
      )
    }
    if (uploadedHoodDocuments.length && !hasHoodUploadedDocuments) {
      requiredDocuments = getHoodRevenueDoc()
      hasHoodUploadedDocuments = requiredDocuments.every((requiredDocument) =>
        uploadedHoodDocuments.some(
          (doc) => requiredDocument === doc.documentType,
        ),
      )
    }

    if (uploadedGambioDocuments.length && !hasGambioDocuments) {
      requiredDocuments = getGambioRevenueDoc()
      hasGambioDocuments = requiredDocuments.every((requiredDocument) =>
        uploadedGambioDocuments.some(
          (doc) => requiredDocument === doc.documentType,
        ),
      )
    }
    if (uploadedGirocardDocuments.length && !hasGirocardDocuments) {
      requiredDocuments = getGirocardRevenueDoc()
      hasGirocardDocuments = requiredDocuments.every((requiredDocument) =>
        uploadedGirocardDocuments.some(
          (doc) => requiredDocument === doc.documentType,
        ),
      )
    }
    if (uploadedEbayDocuments.length && !hasEbayDocuments) {
      requiredDocuments = getEbayRevenueDoc()
      hasEbayDocuments = requiredDocuments.every((requiredDocument) =>
        uploadedEbayDocuments.some(
          (doc) => requiredDocument === doc.documentType,
        ),
      )
    }
    if (uploadedAfterbuyDocuments.length && !hasAfterbuyDocuments) {
      requiredDocuments = getAfterbuyRevenueDoc()
      hasAfterbuyDocuments = requiredDocuments.every((requiredDocument) =>
        uploadedAfterbuyDocuments.some(
          (doc) => requiredDocument === doc.documentType,
        ),
      )
    }

    const customLinkingPlatforms =
      activeTenant?.tenantOverrides?.customLinkingPlatforms || []
    const platformsList = [
      ...(codatIntegrationsList || []).reduce<
        Array<{
          message: string
          redirectUrl: string
          status: string
          value: string
        }>
      >((result, integration) => {
        const isAfterbuy = integration === AFTERBUY
        const isEbay = integration === EBAY
        const isGambio = integration === GAMBIO
        const isGirocard = integration === GIROCARD
        const isManualUpload = integration === MANUAL_UPLOAD
        const isManualReportUpload = integration === MANUAL_REPORT_UPLOAD
        const isHood = integration === HOOD

        let platformInfo: {
          value: string
          message: string
          status: string | null
          redirectUrl: string
        }
        if (isAfterbuy) {
          platformInfo = {
            value: AFTERBUY,
            message: PLATFORM_NAME[AFTERBUY],
            status: hasAfterbuyDocuments ? UPLOAD_STATUS : null,
            redirectUrl: '',
          }
        } else if (isEbay) {
          platformInfo = {
            value: EBAY,
            message: PLATFORM_NAME[EBAY],
            status: hasEbayDocuments ? UPLOAD_STATUS : null,
            redirectUrl: '',
          }
        } else if (isGambio) {
          platformInfo = {
            value: GAMBIO,
            message: PLATFORM_NAME[GAMBIO],
            status: hasGambioDocuments ? UPLOAD_STATUS : null,
            redirectUrl: '',
          }
        } else if (isGirocard) {
          platformInfo = {
            value: GIROCARD,
            message: PLATFORM_NAME[GIROCARD],
            status: hasGirocardDocuments ? UPLOAD_STATUS : null,
            redirectUrl: '',
          }
        } else if (isManualReportUpload) {
          platformInfo = {
            value: MANUAL_REPORT_UPLOAD,
            message: PLATFORM_NAME[MANUAL_REPORT_UPLOAD],
            status: hasManualReportUploadedDocuments ? UPLOAD_STATUS : null,
            redirectUrl: '',
          }
        } else if (isHood) {
          platformInfo = {
            value: HOOD,
            message: PLATFORM_NAME[HOOD],
            status: hasHoodUploadedDocuments ? UPLOAD_STATUS : null,
            redirectUrl: '',
          }
        } else if (isManualUpload) {
          platformInfo = {
            value: MANUAL_UPLOAD,
            message: PLATFORM_NAME[MANUAL_UPLOAD],
            status: hasMultipleUploadedDocuments ? UPLOAD_STATUS : null,
            redirectUrl: '',
          }
        } else {
          platformInfo = {
            value: integration,
            message: PLATFORM_NAME[integration],
            status: connectedPlatforms?.[integration]?.status || null,
            redirectUrl: connectedPlatforms?.[integration]?.redirectUrl || null,
          }
        }
        result.push(platformInfo)
        return result
      }, []),
      ...customLinkingPlatforms,
    ].sort((a, b) => a.status?.localeCompare(b?.status))

    setPlatforms(platformsList)
  }, [codatIntegrationsList, documents, connectedPlatforms])
  const nonLinkedPlatforms = useMemo(
    (): Platform[] =>
      (platforms ?? []).filter(
        ({ status }) => status !== LINKED_STATUS && status !== UPLOAD_STATUS,
      ),

    [platforms],
  )
  const [hasConnectedPlatform, setHasConnectedPlatform] = useState(false)

  useEffect(() => {
    if (nonLinkedPlatforms?.length < platforms?.length) {
      setHasConnectedPlatform(true)
    }
  }, [nonLinkedPlatforms, platforms])
  useEffect(() => {
    if (redirectUrl) {
      window.location.href = `${redirectUrl}?${stringify({
        callback: `${window.location.origin}${pathname}`,
      })}`
    }
  }, [pathname, redirectUrl])

  const handleSelectPlatform = (_, platform: Platform) => {
    setSelectedPlatform(platform)
  }

  const handlePlatformConnection = async () => {
    if (!selectedPlatform) {
      updateLoanApplicationById({
        loanApplicationId: currentLoanApplication.id,
        loanApplication: {
          customerProgress: CUSTOMER_PROGRESS.CONNECT_SHOPPING_PLATFORM,
        },
      }).catch(noop)
      goToNextPage()
      return
    }
    if (selectedPlatform.value === MANUAL_REPORT_UPLOAD) {
      navigateTo(ROUTES.MANUAL_REPORT_UPLOAD)
      return
    }
    if (selectedPlatform.value === MANUAL_UPLOAD) {
      navigateTo(ROUTES.CONNECT_SHOPPING_PLATFORM_DOCUMENTS)
      return
    }
    if (selectedPlatform.value === HOOD) {
      navigateTo(ROUTES.HOOD)
      return
    }
    if (selectedPlatform.value === AFTERBUY) {
      navigateTo(ROUTES.AFTERBUY)
      return
    }
    if (selectedPlatform.value === EBAY) {
      navigateTo(ROUTES.EBAY)
      return
    }
    if (selectedPlatform.value === GAMBIO) {
      navigateTo(ROUTES.GAMBIO)
      return
    }
    if (selectedPlatform.value === GIROCARD) {
      navigateTo(ROUTES.GIROCARD)
      return
    }
    // proceed to next step if the selected platform is a specified in the tenantoverrides
    if (
      activeTenant.tenantOverrides?.customLinkingPlatforms.find(
        (p) => p.value === selectedPlatform.value,
      )
    ) {
      setHasConnectedPlatform(true)
      goToNextPage()
      return
    }

    await performPlatformLinking(selectedPlatform.value)
  }

  const handleRetryConnection = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const platformName = event.currentTarget.name.toLowerCase()
    await performPlatformLinking(platformName)
  }

  const handleGoBack = () => {
    if (currentLoanApplication?.businessDetails?.crefoId) {
      navigateTo(ROUTES.COMPANY_SEARCH)
    } else {
      goToPrevPage()
    }
  }

  if (!currentLoanApplication) return null

  return (
    <Layout
      viewHeader={
        <ViewHeader
          icon={<TransferIcon style={{ position: 'relative', top: '-10px' }} />}
          id="loanApplication.steps.connectShoppingPlatform"
        />
      }
      withProgress
    >
      <Layout.MiddleAndRight>
        <Box mb={4} style={{ whiteSpace: 'pre-line' }} width={1.3 / 2}>
          <Typography variant="h5">
            <FormattedMessage id="connectShoppingPlatform.heading" />
          </Typography>
        </Box>
        <Flex
          width={1.3 / 2}
          flexDirection="column"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
          sx={{ border: (theme) => theme.borders.primary }}
          mb={4}
        >
          {hasConnectedPlatform && (
            <Flex
              flexDirection="row"
              bgcolor="header.bg.main"
              flex="1 1 auto"
              py={4}
              pl={3}
              data-testid="linked-platform-box-header"
            >
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                <FormattedMessage id="connectShoppingPlatform.linked.header" />
              </Typography>
            </Flex>
          )}
          {!platforms.length && <LoanApplicationSpinner />}
          {hasConnectedPlatform &&
            platforms.map(
              (platform) =>
                (platform.status === CONNECTED_PLATFORM_STATUS.LINKED ||
                  platform.status === CONNECTED_PLATFORM_STATUS.UPLOADED ||
                  platform.status ===
                    CONNECTED_PLATFORM_STATUS.PENDING_AUTH) && (
                  <LinkedPlatformBox
                    isError={
                      platform.status === CONNECTED_PLATFORM_STATUS.PENDING_AUTH
                    }
                    companyName={currentLoanApplication.businessDetails.name}
                    handleRetry={handleRetryConnection}
                    key={platform.value}
                    name={platform.message}
                    status={platform.status}
                  />
                ),
            )}
        </Flex>
        <Flex mb={4}>
          <AutocompleteComponent
            data-cy="connect-shopping-platform"
            data-testid="platform-select-field"
            field={{
              name: selectedPlatform?.value,
              value: selectedPlatform?.value,
            }}
            form={{
              touched: { [selectedPlatform?.value]: false },
              errors: null,
              submitCount: 0,
              setFieldValue: () => undefined,
              setTouched: () => undefined,
            }}
            hint={<FormattedMessage id="fields.platform.hint" />}
            name="platform"
            id="platform"
            label={<FormattedMessage id="fields.platform" />}
            onChange={handleSelectPlatform}
            options={nonLinkedPlatforms}
          />
        </Flex>
      </Layout.MiddleAndRight>
      <Layout.Middle>
        <Flex>
          <BackButton onClick={handleGoBack} />
          <Button
            withArrow
            data-testid="nextButton"
            disabled={(!hasConnectedPlatform && !selectedPlatform) || isLoading}
            loading={isLoading}
            onClick={handlePlatformConnection}
          >
            <FormattedMessage id="form.submit" />
          </Button>
        </Flex>
      </Layout.Middle>
    </Layout>
  )
}
