import React from 'react'
import { Box } from 'components/structural'

import { ReactComponent as Trash } from 'images/icons/trash.svg'
import { ReactComponent as Ok } from 'images/icons/ok.svg'
import { ReactComponent as Upload } from 'images/icons/upload.svg'
import { ReactComponent as Attachment } from 'images/icons/attachment.svg'

export const TrashIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Trash />
  </Box>
)

export const OkIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Ok />
  </Box>
)

export const UploadIcon = () => (
  <Box
    sx={{
      svg: {
        g: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Upload />
  </Box>
)

export const AttachmentIcon = () => (
  <Box
    sx={{
      svg: {
        path: {
          stroke: (theme) => theme.palette.global.icoA.main,
        },
      },
    }}
  >
    <Attachment />
  </Box>
)
