import { useState, useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { LOAN_APPLICATION_REJECTION_CODE } from 'constants/loanApplication'
import { ROUTES } from 'constants/routes'
import { useLoanApplication } from 'context/LoanApplication'
import { useError } from 'context/Error'
import { useLanguage } from 'context/Language'
import { BANK_LINKING_STEP } from '../constants'
import { useBankLinkingApiRequests } from './useBankLinkingApiRequests'

export function useBankLinking() {
  const history = useHistory()
  const { currentLoanApplication, fetchLoanApplicationById } =
    useLoanApplication()
  const [linkingStep, setLinkingStep] = useState(BANK_LINKING_STEP.BANK)
  const [sessionKey, setSessionKey] = useState(null)
  const [scoringProcessId, setScoringProcessId] = useState(null)
  const [isFormRendered, setIsFormRendered] = useState(false)
  const [isStartBankLinking, setIsStartBankLinking] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const xs2aFormRef = useRef(null)
  const {
    postRequestScoringProcess,
    patchRequestScoringProcess,
    getRequestLoanApplicationById,
    patchRequestSetExpressFlow,
    pollLoanApplication,
  } = useBankLinkingApiRequests()
  const { handleApplicationError } = useError()

  const startPolling = useCallback(
    async (loanApplicationId) => {
      try {
        const { loanApplication } = await pollLoanApplication(loanApplicationId)
        if (!loanApplication) throw new Error('Error polling loan application ')
        if (
          loanApplication.rejectionCode ===
          LOAN_APPLICATION_REJECTION_CODE.ELIGIBILITY
        ) {
          history.push(ROUTES.LOAN_REJECTION_CREDIT_SCORE)
          return
        }
        patchRequestSetExpressFlow(loanApplicationId)
        fetchLoanApplicationById(loanApplicationId)
        setLinkingStep(BANK_LINKING_STEP.CONNECTED)
      } catch (error) {
        setLinkingStep(BANK_LINKING_STEP.ERROR)
      }
    },
    [
      fetchLoanApplicationById,
      history,
      patchRequestSetExpressFlow,
      pollLoanApplication,
    ],
  )
  const { locale } = useLanguage()
  const initXs2aConnection = useCallback(
    (id) => {
      if (!window.xs2a) return
      let isLoggedIn = false

      window.xs2a.error((errorCode, messages, recoverable) => {
        // eslint-disable-next-line no-console
        console.error('** initXs2aConnection -- error', {
          errorCode,
          messages,
          recoverable,
        })
      })
      window.xs2a.abort(() => {
        setLinkingStep(BANK_LINKING_STEP.ERROR)
      })
      window.xs2a.bank(() => {
        setLinkingStep(BANK_LINKING_STEP.BANK)
      })
      window.xs2a.login(() => {
        setLinkingStep(BANK_LINKING_STEP.LOGIN)
        isLoggedIn = true
      })
      window.xs2a.finish(async () => {
        if (!isLoggedIn) return
        setLinkingStep(BANK_LINKING_STEP.FINISH)
        try {
          await patchRequestScoringProcess(id, BANK_LINKING_STEP.AUTHORIZED)
          const { loanApplication } = await getRequestLoanApplicationById(
            currentLoanApplication.id,
          )
          startPolling(loanApplication.id)
        } catch (error) {
          setLinkingStep(BANK_LINKING_STEP.ERROR)
        }
      })
      window.xs2a.render(() => {
        setIsLoading(false)
      })
      window.xs2a.init()
      window.xs2a.lang(locale)
    },
    [
      currentLoanApplication,
      getRequestLoanApplicationById,
      patchRequestScoringProcess,
      startPolling,
      locale,
    ],
  )

  const initializeBankLinking = useCallback(async () => {
    try {
      setIsLoading(true)
      setLinkingStep(BANK_LINKING_STEP.BANK)
      setSessionKey(null)
      const { scoringProcess } = await postRequestScoringProcess(
        currentLoanApplication.id,
      )
      await fetchLoanApplicationById(currentLoanApplication.id)
      setSessionKey(scoringProcess.wizardSessionKey)
      setScoringProcessId(scoringProcess.id)
      setIsStartBankLinking(true)
    } catch (error) {
      handleApplicationError(error)
      setLinkingStep(BANK_LINKING_STEP.ERROR)
    }
  }, [
    currentLoanApplication,
    fetchLoanApplicationById,
    handleApplicationError,
    postRequestScoringProcess,
  ])

  const resetBankLinking = useCallback(async () => {
    await postRequestScoringProcess(currentLoanApplication.id)
    await fetchLoanApplicationById(currentLoanApplication.id)
  }, [
    currentLoanApplication.id,
    fetchLoanApplicationById,
    postRequestScoringProcess,
  ])

  useEffect(() => {
    // Check whether form is rendered whenever there is an update to the loan application
    setIsFormRendered(!!xs2aFormRef.current)
  }, [currentLoanApplication])

  useEffect(() => {
    if (isFormRendered && isStartBankLinking && scoringProcessId) {
      initXs2aConnection(scoringProcessId)
      setIsStartBankLinking(false)
    }
  }, [initXs2aConnection, isFormRendered, isStartBankLinking, scoringProcessId])

  return {
    initializeBankLinking,
    isLoading,
    linkingStep,
    resetBankLinking,
    sessionKey,
    xs2aFormRef,
  }
}
