import React from 'react'
import { Box, Paper, Theme, Typography, useMediaQuery } from '@mui/material'
import { Flex } from 'components/structural'
import Layout from 'components/Layout'
import { ButtonBase } from 'components/buttons'
import { ReactComponent as BanxyLogo } from 'images/logos/banxy-small.svg'
import { ReactComponent as BanxwareLogo } from 'images/logos/banxware-no-r-small.svg'
import { ReactComponent as ArrowIcon } from 'images/icons/arrow-right.svg'
import { useNavigation } from 'routing/hooks/useNavigation'
import { ROUTES } from 'constants/routes'
import { FormattedMessage } from 'react-intl'
import { isBanxy } from 'setup/tenant'

export function Chooser() {
  const { navigateTo } = useNavigation()
  const hidden = useMediaQuery((theme: Theme) =>
    // @ts-expect-error Argument of type '"desktop"' is not assignable to parameter of type 'number | Breakpoint'
    theme.breakpoints.down('desktop'),
  )

  const handleNavigateToLogin = () => {
    navigateTo(ROUTES.LOGIN)
  }

  const utmSource = isBanxy
    ? 'cmcabanxy&utm_medium=forkpage&utm_campaign=cmcatoelsbanxy'
    : 'cmcarestaurant&utm_medium=forkpage&utm_campaign=cmcatoelsrestaurant'

  return (
    <Layout
      sx={{ backgroundColor: '#f5f5f5' }}
      topHeaderProps={{
        withLogin: false,
        sx: {
          boxShadow: 'none',
        },
      }}
    >
      <Layout.FullSpan>
        <Box
          sx={{
            p: [0, 0, 8],
            borderRadius: [0, 0, '12px'],
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            backgroundColor: ['transparent', 'transparent', 'background.paper'],
          }}
        >
          <Typography variant="h3" color="text.primary" textAlign="center">
            <FormattedMessage id="chooser.title" />
          </Typography>
          {!hidden && (
            <Box mb={6}>
              {isBanxy ? (
                <Flex justifyContent="center" alignItems="center" gap={4}>
                  <BanxyLogo />
                  <ArrowIcon height={24} />
                  <BanxwareLogo />
                </Flex>
              ) : (
                <Typography fontSize={24} textAlign="center">
                  <FormattedMessage id="chooser.subtitle" />
                </Typography>
              )}
            </Box>
          )}
          <Flex
            sx={{
              flexDirection: ['column', 'column', 'row'],
              justifyContent: ['flex-start', 'flex-start', 'space-between'],
              alignItems: ['center', 'center', 'flex-start'],
              gap: 5,
            }}
          >
            <Paper
              sx={{
                borderRadius: '12px',
                display: 'flex',
                maxWidth: '397px',
                padding: '40px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '24px',
                boxShadow: '0px 4px 77px 0px rgba(212, 212, 212, 0.22)',
              }}
            >
              <Typography variant="h4" color="text.primary">
                <FormattedMessage id="chooser.banxy.title" />
              </Typography>
              <Typography>
                <FormattedMessage id="chooser.banxy.description" />
              </Typography>
              <ButtonBase
                sx={{
                  width: '100%',
                  borderRadius: '112px',
                  padding: '11px 32px 12px 32px',
                  height: 'auto',
                }}
                onClick={handleNavigateToLogin}
              >
                <FormattedMessage id="chooser.banxy.cta" />
              </ButtonBase>
            </Paper>
            <Paper
              sx={{
                borderRadius: '12px',
                display: 'flex',
                maxWidth: '397px',
                padding: '40px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '24px',
                boxShadow: '0px 4px 77px 0px rgba(212, 212, 212, 0.22)',
              }}
            >
              <Typography variant="h4" color="text.primary">
                <FormattedMessage id="chooser.restaurant.title" />
              </Typography>
              <Typography>
                <FormattedMessage id="chooser.restaurant.description" />
              </Typography>

              <ButtonBase
                component="a"
                href={`https://portal.banxware.com/api/partner/BXW01GW53E7J8PD0PEBV69BS6YV54?utm_source=${utmSource}`}
                target="_blank"
                rel="noreferrer"
                sx={{
                  width: '100%',
                  borderRadius: '112px',
                  padding: '11px 32px 12px 32px',
                  height: 'auto',
                  color: 'text.primary',
                  backgroundColor: '#A8DBFF',
                  '&:hover': {
                    backgroundColor: '#80caff',
                  },
                }}
              >
                <FormattedMessage id="chooser.restaurant.cta" />
              </ButtonBase>
            </Paper>
          </Flex>
        </Box>
      </Layout.FullSpan>
    </Layout>
  )
}
