import React from 'react'
import { Button } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    height: 'auto',
    padding: 0,
    fontSize: 0,
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
})

export const ButtonRaw = ({ children, ...restProps }) => {
  const classes = useStyles()
  return (
    <Button {...{ classes }} {...restProps}>
      {children}
    </Button>
  )
}
