import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { Box } from 'components/structural'
import { Form } from 'components/Form'
import { ReactComponent as QuestionsIcon } from 'images/questions.svg'
import Layout from 'components/Layout'
import { ViewHeader } from 'components/headers'
import { GlobalSpinner } from 'components/spinners'
import { OptinMerchantAccount, OptinMerchantTerms } from 'components/Fields'
import { useFlowNavigation } from 'routing/hooks/useFlowNavigation'
import { useLoanApplication } from '../../../context/LoanApplication'

export const OptinMerchant = () => {
  const {
    currentLoanApplication,
    fetchLoanApplicationById,
    isLoading,
    updateCurrentMerchant,
  } = useLoanApplication()
  const { goToNextPage, goToPrevPage } = useFlowNavigation()

  const handleSubmit = async (values) => {
    await updateCurrentMerchant(values)
    await fetchLoanApplicationById(currentLoanApplication.id)
    goToNextPage()
  }

  if (isLoading) return <GlobalSpinner />
  const { optinAccount, optinTax, optinTerms } =
    currentLoanApplication?.merchant || {}

  return (
    <Layout
      viewHeader={
        <ViewHeader id="optinMerchant.header" icon={<QuestionsIcon />} />
      }
      withProgress
    >
      <Layout.MiddleAndRight>
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            optinAccount,
            optinTax,
            optinTerms,
          }}
          validateOnMount
          onBack={goToPrevPage}
          withBack
        >
          <Box mb={5}>
            <Typography variant="h5">
              <FormattedMessage id="signUp.termsHeader" />
            </Typography>
          </Box>
          <OptinMerchantAccount />
          <OptinMerchantTerms />
        </Form>
      </Layout.MiddleAndRight>
    </Layout>
  )
}

export default OptinMerchant
