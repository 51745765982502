import React from 'react'
import { Field as FormikField } from 'formik'
import { AutocompleteComponent } from './AutocompleteComponent'

type SelectFieldProps = {
  hint?: React.ReactNode
  inputWrapperSx?: Record<string, unknown>
  label?: React.ReactNode
  name?: string
  options?: Record<string, string>[]
  validate?: () => void
}

export const SelectField = React.memo(
  ({
    hint,
    inputWrapperSx,
    label,
    name,
    options,
    validate,
    ...rest
  }: SelectFieldProps) => (
    <FormikField
      component={AutocompleteComponent}
      hint={hint}
      inputWrapperSx={inputWrapperSx}
      label={label}
      name={name}
      options={options}
      validate={validate}
      id={name}
      {...rest}
    />
  ),
)
